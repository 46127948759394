import { useEffect, useState } from "react";
import VerifyDetailsView from "../../../../Models/Verify/Models/VerifyAuditDetailsView";
import VerifyAuditTab from "../Model/VerifyAuditTab";
import getVerifyAuditDetails from "../../../../http/verify/getVerifyAuditDetails";
import getAuditFavouriteValue from "../../http/getAuditFavouriteValue";

export default (id: string) => {

    const [isLoading, setLoading] = useState(true);
    const [mainDetails, setDetails] = useState<VerifyDetailsView| undefined>(undefined);


    const refresh = () => {
        setLoading(true);
        Promise.allSettled([
          getAuditFavouriteValue(id),
          getVerifyAuditDetails(id).then(({ data }) => setDetails(data)),
        
        ]).finally(() => setLoading(false));
      };
      useEffect(() => {
        refresh();
      }, [id]);
      return {
        isLoading,
        mainDetails,
        refresh,

      };

};