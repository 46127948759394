import React from "react";
import {
  faBan,
  faBars,
  faBox,
  faBoxOpen,
  faCalendarDay,
  faCheck,
  faClipboardList,
  faDownload,
  faFile,
  faPen,
  faPlusCircle,
  faSave,
  faShareAlt,
  faStar,
  faStarAndCrescent,
  faStarHalf,
  faStarOfDavid,
  faStarOfLife,
  faTimes,
  faUndo,
  faUserPlus,
  faUsers,
  faFilePdf,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const IconType = {
  ADD: faPlusCircle,
  SHARE: faShareAlt,
  ARCHIVE: faBox,
  UNARCHIVE: faBoxOpen,
  EDIT: faPen,
  CHANGE_LOG: faClipboardList,
  SCHEDULE: faCalendarDay,
  SUCCESS: faCheck,
  DOWNLOAD: faDownload,
  USER_ADD: faUserPlus,
  USERS: faUsers,
  SUSPEND: faBan,
  UNDO: faUndo,
  SAVE: faSave,
  CLOSE: faTimes,
  MENU: faBars,
  FAVOURTITE:faStar,
  UNFAVOURITE:faStar,
  DownloadPDF:faFilePdf
};

export interface IconProps {
  readonly type: IconDefinition;
  readonly className?: string;
}

const Icon = ({ type, className }: IconProps) => (
  <FontAwesomeIcon icon={type} className={className} />
);

export default Icon;
