import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { create } from "../../../../components/Paths/paths";
import { UserOrganisation } from "../../models/UserDetails";

export interface PostUserBody {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly primaryOrganisation: UserOrganisation;
  readonly secondaryOrganisations: UserOrganisation[];
  readonly phoneNumber: string;
  readonly role: string;
  overrideWarning: boolean;
}

export default (body: PostUserBody) =>
  axiosApi.post(`${BackendServices.USER_SERVICE.USER}${create}`, body, {
    noGlobalError: true,
  });
