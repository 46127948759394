import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { RecallUrls } from "..";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import History from "../../../components/Common/History";
import DetailsPage from "../../../components/DetailsPage";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import { archive } from "../../../components/Paths/paths";
import Entity from "../../../Models/APIGetData/Entity";
import RecallDetails from "./components/Details";
import useGetRecall from "./util/useGetRecall";

const RecallDetailsPage = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<Entity>) => {
  const { mainDetails, isLoading, refresh } = useGetRecall(id);

  let RecallDetailsMenuItems = [DetailsPageMenuItem.CREATE];

  if (mainDetails?.visuals.isAbleToEdit) {
    RecallDetailsMenuItems = RecallDetailsMenuItems.concat([
      DetailsPageMenuItem.ARCHIVE,
      DetailsPageMenuItem.CHANGE_LOG,
    ]);
  }

  const archiveRecall = () =>
    axiosApi
      .put(`${BackendServices.RECALL_SERVICE.RECALL}/${id}${archive}`)
      .then(() => History.push(RecallUrls.searchTable));

  return (
    <DetailsPage
      id={id}
      title={mainDetails?.recallCategory?.name}
      className="audit-detail"
      detailsPageMenuItems={RecallDetailsMenuItems}
      detailsPageMenuConfig={{}}
      isLoading={isLoading}
      archiveConfig={{
        entityName: "recall",
        onClick: archiveRecall,
        isArchived: false,
      }}
    >
      <div className="audit-details__main">
        {mainDetails && (
          <div className="audit-details__main__details">
            <RecallDetails mainDetails={mainDetails} refresh={refresh} />
          </div>
        )}
      </div>
    </DetailsPage>
  );
};
export default RecallDetailsPage;
