import { Action, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import Country from "../../../Models/common/Country";
import sortNameAlphanumerical from "../../../util/sorting/sortNameAlphanumerical";

export const ACTION_GET_COUNTRIES = "ACTION_GET_COUNTRIES";

export interface ActionGetCountries {
  readonly type: typeof ACTION_GET_COUNTRIES;
  readonly payload: Country[];
}

export default (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  axiosApi
    .get<Country[]>(`${BackendServices.PUBLIC_SERVICE}/countries`)
    .then(({ data: payload }) => {
      const action: ActionGetCountries = {
        type: ACTION_GET_COUNTRIES,
        payload: payload.sort(sortNameAlphanumerical),
      };
      dispatch(action);
    })
    .catch(() => {
      dispatch({ type: ACTION_GET_COUNTRIES, payload: [] });
    });
};
