import { Localization } from "material-table";
const userLocalization: Localization = {
    header: {
      actions: "",
    },
    body:{emptyDataSourceMessage:false}
  };
  
  
  export default userLocalization;
  