import { useEffect, useState } from "react";
import axiosApi from "../../../../components/Api/Axios";
import {
  API_REPORT,
  synchroniseDetailsLocation,
  synchroniseDetailsSiteName,
} from "../../../../components/Paths/paths";
import AuditDetailsView from "../../../../Models/Audit/AuditDetailsView";
import AuditTabbedDetailsSource from "../models/AuditTabbedDetailsSource";
import BackendServices from "../../../../components/Api/BackendService";
import parseGenericData from "./parseGenericData";
import AuditTab from "../models/AuditTabs";
import getAuditDetails from "../../../../http/audit/getAuditDetails";

export default (id: string) => {
  const [isLoading, setLoading] = useState(true);
  const [mainDetails, setDetails] = useState<AuditDetailsView>();
  const [tabbedDetails, setTabbedDetails] = useState<AuditTab[]>([]);

  const handleAddressSync = () => {
    axiosApi
      .put(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${synchroniseDetailsLocation}/${id}`
      )
      .then(refresh)
      .catch((error) => console.log(error));
  };

  const handleSiteNameSync = () => {
    axiosApi
      .put(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${synchroniseDetailsSiteName}/${id}`
      )
      .then(refresh)
      .catch((error) => console.log(error));
  };

  const refresh = () => {
    setLoading(true);
    Promise.allSettled([
      getAuditDetails(id).then(({ data }) => setDetails(data)),
      axiosApi
        .get<AuditTabbedDetailsSource>(
          `${BackendServices.AUDIT_SERVICE.AUDIT}/${id}${API_REPORT}`
        )
        .then(parseGenericData)
        .then((data) => setTabbedDetails(data)),
    ]).finally(() => setLoading(false));
  };

  useEffect(() => {
    refresh();
  }, [id]);
  return {
    isLoading,
    mainDetails,
    genericTabDetails: tabbedDetails,
    refresh,
    handleAddressSync,
    handleSiteNameSync,
  };
};
