import Column from "../../../../../components/DataTable/models/Column";
import AuditorRow from "../../core/CoreAuditorSearchTable/models/AuditorRow";
import coreAuditorColumns from "../../core/CoreAuditorSearchTable/utils/auditorColumns";

const pendingAuditorColumns: Column<AuditorRow>[] = [
  ...coreAuditorColumns,
  {
    title: "Submitted",
    field: "submittedDate",
    type: "date",
  },
  {
    title: "Last edited",
    field: "lastEditedDate",
    type: "date",
  },
  { title: "Status", field: "status" },
];
export default pendingAuditorColumns;
