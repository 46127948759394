import React from "react";
import Column from "../../../../DataTable/models/Column";
import SiteRow from "../models/SiteRow";
import { LinkText } from "../../../../Common/hyperlinks";
import { SiteUrls } from "../../../../../views/SiteManagement";

const siteColumns: Column<SiteRow>[] = [
  {
    title: "Site Code",
    field: "siteCode",
  },
  {
    title: "Site",
    field: "siteName",
    render: (rowData: SiteRow) => (
      <LinkText
        link={SiteUrls.details(rowData.siteCode)}
        text={rowData.siteName}
      />
    ),
  },
  {
    title: "Country",
    field: "country",
    filtering: true,
  },
  {
    title: "City",
    field: "city",
  },
  {
    title: "Owning Company",
    field: "owningCompany",
    filtering: true,
  },
  {
    title: "Status",
    field: "status",
    sorting: true,
  },
  {
    title: "Shared (Y/N)",
    field: "shared",
    sorting: false,
  },
];

export default siteColumns;
