import React, { useState } from "react";
import ContentPage from "../../../components/ContentPage";
import TabsBar, { TabPanel } from "../../../components/Common/Tab";
import MainAuditorSearchTable from "./MainAuditorSearchTable";
import PendingAuditorSearchTable from "./PendingAuditorSearchTable";
import AuditorSearchTableFilter from "./Filters";
import styles from "./AuditorSearchTable.module.scss";
import Option from "../../../Models/forms/Option";

const initialValue: SelectedFilters = {
  Standard: [],
  Category: [],
  CertificationBody: [],
  Status: [],
};

export interface SelectedFilters {
  readonly Standard: Option[];
  readonly Category: Option[];
  readonly CertificationBody: Option[];
  readonly Status: Option[];
}

const AuditorSearchTables = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
    initialValue
  );
  return (
    <ContentPage title="Auditors">
      <TabsBar
        centered={false}
        labels={["AUDITORS", "PENDING VALIDATION"]}
        value={currentTab}
        onChange={(val: number) => setCurrentTab(val)}
      />
      <div className={styles.searchTableContent}>
        <AuditorSearchTableFilter
          reset={() => setSelectedFilters(initialValue)}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          isPendingTable={currentTab === 1}
        />
        <TabPanel index={0} value={currentTab}>
          <MainAuditorSearchTable selectedFilters={selectedFilters} />
        </TabPanel>
        <TabPanel index={1} value={currentTab}>
          <PendingAuditorSearchTable selectedFilters={selectedFilters} />
        </TabPanel>
      </div>
    </ContentPage>
  );
};

export default AuditorSearchTables;
