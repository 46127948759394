import React from "react";
import { AxiosResponse } from "axios";
import DebouncedSearchSelector from "../DebouncedSearchSelector";
import Option from "../../Models/forms/Option";
import isNumber from "../../util/number/isNumber";
import getCompanyDetails from "../../http/companies/getCompanyDetails";
import getSiteDetails from "../../http/site/getSiteDetails";

export type CompanySiteOptionType = "Company" | "Site";

interface CompanySiteSelectorProps {
  readonly entities?: CompanySiteOptionType[];
  readonly value: Option<CompanySiteOptionType> | null;
  readonly onChange: (value: Option<CompanySiteOptionType> | null) => void;
  readonly label?: string;
  readonly required?: boolean;
}

interface OptionSource {
  readonly name: string;
  readonly id: number;
}

const mapResponse = <T extends OptionSource>(
  data: T,
  type: CompanySiteOptionType
): Option<CompanySiteOptionType>[] =>
  data ? [{ type, value: data.id, label: data.name }] : [];

const CompanySiteSelector = ({
  entities = ["Company", "Site"],
  onChange,
  value,
  label = "Audit owner ID",
  required,
}: CompanySiteSelectorProps) => {
  const fetchType = <T extends OptionSource>(
    type: CompanySiteOptionType,
    fetchFunction: () => Promise<AxiosResponse<T>>
  ): Promise<Option<CompanySiteOptionType>[]> =>
    entities.includes(type)
      ? fetchFunction()
          .then(({ data }) => mapResponse(data, type))
          .catch(() => Promise.resolve([]))
      : Promise.resolve([]);

  const getCompanyAndSiteWithId: (
    input: string
  ) => Promise<Option<CompanySiteOptionType>[]> = (input) =>
    Promise.all([
      fetchType("Company", () => getCompanyDetails(input)),
      fetchType("Site", () => getSiteDetails(input)),
    ]).then((data) => data.flat());

  return (
    <DebouncedSearchSelector
      label={label}
      getOptions={getCompanyAndSiteWithId}
      onSelect={onChange}
      value={value}
      validateInput={(input) =>
        input.length === 0 || isNumber(input) || input === value?.label
      }
      required={required}
      groupBy={(option) => option.type ?? "Company"}
    />
  );
};

export default CompanySiteSelector;
