import React from "react";
import FilterComponentProps from "../../../../components/FilterGroup/components/FilterComponentProps";
import MultipleSelectDropdown from "../../../../components/MultipleSelectDropdown";
import styles from "../AuditorSearchTable.module.scss";

const CertificationBodyFilter = ({
  onChange,
  options,
  selected,
}: FilterComponentProps) => (
  <MultipleSelectDropdown
    className={styles.certificationBodyFilter}
    options={options}
    value={selected}
    onChange={onChange}
  />
);

export default CertificationBodyFilter;
