import { useEffect, useState } from "react";

export default <T extends {}>(fetch?: () => Promise<T[]>) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<T[]>([]);

  const refresh = () => {
    if (fetch) {
      setLoading(true);
      fetch()
        .then((response) => setData(response))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return { isLoading, data, refresh };
};
