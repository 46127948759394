import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import SiteNew, { SiteFormMode } from "../SiteNew";
import Entity from "../../../Models/APIGetData/Entity";
import CreateSiteRedirectState from "../../../Models/CompanyProps/CreateSiteRedirectState";
import Site from "../../../Models/site/Site";
import getSiteDetails from "../../../http/site/getSiteDetails";
import { SpinnerCenter } from "../../../components/Spinner";
import putSite from "./http/putSite";

const SiteEdit = ({
  match,
  location,
  history,
}: RouteComponentProps<Entity, {}, CreateSiteRedirectState>) => {
  const { id } = match.params;
  const [initialState, setInitialState] = useState<Site | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getSiteDetails(id)
      .then(({ data }) => setInitialState(data))
      .finally(() => setLoading(false));
  }, []);

  if (isLoading) {
    return <SpinnerCenter />;
  }

  return (
    <SiteNew
      history={history}
      location={location}
      match={match}
      initialState={initialState}
      mode={SiteFormMode.EDIT}
      onSubmit={putSite}
    />
  );
};

export default SiteEdit;
