import React from "react";
import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import { SelectValidator } from "react-material-ui-form-validator";
import Heading from "../Heading";
import Option from "../../../Models/forms/Option";

export function Select(
  props: TextFieldProps & { titleText?: string; readonly options?: Option[] }
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { titleText, variant, children, options, ...rest } = props;

  return (
    <div>
      {titleText && <Heading text={titleText} />}
      <TextField select variant="outlined" className="input select" {...rest}>
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {children}
      </TextField>
    </div>
  );
}

export class SelectValidation extends SelectValidator {
  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { titleText, variant, ...rest } = this.props;

    return (
      <div>
        {titleText && <Heading text={titleText} />}
        <SelectValidator
          className="input select"
          variant="outlined"
          {...rest}
        />
      </div>
    );
  }
}
