import React from "react";
import Location from "../../Models/CommonProps/Location";
import CountryDropdown from "../../components/Common/Input/CountryDropdown";
import { Field } from "../../components/CreatePage";
import { isCompanyUser } from "../../Authentication/Auth";

export default (
  locationState: Location,
  setLocation: (value: ((prevState: Location) => Location) | Location) => void
): Field[] => [
  {
    name: "addressLine1",
    value: locationState.addressLine1,
    setTextValue: (value) =>
      setLocation((prev) => ({ ...prev, addressLine1: value })),
    label: "Address line 1",
    required: true,
    disabled: isCompanyUser(),
  },
  {
    name: "addressLine2",
    value: locationState.addressLine2,
    setTextValue: (value) =>
      setLocation((prev) => ({ ...prev, addressLine2: value })),
    label: "Address line 2",
    disabled: isCompanyUser(),
  },
  {
    name: "addressLine3",
    value: locationState.addressLine3,
    setTextValue: (value) =>
      setLocation((prev) => ({ ...prev, addressLine3: value })),
    label: "Address line 3",
    disabled: isCompanyUser(),
  },
  {
    name: "city",
    label: "City",
    value: locationState.city,
    setTextValue: (value) => setLocation((prev) => ({ ...prev, city: value })),
    required: true,
    disabled: isCompanyUser(),
  },
  {
    name: "county",
    label: "County",
    setTextValue: (value) =>
      setLocation((prev) => ({ ...prev, county: value })),
    value: locationState.county,
    disabled: isCompanyUser(),
  },
  {
    name: "country",
    render: () => (
      <CountryDropdown
        required
        value={
          locationState.country
            ? {
                value: locationState.country.name,
                label: locationState.country.name,
              }
            : null
        }
        onChange={(option) =>
          setLocation((prev) => ({
            ...prev,
            country: option ? { name: option.label, id: +option.value } : null,
          }))
        }
        disabled={isCompanyUser()}
      />
    ),
  },
  {
    name: "zipCode",
    label: "Zip code",
    value: locationState.zipCode,
    setTextValue: (value) =>
      setLocation((prev) => ({ ...prev, zipCode: value })),
    disabled: isCompanyUser(),
  },
];
