import React from "react";
import { LinkText } from "../../../../../../components/Common/hyperlinks";
import Column from "../../../../../../components/DataTable/models/Column";
import { SiteUrls } from "../../../../../SiteManagement";
import AssociatedSiteRow from "../models/AssociatedSiteRow";

const associatedSiteColumns: Column<AssociatedSiteRow>[] = [
  {
    title: "Site Code",
    field: "id",
  },
  {
    title: "Site",
    field: "siteName",
    searchable: true,
    render: (rowData: AssociatedSiteRow) => (
      <LinkText link={SiteUrls.details(rowData.id)} text={rowData.siteName} />
    ),
  },
  {
    title: "Country",
    field: "country",
  },
  {
    title: "Status",
    field: "status",
  },
];

export default associatedSiteColumns;
