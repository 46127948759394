import axios from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import HttpStatus from "../../../components/Api/HttpStatus";
import { dispatch } from "../../../components/App/App";
import setError from "../../../store/error/actions/setError";

export default (auditId: string) =>
  axios
    .get(
      `${BackendServices.AUDIT_SERVICE.AUDIT}/${auditId}/downloadCertificate`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = response.headers["content-disposition"];
      let fileName = contentDisposition.split("filename=")[1].split(";")[0];
      if (fileName.includes('"')) {
        fileName = fileName.replaceAll('"', "");
      }

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // eslint-disable-next-line no-unused-expressions
      link.parentNode?.removeChild(link);
    })
    .catch((reason: any) => {
      if (reason?.response?.status === HttpStatus.NOT_FOUND) {
        dispatch(
          setError(
            "No certificate is currently available for this audit record"
          )
        );
      }
    });
