import React from "react";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { H3Heading } from "../../../../../components/Common/Heading";
import MainButton, {
  SecondaryButton,
} from "../../../../../components/Button/button";

interface UploadError {
  readonly closeError: () => void;
  readonly endUploading: () => void;
  readonly returnUserToDetails: () => void;
  readonly messages: string[];
  readonly isEditing?: boolean;
}
const UploadError = ({
  closeError,
  endUploading,
  returnUserToDetails,
  messages,
  isEditing,
}: UploadError) => (
  <div className="audit-new__result">
    <H3Heading text="File has failed to be uploaded" />
    <ErrorOutlineOutlinedIcon className="audit-new__result__failure-icon" />
    <p>Check the file for the following issues and try again</p>
    <ul>
      {messages.map((message) => (
        <li key={message}>{message}</li>
      ))}
    </ul>
    <MainButton text="Try again" onClick={closeError} />
    <SecondaryButton text="Return to audit management" onClick={endUploading} />
    {isEditing && (
      <SecondaryButton
        text="Return to audit details"
        onClick={returnUserToDetails}
      />
    )}
  </div>
);

export default UploadError;
