enum AuditStatus {
  CURRENT = "Current",
  CLOSED = "Closed",
  ARCHIVED = "Archived",
  HOLDING = "Holding",
}

export interface AuditStatusEntity {
  readonly name: string;
  readonly id: number;
}

export default AuditStatus;
