import CompanyDetails from "../../../../Models/companies/Company";
import getCompanyDetails from "../../../../http/companies/getCompanyDetails";
import useGetData from "../../../../http/useGetData";

export default (id: string) => {
  const { isLoading, data, refresh } = useGetData<CompanyDetails>(() =>
    getCompanyDetails(id)
  );

  return { isLoading, company: data, refresh };
};
