import Option from "../forms/Option";

export enum SiteOrSupplierIssue {
  SITE = "Site",
  SUPPLIER = "Supplier",
  UNKNOWN = "Unknown",
}

export const SiteOrSupplierOption: Option[] = [
  {
    value: SiteOrSupplierIssue.SITE,
    label: SiteOrSupplierIssue.SITE,
  },
  {
    value: SiteOrSupplierIssue.SUPPLIER,
    label: SiteOrSupplierIssue.SUPPLIER,
  },
  {
    value: SiteOrSupplierIssue.UNKNOWN,
    label: SiteOrSupplierIssue.UNKNOWN,
  },
];
