import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import sortNameAlphanumerical from "../../../../util/sorting/sortNameAlphanumerical";
import DashboardExtended from "../models/DashboardExtended";

export default (): Promise<DashboardExtended[]> =>
  axiosApi
    .get<DashboardExtended[]>(
      `${BackendServices.DASHBOARD_SERVICE.DASHBOARD}/detailed`
    )
    .then(({ data }) => data.sort(sortNameAlphanumerical))
    .catch(() => []);
