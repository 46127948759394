import React from "react";
import DataTable from "../../../../components/DataTable";
import auditDocumentsColumns from "./util/auditDocumentsColumns";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import AuditDocument from "./models/AuditDocument";
import AuditDocumentRow from "./models/AuditDocumentRow";
import { AuditUrls } from "../../index";
import Permission from "../../../../Authentication/model/Permission";
import Icon, { IconType } from "../../../../components/Icon";
import { DataTableRowAction } from "../../../../components/DataTable/components/DataTableMoreActions";

interface AuditDocumentsTableProps {
  readonly id: string;
  readonly isAbleToEdit: boolean | undefined;
}

const AuditDocumentsTable = ({
  id,
  isAbleToEdit,
}: AuditDocumentsTableProps) => {
  const fetch = (): Promise<AuditDocumentRow[]> =>
    axiosApi
      .get<AuditDocument[]>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}/${id}/attachments`
      )
      .then(({ data }) =>
        data.map(
          (document: AuditDocument): AuditDocumentRow => ({
            id: document.id,
            extDocLink: document.extDocLink,
            fileName: document.fileName,
            fileType: document.fileType,
            uploadDate: document.uploadDate,
            userId: document.userId,
            auditId: id,
          })
        )
      );

  type ActionReturn = Promise<{ readonly refreshOnResolve: boolean }>;

  const onDeleteClick = (rowData?: AuditDocumentRow): ActionReturn => {
    if (!rowData) return Promise.reject();
    return axiosApi
      .delete(`${BackendServices.AUDIT_SERVICE.AUDIT}/Document/${rowData.id}`)
      .then(() => ({ refreshOnResolve: true }));
  };

  const documentActions: (
    data?: AuditDocumentRow
  ) => DataTableRowAction<AuditDocumentRow>[] = (data?: AuditDocumentRow) =>
    isAbleToEdit && data?.fileType !== "Audit"
      ? [
          {
            label: "Delete document",
            id: "delete",
            onClick: onDeleteClick,
            permissionRequired: [{ permission: Permission.VIEW }],
            icon: <Icon type={IconType.ARCHIVE} />,
          },
        ]
      : [];

  return (
    <DataTable<AuditDocumentRow>
      columns={auditDocumentsColumns}
      fetchData={fetch}
      rowActions={documentActions}
      toolBarOptions={{
        searchField: { enable: false },
        newEntityLink: isAbleToEdit
          ? {
              label: "Upload",
              link: AuditUrls.uploadDocument(id),
              permissionAreas: [],
            }
          : undefined,
      }}
    />
  );
};

export default AuditDocumentsTable;
