import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Entity from "../../../Models/APIGetData/Entity";
import "./audit-details.scss";
import useGetAudit from "./util/useGetAudit";
import AuditDetails from "./components/Details";
import TabbedDetails from "./components/TabbedDetails";
import AuditScheduleDetails from "./components/ScheduleDetails";
import AuditDetailHistory from "./components/History";
import AuditDocumentsTable from "../AuditDocuments/AuditDocumentsTable";
import DetailsPage from "../../../components/DetailsPage";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import axios from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { archive } from "../../../components/Paths/paths";
import getMenuItems from "./util/getMenuItems";
import AuditStatus from "../models/AuditStatus";
import { getUserRole } from "../../../Authentication/Auth";
import UserRole from "../../../Models/user/UserRole";

const AuditDetailsMenuItems = [
  DetailsPageMenuItem.CREATE,
  DetailsPageMenuItem.EDIT,
  DetailsPageMenuItem.ARCHIVE,
  DetailsPageMenuItem.CHANGE_LOG,
  DetailsPageMenuItem.SHARE,
];

const AuditDetailsPage = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<Entity>) => {
  const {
    genericTabDetails,
    mainDetails,
    isLoading,
    refresh,
    handleAddressSync,
    handleSiteNameSync,
  } = useGetAudit(id);

  const tabs = [
    ...genericTabDetails
      .filter((tab) => tab.name !== "Additional")
      .map((tab) => ({
        name: tab.name,
        content: (
          <TabbedDetails
            sections={tab.sections}
            refresh={refresh}
            auditId={id}
            isAbleToEdit={false}
          />
        ),
      })),
    { name: "Audit history", content: <AuditDetailHistory id={id} /> },
    {
      name: "Audit Documents",
      content: (
        <AuditDocumentsTable
          id={id}
          isAbleToEdit={mainDetails?.visuals.isAbleToEdit}
        />
      ),
    },
  ];

  const isArchived = mainDetails?.recordStatus === AuditStatus.ARCHIVED;

  const archiveAudit = () =>
    axios
      .post(`${BackendServices.AUDIT_SERVICE.AUDIT}${archive}`, {
        externalId: mainDetails?.id,
        archive: !isArchived,
      })
      .then(refresh);

  return (
    <DetailsPage
      id={id}
      title={mainDetails?.site?.name ?? ""}
      className="audit-detail"
      detailsPageMenuItems={AuditDetailsMenuItems}
      detailsPageMenuConfig={{
        [DetailsPageMenuItem.SHARE]: {
          hide:
            getUserRole() === UserRole.AB_USER ||
            mainDetails?.programOwner !== "BRCGS",
        },
        [DetailsPageMenuItem.CREATE]: {
          label: "Create new record",
          hide: !mainDetails?.visuals.isAbleToEdit,
        },
        [DetailsPageMenuItem.EDIT]: {
          label: "Update existing record",
          hide:
            !mainDetails?.visuals.isAbleToEdit ||
            mainDetails.recordStatus === "Closed",
          state: mainDetails ? { titleName: mainDetails.site.name } : undefined,
        },
        [DetailsPageMenuItem.ARCHIVE]: {
          hide: !mainDetails?.visuals.isAbleToEdit,
        },
        [DetailsPageMenuItem.CHANGE_LOG]: {
          hide: !mainDetails?.visuals.isAbleToEdit,
        },
      }}
      isLoading={isLoading}
      archiveConfig={{
        entityName: "audit",
        isArchived,
        onClick: archiveAudit,
      }}
      menuItems={getMenuItems(mainDetails, refresh)}
      contentTabs={tabs}
    >
      <div className="audit-details__main">
        {mainDetails && (
          <div className="audit-details__main__details">
            <AuditDetails
              mainDetails={mainDetails}
              handleAddressSync={handleAddressSync}
              handleSiteNameSync={handleSiteNameSync}
              refresh={refresh}
            />
            <AuditScheduleDetails mainDetails={mainDetails} />
          </div>
        )}
      </div>
    </DetailsPage>
  );
};
export default AuditDetailsPage;
