import React, { useEffect, useState } from "react";
import { isCBUser } from "../../Authentication/Auth";
import Option from "../../Models/forms/Option";
import SearchableDropdown from "../SearchableDropdown";
import axiosApi from "../Api/Axios";
import BackendServices from "../Api/BackendService";
import CertificationBodySimple from "../../Models/Office/CertificationBodySimple";

interface CertificationBodySelectorProps {
  readonly disabled?: boolean;
  readonly value: Option | null;
  readonly onChange: (val: Option | null) => void;
  readonly required?: boolean;
  readonly className?: string;
  readonly label?: string;
}

const CertificationBodySelector = ({
  disabled,
  value,
  onChange,
  required,
  className,
  label,
}: CertificationBodySelectorProps) => {
  const [options, setOptions] = useState<Option[]>([]);
  useEffect(() => {
    axiosApi
      .get<CertificationBodySimple[]>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/getCertificationBodyOptions`
      )
      .then(({ data }) => {
        const cbOptions: Option[] = data.map((cb) => ({
          label: cb.name ?? "",
          value: cb.id,
        }));
        if (cbOptions.length > 0 && isCBUser()) {
          onChange(cbOptions[0]);
        }
        setOptions(cbOptions);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <SearchableDropdown
      disabled={disabled}
      value={value}
      required={required}
      name="cbSelector"
      Options={options}
      label={label ?? "Certification Body"}
      onChange={(e, newValue) => onChange(newValue)}
      className={className}
    />
  );
};

export default CertificationBodySelector;
