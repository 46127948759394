import React from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import ButtonProps from "../../Models/Button/ButtonProps";
import "./button.scss";

function MainButton({ onClick, text, disabled, className }: ButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      type="submit"
      className={clsx("button primary", className)}
    >
      {text || "Submit"}
    </Button>
  );
}

export function SubmitButton({ onClick, text, disabled }: ButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      type="submit"
      className="button primary"
    >
      {text || "Submit"}
    </Button>
  );
}

export function RetryButton({ onClick, text, disabled }: ButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      className="button primary"
    >
      {text || "Retry User Creation"}
    </Button>
  );
}

export function SecondaryButton({
  onClick,
  text,
  disabled,
  className,
}: ButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      onClick={onClick}
      className={clsx("button secondary", className)}
    >
      {text || "Submit"}
    </Button>
  );
}

export function SecondarySubmitButton({
  onClick,
  text,
  disabled,
}: ButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      onClick={onClick}
      type="submit"
      className="button secondary"
    >
      {text || "Submit"}
    </Button>
  );
}

export function CancelButton({ onClick, text, disabled }: ButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      onClick={onClick}
      className="button secondary"
    >
      {text || "Cancel"}
    </Button>
  );
}

export default MainButton;
