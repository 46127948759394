import axiosApi from "../../../../../components/Api/Axios";
import BackendServices from "../../../../../components/Api/BackendService";
import AuditPostResponse from "../../../AuditNew/http/AuditPostResponse";
import { EditState } from "./utils/editReducer";
import { AuditEntryValue, AuditTableRow } from "../../models/AuditTabs";

interface PutEditElement {
  readonly xmlTag: string;
  readonly value?: AuditEntryValue | null;
  readonly tableValue?: { [key: string]: AuditEntryValue | null }[];
}

const verifyAndTransformValue = (
  value: AuditEntryValue
): AuditEntryValue | null => {
  if (typeof value === "string" || Array.isArray(value)) {
    return value.length === 0 ? null : value;
  }
  return value;
};

export default (
  editData: EditState,
  id: string,
  confirmed: boolean
): Promise<AuditPostResponse> => {
  const elements: (PutEditElement | null)[] = Object.entries(
    editData.values
  ).map(
    ([xmlTag, { value, type }]): PutEditElement => {
      const values = (() => {
        switch (type) {
          case "Text Field":
          case "Numeric Field":
          case "Calendar":
          case "Drop-Down":
            return { value: verifyAndTransformValue(value as AuditEntryValue) };

          case "Table":
          case "Accordion":
            return {
              tableValue: (value as AuditTableRow[]).map((row) => {
                return row.cells.reduce<{
                  [key: string]: AuditEntryValue | null;
                }>((acc, curr) => {
                  acc[curr.xmlTag] = verifyAndTransformValue(curr.data);
                  return acc;
                }, {});
              }),
            };
          default:
            return null;
        }
      })();
      return { xmlTag, ...values };
    }
  );

  return axiosApi
    .put<AuditPostResponse>(
      `${BackendServices.AUDIT_SERVICE.AUDIT}/${id}/Report`,
      {
        confirmed,
        elements,
      }
    )
    .then(({ data }) => data);
};
