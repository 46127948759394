import React, { PropsWithChildren } from "react";
import { Button } from "@material-ui/core";
import DialogBox, { CoreDialogBoxProps } from "../DialogBox";

export interface DialogButtonProps extends CoreDialogBoxProps {
  readonly openButton: string | ((openDialog: () => void) => React.ReactNode);
  readonly children?: React.ReactNode;
}

const DialogBoxButton = ({
  openButton,
  children,
  acceptButtonText,
  declineButtonText,
  dialogHeader,
  onConfirm,
}: PropsWithChildren<DialogButtonProps>) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = (event: React.MouseEvent) => {
    handleClose();
    onConfirm(event);
  };

  const renderOpenButton = () => {
    if (typeof openButton === "string") {
      return <Button onClick={handleClickOpen}>{openButton}</Button>;
    }
    return openButton(handleClickOpen);
  };

  return (
    <>
      {renderOpenButton()}
      <DialogBox
        open={open}
        onCancel={handleClose}
        dialogHeader={dialogHeader}
        onConfirm={handleConfirmation}
        acceptButtonText={acceptButtonText}
        declineButtonText={declineButtonText}
      >
        {children}
      </DialogBox>
    </>
  );
};

export default DialogBoxButton;
