import React, { forwardRef } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Icons } from "material-table";

const dataTableIcons: Partial<Icons> = {
  Clear: forwardRef((props, ref) => (
    <ClearIcon className="table-icon" {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <ClearIcon className="table-icon" {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => (
    <SearchIcon className="table-icon" {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownwardIcon className="table-icon" {...props} ref={ref} />
  )),
  Filter: forwardRef(() => <div />),
};

export default dataTableIcons;
