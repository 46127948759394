export default class GenericLinks {
  protected readonly root: string;

  constructor(root: string) {
    this.root = root;
  }

  get searchTable() {
    return this.root;
  }

  create(params?: { [key: string]: string | number }) {
    return `${this.root}/create/${GenericLinks.parameterized(params)}`;
  }

  details(id: string | number) {
    return `${this.root}/details/${id}`;
  }

  verifyView(id: string | number) {
    return `${this.root}/details/${id}`;
  }


  edit(id: string | number) {
    return `${this.details(id)}/edit`;
  }

  changeLog(id: string | number) {
    return `${this.details(id)}/change-log`;
  }

  share(id: string | number) {
    return `${this.details(id)}/share`;
  }

  private static parameterized(params?: { [key: string]: string | number }) {
    return params
      ? `?${Object.keys(params).map((key) => `${key}=${params[key]}`)}`
      : "";
  }
}
