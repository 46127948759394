import React, { PropsWithChildren } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { H2Heading } from "../../../../../components/Common/Heading";
import {
  SecondaryButton,
  SubmitButton,
} from "../../../../../components/Button/button";
import styles from "./AudiorAccordion.module.scss";
import AuditorStatus from "../../../../../Enums/AuditorStatus";

interface AuditorAccordionProps {
  readonly heading?: string;
  readonly secondaryHeading?: string;
  readonly status?: AuditorStatus;
  readonly actions?: {
    readonly submit: {
      readonly text: string;
      readonly onClick: () => void;
    };
    readonly cancel: {
      readonly text: string;
      readonly onClick: () => void;
    };
  };
  readonly expanded?: boolean;
}

const AuditorAccordion = ({
  children,
  heading,
  actions,
  status,
  expanded,
  secondaryHeading,
}: PropsWithChildren<AuditorAccordionProps>) => {
  const secondaryHeaderColoring = (() => {
    switch (status) {
      case AuditorStatus.IN_REVISION:
        return styles.revision;
      case AuditorStatus.NOT_VALIDATED:
        return styles.notValid;
      case AuditorStatus.VALIDATED:
        return styles.valid;
      default:
        return "";
    }
  })();
  return (
    <Accordion defaultExpanded={expanded} className={styles.main}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <H2Heading text={heading} />
        {secondaryHeading && (
          <p className={clsx(styles.secondaryHeader, secondaryHeaderColoring)}>
            {secondaryHeading}
          </p>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
      {actions && (
        <>
          <Divider />
          <AccordionActions>
            {actions.cancel && (
              <SecondaryButton
                onClick={actions.cancel.onClick}
                text={actions.cancel.text}
              />
            )}
            {actions.submit && (
              <SubmitButton
                onClick={actions.submit.onClick}
                text={actions.submit.text}
              />
            )}
          </AccordionActions>
        </>
      )}
    </Accordion>
  );
};

export default AuditorAccordion;
