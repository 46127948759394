import moment, { Moment } from "moment";
import { FilterState, FilterValues } from "../index";
import Option from "../../../Models/forms/Option";
import getStandardOptions from "./options/getStandardOptions";
import { ReportFilter } from "./templates";
import getCategoryOptions from "./options/getCategoryOptions";
import getCountryOptions from "./options/getCountryOptions";
import DropdownFilter from "../filters/DropdownFilter";
import SearchableDropdownFilter from "../filters/SearchableDropdownFilter";
import CheckboxFilter from "../filters/CheckboxFilter";
import DatePickerFilter from "../filters/DatePickerFilter";
import NumberInputFilter from "../filters/NumberInputFilter";
import getCertificationBodyOptions from "./options/getCertificationBodyOptions";
import getCertificationStatusOptions from "./options/getCertificationStatusOptions";
import ProgramOwnerFilter from "../filters/ProgramOwnerFilter";
import getSeverityOptions from "./options/getSeverityOptions";
import getClauseOptions from "./options/getClauseOptions";
import getAuditorOptions from "./options/getAuditorOptions";
import getRoleOptions from "./options/getRolesOptions";
import getServicesOptions from "./options/getServicesOptions";
import { DateRangeValue } from "../../../components/DateRangePicker";
import getAuditorStatusOptions from "./options/getAuditorStatusOptions";
import OrganisationFilter from "../filters/OrganisationFilter";
import { auditScheduleStatus } from "../../../Enums/AuditScheduleStatus";
import { DEFAULT_DATE_FORMAT } from "../../../util/dates/DateFormats";
import getRecordStatuses from "./options/getRecordStatuses";
import getRecallStatusOptions from "./options/getRecallStatusOptions";

type FilterMappings = {
  [type in ReportFilter]: FilterMapping;
};

export type SetFilterValue = (val: FilterValues) => void;

export interface FilterProps {
  readonly name: string;
  readonly value: FilterValues;
  readonly optionSupplier?: () => Option[];
  readonly setValue: SetFilterValue;
}

export interface FilterMapping {
  readonly initialValue: FilterValues;
  readonly name: string;
  readonly optionSupplier?: (
    filterState: FilterState,
    setValue: SetFilterValue
  ) => Option[];
  readonly component: (props: FilterProps) => JSX.Element;
  readonly mapToBody: (state: FilterState) => object;
}

const mapOptionToValue = (option: Option) => option.value;
const mapOptionToLabel = (option: Option) => option.label;
const mapDate = (date: Moment | null) =>
  date ? moment(date).format(DEFAULT_DATE_FORMAT) : null;

const filterMappings: FilterMappings = {
  companyIDs: {
    name: "Company ID",
    initialValue: [],
    component: NumberInputFilter,
    optionSupplier: getCertificationStatusOptions,
    mapToBody: (state) => ({
      companyIDs: (state.companyIDs as Option[])?.map(mapOptionToValue),
      
    }),
  },
  standards: {
    initialValue: [],
    name: "Standard",
    optionSupplier: getStandardOptions,
    component: DropdownFilter,
    mapToBody: (state) => ({
      standard: (state.standards as Option[])?.map(mapOptionToLabel),
    }),
  },
  sites: {
    initialValue: [],
    name: "Site code",
    component: NumberInputFilter,
    mapToBody: (state) => ({
      siteCodes: (state.sites as Option[])?.map(mapOptionToValue),
    }),
  },
  archived: {
    initialValue: false,
    name: "Show archived",
    component: CheckboxFilter,
    mapToBody: (state) => ({
      showArchived: state.archived,
    }),
  },
  auditStart: {
    initialValue: [null, null],
    name: "Audit start date",
    component: DatePickerFilter,
    mapToBody: (state) => {
      const dates = state.auditStart as DateRangeValue;
      return {
        auditStartDateStartRange: mapDate(dates[0]),
        auditStartDateEndRange: mapDate(dates[1]),
      };
    },
  },
  categories: {
    initialValue: [],
    optionSupplier: getCategoryOptions,
    name: "Category",
    component: DropdownFilter,
    mapToBody: (state) => ({
      category: (state.categories as Option[])?.map(mapOptionToLabel),
    }),
  },
  certificationBodyIds: {
    initialValue: [],
    name: "Certification body",
    component: SearchableDropdownFilter,
    optionSupplier: getCertificationBodyOptions,
    mapToBody: (state) => ({
      certificationBodyIds: (state.certificationBodyIds as Option[])?.map(
        mapOptionToValue
      ),
    }),
  },
  certificationBodyNames: {
    initialValue: [],
    name: "Certification body",
    component: SearchableDropdownFilter,
    optionSupplier: getCertificationBodyOptions,
    mapToBody: (state) => ({
      CertificationBodies: (state.certificationBodyNames as Option[])?.map(
        mapOptionToLabel
      ),
    }),
  },
  certificationStatuses: {
    name: "Certification status",
    initialValue: [],
    component: DropdownFilter,
    optionSupplier: getCertificationStatusOptions,
    mapToBody: (state) => ({
      certificationStatus: (state.certificationStatuses as Option[])?.map(
        mapOptionToLabel
      ),
    }),
  },
  countryIds: {
    initialValue: [],
    optionSupplier: getCountryOptions,
    name: "Countries",
    component: SearchableDropdownFilter,
    mapToBody: (state) => ({
      countryIds: (state.countryIds as Option[])?.map(mapOptionToValue),
    }),
  },
  countryNames: {
    initialValue: [],
    optionSupplier: getCountryOptions,
    name: "Countries",
    component: SearchableDropdownFilter,
    mapToBody: (state) => ({
      countries: (state.countryNames as Option[])?.map(mapOptionToLabel),
    }),
  },
  expiryDate: {
    initialValue: [null, null],
    name: "Expiry date",
    component: DatePickerFilter,
    mapToBody: (state) => {
      const dates = state.expiryDate as DateRangeValue;
      return {
        expiryDateStartRange: mapDate(dates[0]),
        expiryDateEndRange: mapDate(dates[1]),
      };
    },
  },
  programOwners: {
    initialValue: [],
    name: "Program owner",
    component: ProgramOwnerFilter,
    mapToBody: (state) => ({
      programOwnerIds: (state.programOwners as Option[])?.map(mapOptionToValue),
    }),
  },
  severity: {
    initialValue: [],
    name: "Severity",
    component: DropdownFilter,
    mapToBody: (state) => ({
      severities: (state.severity as Option[])?.map(mapOptionToValue),
    }),
    optionSupplier: getSeverityOptions,
  },
  clause: {
    initialValue: [],
    name: "Clause",
    optionSupplier: getClauseOptions,
    mapToBody: (state) => ({
      clauses: (state.clause as Option[])?.map(mapOptionToValue),
    }),
    component: SearchableDropdownFilter,
  },
  organisation: {
    name: "Organisation",
    component: OrganisationFilter,
    mapToBody: (state) => ({
      organisations: (state.organisation as Option[])?.map(mapOptionToValue),
    }),
    initialValue: [],
  },
  auditor: {
    name: "Auditor",
    component: SearchableDropdownFilter,
    initialValue: [],
    optionSupplier: getAuditorOptions,
    mapToBody: (state) => ({
      auditorIds: (state.auditor as Option[])?.map(mapOptionToValue),
    }),
  },
  auditorStatus: {
    name: "Status",
    component: DropdownFilter,
    initialValue: [],
    optionSupplier: getAuditorStatusOptions,
    mapToBody: (state) => ({
      auditorStatusIds: (state.auditorStatus as Option[])?.map(
        mapOptionToValue
      ),
    }),
  },
  roles: {
    name: "Role",
    component: DropdownFilter,
    optionSupplier: getRoleOptions,
    mapToBody: (state) => ({
      roles: (state.roles as Option[])?.map(mapOptionToValue),
    }),
    initialValue: [],
  },
  services: {
    name: "Services",
    component: DropdownFilter,
    initialValue: [],
    optionSupplier: getServicesOptions,
    mapToBody: (state) => ({
      services: (state.services as Option[])?.map(mapOptionToValue),
    }), // todo ??
  },
  action: {
    name: "Action",
    component: DropdownFilter,
    initialValue: [],
    optionSupplier: () =>
      [auditScheduleStatus.CONFIRMED, auditScheduleStatus.INTERRIM].map(
        (option) => ({
          value: option,
          label: option,
        })
      ),
    mapToBody: (state) => ({
      actions: (state.action as Option[])?.map(mapOptionToValue),
    }),
  },
  actionDate: {
    name: "Action Date",
    component: DatePickerFilter,
    initialValue: [null, null],
    mapToBody: (state) => {
      const dates = state.actionDate as DateRangeValue;
      return {
        ActionDateRangeStart: mapDate(dates[0]),
        ActionDateRangeEnd: mapDate(dates[1]),
      };
    }, // todo ??
  },
  submissionDate: {
    name: "Submission Date",
    initialValue: [null, null],
    component: DatePickerFilter,
    mapToBody: (state) => {
      const dates = state.submissionDate as DateRangeValue;
      return {
        SubmissionDateStartRange: mapDate(dates[0]),
        SubmissionDateEndRange: mapDate(dates[1]),
      };
    },
  },
  modificationDate: {
    name: "Modification Date",
    component: DatePickerFilter,
    initialValue: [null, null],
    mapToBody: () => ({}), // todo ??
  },
  recordStatus: {
    name: "Record status",
    component: DropdownFilter,
    initialValue: [],
    optionSupplier: getRecordStatuses,
    mapToBody: (state) => ({
      RecordStatusIds: (state.recordStatus as Option[])?.map(mapOptionToValue),
    }),
  },
  privateSiteCode: {
    initialValue: [],
    name: "Specifier Site Code",
    component: NumberInputFilter,
    mapToBody: (state) => ({
      PrivateSiteCodes: (state.privateSiteCode as Option[])?.map(
        mapOptionToValue
      ),
    }),
  },
  recallStatusIds: {
    initialValue: [],
    name: "Recall Status",
    component: DropdownFilter,
    optionSupplier: getRecallStatusOptions,
    mapToBody: (state) => ({
      RecallStatusIds: (state.recallStatusIds as Option[])?.map(
        mapOptionToValue
      ),
    }),
  },
  dateOfNotification: {
    name: "Date of Initial Notification",
    component: DatePickerFilter,
    initialValue: [null, null],
    mapToBody: (state) => {
      const dates = state.dateOfNotification as DateRangeValue;
      return {
        dateOfNotificationStart: mapDate(dates[0]),
        dateOfNotificationEnd: mapDate(dates[1]),
      };
    },
  },
  dateOfRecall: {
    name: "Date of Recall",
    component: DatePickerFilter,
    initialValue: [null, null],
    mapToBody: (state) => {
      const dates = state.dateOfRecall as DateRangeValue;
      return {
        dateOfRecallStart: mapDate(dates[0]),
        dateOfRecallEnd: mapDate(dates[1]),
      };
    },
  },
  HaviSiteCode: {
    initialValue: [],
    name: "Supplier ID Number",
    component: NumberInputFilter,
    mapToBody: (state) => ({
      HaviSiteCodes: (state.HaviSiteCode as Option[])?.map(mapOptionToValue),
    }),
  },
};

export default filterMappings;
