import React from "react";
import SearchableDropdown from "../../SearchableDropdown";
import FilterComponentProps from "./FilterComponentProps";
import Option from "../../../Models/forms/Option";

const SearchFilter = ({
  selected,
  onChange,
  options,
}: FilterComponentProps) => {
  const selectedValue = selected[0] ?? null;
  return (
    <SearchableDropdown
      Options={options}
      label="Select country"
      value={selectedValue}
      onChange={(e: React.ChangeEvent<{}>, option: Option | null) =>
        onChange(option ? [option] : [])
      }
    />
  );
};

export default SearchFilter;
