import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../store/reducers";
import Country from "../../Models/common/Country";
import getCountries from "../../store/details/actions/getCountries";

export default () => {
  const countries: Country[] = useSelector(
    (state: RootState) => state.details.countries
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(getCountries());
    }
  }, []);

  return countries;
};
