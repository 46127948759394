import React from "react";
import { privateArea } from "../../components/Paths/paths";
import GenericRoutes from "../../routing/GenericRoutes";
import TypeSelecting from "./TypeSelecting";
import GenericLinks from "../../routing/GenericLinks";
import PermissionArea from "../../Authentication/model/PermissionArea";
import SiteDetails from "./SiteDetails";
import SiteNew from "./SiteNew";
import SiteEdit from "./SiteEdit";
import SiteUsers from "./SiteUsers";

const PATH_SITE = `${privateArea}/site`;
class SiteLinks extends GenericLinks {
  users(id: string | number) {
    return `${this.details(id)}/users`;
  }
}
export const SiteUrls = new SiteLinks(PATH_SITE);
const SiteRouting = GenericRoutes({
  root: PATH_SITE,
  urls: SiteUrls,
  entityType: "site",
  permissionArea: PermissionArea.SITE,
  detailsRoutes: [
    {
      path: "users",
      component: SiteUsers,
      confirmOnLeave: false,
    },
  ],
  components: {
    searchComponent: TypeSelecting,
    detailsComponent: SiteDetails,
    editComponent: SiteEdit,
    createComponent: SiteNew,
  },
});

const SiteRoutes = () => <SiteRouting />;
export default SiteRoutes;
