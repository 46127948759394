import React, { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./Spinner.module.scss";

interface SpinnerProps {
  readonly delay?: number;
  readonly className?: string;
}

export const SpinnerCenter = ({ className, delay }: SpinnerProps) => {
  return (
    <div className={clsx(styles.spinnerCentered, className)}>
      <Spinner delay={delay} />
    </div>
  );
};

const Spinner = ({ delay = 600, className }: SpinnerProps) => {
  const [show, setShow] = useState(false);
  let isClosed = false;
  useEffect(() => {
    setTimeout(() => {
      if (!isClosed) setShow(true);
    }, delay);
    return () => {
      isClosed = true;
    };
  }, []);

  if (!show) {
    return <div className={styles.spinnerPlaceholder} />;
  }

  return (
    <div className={clsx(styles.spinner, className)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
