import SharingType from "../models/SharingType";
import EditShareBody from "../models/EditShareBody";
import axiosApi from "../../../components/Api/Axios";
import getShareRootUrl from "./getShareRootUrl";

export default (type: SharingType, body: EditShareBody, share: boolean) => {
  if (share) {
    return axiosApi.post(`${getShareRootUrl(type)}/share`, body);
  }

  return axiosApi.delete(`${getShareRootUrl(type)}/removeShare`, {
    data: body,
  });
};
