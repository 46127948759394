import { useEffect, useState } from "react";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";

interface ProgramOwner {
  readonly id: number;
  readonly name: string;
}

export default (): { programOwners: ProgramOwner[] } => {
  const [programOwners, setProgramOwners] = useState<ProgramOwner[]>([]);
  useEffect(() => {
    axiosApi
      .get<ProgramOwner[]>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}/programOwners`
      )
      .then(({ data }) => {
        setProgramOwners(data);
      })
      .catch(() => setProgramOwners([]));
  }, []);
  return { programOwners };
};
