import React from "react";
import DataTable, { DataTableQueryParams } from "../../../DataTable";
import SiteRow from "./models/SiteRow";
import siteColumns from "./util/siteColumns";
import axiosApi from "../../../Api/Axios";
import BackendServices from "../../../Api/BackendService";
import SearchResponse from "../../../../Models/APIGetData/SearchResponse";
import Site from "../../../../Models/CompanyManagementProps/Site";
import { SiteUrls } from "../../../../views/SiteManagement";
import PermissionArea from "../../../../Authentication/model/PermissionArea";

const SiteSearchTable = () => {
  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: SiteRow[]; totalPages: number }> =>
    axiosApi
      .get<SearchResponse<Site>>(`${BackendServices.COMPANY_SERVICE.SITES}`, {
        params,
      })
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultList.map(
          (site: Site): SiteRow => ({
            siteCode: site.id,
            siteName: site.name,
            country: site.location.country?.name ?? "",
            city: site.location.city,
            owningCompany: site.companyName,
            status: site.status,
            shared: site.isShared ? "Y" : "N",
          })
        ),
      }));

  return (
    <DataTable<SiteRow>
      columns={siteColumns}
      fetchRemoteData={fetch}
      toolBarOptions={{
        newEntityLink: {
          label: "Add Site",
          link: SiteUrls.create(),
          permissionAreas: [PermissionArea.SITE],
        },
      }}
      archiveFilter={{ enabled: true }}
    />
  );
};

export default SiteSearchTable;
