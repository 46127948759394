import { Action, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import Standard from "../../../Models/common/Standard";

export const ACTION_GET_STANDARDS = "ACTION_GET_STANDARDS";

export interface ActionGetStandards {
  readonly type: typeof ACTION_GET_STANDARDS;
  readonly payload: Standard[];
}

export default (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  axiosApi
    .get<Standard[]>(`${BackendServices.PUBLIC_SERVICE}/standards`)
    .then(({ data: payload }) => {
      const action: ActionGetStandards = {
        type: ACTION_GET_STANDARDS,
        payload,
      };
      dispatch(action);
    })
    .catch(() => {
      dispatch({ type: ACTION_GET_STANDARDS, payload: [] });
    });
};
