import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import clsx from "clsx";
import styles from "./FieldValidator.module.scss";

export enum FieldValidator {
  NUMBER = "isNumber",
  EMAIL = "isEmail",
  PHONE_NUMBER = "matchRegexp:^([0-9\\(\\)\\/\\+ \\-]*)$",
}

type ValidatorErrors = {
  [key in FieldValidator]: string;
};

const validatorErrors: ValidatorErrors = {
  [FieldValidator.EMAIL]: "Incorrect email format",
  [FieldValidator.NUMBER]: "Is not a number",
  [FieldValidator.PHONE_NUMBER]: "Incorrect phone number format",
};

export class Validator extends ValidatorComponent {
  render() {
    const { children, className } = this.props;
    // @ts-ignore
    const { isValid } = this.state;
    // @ts-ignore
    const errorMessage = !isValid && this.getErrorMessage();

    return (
      <div className={clsx(styles.validate, className)}>
        {children}
        {!isValid && <p className={styles.error}>{errorMessage}</p>}
      </div>
    );
  }
}

export interface ValidationFieldProps {
  readonly value?: any;
  readonly disabled?: boolean;
  readonly required?: boolean | null;
  readonly label?: string;
  readonly name?: string;
  readonly limit?: number;
  readonly className?: string;
  readonly validators?: FieldValidator[];
}

function withValidation<T extends ValidationFieldProps>(
  WrappedComponent: (props: T) => JSX.Element
): (props: T) => JSX.Element {
  return (props: T) => {
    const {
      disabled,
      required,
      label,
      value,
      name,
      limit = 0,
      className,
      validators: fieldValidators = [],
    } = props;
    const requiredText = (() => {
      if (disabled || label?.length === 0 || required == null) return "";
      if (required) return "*";
      return " (optional)";
    })();

    const validators: {
      readonly name: string;
      readonly errorMessage: string;
    }[] = [
      ...fieldValidators.map((v) => ({
        name: v,
        errorMessage: validatorErrors[v],
      })),
      ...(required
        ? [{ name: "required", errorMessage: "Field is required" }]
        : []),
      ...(limit > 0
        ? [
            {
              name: `maxStringLength:${limit}`,
              errorMessage: `Max ${limit} characters allowed`,
            },
          ]
        : []),
    ];
    const hasValidation = validators.length > 0 && name;

    const innerComponent = (
      <WrappedComponent
        {...props}
        required={false}
        label={`${label} ${requiredText}`}
        className={hasValidation ? "" : className}
      />
    );
    if (!hasValidation) return innerComponent;
    return (
      <Validator
        name={name ?? ""}
        value={value ?? ""}
        validators={validators.map((v) => v.name)}
        errorMessages={validators.map((v) => v.errorMessage)}
        className={className}
      >
        {innerComponent}
      </Validator>
    );
  };
}

export default withValidation;
