import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import PermissionArea from "../../Authentication/model/PermissionArea";
import { Permission } from "../../Authentication/model/Permission";

const PermissionContext = React.createContext<PermissionArea | null>(null);

export const usePermissionContext = (): Permission[] => {
  const permissionTarget = useContext(PermissionContext);
  const userPermissions = useUserPermission();
  const result = permissionTarget ? userPermissions[permissionTarget] : [];
  return result ?? [];
};

export const useUserPermission = () =>
  useSelector((rootState: RootState) => rootState.permissions.userPermissions);

export default PermissionContext;
