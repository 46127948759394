import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import Site from "../../Models/site/Site";
import AuditHistory from "../../views/AuditManagement/AuditDetails/components/History/models/AuditHistory";

export default (id: string | number) =>
  axiosApi.get<Site>(`${BackendServices.COMPANY_SERVICE.SITES}/${id}`);

export const getAuditHistoryForSiteById = (id: string | number) =>
  axiosApi.get<AuditHistory>(
    `${BackendServices.AUDIT_SERVICE.AUDIT}/${id}/history`
  );
