import React from "react";
import { idParam, privateArea } from "../../components/Paths/paths";
import AuditDetailsPage from "./AuditDetails";
import AuditSchedule from "./AuditSchedule";
import AuditNew from "./AuditNew";
import AuditExpiryReasonForm from "./AuditExpiry";
import AuditAdditionalDocumentUpload from "./AuditDocuments/AuditDocUpload";
import GenericRoutes from "../../routing/GenericRoutes";
import GenericLinks from "../../routing/GenericLinks";
import AuditSearchTable from "./AuditSearchTable";
import PermissionArea from "../../Authentication/model/PermissionArea";
import Permission from "../../Authentication/model/Permission";

const PATH_AUDIT_MANAGEMENT = `${privateArea}/audit`;

class AuditLinks extends GenericLinks {
  schedule(id: string | number) {
    return `${this.details(id)}/schedule`;
  }

  expiry(id: string | number) {
    return `${this.details(id)}/expiry`;
  }

  uploadDocument(id: string | number) {
    return `${this.details(id)}/upload-document`;
  }

  scheduleSite(siteId: number | string) {
    return `${this.root}/schedule-site/${siteId}`;
  }

  expirySite(siteId: number | string) {
    return `${this.root}/expiry-site/${siteId}`;
  }
}

export const AuditUrls = new AuditLinks(PATH_AUDIT_MANAGEMENT);

const AuditRouting = GenericRoutes({
  root: PATH_AUDIT_MANAGEMENT,
  urls: AuditUrls,
  entityType: "audit",
  permissionArea: PermissionArea.AUDIT,
  components: {
    searchComponent: AuditSearchTable,
    detailsComponent: AuditDetailsPage,
    createComponent: AuditNew,
    editComponent: AuditNew,
  },
  detailsRoutes: [
    {
      path: "schedule",
      component: AuditSchedule,
      requiredPermission: Permission.SCHEDULE,
    },
    {
      path: "expiry",
      component: AuditExpiryReasonForm,
      requiredPermission: Permission.GENERATE,
    },
    { path: "upload-document", component: AuditAdditionalDocumentUpload },
  ],
  rootRoutes: [
    {
      path: `schedule-site${idParam}`,
      component: AuditSchedule,
      requiredPermission: Permission.SCHEDULE,
    },
    {
      path: `expiry-site${idParam}`,
      component: AuditExpiryReasonForm,
      requiredPermission: Permission.GENERATE,
    },
  ],
});

const AuditRoutes = () => <AuditRouting />;

export default AuditRoutes;
