import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import DashboardExtended from "../models/DashboardExtended";
import { SpinnerCenter } from "../../../../components/Spinner";
import DashboardNew from "../DashboardNew";
import useGetData from "../../../../http/useGetData";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import putDashboard from "../http/putDashboard";

const DashboardEdit = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { data, isLoading } = useGetData<DashboardExtended>(() =>
    axiosApi.get(`${BackendServices.DASHBOARD_SERVICE.DASHBOARD}/${id}`)
  );
  useEffect(() => {});
  if (isLoading) {
    return <SpinnerCenter />;
  }
  return (
    <DashboardNew
      dashboard={data}
      onSubmit={(dashboardData) => putDashboard(dashboardData, id)}
    />
  );
};

export default DashboardEdit;
