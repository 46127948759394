import React from "react";
import DataTable from "../../../../../components/DataTable";
import auditHistoryColumns from "./util/auditHistoryColumns";
import AuditHistoryRow from "./models/AuditHistoryRow";
import axiosApi from "../../../../../components/Api/Axios";
import BackendServices from "../../../../../components/Api/BackendService";
import AuditHistory from "./models/AuditHistory";

interface AuditDetailHistoryProps {
  readonly id: string;
}

const AuditDetailHistory = ({ id }: AuditDetailHistoryProps) => {
  const fetch: () => Promise<AuditHistoryRow[]> = () =>
    axiosApi
      .get<AuditHistory[]>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}/${id}/history`
      )
      .then(({ data }) =>
        data.map(
          (item): AuditHistoryRow => ({
            auditStartDate: item.auditStartDate,
            auditValidationDate: item.auditValidationDate,
            certificationBodyName: item.certificationBodyName,
            certificationStatus: item.certificationStatus,
            id: item.id,
            uploadDate: item.uploadDate,
          })
        )
      );
  return (
    <DataTable
      fetchData={fetch}
      columns={auditHistoryColumns}
      toolBarOptions={{ searchField: { enable: false } }}
    />
  );
};

export default AuditDetailHistory;
