import EntityStatus from "../../Enums/EntityStatus";
import { suspensionType } from "../../Enums/SuspentionType";
import CertificationBody from "../../Models/Office/CertificationBody";
import Office from "../../Models/Office/Office";

export function IsSuspended(certificationBody: CertificationBody): boolean {
  if (!certificationBody || certificationBody.suspensionStatus == null)
    return false;
  return certificationBody.suspensionStatus !== "Active";
}

export function getOfficeStatusDisplay(office: Office): EntityStatus {
  let { status } = office;
  if (office?.certificationBody?.suspensionStatus) {
    if (office.certificationBody.suspensionStatus === suspensionType.FULL)
      status = EntityStatus.SUSPENDED;
    else if (
      office.certificationBody.suspensionStatus === suspensionType.LIMITED
    )
      status = EntityStatus.PROBATION;
  }
  return status;
}
