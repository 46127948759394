import React from "react";
import Column from "../../../../components/DataTable/models/Column";
import { LinkText } from "../../../../components/Common/hyperlinks";
import AuditRow from "../models/AuditRow";
import { AuditUrls } from "../../index";

const auditColumns: Column<AuditRow>[] = [
  {
    title: "Audit Id",
    field: "Id",
    render: (rowData: AuditRow) => (
      <LinkText link={AuditUrls.details(rowData.Id)} text={rowData.Id} />
    ),
  },
  { title: "Site", field: "SiteName" },
  {
    title: "Site Code",
    field: "SiteId",
  },
  {
    title: "Certification Body",
    field: "CertificationBody",
    filtering: true,
  },
  {
    title: "Program Owner",
    field: "ProgramOwner",
    render: (data) => data.ProgramOwner,
  },
  {
    title: "Standard",
    field: "Standard",
  },
  {
    title: "Audit Owner",
    field: "AuditOwner",
    filtering: true,
  },
  {
    title: "Audit Date",
    field: "AuditStartDate",
    sorting: true,
    type: "date",
    filtering: true,
  },
  {
    title: "Validation Date",
    field: "AuditValidationDate",
    sorting: true,
    type: "date",
    filtering: true,
  },
  {
    title: "Certification Status",
    field: "CertificationStatus",
  },
  {
    title: "Record Status",
    field: "AuditStatus",
  },
];

export default auditColumns;
