import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import CreatePage, { Field } from "../../../components/CreatePage";
import Location, {
  defaultLocation,
} from "../../../Models/CommonProps/Location";
import Option from "../../../Models/forms/Option";
import Contact, {
  ContactType,
  defaultContact,
} from "../../../Models/CommonProps/Contact";
import CompanySelector from "../../../components/CompanySelector";
import Entity from "../../../Models/APIGetData/Entity";
import axios from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import PostSite, { ContactDetails } from "./model/PostSite";
import postSite from "./http/postSite";
import { SiteUrls } from "../index";
import Site from "../../../Models/site/Site";
import CompanyUser from "../../../Models/site/CompanyUser";
import { isCompanyUser } from "../../../Authentication/Auth";
import useLocationInput from "../../../util/location/useLocationInput";
import useContactInput from "../../../util/contact/useContactInput";
import CertificationBodySelector from "../../../components/CertificationBodySelector";

interface CreateSiteRedirectState {
  companyId: number;
  companyName: string;
}

export enum SiteFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
}

interface SiteNewProps {
  readonly mode?: SiteFormMode;
  readonly onSubmit?: (payload: PostSite) => Promise<string>;
  readonly initialState: Site | null;
}

const SiteNew = ({
  mode = SiteFormMode.CREATE,
  onSubmit = postSite,
  location: routeLocation,
  initialState,
}: RouteComponentProps<Entity, {}, CreateSiteRedirectState> & SiteNewProps) => {
  const { companyId: stateCompanyId, companyName: stateCompanyName } =
    routeLocation.state ?? {};
  const isFromCompanyDetails = !!stateCompanyId;
  const id = initialState?.id;
  const [location, setLocation] = useState<Location>(
    initialState?.location ?? defaultLocation
  );

  const locationFields = useLocationInput(location, setLocation);
  const [name, setName] = useState(initialState?.name ?? "");
  const [tradingAsName, setTradingName] = useState(
    initialState?.tradingAsName ?? ""
  );
  const status = initialState?.status;
  const [company, setCompany] = useState<Option | null>(
    ((): Option | null => {
      if (initialState?.companyId) {
        return {
          label: initialState.companyName,
          value: initialState.companyId,
        };
      }
      if (isFromCompanyDetails) {
        return { label: stateCompanyName, value: stateCompanyId };
      }
      return null;
    })()
  );
  const [certificationBody, setCertificationBody] = useState<Option | null>(
    null
  );

  const [technicalContact, setTechnicalContact] = useState<Contact>(
    initialState?.technicalContact ?? defaultContact
  );
  const [commercialContact, setCommercialContact] = useState<Contact>(
    initialState?.commercialContact ?? defaultContact
  );
  const [generalContact, setGeneralContact] = useState<Contact>(
    initialState?.generalContact ?? defaultContact
  );

  const generalContactInput = useContactInput(
    generalContact,
    setGeneralContact,
    { phoneRequired: false, contactType: ContactType.General }
  );
  const technicalContactInput = useContactInput(
    technicalContact,
    setTechnicalContact,
    { contactType: ContactType.Technical }
  );

  const commercialContactInput = useContactInput(
    commercialContact,
    setCommercialContact,
    { contactType: ContactType.Commercial }
  );
  const history = useHistory();

  const toPayload = (): PostSite => {
    const mapContact = (
      contact?: Contact
    ):
      | {
          readonly id?: number;
          readonly contactDetails: ContactDetails;
        }
      | undefined => {
      if (!contact) return undefined;
      return {
        id: contact.id,
        contactDetails: {
          id: contact.id,
          phoneNumber: contact.phoneNumber,
          faxNumber: contact.faxNumber,
          email: contact.email,
          name: contact.name,
        },
      };
    };
    return {
      site: {
        id,
        companyId: company?.value ? +company.value : -1,
        name,
        tradingAsName,
        location,
      },
      technicalContact: mapContact(technicalContact),
      generalContact: mapContact(generalContact),
      commercialContact: mapContact(commercialContact),
      certificationBodyExternalId: certificationBody?.value
        ? +certificationBody.value
        : -1,
    };
  };

  useEffect(() => {
    if (!company?.value) return;
    axios
      .get<CompanyUser[]>(
        `${BackendServices.USER_SERVICE.USER}/getCompanyUsers?companyId=${company.value}`
      )
      .then();
  }, [company]);

  const fields: (Field | string)[] = [
    {
      name: "SiteName",
      label: "Site name",
      value: name,
      setTextValue: setName,
      required: true,
      disabled: isCompanyUser(),
    },
    {
      name: "TradingName",
      label: "Trading As name",
      value: tradingAsName,
      setTextValue: setTradingName,
      required: false,
      disabled: isCompanyUser(),
    },
    {
      name: "SiteCame",
      label: "Site Code",
      value: `${id}`,
      disabled: true,
      show: mode === SiteFormMode.EDIT,
    },
    {
      name: "status",
      label: "Status",
      value: status,
      disabled: true,
      show: mode === SiteFormMode.EDIT,
    },
    {
      name: "company",
      render: () => (
        <CompanySelector
          label="Select company"
          value={company}
          onSelect={setCompany}
          disabled={isFromCompanyDetails || isCompanyUser()}
          sharedOnly
          required
        />
      ),
    },

    ...locationFields,
    {
      name: "cb",
      show: mode === SiteFormMode.CREATE,
      render: () => (
        <CertificationBodySelector
          value={certificationBody}
          onChange={setCertificationBody}
        />
      ),
    },
    ...generalContactInput,
    ...technicalContactInput,
    ...commercialContactInput,
  ];

  const onFormSubmit = () =>
    onSubmit(toPayload()).then((siteId) =>
      history.push(SiteUrls.details(siteId))
    );

  return (
    <CreatePage
      title={mode === SiteFormMode.CREATE ? "Create Site" : "Edit Site"}
      subtitle={name}
      fields={fields}
      onSubmit={onFormSubmit}
    />
  );
};

export default SiteNew;
