import NamedEntity from "../APIGetData/NamedEntity";

export default interface Location {
  readonly id?: number;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly addressLine3?: string;
  readonly city: string;
  readonly zipCode?: string;
  readonly country: NamedEntity | null;
  readonly county?: string;
}

export const defaultLocation: Location = {
  id: -1,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  county: "",
  zipCode: "",
  country: null,
};
