import React from "react";
import clsx from "clsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Heading from "../Common/Heading";
import styles from "./FilterGroup.module.scss";
import FilterComponentProps from "./components/FilterComponentProps";
import CheckboxFilter from "./components/CheckboxFilter";
import Option from "../../Models/forms/Option";

export interface Filter<T> {
  readonly selected: Option[];
  readonly options: Option[];
  readonly enabled?: boolean;
  readonly title: string;
  readonly name: T;
  readonly component?: (props: FilterComponentProps) => JSX.Element;
  readonly renderOption?: (value: Option) => React.ReactNode;
}

interface FilterGroupProps<T> {
  readonly reset: () => void;
  readonly filters: Filter<T>[];
  readonly handleChange: (name: T, values: Option[]) => void;
  readonly className?: string;
}

const FilterGroup = <T extends {}>({
  reset,
  filters,
  handleChange,
  className,
}: FilterGroupProps<T>) => (
  <div className={clsx(styles.FilterGroup, className)}>
    <div className={styles.header}>
      <Heading text="FILTER" />
      <IconButton className={styles.resetButton} onClick={reset}>
        Clear all
      </IconButton>
    </div>

    {filters.map(
      ({
        title,
        enabled = true,
        options,
        selected,
        name,
        component: FilterComponent = CheckboxFilter,
        renderOption,
      }) => {
        const onChange = (values: Option[]) => {
          handleChange(name, values);
        };
        return (
          <Accordion key={title} disabled={!enabled} className="filter-row">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {title}
              {selected.length > 0 && (
                <IconButton
                  className={styles.resetButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange([]);
                  }}
                >
                  Clear
                </IconButton>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <FilterComponent
                key={title}
                options={options}
                selected={selected}
                onChange={onChange}
                renderOption={renderOption}
              />
            </AccordionDetails>
          </Accordion>
        );
      }
    )}
  </div>
);

export default FilterGroup;
