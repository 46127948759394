import useGetData from "../../../../http/useGetData";
import Site from "../../../../Models/site/Site";
import getSiteDetails from "../../../../http/site/getSiteDetails";

export default (id: string) => {
  const { isLoading, data, refresh } = useGetData<Site>(() =>
    getSiteDetails(id)
  );

  return { isLoading, site: data, refresh };
};
