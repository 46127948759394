import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import CheckBox from "../../../../components/Common/CheckBox";
import Spinner from "../../../../components/Spinner";
import styles from "./SharingTable.module.scss";
import { updatedCells } from "../../index";

interface SharingCellProps {
  readonly id: number;
  readonly onShare: (value: boolean) => Promise<AxiosResponse>;
  readonly onComplete: (newValue: boolean) => void;
  readonly checked: boolean;
}

const SharingCell = ({
  id,
  onShare,
  checked,
  onComplete,
}: SharingCellProps) => {
  const [isLoading, setLoading] = useState(false);

  const [newCheckedValue, setNewCheckedValue] = useState<boolean>(
    updatedCells[id] ?? checked
  );
  useEffect(() => {
    setNewCheckedValue(updatedCells[id] ?? checked);
  }, [id]);

  const onChange = (value: boolean) => {
    setLoading(true);
    onShare(value)
      .then(() => {
        onComplete(value);
        setNewCheckedValue(value);
        updatedCells[id] = value;
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.sharingCell}>
      {isLoading ? (
        <Spinner delay={0} className={styles.spinner} />
      ) : (
        <CheckBox
          checked={newCheckedValue}
          onChange={(event, value) => {
            if (!isLoading) onChange(value);
          }}
        />
      )}
    </div>
  );
};

export default SharingCell;
