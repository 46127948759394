import { MinifiedOrganisationType, PrimaryOrganisations } from "../constants";
import sortPrimaryOrgs from "./sortPrimaryOrgs";
import Option from "../../../../Models/forms/Option";

export default function mapPrimaryOrgs(
  orgs: PrimaryOrganisations[]
): Option<MinifiedOrganisationType>[] {
  const options = orgs
    .map((companyType) => companyType.items)
    .flat()
    .map((org) => ({
      label: org.name,
      value: org.externalId,
      type: org.type,
    }));
  return sortPrimaryOrgs(options);
}
