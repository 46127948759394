import React from "react";
import { RouteComponentProps } from "react-router-dom";
import CompanyNew from "../CompanyNew";
import useGetCompanyDetails from "../CompanyDetails/http/useGetCompanyDetails";
import Spinner from "../../../components/Spinner";
import { Field } from "../../../components/CreatePage";
import UpdateCompanyData from "../../../Models/APIPutData/Company/UpdateCompanyData";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import history from "../../../components/Common/History";
import { CompanyUrls } from "../index";
import PostCompany from "../CompanyNew/models/PostCompany";

const CompanyEdit = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { isLoading, company } = useGetCompanyDetails(id);
  if (isLoading) return <Spinner />;

  const onSubmit = (postBody: PostCompany) =>
    axiosApi
      .put<UpdateCompanyData>(
        `${BackendServices.COMPANY_SERVICE.COMPANY}/${id}`,
        mapStateToPutData(postBody)
      )
      .then(() => history.push(CompanyUrls.details(id)));

  const mapStateToPutData = (postBody: PostCompany): UpdateCompanyData => {
    return {
      Company: {
        Id: company?.id ?? -1,
        Name: postBody.company.name,
        Website: postBody.company.website,
        BlockSharing: postBody.company.blockSharing,
        Location: postBody.company.location,
      },
      Contact: {
        Id: company?.contact.id,
        ContactDetails: {
          Email: postBody.contact.contactDetails.email,
          PhoneNumber: postBody.contact.contactDetails.phoneNumber,
          FaxNumber: postBody.contact.contactDetails.faxNumber,
        },
      },
    };
  };

  const editFields: Field[] = [
    { label: "Id", value: `${company?.id}`, name: "status", disabled: true },
    {
      label: "Status",
      value: `${company?.status}`,
      name: "id",
      disabled: true,
    },
  ];
  return (
    <CompanyNew
      company={company}
      editOnlyFields={editFields}
      onSubmit={onSubmit}
    />
  );
};

export default CompanyEdit;
