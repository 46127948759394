import React from "react";
import { Grid } from "@material-ui/core";
import AuditorStatus from "../../../../Enums/AuditorStatus";
import useStandards from "../../../../http/public/useStandards";
import useCategories from "../../../../http/public/useCategories";
import axios from "../../../Api/Axios";
import BackendServices from "../../../Api/BackendService";
import {
  deleteAuditorCategory,
  suspendAuditorCategory,
  unsuspendAuditorCategory,
} from "../../../Paths/paths";
import FormGridItem from "../../../Common/FormGridItem";
import styles from "../AuditorForm.module.scss";
import { SecondaryButton } from "../../../Button/button";
import StandardSelector from "./StandardSelector";
import { AuditorStandard } from "../../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import useGetTechnologies from "../../../../http/auditor/useGetTechnologies";
import { QualificationType } from "../models/AuditorQualification";

interface QualificationsProps {
  readonly auditorId: number;
  readonly isEditMode: boolean;
  readonly status: AuditorStatus | null;
  readonly refresh: () => void;
  readonly selectedStandards: AuditorStandard[];
  readonly setSelectedStandards: React.Dispatch<
    React.SetStateAction<AuditorStandard[]>
  >;
}

export default function QualificationsSection({
  auditorId,
  isEditMode,
  status,
  refresh,
  selectedStandards,
  setSelectedStandards,
}: QualificationsProps) {
  const standards = useStandards();
  const categories = useCategories();
  const technologies = useGetTechnologies();

  const availableStandardOptions = standards.filter(
    (s) => !selectedStandards.some((_s) => _s.id === s.id)
  );

  const deleteQualification = (qualificationId: number) =>
    axios
      .delete(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}${deleteAuditorCategory}/${auditorId}/${qualificationId}`
      )
      .then(refresh);

  const suspendQualification = (qualificationId: number) =>
    axios
      .put(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}${suspendAuditorCategory}/${auditorId}/${qualificationId}`
      )
      .then(refresh);

  const unsuspendQualification = (qualificationId: number) =>
    axios
      .put(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}${unsuspendAuditorCategory}/${auditorId}/${qualificationId}`
      )
      .then(refresh);

  return (
    <FormGridItem>
      {selectedStandards.map((standard: AuditorStandard, index) => (
        <StandardSelector
          standard={standard}
          standardOptions={availableStandardOptions}
          qualificationOptions={[
            ...(technologies.filter((tech) => tech.standardId === standard.id)
              .length === 0
              ? categories
                  .filter((c) => c.standardName === standard.name)
                  .map((category) => ({
                    entityType: QualificationType.CATEGORY,
                    entityId: category.externalId,
                    justificationsAndExceptions: "",
                    entityName: category.name,
                  }))
              : []),
            ...technologies
              .filter((tech) => tech.standardId === standard.id)
              .map((tech) => ({
                entityId: tech.technologyId,
                entityName: tech.name,
                justificationsAndExceptions: "",
                entityType: QualificationType.TECHNOLOGY,
              })),
          ]}
          deleteQualification={deleteQualification}
          suspendQualification={suspendQualification}
          unsuspendQualification={unsuspendQualification}
          key={standard.id}
          isEditAfterValidation={
            isEditMode && status === AuditorStatus.VALIDATED
          }
          updateStandard={(newStandard: AuditorStandard) =>
            setSelectedStandards((prev) =>
              prev.map((s, i) => (index === i ? newStandard : s))
            )
          }
          removeStandard={() =>
            setSelectedStandards((prev) =>
              prev.filter((s) => s.id !== standard.id)
            )
          }
        />
      ))}

      <Grid className={styles.addStandardButton} item>
        {availableStandardOptions.length > 0 && (
          <SecondaryButton
            text="Add new standard"
            onClick={() => {
              setSelectedStandards((prev) => [
                ...prev,
                { ...availableStandardOptions[0], qualifications: [] },
              ]);
            }}
          />
        )}
      </Grid>
    </FormGridItem>
  );
}
