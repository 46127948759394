import { useEffect, useState } from "react";
import Option from "../../../../Models/forms/Option";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (): Option[] => {
  const [certificationStatusOptions, setCertificationStatusOptions] = useState<
    Option[]
  >([]);

  useEffect(() => {
    axiosApi
      .get<{ name: string; id: number }[]>(
        `${BackendServices.REPORT_SERVICE.REPORT}/data/certificationStatuses`
      )
      .then(({ data }) =>
        setCertificationStatusOptions(
          data.map((val) => ({ label: val.name, value: val.id }))
        )
      );
  }, []);

  return certificationStatusOptions;
};
