import React from "react";
import Column from "../../../../components/DataTable/models/Column";
import { LinkText } from "../../../../components/Common/hyperlinks";
import { RecallUrls } from "../../index";
import RecallRow from "../models/RecallRow";

const recallColumns: Column<RecallRow>[] = [
  {
    title: "Submission Id",
    field: "id",
    render: (rowData: RecallRow) => (
      <LinkText link={RecallUrls.details(rowData.id)} text={rowData.id} />
    ),
  },
  { title: "Site Code", field: "siteCode" },
  { title: "Site Name", field: "siteName" },
  { title: "Country", field: "country" },
  { title: "Certification Body", field: "certificationBody" },
  { title: "Incident Category", field: "recallCategory" },
  { title: "Initial Notification Date", field: "initialNotificationDate" },
  { title: "Submission Status", field: "recallStatus" },
];

export default recallColumns;
