import React from "react";
import { Pagination } from "@material-ui/lab";
import styles from "./DataTablePagination.module.scss";

export interface DataTableInnerPaginationProps {
  readonly count: number;
  readonly onChangePage: (event: any, page: number) => void;
  readonly page: number;
}

interface DataTablePaginationProps {
  readonly innerProps: DataTableInnerPaginationProps;
  readonly columns: number;
  readonly totalPages: number;
}

const DataTablePagination = ({
  columns,
  totalPages,
  innerProps: { page, onChangePage },
}: DataTablePaginationProps) => (
  <td colSpan={columns} className={styles.tablePagination}>
    <Pagination
      className="pagination"
      variant="outlined"
      color="primary"
      count={totalPages}
      page={page + 1}
      onChange={(event, newPage) => onChangePage(event, newPage - 1)}
    />
  </td>
);

export default DataTablePagination;
