import React from "react";
import { FilterProps } from "../templates/filterMappings";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import Option from "../../../Models/forms/Option";
import DebouncedMultipleSelector from "../../../components/DebouncedMultipleSelector";

const OrganisationFilter = ({ name, value, setValue }: FilterProps) => {
  const getOptions = (input: string): Promise<Option[]> =>
    axiosApi
      .get<string[]>(
        `${BackendServices.REPORT_SERVICE.REPORT}/data/organisations?filter=${input}`
      )
      .then(({ data }) =>
        data.map((result): Option => ({ label: result, value: result }))
      );
  return (
    <DebouncedMultipleSelector
      getOptions={getOptions}
      value={value as Option[]}
      onSelect={setValue}
      label={name}
    />
  );
};

export default OrganisationFilter;
