import React from "react";
import MultipleSelectDropdown from "../../../components/MultipleSelectDropdown";
import { FilterProps } from "../templates/filterMappings";
import Option from "../../../Models/forms/Option";

const DropdownFilter = ({
  name,
  value,
  optionSupplier,
  setValue,
}: FilterProps) => {
  const options = optionSupplier?.() ?? [];

  return (
    <MultipleSelectDropdown
      label={name}
      value={value as Option[]}
      options={options}
      onChange={setValue}
    />
  );
};

export default DropdownFilter;
