import React, { useEffect, useState } from "react";
import SyncIcon from "@material-ui/icons/Sync";
import { IconButton, MenuItem, Select } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import moment from "moment";
import AuditDetailsView from "../../../../../Models/Audit/AuditDetailsView";
import useGetEditData from "./util/useGetEditData";
import EditedData from "./models/AuditEditData";
import axiosApi from "../../../../../components/Api/Axios";
import BackendServices from "../../../../../components/Api/BackendService";
import { details as detailsPath } from "../../../../../components/Paths/paths";
import EditButtons from "../../../../../components/EditButtons";
import InfoBox from "../../../../../components/Infobox";
import {
  DEFAULT_DATE_FORMAT,
  formatDate,
} from "../../../../../util/dates/DateFormats";
import Option from "../../../../../Models/forms/Option";
import AuditPostResponse from "../../../AuditNew/http/AuditPostResponse";
import { AUDIT_UPDATE_SUCCESS_STATUSES } from "../../../AuditNew/http/PostAuditStatus";
import UpdatePrompt from "../UpdatePromt";
import parseInt from "../../../../../util/number/parseInt";
import { LinkText } from "../../../../../components/Common/hyperlinks";
import { SiteUrls } from "../../../../SiteManagement";
import { CompanyUrls } from "../../../../CompanyManagement";
import AuditStatus from "../../../models/AuditStatus";
import ListEntry, {
  ListEntryValue,
} from "../../../../../Models/CommonProps/ListEntry";
import CompanySiteSelector, {
  CompanySiteOptionType,
} from "../../../../../components/CompanySiteSelector";
import SelectField from "../../../../../components/SelectField";
import { mapNamedEntityToOption } from "../../../../../components/AuditorManagement/AuditorForm/TrainingSection";
import DatePicker from "../../../../../components/DatePicker";
import InputField from "../../../../../components/InputField";
import { isCBUser } from "../../../../../Authentication/Auth";
interface AuditDetailsProps {
  readonly mainDetails?: AuditDetailsView | null;
  readonly refresh: () => void;
  readonly handleAddressSync: () => void;
  readonly handleSiteNameSync: () => void;
}

function AuditDetails({
  mainDetails,
  refresh,
  handleAddressSync,
  handleSiteNameSync,
}: AuditDetailsProps) {
  const [auditOwner, setAuditOwner] = useState<Option<
    CompanySiteOptionType
  > | null>(
    mainDetails?.auditOwner
      ? {
          value: mainDetails.auditOwner.id,
          label: mainDetails.auditOwner.name,
        }
      : null
  );
  const [newAvailableInPortal, setNewAvailableInPortal] = useState(
    mainDetails?.availableInPortal ?? false
  );
  const [
    newCertificationStatus,
    setNewCertificationStatus,
  ] = useState<Option | null>(null);

  const [newScope, setNewScope] = useState<string | null | undefined>(
    mainDetails?.scope
  );
  const [newExclusion, setNewExclusion] = useState<string | null | undefined>(
    mainDetails?.exclusion
  );

  const [newJustification, setNewJustification] = useState<
    string | null | undefined
  >(mainDetails?.justification);

  const [newStartDate, setNewStartDate] = useState<string | null | undefined>(
    mainDetails?.startDate
  );

  const [newIssueDate, setNewIssueDate] = useState<string | null | undefined>(
    mainDetails?.certificateIssueDate
  );

  const [newExpiryDate, setNewExpiryDate] = useState<string | null | undefined>(
    mainDetails?.certificateExpiryDate
  );

  const [auditorOwnerError, setAuditorOwnerError] = useState(false);

  const [isEditing, setEditing] = useState(false);

  const { certificationStatuses } = useGetEditData();

  useEffect(() => {
    const status = certificationStatuses.find(
      (cs) => cs.id === mainDetails?.certificationStatus.id
    );
    setNewCertificationStatus(status ? mapNamedEntityToOption(status) : null);
  }, [certificationStatuses]);

  const [
    updateResponse,
    setUpdateResponse,
  ] = useState<AuditPostResponse | null>(null);
  let startDateEdit=false;
  if(isEditing)
    {
      if(isCBUser())
       {
     startDateEdit=false;
       }
     else
       {
    startDateEdit=true;
       }
     }
    

  const submitChanges = (confirmed = false): undefined | Promise<any> => {
    setAuditorOwnerError(false);
    if (!mainDetails) return undefined;
    if (
      !auditOwner ||
      (`${auditOwner.value}` === "0" && auditOwner.label === null)
    ) {
      setAuditorOwnerError(true);
      return undefined;
    }
    const editedValues: EditedData = {
      auditOwnerType: auditOwner.type ?? mainDetails.auditOwner.type,
      auditOwnerId: parseInt(auditOwner.value) ?? mainDetails.auditOwner.id,
      isVisibleInPublicDirectory:
        newAvailableInPortal ?? mainDetails.availableInPortal,
      certificationStatusId: +(
        newCertificationStatus?.value ?? mainDetails.certificationStatus.id
      ),
      startDate: newStartDate,
      issueDate: newIssueDate,
      expiryDate: newExpiryDate,
      scope: newScope,
      exclusion: newExclusion,
      justification: newJustification,
      confirmed,
    };

    return axiosApi
      .put<AuditPostResponse>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${detailsPath}/${mainDetails.id}`,
        editedValues
      )
      .then(({ data }): AuditPostResponse => data)
      .then((response) => {
        if (AUDIT_UPDATE_SUCCESS_STATUSES.includes(response.status)) {
          refresh();
        } else {
          setUpdateResponse(response);
        }
      });
  };

  const addressComponent = (): ListEntryValue => {
    const { addressLine3, addressLine2, addressLine1 } =
      mainDetails?.location ?? {};
    const addresses = (
      <ul>
        {[addressLine1, addressLine2, addressLine3]
          .filter((a) => !!a)
          .map((address, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{address}</li>
          ))}
      </ul>
    );
    if (isEditing) {
      return () => (
        <div className="audit-details__main__details__sync-row">
          {addresses}
          <IconButton className="sync-button" onClick={handleAddressSync}>
            <SyncIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }
    return () => addresses;
  };

  const siteNameComponent = () => {
    const siteName = mainDetails ? mainDetails.site.name : "";
    if (isEditing) {
      return () => (
        <div className="audit-details__main__details__sync-row">
          {siteName}
          <IconButton className="sync-button" onClick={handleSiteNameSync}>
            <SyncIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }
    return siteName;
  };

  const recordStatusOnHolding = () => {
    const text = mainDetails?.recordStatus;
    if (text === AuditStatus.HOLDING) {
      return () => (
        <div>
          {text} <ReportProblemOutlinedIcon fontSize="small" />
        </div>
      );
    }
    return text;
  };

  const auditOwnerComponent = () => {
    const id = mainDetails?.auditOwner?.id ?? "";
    const text = mainDetails?.auditOwner?.name ?? "";
    const fullText = `${id} - ${text}`;
    return text
      ? () => <LinkText link={CompanyUrls.details(id)} text={fullText} />
      : fullText;
  };

  const siteCodeComponent = () => {
    const text = mainDetails?.site?.id ?? "";
    return text
      ? () => <LinkText link={SiteUrls.details(text)} text={text} />
      : text;
  };

  const certificationStatusComponent = () =>
    isEditing && mainDetails
      ? () => (
          <SelectField
            label=""
            value={newCertificationStatus}
            options={certificationStatuses.map(mapNamedEntityToOption)}
            onChange={(input) => setNewCertificationStatus(input)}
          />
        )
      : mainDetails?.certificationStatus?.name ?? "";

  const availableInPortalComponent = () => {
    const text = mainDetails?.availableInPortal ? "Yes" : "No";
    return isEditing
      ? () => (
          <Select
            onChange={(input) => {
              setNewAvailableInPortal(input.target.value === 1);
            }}
            value={newAvailableInPortal ? 1 : 0}
          >
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </Select>
        )
      : text;
  };

  const auditDetailsColumn1: ListEntry[] = [
    {
      label: "Site Code",
      value: siteCodeComponent(),
      required: true,
    },
    {
      label: "Site Name",
      value: siteNameComponent(),
      required: true,
    },
    {
      label: "Address",
      value: addressComponent(),
      required: true,
    },
    {
      label: "Site Owner",
      value: `${mainDetails?.company.id} - ${mainDetails?.company.name}`,
      required: true,
    },
    {
      label: "Audit owner",
      value: isEditing
        ? () => (
            <>
              <CompanySiteSelector
                value={auditOwner}
                onChange={setAuditOwner}
                label=""
              />
              {auditorOwnerError && <p className="required-text">Required</p>}
            </>
          )
        : auditOwnerComponent(),
      required: true,
    },
    {
      label: "Standard",
      value: mainDetails?.standard.name ?? "",
      required: true,
    },
    {
      label: "Certification Body",
      value: `${mainDetails?.certificationBody.name}`,
      required: true,
    },
    {
      label: "Scope",
      key: "auditScope",
      value: isEditing ? (
        <div className="audit-details__main__details__scope">
          <InputField
            label=""
            value={newScope}
            multiline
            onChange={(value) => setNewScope(value)}
          />
        </div>
      ) : (
        mainDetails?.scope ?? ""
      ),
      required: false,
    },
  ];

  const auditDetailsColumn2: ListEntry[] = [
    {
      label: "Audit ID",
      value: mainDetails?.id ?? "",
      required: true,
    },
    {
      label: "Record status",
      value: recordStatusOnHolding(),
      required: true,
    },
    {
      label: "Certification status",
      value: certificationStatusComponent(),
      required: true,
    },
    {
      label: "Audit start date",
      key: "ASDate",
      value:
      startDateEdit ? (
       <DatePicker
         value={newStartDate || null}
        onChange={(d) => setNewStartDate(formatDate(d))}
        label=""
        onClear={() => setNewStartDate("")}
        />
      ) :
       (
        moment(mainDetails?.startDate).format(DEFAULT_DATE_FORMAT) ?? ""
      ),
      required: true,
    },
    {
      label: "Certificate Issue Date",
      key: "CIDate",
      value: isEditing ? (
        <DatePicker
          value={newIssueDate || null}
          onChange={(d) => setNewIssueDate(formatDate(d))}
          label=""
          onClear={() => setNewIssueDate("")}
        />
      ) : (
        moment(mainDetails?.certificateIssueDate).format(DEFAULT_DATE_FORMAT) ??
        ""
      ),
      required: true,
    },
    {
      label: "Certificate Expiry Date",
      key: "CEDate",
      value: isEditing ? (
        <DatePicker
          value={newExpiryDate || null}
          onChange={(d) => setNewExpiryDate(formatDate(d))}
          label=""
          onClear={() => setNewExpiryDate("")}
        />
      ) : (
        moment(mainDetails?.certificateExpiryDate).format(
          DEFAULT_DATE_FORMAT
        ) ?? ""
      ),
      required: true,
    },
    {
      label: "Grade",
      value: mainDetails?.grade ?? "",
      required: true,
    },
    {
      label: "Validation date",
      value:
        moment(mainDetails?.validationDate).format(DEFAULT_DATE_FORMAT) ?? "",
      required: true,
    },

    {
      label: "Exclusion from scope",
      key: "scopeExclusion",
      value: isEditing ? (
        <div className="audit-details__main__details__exclusion">
          <InputField
            label=""
            value={newExclusion}
            multiline
            onChange={(value) => setNewExclusion(value)}
          />
        </div>
      ) : (
        mainDetails?.exclusion ?? ""
      ),
      required: false,
    },
    {
      label: "Justification for exclusion",
      key: "exclJustification",
      value: isEditing ? (
        <div className="audit-details__main__details__justification">
          <InputField
            label=""
            value={newJustification}
            multiline
            onChange={(value) => setNewJustification(value)}
          />
        </div>
      ) : (
        mainDetails?.justification ?? ""
      ),
      required: false,
    },
    {
      label: "Available in portal",
      value: availableInPortalComponent(),
      required: true,
    },
  ];

  const auditDetails = [];
  auditDetails.push(auditDetailsColumn1);
  auditDetails.push(auditDetailsColumn2);

  return (
    <>
      <UpdatePrompt
        response={updateResponse}
        onCancel={() => setUpdateResponse(null)}
        confirmChange={() => submitChanges(true)}
      />
      <InfoBox
        header="Details"
        items={auditDetails}
        columns={2}
        actionRow={
          mainDetails?.visuals.isAbleToEdit && (
            <EditButtons
              direction="left"
              isEditing={isEditing}
              setEditing={setEditing}
              submit={submitChanges}
              onComplete={() => {}}
            />
          )
        }
      />
    </>
  );
}

export default AuditDetails;
