import React from "react";
import CoreAuditorSearchTable from "../core/CoreAuditorSearchTable";
import pendingAuditorColumns from "./util/pendingAuditorColumns";
import { SelectedFilters } from "../index";

interface PendingAuditorSearchTableProps {
  readonly selectedFilters: SelectedFilters;
}

const PendingAuditorSearchTable = ({
  selectedFilters,
}: PendingAuditorSearchTableProps) => (
  <CoreAuditorSearchTable
    columns={pendingAuditorColumns}
    endpoint="/searchPending"
    selectedFilters={selectedFilters}
    isPending
  />
);

export default PendingAuditorSearchTable;
