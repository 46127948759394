import React, { FormEvent, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import moment from "moment";
import MainButton, {
  CancelButton,
  SecondarySubmitButton,
} from "../../Button/button";
import history from "../../Common/History";
import CountryDropdown from "../../Common/Input/CountryDropdown";
import CheckBox from "../../Common/CheckBox";
import axios from "../../Api/Axios";
import LanguageSection from "./LanguageSection";
import EducationSection from "./EducationSection";
import TrainingSection, {
  mapNamedEntityToOption,
  mapOptionToNamedEntity,
} from "./TrainingSection";
import styles from "./AuditorForm.module.scss";
import WitnessedAuditsSection from "./WitnessedAuditsSection";
import GFSIExamsSection from "./GFSIExamsSection";
import { details, getNotSharingReasons } from "../../Paths/paths";
import BackendServices from "../../Api/BackendService";
import { formatDate } from "../../../util/dates/DateFormats";
import CertificationBodySection from "./CertificationBodySection";
import ContentPage from "../../ContentPage";
import { AuditorUrls } from "../../../views/AuditorManagement";
import FormGridItem from "../../Common/FormGridItem";
import Snackbar from "../../Snackbar";
import AuditorStatus from "../../../Enums/AuditorStatus";
import QualificationsSection from "./QualificationSection";
import AuditorAccordion from "../../../views/AuditorManagement/AuditorDetails/components/AuditorAccordion";
import AuditorDetails, {
  AuditorNamedEntity,
  AuditorStandard,
  AuditorSubmissionBase,
  Education,
  GFSIExam,
  Training,
  WitnessedAudit,
} from "../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import { SubmitType } from "./constants";
import Option from "../../../Models/forms/Option";
import InputField from "../../InputField";
import SelectField from "../../SelectField";
import DatePicker from "../../DatePicker";
import sortNameAlphanumerical from "../../../util/sorting/sortNameAlphanumerical";

interface IAuditorForm {
  isEditMode: boolean;
}

export default function AuditorForm({ isEditMode }: IAuditorForm) {
  const todaysDate = formatDate(moment());
  const [externalId, setExternalId] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(todaysDate);
  const [country, setCountry] = useState<Option | null>(null);
  const [certificationBodies, setCertificationBodies] = useState<
    (null | AuditorNamedEntity)[]
  >([null]);
  const [isShared, setIsShared] = useState<boolean>(true);
  const [sharedReason, setSharedReason] = useState<Option | null>(null);
  const [status, setStatus] = useState<AuditorStatus | null>(null);
  const [workExperience, setWorkExperience] = useState<string>("");
  const [auditExperience, setAuditExperience] = useState<string>("");
  const [languageOptions, setLanguageOptions] = useState<Option[]>([]);
  const [notSharedOptions, setNotSharedOptions] = useState<Option[]>([]);
  const [languages, setLanguages] = useState<(AuditorNamedEntity | null)[]>([
    null,
  ]);
  const [education, setEducation] = useState<Education[]>([{} as Education]);
  const [trainings, setTrainings] = useState<Training[]>([{} as Training]);
  const [witnessedAudits, setWitnessedAudits] = useState<WitnessedAudit[]>([]);
  const [GFSIExams, setGFSIExams] = useState<GFSIExam[]>([]);
  const [qualifications, setQualifications] = useState<AuditorStandard[]>([]);
  const params: any = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitType, setSubmitType] = useState<SubmitType>(SubmitType.submit);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const submitMode = submitType === SubmitType.submit;

  useEffect(() => {
    getLanguageOptions();
    getNotSharedOptions();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      getAuditor();
    }
  }, []);

  const getLanguageOptions = () => {
    axios
      .get(`${BackendServices.AUDITOR_SERVICE.AUDITOR}/languages`)
      .then((response) =>
        setLanguageOptions(
          response.data.sort(sortNameAlphanumerical).map(mapNamedEntityToOption)
        )
      );
  };

  const getNotSharedOptions = () => {
    axios
      .get<AuditorNamedEntity[]>(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}${getNotSharingReasons}`
      )
      .then((response) =>
        setNotSharedOptions(response.data.map(mapNamedEntityToOption))
      );
  };

  const getAuditor = () => {
    axios
      .get(`${BackendServices.AUDITOR_SERVICE.AUDITOR}${details}/${params.id}`)
      .then((response) => mapAuditorData(response.data));
  };

  const refetchQualifications = () => {
    axios
      .get(`${BackendServices.AUDITOR_SERVICE.AUDITOR}${details}/${externalId}`)
      .then((response) => setQualifications(response.data.standards))
      .catch((error) => console.log(error));
  };

  const mapAuditorData = (auditor: AuditorDetails) => {
    setExternalId(auditor.externalId);
    setFirstName(auditor.firstName);
    setLastName(auditor.lastName);
    setStartDate(auditor.startDate);
    setCountry({ value: auditor.country.id, label: auditor.country.name });
    setIsShared(auditor.isShared);
    setSharedReason(
      auditor.sharedReason ? mapNamedEntityToOption(auditor.sharedReason) : null
    );
    setStatus(auditor.status as AuditorStatus);
    setWorkExperience(auditor.workExperience);
    setAuditExperience(auditor.auditExperience);
    setCertificationBodies(auditor.certificationBodies ?? []);
    setLanguages(auditor.languages);
    setEducation(auditor.education);
    setTrainings(auditor.qualifications);
    setWitnessedAudits(auditor.witnessedAudits);
    setGFSIExams(auditor.gfsiExams);
    setQualifications(auditor.standards);
  };

  const mapToPost = (): AuditorSubmissionBase => {
    return {
      firstName,
      lastName,
      startDate: startDate ?? "",
      countryExternalId: +(country?.value ?? -1),
      certificationBodies: mapNotNull(certificationBodies),
      isShared,
      sharedReason: sharedReason
        ? mapOptionToNamedEntity(sharedReason)
        : undefined,
      languages: mapNotNull(languages),
      education,
      witnessedAudits,
      trainings,
      gfsiExams: GFSIExams,
      workExperience,
      auditExperience,
      qualifications: qualifications
        .map((q) => q.qualifications)
        .flat()
        .map((q) => ({
          entityId: q.entityId,
          entityType: q.entityType,
          externalId: q.qualificationId,
          justificationAndExceptions: q.justificationsAndExceptions,
        })),
      externalId,
    };
  };

  const redirectToDetailsPage = (id: number) => {
    history.push(AuditorUrls.details(id));
  };

  const updateAuditor = () =>
    axios
      .put(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}/updateAuditor/${submitMode}`,
        mapToPost()
      )
      .then(() => redirectToDetailsPage(externalId));

  const createAuditor = () =>
    axios
      .post<number>(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}/insertAuditor/${submitMode}`,
        mapToPost()
      )
      .then((response) => redirectToDetailsPage(response.data));

  const getSubmitAction = (): Promise<any> => {
    if (isEditMode) {
      return updateAuditor();
    }
    return createAuditor();
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setShowSubmitError(false);
    getSubmitAction()
      .catch(() => {
        setShowSubmitError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <ContentPage
      title={isEditMode ? "Edit auditor profile" : "Add new auditor profile"}
      subtitle={isEditMode ? `${firstName} ${lastName}` : ""}
    >
      <ValidatorForm
        id="auditor-form"
        onSubmit={handleSubmit}
        className="validator-form"
        onKeyDown={(e: KeyboardEvent) => {
          // @ts-ignore
          if (e.which === 13 && e.target?.tagName !== "TEXTAREA")
            e.preventDefault();
        }}
      >
        <Grid container spacing={3} direction="column">
          {isEditMode && (
            <FormGridItem>
              <InputField
                name="AuditorNumber"
                value={`${externalId}`}
                disabled
                label="Auditor Number"
              />
            </FormGridItem>
          )}
          <FormGridItem>
            <InputField
              name="FirstName"
              label="First name"
              value={firstName}
              onChange={setFirstName}
              required
            />
          </FormGridItem>
          <FormGridItem>
            <InputField
              name="LastName"
              label="Last name"
              value={lastName}
              onChange={setLastName}
              required
            />
          </FormGridItem>
          <FormGridItem>
            <DatePicker
              value={startDate}
              label="Auditor start date"
              name="startDate"
              required
              onChange={(d) => d && setStartDate(formatDate(d))}
            />
          </FormGridItem>
          <FormGridItem>
            <CountryDropdown value={country} onChange={setCountry} required />
          </FormGridItem>
          <FormGridItem>
            <CheckBox
              label="Shared profile"
              checked={isShared}
              onChange={() => setIsShared(!isShared)}
            />
          </FormGridItem>
          {!isShared && (
            <FormGridItem>
              <SelectField
                name="SharedReason"
                label="Justification for not sharing a profile"
                options={notSharedOptions}
                value={sharedReason}
                onChange={setSharedReason}
                required
              />
            </FormGridItem>
          )}
          <FormGridItem>
            <AuditorAccordion heading="Certification bodies" expanded>
              <CertificationBodySection
                selectedCertificationBodies={certificationBodies}
                setSelectedCertificationBodies={setCertificationBodies}
              />
            </AuditorAccordion>
          </FormGridItem>
          <FormGridItem>
            <AuditorAccordion heading="Languages" expanded>
              <LanguageSection
                languageOptions={languageOptions}
                selectedLanguages={languages}
                setSelectedLanguages={setLanguages}
              />
            </AuditorAccordion>
          </FormGridItem>
          <FormGridItem>
            <AuditorAccordion heading="Education" expanded>
              <EducationSection
                selectedEducation={education}
                setSelectedEducation={setEducation}
              />
            </AuditorAccordion>
          </FormGridItem>
          <FormGridItem>
            <AuditorAccordion heading="Work experience" expanded>
              <InputField
                className={styles.experienceInputAccordionDetails}
                name="WorkExperience"
                label="Work experience"
                value={workExperience}
                required
                onChange={setWorkExperience}
              />
            </AuditorAccordion>
          </FormGridItem>
          <FormGridItem>
            <AuditorAccordion heading="Audit experience" expanded>
              <InputField
                className={styles.experienceInputAccordionDetails}
                name="AuditExperience"
                label="Audit experience"
                value={auditExperience}
                required
                onChange={setAuditExperience}
              />{" "}
            </AuditorAccordion>
          </FormGridItem>
          <FormGridItem>
            <AuditorAccordion heading="Training" expanded>
              <TrainingSection
                newDate={todaysDate}
                selectedTraining={trainings}
                setSelectedTraining={setTrainings}
              />
            </AuditorAccordion>
          </FormGridItem>
          <FormGridItem>
            <AuditorAccordion heading="Witnessed Audits">
              <WitnessedAuditsSection
                newDate={todaysDate}
                selectedWitnessedAudits={witnessedAudits}
                setSelectedWitnessedAudits={setWitnessedAudits}
              />
            </AuditorAccordion>
          </FormGridItem>
          <FormGridItem>
            <AuditorAccordion heading="GFSI Exams">
              <GFSIExamsSection
                newDate={todaysDate}
                languageOptions={languageOptions}
                selectedGFSIExams={GFSIExams}
                setSelectedGFSIExams={setGFSIExams}
              />
            </AuditorAccordion>
          </FormGridItem>
          <QualificationsSection
            auditorId={externalId}
            isEditMode={isEditMode}
            status={status}
            refresh={refetchQualifications}
            selectedStandards={qualifications}
            setSelectedStandards={setQualifications}
          />
          <FormGridItem>
            {isEditMode && status && status !== AuditorStatus.IN_PROGRESS ? (
              <MainButton
                disabled={isSubmitting}
                text="Submit all"
                onClick={() => setSubmitType(SubmitType.submit)}
              />
            ) : (
              <>
                <MainButton
                  disabled={isSubmitting}
                  onClick={() => setSubmitType(SubmitType.submit)}
                />
                <SecondarySubmitButton
                  disabled={isSubmitting}
                  text="Save"
                  onClick={() => setSubmitType(SubmitType.save)}
                />
              </>
            )}
            <CancelButton onClick={() => goBack()} />
            {showSubmitError && <p>Could not submit changes</p>}
          </FormGridItem>
        </Grid>
      </ValidatorForm>
      <Snackbar
        close={() => setShowConfirmation(false)}
        message="Auditor profile saved"
        open={showConfirmation}
      />
    </ContentPage>
  );
}

const mapNotNull = (
  input: (AuditorNamedEntity | null)[]
): AuditorNamedEntity[] => input.filter((val) => val) as AuditorNamedEntity[];
