import React from "react";
import { LinkText } from "../../../components/Common/hyperlinks";

function About() {
  return (
    <div>
      <h1>About & FAQs</h1>

      <h2>About</h2>

      <p>
        The BRCGS Directory is the official database of all audits conducted
        against a BRCGS Standard. Certification bodies administrate all changes
        to site listing, audit documents and certification status information
        held on the Directory.
      </p>

      <p>
        Due to concerns with financial crime, terrorist financing, political,
        and business risks associated with Cuba and Iran, BRCGS no longer
        publishes any information relating to Cuba- or Iran-related sites.
      </p>

      <p>The Directory is available in two formats;</p>

      <ol>
        <li>
          Public access certificate search Directory
          <br />
          Certificate details for currently certificated sites
          <br />
          Results exclude expired, suspend or withdrawn sites
          <br />
          Certification body search function listing only BRCGS approved audit
          bodies
        </li>
        <li>Password protected private area</li>
      </ol>

      <p>
        Hosts complete and authenticate BRCGS audit reports and certificates in
        PDF format.
        <br />
        Allows audit owners to share their audit documents with registered
        retailers, manufacturers and other specifiers.
        <br />
        Describes why and when certification has expired or been withdrawn.
      </p>

      <h3>Notification alerts</h3>

      <p>
        Directory account holders receive email alerts relating to audits they
        own or that are shared with them on the Directory by the audit owner.
      </p>

      <p>
        New report availability, or replacement
        <br />
        Certificate expiry, suspension or withdrawal
        <br />
        Removal of access privileges (cancelled sharing)
      </p>

      <h3>Exportable reporting suite</h3>

      <p>
        The Directory features a reports suite, allowing account holders to
        export audit information for all shared and owned audits in .XLS or .CSV
        spreadsheets
        <br />
        Report results describe the most recent set of audit information
        available & include key content such as non-conformities by clause,
        severity, resolution
        <br />
        This allows easy analysis of supplier performance, removing the need to
        access and read individual audit documents on a one-by-one basis
      </p>

      <h2>FAQs</h2>

      <h3>Who can access my audit details on the Directory?</h3>

      <p>
        Audit owners can choose who can access their audit documents via the
        Directory. Audit owners can share their complete BRCGS audit report and
        certificate in a PDF format with any Directory participant retailer,
        manufacturer or specifier. Audit owners can also remove sharing and
        access at any time, administrated via the Directory. The only exception
        to this rule is where a specifier has a contractual agreement with their
        supplier&#39;s allowing access to BRCGS audit documents as a contingency
        of supply. In this instance audit owners will be notified that their
        documents are available to the customer and access can be removed on the
        administration of the audit owner.
      </p>

      <h3>Whose audit details can I access on the Directory?</h3>

      <p>
        Directory users can access the BRCGS audit reports and certificates that
        have been shared with them by the audit owners.
      </p>

      <h3>Why can&#39;t I find a site listed on the public Directory?</h3>

      <p>
        The public Directory lists sites who are currently certificated against
        any of the BRCGS Standards. Expired, suspended or withdrawn status sites
        are not listed. Certificated sites can also choose to be removed from
        public listing.
      </p>

      <h3>How do I get my details corrected on the Directory?</h3>

      <p>
        All Directory administration is conducted by the certification body that
        conducted the audit. For changes, updates and corrections, contact the
        certification body.
      </p>

      <h3>What is the BRCGS site code?</h3>

      <p>
        The BRCGS site code is a 6 or 7-digit number unique to each audited
        site.
        <br />
        Site codes are location specific and created for the site&#39;s initial
        audit by the auditing certification body.
        <br />
        Site codes are permanent, remaining constant irrespective of changes to
        site name, certificated status, Standard or auditing certification body.
        <br />
        All BRCGS audit reports and certificates detail the relevant site code.
      </p>

      <h3>How do I get a username and password for Directory access?</h3>

      <p>
        Please complete and submit the following form;{" "}
        <LinkText
          link="https://form.jotform.com/61322554434955"
          text="https://form.jotform.com/61322554434955"
          external
        />
      </p>

      <h3>How do I get a certificated supplier logo?</h3>

      <p>
        Please complete and submit a logo application form;{" "}
        <LinkText
          link="https://form.jotform.com/61323530244949"
          text="https://form.jotform.com/61323530244949"
          external
        />
      </p>

      <h3>How do I validate document authenticity?</h3>

      <p>
        Documents obtained from the Directory (and not from suppliers) can be
        considered complete, contemporary and authenticated.
      </p>

      <h2>Assistance & Queries</h2>

      <p>
        If you require assistance using the Directory, or would like to ask a
        question about it, please
        <LinkText
          link="https://form.jotform.com/210703256820043"
          text=" complete this form"
          external
        />
      </p>

      <p>
        Please do not use this form if you represent a BRCGS approved
        Certification Body.
      </p>
    </div>
  );
}

export default About;
