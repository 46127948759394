import { useEffect, useState } from "react";
import useDebounced from "../../../util/debouncer/useDebouncer";
import Option from "../../../Models/forms/Option";

export default <T extends any>(
  getOptions: (searchTerm: string) => Promise<Option<T>[]>,
  value?: Option<T> | Option<T>[] | null,
  sorter?: (a: Option<T>, b: Option<T>) => number,
  groupBy?: (option: Option<T>) => string
) => {
  const [searchResult, setSearchResult] = useState<Option<T>[]>([]);
  const [initialSearchPerformed, setInitialSearchPerformed] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounced(searchTerm);

  useEffect(() => {
    setSearchResult([]);
  }, [getOptions]);

  useEffect(() => {
    if (
      (Array.isArray(value) || debouncedSearchTerm !== value?.label) &&
      debouncedSearchTerm.length >= 2
    ) {
      setLoading(true);
      getOptions(debouncedSearchTerm)
        .then((options) =>
          setSearchResult(groupBy ? options : options.sort(sorter))
        )
        .catch(() => [])
        .finally(() => {
          setLoading(false);
          setInitialSearchPerformed(true);
        });
    }
  }, [debouncedSearchTerm]);
  return {
    searchResult,
    initialSearchPerformed,
    isLoading,
    setSearchTerm,
    searchTerm,
  };
};
