import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import EditableText from "../../EditableFields/EditableText";
import {
  AuditFieldType,
  AuditTableColumn,
  AuditTableRow,
} from "../../../models/AuditTabs";
import { CoreTableChildProps } from "../CoreTable";

type SortDirection = "asc" | "desc";

type numberSorter = (a: number, b: number) => number;
type stringSorter = (a: string, b: string) => number;

const getSortingFunction = (
  type: AuditFieldType
): numberSorter | stringSorter => {
  switch (type) {
    case "Numeric Field":
      return (a: number, b: number) => a - b;
    case "Calendar":
      return (a: string, b: string) => (new Date(a) > new Date(b) ? 1 : -1);
    default:
      return (a: string, b: string) => (a > b ? 1 : -1);
  }
};

interface TableSimpleProps extends CoreTableChildProps {
  readonly columns: AuditTableColumn[];
  readonly rows: AuditTableRow[];
}

const TableSimple = ({
  columns,
  rows,
  updateRow,
  deleteRow,
  isEditing,
}: TableSimpleProps) => {
  const [sortingIndex, setSortingIndex] = useState(0);
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const toggleDirection = () =>
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");

  const ascendingData: AuditTableRow[] = isEditing
    ? rows
    : rows.sort((aRow, bRow) =>
        getSortingFunction(columns[sortingIndex].type)(
          // @ts-ignore
          aRow.cells[sortingIndex].data,
          bRow.cells[sortingIndex].data
        )
      );

  const sortedData: AuditTableRow[] =
    sortDirection === "desc" ? ascendingData.reverse() : ascendingData;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={column.id}>
              <TableSortLabel
                active={index === sortingIndex}
                direction={sortDirection}
                onClick={
                  index === sortingIndex
                    ? toggleDirection
                    : () => setSortingIndex(index)
                }
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map(({ id, cells }) => {
          return (
            <TableRow key={id}>
              {cells.map(({ id: cellId, data, xmlTag }, index) => (
                <TableCell key={cellId}>
                  <EditableText
                    isEditing={isEditing}
                    type={columns[index].type}
                    value={data}
                    xmlTag={xmlTag}
                    setUpdateValue={(_xmlTag, _value) =>
                      updateRow(id, _xmlTag, _value)
                    }
                  />
                </TableCell>
              ))}
              <TableCell className="tabbed-details__delete-row-cell">
                {isEditing && (
                  <IconButton onClick={() => deleteRow(id)}>
                    <RemoveIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableSimple;
