import Column from "../../../../../components/DataTable/models/Column";
import AuditorStatus from "../../../../../Enums/AuditorStatus";
import AuditorRow from "../../core/CoreAuditorSearchTable/models/AuditorRow";
import coreAuditorColumns from "../../core/CoreAuditorSearchTable/utils/auditorColumns";

const mainAuditorColumns: Column<AuditorRow>[] = [
  ...coreAuditorColumns,
  {
    title: "Status",
    field: "status",
    render: (data) =>
      data.status === AuditorStatus.IN_REVISION
        ? "Pending validation"
        : data.status,
  },
];
export default mainAuditorColumns;
