import React from "react";
import CountryOption from "../../../Models/APIGetData/CountryOption";
import SearchableDropdown from "../../SearchableDropdown";
import axiosApi from "../../Api/Axios";
import BackendServices from "../../Api/BackendService";
import Option from "../../../Models/forms/Option";

interface CountryDropdownProps {
  value?: Option | null;
  required?: boolean;
  onChange: (country: Option | null) => void;
  disabled?: boolean;
}

export default class CountryDropdown extends React.Component<
  CountryDropdownProps,
  { options: Option[] }
> {
  constructor(props: CountryDropdownProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getCountryOptions();
  }

  getCountryOptions(): void {
    axiosApi
      .get<CountryOption[]>(`${BackendServices.PUBLIC_SERVICE}/countries`)
      .then<Option[]>((response) =>
        CountryDropdown.mapCountriesToOptions(response.data)
      )
      .then((options) => options.sort((a, b) => (a.label < b.label ? -1 : 1)))
      .then((data) => {
        this.setState(() => ({ options: data }));
      })
      .catch((error) => console.log(error));
  }

  private static mapCountriesToOptions(response: CountryOption[]): Option[] {
    return response.map((element: CountryOption) => ({
      value: element.externalId.toString(),
      label: element.name,
    }));
  }

  render() {
    const { required, value, onChange, disabled } = this.props;
    const { options } = this.state;

    return (
      <SearchableDropdown
        required={required}
        Options={options}
        label="Select country"
        value={value}
        name="selectCountry"
        onChange={(e: React.ChangeEvent<{}>, option: Option | null) =>
          onChange?.(option)
        }
        disabled={disabled}
      />
    );
  }
}
