import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import { RouteComponentProps, useHistory } from "react-router-dom";
import CreatePage, { Field } from "../../../components/CreatePage";
import { officeType } from "../../../Enums/officeType";
import { required } from "../../../Enums/ValidationRule";
import { mainText, subText } from "../../../constants/CBConstants";
import { SelectValidation } from "../../../components/Common/Input/Select";
import Entity from "../../../Models/APIGetData/Entity";
import CreateCBLocationStateProps from "../../../Models/CBProps/CreateCBLocationStateProps";
import Option from "../../../Models/forms/Option";
import AccreditationBodyDropdown from "../../../components/Common/Input/AccreditationBodyDropdown";
import { RatingMenuItems } from "../../../constants/RatingMenuItems";
import CheckBoxList from "../../../components/Common/CheckBoxList";
import useStandards from "../../../http/public/useStandards";
import Contact, { defaultContact } from "../../../Models/CommonProps/Contact";
import useContactInput from "../../../util/contact/useContactInput";
import Location, {
  defaultLocation,
} from "../../../Models/CommonProps/Location";
import useLocationInput from "../../../util/location/useLocationInput";
import { InsertCertificationBody } from "./models/CBSubmission";
import Office from "../../../Models/Office/Office";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { CBUrls } from "../index";
import { InsertOffice, UpdateOffice } from "./models/OfficeSubmission";
import CertificationBodySelector from "../../../components/CertificationBodySelector";
import { isBRCGSUser } from "../../../Authentication/Auth";

interface CBNewProps {
  readonly office?: Office;
  readonly title?: string;
}

const CBNew = ({
  location: routeLocation,
  office,
  title = "Add certification body",
}: RouteComponentProps<Entity, {}, CreateCBLocationStateProps> &
  CBNewProps) => {
  const {
    state: { fromDetailsPage, cb: externalCb } = {
      fromDetailsPage: false,
      cb: null,
    },
  } = routeLocation;
  const isEditing = !!office;

  const [type, setType] = useState<officeType>(
    (() => {
      if (isEditing) {
        return office?.isMainOffice ? officeType.MAIN : officeType.SUB;
      }
      if (fromDetailsPage || !isBRCGSUser()) return officeType.SUB;
      return officeType.MAIN;
    })()
  );

  const [name, setName] = useState(office?.name ?? "");
  const [cb, setCb] = useState<Option | null>(externalCb);
  const [ab, setAb] = useState<Option | null>(null);
  const [rating, setRating] = useState(0);
  const [ratingComments, setRatingComments] = useState("");
  const [selectedStandards, setSelectedStandards] = useState<string[]>(
    (office?.standards ?? []).map((s) => s.id.toString())
  );
  const [visibleInPublicDirectory, setVisibleInPublicDirectory] = useState(
    office?.visibility ?? false
  );
  const [contact, setContact] = useState<Contact>(
    office?.contact ?? { ...defaultContact }
  );
  const [location, setLocation] = useState<Location>(
    office?.location ?? {
      ...defaultLocation,
    }
  );
  const standards = useStandards();
  const contactFields = useContactInput(contact, setContact);
  const locationFields = useLocationInput(location, setLocation);
  const history = useHistory();
  const fields: (Field | string)[] = [
    {
      name: "officeType",
      show: !isEditing,
      render: () => (
        <SelectValidation
          name="OfficeType"
          label="Office type"
          disabled={fromDetailsPage}
          value={type}
          onChange={(e: React.ChangeEvent<{ value: officeType }>) =>
            setType(e.target.value)
          }
          validators={[required.name]}
          errorMessages={[required.message]}
        >
          {isBRCGSUser() && (
            <MenuItem value={officeType.MAIN}>{mainText}</MenuItem>
          )}
          <MenuItem value={officeType.SUB}>{subText}</MenuItem>
        </SelectValidation>
      ),
    },
    {
      name: "name",
      label: "Office name",
      value: name,
      setTextValue: setName,
      required: true,
    },
    {
      name: "cb",
      show: type === officeType.SUB && !isEditing,
      render: () => (
        <CertificationBodySelector
          disabled={fromDetailsPage}
          required
          value={cb}
          onChange={setCb}
        />
      ),
    },
    ...(type === officeType.MAIN && !isEditing
      ? [
          {
            name: "ab",
            show: type === officeType.MAIN && !isEditing,
            render: () => (
              <AccreditationBodyDropdown
                required
                accreditationBody={ab}
                updateAccreditationBody={setAb}
              />
            ),
          },
          {
            name: "rating",
            render: () => (
              <SelectValidation
                name="Rating"
                label="Rating"
                value={rating}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setRating(+e.target.value)
                }
                validators={[required.name]}
                errorMessages={[required.message]}
              >
                {RatingMenuItems()}
              </SelectValidation>
            ),
          },
          {
            name: "ratingComment",
            label: "Rating Comments",
            value: ratingComments,
            setTextValue: setRatingComments,
          },
        ]
      : []),
    {
      name: "standards",
      render: () => (
        <CheckBoxList
          CheckBoxArray={standards.map(({ id, name: standardName }) => ({
            id: id.toString(),
            name: standardName,
          }))}
          checkedList={selectedStandards}
          Heading="Standards"
          handleToggleBox={({ target: { id } }, selected) =>
            setSelectedStandards((prev) =>
              selected ? [...prev, id] : prev.filter((s) => s !== id)
            )
          }
        />
      ),
    },
    "Other",
    {
      name: "pdVisible",
      label: "Visible in public directory",
      setBooleanValue: setVisibleInPublicDirectory,
      value: visibleInPublicDirectory,
    },
    "Contact",
    ...contactFields,
    "Location",
    ...locationFields,
  ];

  const submitNewSubOffice = () => {
    const postBody: InsertOffice = {
      name,
      isMainOffice: false,
      certificationBodyId: +(cb?.value ?? -1),
      standards: selectedStandards.map((standard) => +standard),
      isVisibleInPublicDirectory: visibleInPublicDirectory,
      contact,
      location,
    };

    return axiosApi
      .post<InsertOffice>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/office`,
        postBody
      )
      .then(() => history.push(CBUrls.searchTable));
  };

  const submitNewCB = () => {
    const body: InsertCertificationBody = {
      accreditationBodyId: +(ab?.value ?? -1),
      rating,
      ratingComments,
      officeInsert: {
        contact,
        location,
        isMainOffice: true,
        name,
        standards: selectedStandards.map((s) => +s),
        isVisibleInPublicDirectory: visibleInPublicDirectory,
      },
      removeFromPublicView: !visibleInPublicDirectory,
    };
    return axiosApi
      .post<InsertCertificationBody>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}`,
        body
      )
      .then(() => history.push(CBUrls.searchTable));
  };

  const submitEditedOffice = () => {
    const { id } = office ?? {};
    if (!id) return Promise.reject();
    const putBody: UpdateOffice = {
      name,
      isMainOffice: type === officeType.MAIN,
      standards: selectedStandards.map((s) => +s),
      isVisibleInPublicDirectory: visibleInPublicDirectory,
      contact,
      location,
    };

    return axiosApi
      .put<UpdateOffice>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/office/${id}`,
        putBody
      )
      .then(() => history.push(CBUrls.details(id)));
  };

  const onSubmit = (): Promise<any> => {
    if (isEditing) return submitEditedOffice();
    if (type === officeType.MAIN) return submitNewCB();
    return submitNewSubOffice();
  };
  return (
    <CreatePage
      subtitle={name}
      title={title}
      fields={fields}
      onSubmit={() => onSubmit()}
    />
  );
};

export default CBNew;
