import { Options } from "material-table";

const tableOptions: Partial<Options<any>> = {
  actionsColumnIndex: -1,
  pageSize: 10,
  sorting: true,
  draggable: false,
  search: true,
  debounceInterval: 750,
};

export default tableOptions;
