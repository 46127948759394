import React from "react";
import { LinkText } from "../../Common/hyperlinks";
import { UserRow } from "../models/UserRow";
import Column from "../../DataTable/models/Column";
import { UserUrls } from "../../../views/UserManagement";
import { getUserRole } from "../../../Authentication/Auth";
import UserRole from "../../../Models/user/UserRole";

const userColumns: () => Column<UserRow>[] = () => {
  const userRole: UserRole = getUserRole();
  return [
    {
      title: "Email",
      field: "email",
      render: (rowData: UserRow) =>
        userRole !== UserRole.SITE_USER ||
        rowData.roleName === UserRole.SITE_USER ? (
          <LinkText
            link={UserUrls.details(rowData.identificator)}
            text={rowData.email}
          />
        ) : (
          rowData.email
        ),
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Organisation",
      field: "organisationName",
      filtering: true,
      type: "string",
    },
    {
      title: "Role",
      field: "roleName",
      render: (data: UserRow) => data.roleName,
    },
    {
      title: "Status",
      field: "status",
    },
  ];
};

export default userColumns;
