import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { UserUrls } from "../../UserManagement";
import UserParent from "../../UserManagement/UserNew/util/UserParent";
import UserTable from "../../../components/UserTable";

const SiteUsers = ({
  match: {
    params: { id },
  },
  location: { state },
}: RouteComponentProps<
  { readonly id: string },
  {},
  { readonly name: string }
>) => {
  const { name = "" } = state ?? {};
  return (
    <UserTable
      subtitle={name}
      queryParams={{ organisationType: "Site", organisationId: id }}
      createUrl={UserUrls.create({ [UserParent.SITE]: id })}
    />
  );
};

export default SiteUsers;
