import { useEffect, useState } from "react";
import Option from "../../../../Models/forms/Option";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { SetFilterValue } from "../filterMappings";
import { FilterState } from "../../index";

export default (
  filterState: FilterState,
  setValue: SetFilterValue
): Option[] => {
  const [auditorStatuses, setAuditorStatuses] = useState<Option[]>([]);

  useEffect(() => {
    axiosApi
      .get<{ readonly statusId: number; readonly statusName: string }[]>(
        `${BackendServices.REPORT_SERVICE.REPORT}/data/statuses`
      )
      .then(({ data }) => {
        const options: Option[] = data.map((value) => ({
          label: value.statusName,
          value: value.statusId,
        }));
        setAuditorStatuses(options);
        const statuses = options.filter((o) => o.label !== "Archived");
        if (statuses) setValue(statuses);
      });
  }, []);
  return auditorStatuses;
};
