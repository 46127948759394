import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Divider } from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <>
      {value === index && (
        <Box display="flex" alignItems="center" justifyContent="center">
          {children}
        </Box>
      )}
    </>
  );
}

export default function TabsBar({
  value,
  labels,
  onChange,
  centered = true,
  className,
}: {
  labels: string[];
  value: number;
  onChange: (value: number) => void;
  centered?: boolean;
  className?: string;
}) {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onChange(newValue);
  };

  const renderTab = (label: string) => (
    <Tab className="tab" label={label} key={label} />
  );
  return (
    <div className={className}>
      <Tabs value={value} onChange={handleChange} centered={centered}>
        {labels.map((label: string) => renderTab(label))}
      </Tabs>
      <Divider />
    </div>
  );
}
