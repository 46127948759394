import React from "react";
import { MenuItem } from "@material-ui/core";
import StarRating from "../components/StarRating/StarRating";

const ratingValues: number[] = [0, 1, 2, 3, 4, 5];

export function RatingMenuItems() {
  return ratingValues.map((rating) => (
    <MenuItem key={`rating-${rating}`} value={rating}>
      <StarRating value={rating} size="small" />
    </MenuItem>
  ));
}
