import React from "react";
import {
  MinifiedOrganisation,
  MinifiedOrganisationType,
} from "../../../UserNew/constants";
import { CompanyUrls } from "../../../../CompanyManagement";
import { CBUrls } from "../../../../CBManagement";
import { SiteUrls } from "../../../../SiteManagement";
import { LinkText } from "../../../../../components/Common/hyperlinks";

interface OrganisationLinkTextProps {
  readonly organisation: MinifiedOrganisation;
  readonly mappedCBDetails: { [key: number]: number };
}
const OrganisationLinkText = ({
  organisation,
  mappedCBDetails,
}: OrganisationLinkTextProps) => {
  if (!organisation) return null;
  const url = (() => {
    switch (organisation.type) {
      case MinifiedOrganisationType.Company:
        return CompanyUrls.details(organisation.externalId);
      case MinifiedOrganisationType.CertificationBody: {
        const mappedId = mappedCBDetails[organisation.externalId];
        return mappedId ? CBUrls.details(mappedId) : undefined;
      }
      case MinifiedOrganisationType.Office:
        return CBUrls.details(organisation.externalId);
      case MinifiedOrganisationType.Site:
        return SiteUrls.details(organisation.externalId);
      default:
        return undefined;
    }
  })();

  return url ? (
    <LinkText link={url} text={organisation.name} />
  ) : (
    <>{organisation.name}</>
  );
};

export default OrganisationLinkText;
