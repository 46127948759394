import axios from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";

export default (auditId: string) =>
  axios
    .get(`${BackendServices.AUDIT_SERVICE.AUDIT}/${auditId}/PDF`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.split("filename=")[1].split(";")[0];

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // eslint-disable-next-line no-unused-expressions
      link.parentNode?.removeChild(link);
    });
