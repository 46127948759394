import Location from "../../../Models/CommonProps/Location";
import ListEntry from "../../../Models/CommonProps/ListEntry";

export default (location: Location): ListEntry[] => [
  {
    label: "Address",
    value: [
      location.addressLine1,
      location.addressLine2,
      location.addressLine3,
    ].filter((val) => !!val) as string[],
    required: true,
  },
  { label: "City", value: location.city, required: true },
  { label: "County", value: location.county },
  { label: "Zip Code/Post Code", value: location.zipCode },
  { label: "Country", value: location.country?.name },
];
