import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./DuoSpacing.module.scss";

interface DuoSpacingProps {
  readonly className?: string;
}

const DuoSpacing = ({
  className,
  children,
}: PropsWithChildren<DuoSpacingProps>) => (
  <div className={clsx(styles.duoSpacing, className)}>{children}</div>
);

export default DuoSpacing;
