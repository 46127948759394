import { Method } from "axios";
import HttpStatus from "./HttpStatus";

type SupportedMethods = Extract<Method, "get" | "post" | "patch">;

type Errors = {
  [key in SupportedMethods]: {
    [status in HttpStatus]: string;
  };
};

const errors: Errors = {
  get: {
    [HttpStatus.FORBIDDEN]:
      "You don't have access to get the selected resource",
    [HttpStatus.UNAUTHORIZED]: "You don't have access to the selected resource",
    [HttpStatus.NOT_FOUND]: "Could not get the selected resource",
  },
  post: {
    [HttpStatus.FORBIDDEN]:
      "You don't have access to submit the selected resource",
    [HttpStatus.UNAUTHORIZED]:
      "You don't have access to submit the selected resource",
    [HttpStatus.NOT_FOUND]: "Could not get the selected resource",
  },
  patch: {
    [HttpStatus.FORBIDDEN]:
      "You don't have access to change the selected resource",
    [HttpStatus.UNAUTHORIZED]:
      "You don't have access to change the selected resource",
    [HttpStatus.NOT_FOUND]: "Could not get the selected resource",
  },
};

export default (httpStatus: HttpStatus, method?: Method): string => {
  if (!httpStatus)
    return "Your request was unsuccessful due to a network error!";
  try {
    if (!method) return "Your request was unsuccessful.";
    return (
      errors[method?.toLowerCase() as SupportedMethods][httpStatus] ??
      "Server error"
    );
  } catch {
    return "Your request was unsuccessful.";
  }
};
