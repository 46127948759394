import React from "react";
import axios from "axios";
import { RouteComponentProps } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@material-ui/core";
import Entity from "../../../Models/APIGetData/Entity";
import AuditExpiryReasonFormState, {
  expiryDefaultState,
} from "../../../Models/AuditProps/AuditExpiryReasonFormState";
import ExpiryReasonInsert from "../../../Models/APIPostData/Audit/ExpiryReasonInsert";
import axiosApi from "../../../components/Api/Axios";
import { expiryReasons } from "../../../components/Paths/paths";
import { TextValidation } from "../../../components/Common/Input/Text";
import {
  expiryCommentsLabel,
  expiryCommentsTitle,
} from "../AuditDetails/components/Comments/constants";
import { SubmitButton } from "../../../components/Button/button";
import history from "../../../components/Common/History";
import BackendServices from "../../../components/Api/BackendService";
import AuditExpiryReasonFormProps from "./models/AuditExpiryReasonFormProps";
import ExpiryReasonsDropdown from "./ExpiryReasonsDropdown";
import ContentPage from "../../../components/ContentPage";
import { VerifyUrls } from "../index";
import { SiteUrls } from "../../SiteManagement";
import FormGridItem from "../../../components/Common/FormGridItem";
import { DatePicker } from "../../../components/Common/Input/DatePicker";

export default class AuditExpiryReasonForm extends React.Component<
  RouteComponentProps<{}, {}, AuditExpiryReasonFormProps>,
  AuditExpiryReasonFormState
> {
  signal = axios.CancelToken.source();

  constructor(
    props: RouteComponentProps<Entity, {}, AuditExpiryReasonFormProps>
  ) {
    super(props);
    this.state = { ...expiryDefaultState, ...props.location.state };
  }

  onSubmit() {
    const { auditId, expiryReason, comments, assessmentDate } = this.state;

    const postBody: ExpiryReasonInsert = {
      AuditId: +auditId,
      ReasonId: +expiryReason,
      ExpiryComments: comments,
      AssessmentDate: assessmentDate,
    };

    axiosApi
      .post<ExpiryReasonInsert>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${expiryReasons}`,
        postBody
      )
      .then(() => this.handlePostResponse())
      .catch((error) => console.log(error));
  }

  handlePostResponse() {
    const { fromAuditPage, auditId, site } = this.state;
    if (fromAuditPage) {
      history.push(VerifyUrls.details(auditId));
    } else {
      history.push(SiteUrls.details(site?.id ?? ""));
    }
  }

  updateExpiryReason(value: string): void {
    this.setState(() => {
      return { expiryReason: value };
    });
  }

  updateComments(value: string): void {
    this.setState(() => {
      return { comments: value };
    });
  }

  updateAssessmentDate(value: Date): void {
    this.setState(() => {
      return { assessmentDate: value };
    });
  }

  renderForm() {
    const { expiryReason, comments, assessmentDate } = this.state;
    return (
      <ValidatorForm
        onSubmit={() => this.onSubmit()}
        className="validator-form"
      >
        <Grid container spacing={3} direction="column">
          <FormGridItem>
            <ExpiryReasonsDropdown
              expiryReason={expiryReason}
              onChange={(reason: string) => this.updateExpiryReason(reason)}
            />
          </FormGridItem>
          <FormGridItem>
            <TextValidation
              name={expiryCommentsTitle}
              label={expiryCommentsLabel}
              value={comments}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.updateComments(e.target.value)
              }
            />
          </FormGridItem>
          <FormGridItem>
            <DatePicker
              value={assessmentDate ?? null}
              label="Assessment Date"
              onChange={(e: any) => this.updateAssessmentDate(e)}
            />
          </FormGridItem>
          <FormGridItem>
            <SubmitButton />
          </FormGridItem>
        </Grid>
      </ValidatorForm>
    );
  }

  render() {
    const { site } = this.state;
    return <ContentPage title={site.name}>{this.renderForm()}</ContentPage>;
  }
}
