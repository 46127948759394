import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import {Route, Router, Switch } from 'react-router-dom';
import "./css/index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import "./Authentication/Auth";
import { createBrowserHistory } from 'history';
 
// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);
 
const history = createBrowserHistory();
 
Sentry.init({
  dsn: "https://09f199fb386e1aafb63983fbbdb38d84@o4507090898321408.ingest.de.sentry.io/4507578201014352",
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    // or
    Sentry.reactRouterV4BrowserTracingIntegration({ history }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
 
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const allSettled = require("promise.allsettled");

allSettled.shim();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
