/* eslint-disable react/prop-types */

import React from "react";

function Heading({ text }) {
  return <div className="header">{text}</div>;
}

export function H1Heading({ text }) {
  return <h1 className="header">{text}</h1>;
}

export function H2Heading({ text }) {
  return <h2 className="header">{text}</h2>;
}

export function H3Heading({ text }) {
  return <h3 className="header">{text}</h3>;
}

export function H6Heading({ text }) {
  return <h6 className="header">{text}</h6>;
}

export default Heading;
