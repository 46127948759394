import React, { useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { IEmbedConfiguration } from "powerbi-client";
import { TokenType } from "powerbi-models";
import ContentPage from "../../components/ContentPage";
import DashboardSelector from "./components/dashboard-selector/DashboardSelector";
import getEmbedDetails from "./http/getEmbedDetails";
import styles from "./Dashboard.module.scss";

const DashboardsNew = () => {
  const [
    selectedDashboard,
    setSelectedDashboard,
  ] = useState<IEmbedConfiguration | null>(null);
  const [embedError, setEmbedError] = useState(false);

  const getDashboardDetails = (id: number) => {
    setEmbedError(false);
    getEmbedDetails(id)
      .then(({ data }) => {
        setSelectedDashboard({
          id: data.reportId,
          tokenType: TokenType.Embed,
          type: "report",
          embedUrl: data.embedUrl,
          pageName: data.name,
          accessToken: data.embedToken,
        });
      })
      .catch(() => setEmbedError(true));
  };

  return (
    <ContentPage title="Analytics">
      <div className={styles.dashboardSelector}>
        <DashboardSelector onDashboardSelect={getDashboardDetails} />
      </div>
      {embedError && <p>Could not load the selected dashboard.</p>}
      {selectedDashboard && (
        <div>
          {selectedDashboard.pageName && <h3>{selectedDashboard.pageName}</h3>}
          <PowerBIEmbed
            embedConfig={selectedDashboard}
            cssClassName={styles.dashboardContainer}
          />
        </div>
      )}
    </ContentPage>
  );
};

export default DashboardsNew;
