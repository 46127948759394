import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { edit } from "../../../../components/Paths/paths";
import { UserOrganisation } from "../../models/UserDetails";
import history from "../../../../components/Common/History";
import { UserUrls } from "../../index";

interface PutUserBody {
  readonly firstName: string;
  readonly lastName: string;
  readonly primaryOrganisation?: UserOrganisation;
  readonly secondaryOrganisations: UserOrganisation[];
  readonly phoneNumber: string;
  readonly role: string;
}

export default (body: PutUserBody, id: string | null) =>
  axiosApi
    .put(`${BackendServices.USER_SERVICE.USER}${edit}/${id}`, body)
    .then(() => history.push(UserUrls.details(id ?? "")));
