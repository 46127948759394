import React from "react";
import Option from "../../../../Models/forms/Option";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import { ReasonForNotificationOption } from "../../../../Models/Recall/ReasonForNotification";

interface ReasonForNotificationDropdownProps {
  value?: Option | null;
  required?: boolean;
  onChange: (reasonForNotification: Option | null) => void;
  label?: string;
}

export default class ReasonForNotificationDropdown extends React.Component<
  ReasonForNotificationDropdownProps,
  { options: Option[] }
> {
  constructor(props: ReasonForNotificationDropdownProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getReasonForNotificationOptions();
  }

  getReasonForNotificationOptions(): void {
    this.setState({ options: ReasonForNotificationOption });
  }

  render() {
    const { required, value, onChange, label } = this.props;
    const { options } = this.state;

    return (
      <SearchableDropdown
        required={required}
        Options={options}
        label={label ?? "Reason for Notification"}
        value={value}
        name="Reason for Notification"
        onChange={(e: React.ChangeEvent<{}>, option: Option | null) =>
          onChange?.(option)
        }
      />
    );
  }
}
