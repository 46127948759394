import React from "react";
import Column from "../../../../../../components/DataTable/models/Column";
import OfficeRow from "../models/OfficeRow";
import { LinkText } from "../../../../../../components/Common/hyperlinks";

const officeColumns: Column<OfficeRow>[] = [
  {
    title: "Office ID",
    field: "id",
  },
  {
    title: "Office Name",
    field: "name",
    searchable: true,
    filtering: false,
    render: (rowData: OfficeRow) => (
      <LinkText link={`${rowData.id}`} text={rowData.name} />
    ),
  },
  {
    title: "Country",
    field: "countryName",
  },
  {
    title: "Status",
    field: "status",
  },
];
export default officeColumns;
