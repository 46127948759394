import React, { PropsWithChildren } from "react";

class ErrorBoundary extends React.Component<
  PropsWithChildren<{}>,
  { readonly error: Error | null }
> {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return `Error: ${error.message}`;
    }
    return children;
  }
}

export default ErrorBoundary;
