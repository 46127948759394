import React from "react";
import { RouteComponentProps } from "react-router-dom";
import DetailsPage from "../../../components/DetailsPage";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import useGetCompanyDetails from "./http/useGetCompanyDetails";
import InfoBox from "../../../components/Infobox";
import mapLocationToEntries from "../../../components/Infobox/utils/mapLocationToEntries";
import AssociatedSiteSearchTable from "./components/AssociatedSitesTable";
import Box, { BoxPadding } from "../../../components/Box";
import EntityStatus from "../../../Enums/EntityStatus";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { UserUrls } from "../../UserManagement";
import UserParent from "../../UserManagement/UserNew/util/UserParent";
import Icon, { IconType } from "../../../components/Icon";
import { DropdownMenuItemType } from "../../../components/DropdownMenu";
import Permission from "../../../Authentication/model/Permission";
import PermissionArea from "../../../Authentication/model/PermissionArea";
import { CompanyUrls } from "../index";
import mapNamelessContactToEntries from "../../../components/Infobox/utils/mapNamelessContactToEntries";

const sharedCompanyDetailsMenu: DetailsPageMenuItem[] = [
  DetailsPageMenuItem.SHARE,
  DetailsPageMenuItem.EDIT,
  DetailsPageMenuItem.ARCHIVE,
];

const CompanyDetails = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { isLoading, company, refresh } = useGetCompanyDetails(id);
  if (isLoading || !company)
    return <DetailsPage isLoading={isLoading} title="" />;
  const isArchived = company.status === EntityStatus.ARCHIVED;

  const postArchive = () => {
    axiosApi
      .post(
        `${BackendServices.COMPANY_SERVICE.COMPANY}/${id}/${
          isArchived ? "unarchive" : "archive"
        }`
      )
      .catch(() => {})
      .finally(refresh);
  };

  return (
    <DetailsPage
      id={company.id}
      title={company.name}
      isLoading={isLoading}
      detailsPageMenuItems={[
        DetailsPageMenuItem.CHANGE_LOG,
        ...(company.isShared ? sharedCompanyDetailsMenu : []),
      ]}
      archiveConfig={{
        entityName: "company",
        isArchived,
        onClick: postArchive,
      }}
      menuItems={
        !company.isShared
          ? []
          : [
              {
                icon: <Icon type={IconType.USERS} />,
                label: "View Users",
                type: DropdownMenuItemType.LINK,
                state: { name: company.name },
                link: CompanyUrls.users(company.id),
                permissionRequired: [
                  {
                    permission: Permission.VIEW,
                    area: PermissionArea.USER,
                  },
                ],
              },
              {
                icon: <Icon type={IconType.USER_ADD} />,
                label: "Create User",
                type: DropdownMenuItemType.LINK,
                link: UserUrls.create({ [UserParent.COMPANY]: id }),
                permissionRequired: [
                  {
                    permission: Permission.GENERATE,
                    area: PermissionArea.USER,
                  },
                ],
              },
            ]
      }
      contentTabs={[
        {
          content: (
            <Box padding={BoxPadding.None} header="Associated Sites">
              <AssociatedSiteSearchTable
                companyId={id}
                companyName={company.name}
                isShared={company.isShared}
              />
            </Box>
          ),
        },
      ]}
    >
      <InfoBox
        header="Details"
        items={[
          { label: "Company ID", value: company.id, required: true },
          { label: "Status", value: company.status, required: true },
        ]}
      />
      <InfoBox
        header="Contact"
        columns={2}
        items={[
          ...mapLocationToEntries(company.location),
          ...mapNamelessContactToEntries(company.contact),
          {
            label: "Website",
            value: company.website
              ? {
                  label: company.website,
                  link: company.website,
                  external: true,
                }
              : "",
          },
        ]}
      />
    </DetailsPage>
  );
};

export default CompanyDetails;
