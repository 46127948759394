import { PublicFilter } from "./useGetPublicFilters";
import {
  getPublicCBs,
  getPublicSites,
  PublicMetaFilters,
} from "../../../store/public-directory/actions/getData";
import { PublicFilters } from "../../../store/public-directory";
import { DefaultThunkAction } from "../../../store/reducers";

export interface TabConfig {
  readonly filters: PublicFilter[];
  readonly searchPlaceholder: string;
  readonly searchHelpText: string[];
  readonly Message:string[];
  readonly getData: (
    metaFilters: PublicMetaFilters,
    selectedFilters: PublicFilters
  ) => DefaultThunkAction;
}

export enum PublicTab {
  SITE,
  CB,
}

type TabConfigs = {
  [key in PublicTab]: TabConfig;
};
const tabConfigImpl: TabConfigs = {
  [PublicTab.SITE]: {
    searchPlaceholder: "Search site name, trading as name, code or scope",
    filters: ["Country", "Grade", "AdditionalModules", "Standard", "Category"],
    searchHelpText: [
      "Entering a site name, trading as name, site code or scope into the search bar at the top",
      "Using the filter function on the left to filter by Country, Grade, Additional Modules, Standards and Category",
    ],
    Message:["BRCGS confirms that any entity, which is not listed as an approved certification body on BRCGS’s official Directory (https://directory.brcgs./) is not authorised to issue any certificate referencing any of BRCGS’s standards. We take product safety and the quality management process of certifying sites against our standards very seriously. We will take robust legal action against any unauthorised entity in any part of the world which issues certificates to any of our standards without our authorisation."],
    getData: getPublicSites,
  },
  [PublicTab.CB]: {
    searchPlaceholder: "Search certification body name",
    filters: ["Country", "Rating", "Standard"],
    searchHelpText: [
      "Entering a certification body name into the search bar at the top",
      "Using the filter function on the left to filter by Country, Rating or Standard",
    ],
    Message:["BRCGS confirms that any entity, which is not listed as an approved certification body on BRCGS’s official Directory (https://directory.brcgs./) is not authorised to issue any certificate referencing any of BRCGS’s standards. We take product safety and the quality management process of certifying sites against our standards very seriously. We will take robust legal action against any unauthorised entity in any part of the world which issues certificates to any of our standards without our authorisation."],
    getData: getPublicCBs,
  },
};

export default tabConfigImpl;
