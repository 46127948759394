import { UserAgentApplication } from "msal/lib-commonjs/UserAgentApplication";
import React, { useEffect, useState } from "react";
import { AuthResponse } from "msal";
import config from "./config";
import { dispatch } from "../components/App/App";
import getPermissions from "../store/permissions/actions/getPermissions";
import UserRole from "../Models/user/UserRole";
import { email } from "../Enums/ValidationRule";
import axiosApi from "../components/Api/Axios";
import BackendServices from "../components/Api/BackendService";
import { current } from "@reduxjs/toolkit";
import Intercom from '@intercom/messenger-js-sdk';
import moment from "moment";
import axios from "axios";
import { Menu } from "@material-ui/core";
import { useLocation } from "react-router-dom";



const request = { scopes: [process.env.REACT_APP_AUTH_SCOPE as string] };

let msalInstance: UserAgentApplication;
try {
  msalInstance = new UserAgentApplication(config);
} catch (e) {
  window.history.replaceState({}, document.title, window.location.origin);
  msalInstance = new UserAgentApplication(config);
}

msalInstance
  .acquireTokenSilent(request)
  .then(() => dispatch(getPermissions()))
  .catch(() => {});

export const verifyAuth = (): Promise<void | AuthResponse> => {
  if (!msalInstance.getAccount()) {
    msalInstance.loginRedirect(request);
    return Promise.reject();
  }
  return msalInstance.acquireTokenSilent(request).catch(() => {
    msalInstance.acquireTokenRedirect(request);
    return Promise.reject();
  });
};
export const getUserPrimaryOrg = ()=> msalInstance.getAccount()?.idTokenClaims.extension_Business;
export const getUserId = () => {
  return msalInstance.getAccount()?.accountIdentifier;
};

export const getAccessToken = async (): Promise<string> => {
  
  return msalInstance
    .acquireTokenSilent(request)
    .then((result) => result.accessToken)
    .catch(() => "");
    
};

export const getUsername = () => msalInstance.getAccount()?.name ?? "";

export const getUserEmail = () =>
  msalInstance.getAccount()?.idTokenClaims.emails[0] ?? "";

export const getUserRole = (): UserRole => {
  const {
    extension_Role: extensionRole,
  } = msalInstance.getAccount()?.idTokenClaims;
  return Object.values<string>(UserRole).includes(extensionRole)
    ? (extensionRole as UserRole)
    : UserRole.UNKNOWN;
};

export const isCBUser = () =>
  [
    UserRole.CB_AUDIT_ADMIN,
    UserRole.CB_AUDITOR_ADMIN,
    UserRole.CB_FULL_ADMIN,
    UserRole.CB_READ_ONLY,
    UserRole.CB_BI_USER,
    UserRole.CB_BI_USER_T,
  ].includes(getUserRole());

export const isBRCGSUser = () =>
  [
    UserRole.BRCGS_ADMIN,
    UserRole.BRCGS_AUDITOR_ADMIN,
    UserRole.BRCGS_AUDIT_ADMIN,
    UserRole.BRCGS_USER,
  ].includes(getUserRole());

  export const IsCommercialHorizon = () =>
  [
    UserRole.CB_COMMERCIAL_HORIZON,
  ].includes(getUserRole());
export const isCompanyUser = () =>
  [
    UserRole.COMPANY_BI_BO_USER,
    UserRole.COMPANY_BI_SC_USER,
    UserRole.COMPANY_BI_S_USER,
    UserRole.COMPANY_USER,
  ].includes(getUserRole());
  export const isCompanyBiUser = () =>
    [
      UserRole.COMPANY_BI_S_USER,
    ].includes(getUserRole());
export const signOut = () => {
  msalInstance.logout();
};

export const requireAuth = (WrappedComponent: any) => {
  return () => {
    const [authed, setAuthed] = useState(false);
    useEffect(() => {
      verifyAuth().then(() => {
        setAuthed(true)
        
      });
    }); 
    useEffect(() => {
      if (authed) {
        // Call setIntercomUser only once when the user is authenticated
        setIntercomUser(getUserEmail());
      }
    }, [authed]);
    if (authed) {
      
      return <WrappedComponent />;
     }
    return null;
  };
};

export const getCompanyId =()=>{
  const companyUser=isCompanyUser();
  let propertyValue="";
 
  const primaryOrg=getUserPrimaryOrg();
  const jsonData=JSON.parse(primaryOrg);
  
  let propertyNameCo = "co"
  let propertyNameCb="cb"
  let propertyNamea = "a"
  let propertyNameo="o";
  let propertyNames="s";
  
  if( jsonData[propertyNameCo].length==1)
  {
    propertyValue= jsonData[propertyNameCo];
  }
  else if(jsonData[propertyNameCb].length==1)
  {
    propertyValue= jsonData[propertyNameCb];
  }
  else if(jsonData[propertyNamea].length==1)
  {
    propertyValue= jsonData[propertyNamea];
  }
  else if(jsonData[propertyNameo].length==1)
  {
    propertyValue= jsonData[propertyNameo];
  }
  else if(jsonData[propertyNames].length==1)
  {
    propertyValue= jsonData[propertyNames];
  }
  
  return propertyValue;
};


export const setIntercomUser = async (email: string) => {
  const userHash = await axiosApi.get(`${BackendServices.PUBLIC_SERVICE}/GenerateHash?email=${email}`);
   

  //const count = await axiosApi.get(`${BackendServices.PUBLIC_SERVICE}/IntercomUserData?email=${email}`);
  
    Intercom({
      app_id: 'ug5rfhuu',
      name: getUsername(), // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: getUserEmail(), // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      Role:getUserRole(),
      LoginDateTime:new Date().toLocaleString(),
      Environment:process.env.REACT_APP_AUTH_REDIRECT,
      user_hash: userHash.data,
     // LoginMonth: count.data,
    });


};
 
