import React from "react";
import CheckBoxListProps from "../../Models/CommonProps/CheckBoxListProps";
import Checkboxes from "./CheckBox";
import CheckBoxItemProps from "../../Models/CommonProps/CheckBoxItemProps";
import { H3Heading } from "./Heading";

export default function CheckBoxList({
  Heading: heading,
  CheckBoxArray,
  checkedList,
  handleToggleBox,
}: CheckBoxListProps) {
  return (
    <div>
      <H3Heading text={heading} />
      {CheckBoxArray.map((checkbox: CheckBoxItemProps) => (
        <Checkboxes
          key={checkbox.id}
          id={checkbox.id}
          label={checkbox.name}
          checked={checkedList.includes(checkbox.id)}
          onChange={(e, c) => handleToggleBox(e, c)}
        />
      ))}
    </div>
  );
}
