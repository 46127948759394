import React from "react";
import { RouteComponentProps } from "react-router-dom";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { SpinnerCenter } from "../../../../components/Spinner";
import PublicCBSearch from "../../models/CB/PublicCBSearch";
import StarRating from "../../../../components/StarRating/StarRating";
import locationToString from "../../../../util/address/locationToString";
import { ContactType } from "../../../../Models/CommonProps/Contact";
import { getFullName } from "../../../../helpers/NameHelper";
import useGetData from "../../../../http/useGetData";
import Box from "../../../../components/Box";
import PublicDetails from "../PublicDetails";
import InfoBox from "../../../../components/Infobox";

const PublicCB = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const fetch = () =>
    axiosApi.get<PublicCBSearch>(
      `${BackendServices.PUBLIC_SERVICE.PUBLIC_DIRECTORY}/CertificationBody/${id}`
    );
  const { data, isLoading, error } = useGetData<PublicCBSearch>(fetch);

  if (isLoading) return <SpinnerCenter delay={0} />;
  if (error || !data)
    return (
      <PublicDetails title="Error">
        <Box> Could not load details for Certification Body {id}</Box>
      </PublicDetails>
    );

  const generalContact = data.contacts.find(
    (contact) => contact.contactType === ContactType.General
  );

  return (
    <PublicDetails title={data.name}>
      <InfoBox
        header="Details"
        columns={2}
        items={[
          {
            label: "Rating",
            value: () => <StarRating value={data?.rating} />,
          },
          { label: "Rating comments", value: data.ratingComments },
          { label: "Standards", value: data.standards.join(", ") },
          { label: "Status", value: data.status },
          { label: "Accreditation Body", value: data.accreditationBody },
        ]}
      />
      <InfoBox
        columns={2}
        header="Contact"
        items={[
          {
            label: "Address",
            value: locationToString(data.location),
          },
          {
            label: "Contact Person",
            value: generalContact ? getFullName(generalContact) : "",
          },
          {
            label: "Telephone",
            value: generalContact?.phoneNumber,
            required: true,
          },
          { label: "Email", value: generalContact?.email, required: true },
          { label: "Fax", value: generalContact?.faxNumber },
        ]}
      />
      {data.otherOffices
        .map((officeDetails, index) => ({ ...officeDetails, key: index }))
        .map((otherOffices) => {
          const otherOfficesContact = otherOffices.contacts.find(
            (contact) => contact.contactType === ContactType.General
          );
          return (
            <InfoBox
              key={otherOffices.key}
              header={otherOffices.isMainOffice ? "Main Office" : "Sub Office"}
              columns={2}
              items={[
                {
                  label: "Name",
                  value: otherOffices.name,
                },
                {
                  label: "Address",
                  value: locationToString(otherOffices.location),
                },
                {
                  label: "Contact Person",
                  value: otherOfficesContact
                    ? getFullName(otherOfficesContact)
                    : "",
                },
                {
                  label: "Telephone",
                  value: otherOfficesContact?.phoneNumber,
                },
                { label: "Email", value: otherOfficesContact?.email },
                { label: "Fax", value: otherOfficesContact?.faxNumber },
              ]}
            />
          );
        })}
    </PublicDetails>
  );
};

export default PublicCB;
