import React from "react";
import GenericRoutes from "../../routing/GenericRoutes";
import { privateArea } from "../../components/Paths/paths";
import AuditorEditPage from "../../components/AuditorManagement/AuditorEditPage";
import AuditorCreatePage from "../../components/AuditorManagement/AuditorCreatePage";
import GenericLinks from "../../routing/GenericLinks";
import AuditorSearchTables from "./AuditorSearchTable";
import PermissionArea from "../../Authentication/model/PermissionArea";
import AuditorDetails from "./AuditorDetails";

const PATH_AUDITOR_MANAGEMENT = `${privateArea}/auditor`;
export const AuditorUrls = new GenericLinks(PATH_AUDITOR_MANAGEMENT);

const AuditorRouting = GenericRoutes({
  root: PATH_AUDITOR_MANAGEMENT,
  permissionArea: PermissionArea.AUDITOR,
  urls: AuditorUrls,
  entityType: "auditor",
  components: {
    editComponent: AuditorEditPage,
    searchComponent: AuditorSearchTables,
    detailsComponent: AuditorDetails,
    createComponent: AuditorCreatePage,
  },
});

const AuditorRoutes = () => <AuditorRouting />;

export default AuditorRoutes;
