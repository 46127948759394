import React, { useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { TokenType } from "powerbi-models";
import { IEmbedConfiguration } from "powerbi-client";
import ContentPage from "../../components/ContentPage";
import { SecondaryButton, SubmitButton } from "../../components/Button/button";
import styles from "./Dashboard.module.scss";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import ReportDetails from "./models/ReportDetails";
import AccessToken from "./models/AccessToken";
import DuoSpacing from "../../components/DuoSpacing";

const Dashboards = () => {
  const [accessToken, setAccessToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [reportId, setReportId] = useState("");
  const [identityUserName, setIdentityUserName] = useState("");
  const [roles, setRoles] = useState("");
  const [customData, setCustomData] = useState("");
  const [error, setError] = useState("");
  const [
    reportDetails,
    setReportDetails,
  ] = useState<IEmbedConfiguration | null>(null);

  const signinForm = () => (
    <form
      className={styles.signin}
      onSubmit={(e) => {
        e.preventDefault();
        axiosApi
          .post<AccessToken>(
            `${BackendServices.REPORT_SERVICE.DASHBOARD}/signin`,
            {
              username,
              password,
            }
          )
          .then(({ data }) => setAccessToken(data.accessToken));
      }}
    >
      <label htmlFor="username">
        Power BI user:
        <input
          placeholder="Username"
          id="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
      </label>
      <label htmlFor="password">
        Password:
        <input
          placeholder="Password"
          id="password"
          value={password}
          type="password"
          onChange={(event) => setPassword(event.target.value)}
        />
      </label>
      <SubmitButton text="Signin" />
    </form>
  );

  const reportIdForm = () => {
    return (
      <form
        className={styles.signin}
        onSubmit={(e) => {
          e.preventDefault();
          setError("");
          axiosApi
            .post<ReportDetails>(
              `${BackendServices.REPORT_SERVICE.DASHBOARD}/reportDetails`,
              {
                accessToken,
                reportId,
                username: identityUserName,
                roles: roles.split(","),
                customData,
              }
            )
            .then(({ data }) =>
              setReportDetails({
                id: data.reportId,
                tokenType: TokenType.Embed,
                type: "report",
                embedUrl: data.embedUrl,
                pageName: data.name,
                accessToken: data.embedToken,
              })
            )
            .catch((err) => {
              setError(err.response?.data);
            });
        }}
      >
        <label htmlFor="reportId">
          Report id:
          <input
            placeholder="Report id"
            id="reportId"
            value={reportId}
            onChange={(event) => setReportId(event.target.value)}
          />
        </label>
        <label htmlFor="userName">
          Identity username
          <input
            placeholder="Username"
            id="userName"
            value={identityUserName}
            onChange={(event) => setIdentityUserName(event.target.value)}
          />
        </label>
        <label htmlFor="roles">
          Roles
          <input
            placeholder="Roles (comma separated)"
            id="roles"
            value={roles}
            onChange={(event) => setRoles(event.target.value)}
          />
        </label>
        <label htmlFor="customData">
          CustomData (Organisation)
          <input
            placeholder="CustomData (Organisation)"
            id="customData"
            value={customData}
            onChange={(event) => setCustomData(event.target.value)}
          />
        </label>
        {error}
        <DuoSpacing>
          <SubmitButton text="Show" />
          <SecondaryButton
            text="Clear"
            onClick={() => setReportDetails(null)}
          />
        </DuoSpacing>
      </form>
    );
  };

  return (
    <ContentPage title="Dashboards">
      {accessToken ? reportIdForm() : signinForm()}

      {reportDetails && (
        <PowerBIEmbed
          embedConfig={reportDetails}
          cssClassName={styles.dashboardContainer}
        />
      )}
    </ContentPage>
  );
};

export default Dashboards;
