import { useEffect, useState } from "react";
import getRecallDetails from "../../../../http/recall/getRecallDetails";
import RecallDetailsView from "../../../../Models/Recall/RecallDetailsView";

export default (id: string) => {
  const [isLoading, setLoading] = useState(true);
  const [mainDetails, setDetails] = useState<RecallDetailsView>();

  const refresh = () => {
    setLoading(true);
    Promise.allSettled([
      getRecallDetails(id).then(({ data }) => setDetails(data)),
    ]).finally(() => setLoading(false));
  };

  useEffect(() => {
    refresh();
  }, [id]);
  return {
    isLoading,
    mainDetails,
    refresh,
  };
};
