import React from "react";
import { officeType } from "../../../Enums/officeType";
import SearchResponses from "../../../Models/APIGetData/SearchResponses";
import { CBUrls } from "../../../views/CBManagement";
import axiosApi from "../../Api/Axios";
import BackendServices from "../../Api/BackendService";
import ContentPage from "../../ContentPage";
import DataTable, { DataTableQueryParams } from "../../DataTable";
import { search } from "../../Paths/paths";
import CBRow from "./models/CBRow";
import cbColumns from "./util.tsx/CBColumns";
import Office from "../../../Models/Office/Office";
import { getOfficeStatusDisplay } from "../OfficeHelper";
import PermissionArea from "../../../Authentication/model/PermissionArea";

const CBSearchTable = () => {
  const officeTypeFilter = { MAIN: "Main Office", SUB: "Sub Office" };
  const statusFilter = {
    Active: "Active",
    Suspended: "Suspended",
    Probation: "Probation",
    Limited: "Limited",
  };

  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: CBRow[]; totalPages: number }> =>
    axiosApi
      .get<SearchResponses<Office>>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}${search}`,
        {
          params,
        }
      )
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultsList.map(
          (cb: Office): CBRow => ({
            OfficeId: cb.id,
            OfficeName: cb.name,
            officeTypes: cb.isMainOffice ? officeType.MAIN : officeType.SUB,
            Country: cb.location.country?.name ?? "",
            OfficeStatus: getOfficeStatusDisplay(cb),
          })
        ),
      }));

  return (
    <ContentPage title="Certification Bodies">
      <DataTable<CBRow>
        columns={cbColumns}
        fetchRemoteData={fetch}
        filters={{
          officeTypes: () => officeTypeFilter,
          OfficeStatus: () => statusFilter,
        }}
        toolBarOptions={{
          newEntityLink: {
            label: "Add New",
            link: CBUrls.create(),
            permissionAreas: [PermissionArea.CB, PermissionArea.SUB_OFFICE],
          },
        }}
        archiveFilter={{ enabled: true }}
      />
    </ContentPage>
  );
};

export default CBSearchTable;
