import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import ContentPage from "../../components/ContentPage";
import DataTable, { DataTableQueryParams } from "../../components/DataTable";
import changeLogColumns from "./utils/ChangeLogColumns";
import axiosApi from "../../components/Api/Axios";
import { changeLog } from "../../components/Paths/paths";
import SearchResponses from "../../Models/APIGetData/SearchResponses";
import ChangeLogEntry from "./models/ChangeLogEntry";
import ChangeLogRow from "./models/ChangeLogRow";
import BackendServices from "../../components/Api/BackendService";
import { EntityType, EntityTypeContext } from "../../routing/GenericRoutes";

const getEndpoint = (type: EntityType | undefined) => {
  switch (type) {
    case "audit":
      return BackendServices.AUDIT_SERVICE.AUDIT;
    case "auditor":
      return BackendServices.AUDITOR_SERVICE.AUDITOR;
    case "company":
      return BackendServices.COMPANY_SERVICE.COMPANY;
    case "site":
      return BackendServices.COMPANY_SERVICE.SITES;
    case "cb":
      return BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY;
    case "recall":
      return BackendServices.RECALL_SERVICE.RECALL;
    case "user":
      return BackendServices.USER_SERVICE.USER;
    default:
      throw Error("Unsupported change log type");
  }
};

const mapEmptyField = (field?: string | null) => {
  if (field == null || field.length === 0) {
    return " --- ";
  }
  return field;
};

const ChangeLog = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const typeContext = useContext(EntityTypeContext);
  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: ChangeLogRow[]; totalPages: number }> =>
    axiosApi
      .get<SearchResponses<ChangeLogEntry>>(
        `${getEndpoint(typeContext?.type)}/${id}${changeLog}`,
        { params }
      )
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultsList.map(
          (entry: ChangeLogEntry): ChangeLogRow => ({
            changeBy: entry.changeBy,
            changeDate: entry.changeDate,
            event: entry.event,
            fieldsChanged: entry.fieldsChanged
              .map((field) => field.fieldName)
              .map(mapEmptyField),
            oldValues: entry.fieldsChanged
              .map((field) => field.oldValue)
              .map(mapEmptyField),
            newValues: entry.fieldsChanged
              .map((field) => field.newValue)
              .map(mapEmptyField),
          })
        ),
      }));
  return (
    <ContentPage title="Change log">
      <DataTable<ChangeLogRow>
        columns={changeLogColumns}
        fetchRemoteData={fetch}
        toolBarOptions={{
          enableDateRange: true,
          searchField: { enable: true, placeholder: "Search change by user " },
        }}
      />
    </ContentPage>
  );
};

export default ChangeLog;
