import React from "react";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

export function DatePicker(
  props: KeyboardDatePickerProps & { onClear?: () => void }
) {
  const { onClear } = props;
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        className="date-field"
        variant="inline"
        inputVariant="outlined"
        disableToolbar
        format="YYYY-MM-DD"
        autoOk
        margin="normal"
        minDateMessage="Date must be after the minimum date"
        maxDateMessage="Date must be before the maximum date"
        InputProps={
          onClear
            ? {
                startAdornment: (
                  <IconButton onClick={() => props.onClear?.()}>
                    <ClearIcon />
                  </IconButton>
                ),
              }
            : {}
        }
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
