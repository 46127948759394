export enum auditSubmissionType {
  ALLOW = "Allow",
  HOLD = "Hold",
  PROHIBIT = "Prohibit",
}

export const auditSubmissionOption: {
  value: auditSubmissionType;
  text: string;
}[] = [
  {
    value: auditSubmissionType.ALLOW,
    text: "Allow",
  },
  {
    value: auditSubmissionType.HOLD,
    text: "Hold",
  },
  {
    value: auditSubmissionType.PROHIBIT,
    text: "Prohibit",
  },
];
