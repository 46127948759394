/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { LinkText } from "../../../components/Common/hyperlinks";
import { privacyPolicy } from "../../../components/Paths/paths";

function TermsConditions() {
  return (
    <div>
      <img
        src="images/Terms&Conditions.png"
        className="responsive"
        alt="Terms and Conditions"
      />

      <h1>Terms and Conditions</h1>

      <h2>BRCGS Directory Terms and Conditions</h2>
      <p>(Version Date 1 January 2021)</p>

      <p>
        By accessing and using the Directory, you agree to be bound by and
        comply with these Terms and Conditions. The Applications used to provide
        the Directory are provided as is and are subject to the End User Licence
        Agreement accepted the user on account creation.
      </p>

      <p>
        Please read these Terms and Conditions and the End User Licence
        Agreement carefully as they set out your rights and obligations in
        respect of your use of the Directory.
      </p>

      <p>
        In consideration of the mutual promises and agreements contained in
        these Terms and Conditions, you and we agree as follows:
      </p>

      <h3>1. Definitions</h3>

      <p>These Terms and Conditions use the following definitions:</p>

      <ul className="list-style-bullet">
        <li>
          &#34;Applications&#34; means the software used to provide the
          Directory and Services
        </li>
        <li>
          &#34;Authorised Entity&#34; means the accreditation body,
          certification body, supplier or retailer that you work for, or
          represent and on whose behalf you are using the Directory and Services
        </li>
        <li>
          &#34;BRCGS", &#34;we&#34;, &#34;us&#34; or &#34;our&#34; means BRC
          Trading Limited whose registered office is LGC, Queens Road,
          Teddington TW11 0LY (company registration number 4281617)
        </li>
        <li>
          &#34;Directory&#34; means the BRCGS Directory which is the official
          database of all audits conducted against a BRCGS Standard
        </li>
        <li>
          &#34;Directory Data&#34; means all data, personal data, information
          and/or documents stored on the Directory
        </li>
        <li>
          &#34;including&#34; means including without limitation to the
          generality of any preceding description, definition or phrase
        </li>
        <li>
          &#34;personal data&#34; means any information or data relating to an
          identified or identifiable natural person who can be identified
          directly or indirectly
        </li>
        <li>
          &#34;Services&#34; means (i) access and use of the Directory and any
          documentation and materials available via the Directory, (ii) use of
          the Applications and (iii) the ability to upload User Data to the
          Directory
        </li>
        <li>
          &#34;User&#34; means a person authorised by BRCGS to access and use
          the Directory and Services
        </li>
        <li>
          &#34;User Data&#34; means all data, personal data, information and
          documents provided or uploaded to the Directory when using the
          Services
        </li>
        <li>
          &#34;You&#34; or &#34;Your&#34; means the person who has requested and
          been granted access to the Directory
        </li>
      </ul>

      <h3>2. Access to the directory and services</h3>

      <p>
        <b>2.1)</b> <i>Conditions of access:</i> You agree to comply with the
        following obligations when accessing and using any part of the
        Directory, Services and/or uploading any User Data to the Directory:
      </p>

      <ol type="a">
        <li>
          you must be an employee, representative or agent of an accreditation
          body, a certification body, a supplier or a retailer (referred to as
          an Authorised Entity in these Terms and Conditions) and have been
          invited by us to register to use the Directory and Services;
        </li>
        <li>
          you must be authorised or instructed by an Authorised Entity to upload
          the User Data to the Directory;
        </li>
        <li>
          you must ensure that all necessary consents and/or authorisations from
          individuals (whose personal data is to be uploaded to the Directory)
          have been obtained;
        </li>
        <li>
          any personal data you provide about yourself when creating an online
          account or profile to use the Directory and Services must be accurate
          and complete, and you will keep that data up to date;
        </li>
        <li>
          you will safeguard your access code and password at all times and not
          share them with anyone. You will take all necessary precautions to
          control and prevent unauthorised access or use of the Directory and
          Services. Any connection to the Directory and Services using your
          access code and password shall be deemed to be access by you and you
          shall be solely responsible for all such access and use. We accept no
          liability in the event of fraudulent or improper use of your access
          code and password. In the event of loss or theft of your access code
          or password, you must notify us immediately by email to
          submissions@brcgs.com; and
        </li>
        <li>
          you will ensure the computer system you use to connect to the
          Directory or Services and your Internet connection meet any technical
          requirements as specified by BRCGS and you must take all reasonable
          measures to safeguard your computer system against viruses, worms,
          Trojan horses, bugs and other malicious software which could impact
          the Directory or Services.
        </li>
      </ol>

      <p>
        <b>2.2)</b> <i>Personal identifiers:</i> Your connection to the
        Directory and Services is authorized solely on the condition that you
        use the personal identifiers set out in your account. We may change your
        personal identifiers for technical reasons, subject to providing you
        with three (3) days&#39; prior notice, or without notice if the change
        is required for security or emergency purposes.
      </p>

      <p>
        <b>2.3)</b> <i>Availability:</i> Access to the Directory or Services may
        be unavailable without notice at certain times, including when systems
        require maintenance or upgrades, or in the case of unforeseen
        circumstances. Your access to the Directory or Services is therefore not
        guaranteed to be always available or interruption free. We will not be
        liable for the lack of availability of the Directory or Services, or for
        any damages that may result from such lack of availability, and we
        provide no warranties or service levels in respect of the availability
        of the Directory or Services.
      </p>

      <h3>3. Your rights and obligations</h3>

      <p>
        <b>3.1)</b> We grant to you, for the duration of these Terms and
        Conditions, a non-transferable, non-exclusive, non-assignable, limited
        and revocable right to access and use the Directory and Services on
        behalf of the Authorised Entity for whom you work in accordance with
        these Terms and Conditions.
      </p>

      <p>
        <b>3.2)</b> You warrant that you will not, in connection with your use
        of the Directory or Services, do any of the following:
      </p>

      <ol type="a">
        <li>
          permit any third party to use the Directory or Services except as
          permitted by these Terms and Conditions;
        </li>
        <li>
          disclose or transmit any Directory Data to any unauthorized third
          parties;
        </li>
        <li>
          reproduce, download, copy, modify, translate, reverse engineer,
          reconfigure, disassemble or recreate the Applications, even partially,
          or permit a third party to carry out any such act;
        </li>
        <li>
          modify, alter or delete notices of the copyright, trademark, or any
          other intellectual property right appearing in or on the Applications,
          or prevent their identification;
        </li>
        <li>
          sell, lease, sublicense, dispose of, re-distribute or otherwise
          transfer, or share all or any part of your rights relating to the
          Applications, by any means whatsoever;
        </li>
        <li>
          use the Directory or Services to send spam, unsolicited messages or
          any other message in violation of any applicable law;
        </li>
        <li>
          send, display, maintain or transmit illegal, obscene, menacing,
          defamatory or otherwise illegal or unlawful data, including
          information infringing a third party&#39;s intellectual property
          rights;
        </li>
        <li>
          send, display, maintain or transmit information that contains any
          virus, computer worm, Trojan horse or other damaging device, data
          file, script, agent or program; and
        </li>
        <li>
          interfere with or disrupt the integrity or functioning of the
          Directory or Services.
        </li>
      </ol>

      <h3>4. Data protection and your privacy rights</h3>

      <p>
        <b>4.1)</b> <i>Purposes for which we use your personal data:</i> When
        you use the Directory or Services we will obtain personal data about you
        which we will use in accordance with (i) the data protection notice
        provided to you at the time you registered to use the Directory and (ii)
        our Privacy Notice (which can be accessed{" "}
        <LinkText link={privacyPolicy} text="here" />
        ). By way of reminder, we will use your personal data for:
      </p>

      <ol type="a">
        <li>
          administration, management and enhancement of the Directory and
          Services (which may involve sending you service related
          communications, including by email, regarding the Services, the
          Directory and other relevant services);
        </li>
        <li>administration and management of these Terms and Conditions;</li>
        <li>
          delivery, enhancement and development of our products and services;
        </li>
        <li>
          statistical analysis and understanding user-interactions with the
          Directory and Services; and
        </li>
        <li>with your prior consent, marketing by email, telephone and SMS.</li>
      </ol>

      <p>
        <b>4.2)</b> <i>Types of personal data we collect about you:</i> The type
        of personal data we may collect about you includes: your name, job
        title, location, username and password, email address, phone number,
        time zone, user preferences, user profile and Directory usage
        information (including a record of your download and upload activities).
      </p>

      <p>
        <b>4.3)</b> <i>Disclosures of your personal data:</i> We may share your
        personal data with (i) our third party vendors, including any who may
        supply, host and manage the Applications and platform on which the
        Directory operates and (ii) where appropriate, the Authorised Entity
        that you represent. We may also disclose your personal data if required
        or authorised to do so by applicable law, by an order of a court or
        enforcement authority, and/or where necessary to ensure compliance with
        legal or contractual obligations.
      </p>

      <p>
        <b>4.4)</b> <i>Legal basis for the processing of your personal data:</i>{" "}
        The legal bases that permit us to process your personal data are:
      </p>

      <ol type="a">
        <li>
          our legitimate interests, namely the provision of the Directory and
          Services; the delivery, enhancement and/or development of our products
          and services; and the management and support of the Directory and
          Services, to the extent these activities do not cause you harm or
          prejudice;{" "}
        </li>
        <li>
          performance of and compliance with these Terms and Conditions; and
        </li>
        <li>your consent in respect of any direct marketing communications.</li>
      </ol>

      <p>
        <b>4.5)</b> <i>Retention of your personal data:</i> We will keep your
        personal data for as long as necessary in connection with the purposes
        described in clause 4.1 and in accordance with our retention policy and
        applicable law.
      </p>

      <p>
        <b>4.6)</b> <i>International transfers:</i> Personal data collected and
        stored within the Directory may be transferred to suppliers outside the
        UK or European Union solely for the purpose of providing the Directory
        and Services. Where such transfers are to be made BRCGS shall have put
        in place adequate safeguards to protect your data, which include the EU
        Commission-approved Standard Contractual Clauses (which is a contract
        that governs the transfer of data from the EEA/UK to non-EEA/UK
        countries).
      </p>

      <p>
        <b>4.7)</b> <i>Your data protection rights:</i> Under EU/UK data
        protection laws, you have certain rights in relation to the personal
        data we process about you. Please note, these rights are not absolute
        and are subject to conditions set out in applicable law. We will give
        effect to these rights to the extent we are legally required to do so:
      </p>

      <ol type="a">
        <li>
          <i>Access:</i> you have the right to request information about how
          your personal data is processed and obtain a copy of that personal
          data;
        </li>
        <li>
          <i>Rectification:</i> you have the right to request the rectification
          of inaccurate personal data about you and for any incomplete personal
          data about you to be completed;
        </li>
        <li>
          <i>Objection:</i> you have the right to object to the processing of
          your personal data which is based on our legitimate interests (as
          described in clause 4.4(a) above);
        </li>
        <li>
          <i>Erasure:</i> you have the right to request the erasure of your
          personal data in certain circumstances;
        </li>
        <li>
          <i>Automated decision-making:</i> you have the right not to have a
          decision made about you that is based solely on automated processing
          if that decision produces legal effects about you or significantly
          affects you;
        </li>
        <li>
          <i>Restriction:</i> you have the right to ask us to restrict the
          processing of your personal data, so that your personal data is no
          longer processed until that restriction is lifted;
        </li>
        <li>
          <i>Portability:</i> you have the right to receive your personal data,
          which you have provided to us, in a structured, commonly used and
          machine-readable format and to have that data transmitted to another
          person in certain circumstances.
        </li>
      </ol>

      <p>
        In addition to the above, you have the right to lodge a complaint with a
        supervisory authority (such as the supervisory authority in your member
        state of habitual residence, place of work or where the alleged
        infringement took place) if you consider that our processing of your
        personal data infringes applicable data protection law. The supervisory
        authority for the United Kingdom is the Information Commissioner&#39;s
        Office whose website is:{" "}
        <LinkText
          link="https://www.ico.org.uk"
          text="https://www.ico.org.uk"
          external
        />
      </p>

      <p>
        <b>4.8)</b> <i>Cookies:</i> The Directory uses cookies to enable you to
        sign in and use the Directory and Services and to help personalise and
        improve your online experience. For more information about our use of
        cookies, click <LinkText link={privacyPolicy} text="here" />.
      </p>

      <p>
        <b>4.9)</b> <i>Controller:</i> BRCGS is the controller of your personal
        data obtained in connection with these Terms and Conditions and your use
        of the Directory and Services. You can contact us by email at{" "}
        <LinkText
          link="mailto:enquiries@brcgs.com"
          text="enquiries@brcgs.com"
          external
        />{" "}
        or you can write to us at the following address: BRCGS Customer Service,
        2nd floor, 7 Harp Lane, London EC3R 6DP.
      </p>

      <h3>5. Your privacy and confidentiality obligations</h3>

      <p>
        <b>5.1)</b> You agree:
      </p>

      <ol type="a">
        <li>
          to provide accurate and complete personal data about yourself when you
          register to use the Directory and to keep your account data up-to-date
          and accurate at all times;
        </li>
        <li>
          to use appropriate technical and organizational measures to protect
          any personal data that you obtain as a result of your use of the
          Directory or Services against accidental or unlawful destruction,
          loss, disclosure, access or lawful processing.
        </li>
      </ol>

      <p>
        <b>5.2)</b> You acknowledge and agree that Directory Data may include
        personal data that is governed by data protection, privacy and other
        related laws (such as the General Data Protection Regulation 2016)
        (&#34;Laws&#34;) and for that reason you agree to only use such personal
        data in accordance with the rights granted to you in these Terms and
        Conditions. If you do not comply with this requirement you may be in
        breach of applicable Laws and if that results in BRCGS or our suppliers
        incurring liability, you and the Authorised Entity will be responsible
        for that in accordance with these Terms and Conditions.
      </p>

      <p>
        <b>5.3)</b> You agree to treat as confidential all Directory Data or
        other information that you obtain as a result of using the Directory or
        Services (&#34;Confidential Information&#34;) and you undertake:
      </p>

      <ol type="a">
        <li>
          only to use and disclose Confidential Information for the purposes
          permitted by these Terms and Conditions; and
        </li>
        <li>
          not to disclose the Confidential Information to any unauthorised third
          party. This obligation will not apply where the disclosure is required
          by applicable law, or where the Confidential Information (excluding
          any personal data) becomes part of the public domain through no
          unauthorised act or omission on either your part or our part.
        </li>
      </ol>

      <h3>6. Intellectual property rights and licence to use</h3>

      <p>
        <b>6.1)</b> If you upload to the Directory any User Data in which you
        own the intellectual property rights, including copyright,
        (&#34;IPRs&#34;) you hereby grant to BRCGS and other Users a
        non-exclusive, non-transferable, perpetual licence to use such User Data
        for the purpose of enabling us to operate and provide the Directory and
        Services, and for the benefit of other Users (who may be located
        anywhere in the world) (&#34;Licence to Use&#34;).
      </p>

      <p>
        <b>6.2)</b> If you upload to the Directory any User Data in which the
        IPRs are owned by the Authorised Entity you work for or represent, you
        hereby confirm that you are authorised by such Authorised Entity to
        grant to BRCGS and other Users a Licence to Use such User Data.
      </p>

      <p>
        <b>6.3)</b> You are granted a non-exclusive, non-transferable, revocable
        and limited licence to use the Directory and Directory Data for the
        purpose of uploading BRCGS audit reports and other materials to the
        Directory on behalf of the Authorised Entity. You may not sell, rent,
        lease, loan, sublicence or create derivative works of the Directory
        Data.
      </p>

      <p>
        <b>6.4)</b> Upon termination of these Terms and Conditions, your right
        to use the Directory and Services shall immediately cease. We will not
        have any obligation to archive, delete or return to you any User Data
        you have submitted to the Directory, and we may continue to use such
        User Data under our Licence to Use.
      </p>

      <p>
        <b>6.5)</b> The Directory, Directory Data and Applications are original
        works protected by national and international intellectual property laws
        and the exclusive property of BRCGS or our third-party licensors. You
        agree not to harm, directly or indirectly, the legitimate rights and
        interests of our licensors in relation to the Applications, or the
        rights and interests of BRCGS in relation to the Directory or the
        Directory Data.
      </p>

      <p>
        <b>6.6)</b> You undertake to maintain intact any copyright or other
        intellectual property rights notices relating to the Applications,
        Directory and Directory Data and to respect the distinctive marks
        belonging to BRCGS and its licensors, such as names, marks, acronyms,
        logos, symbols, slogans, colours and graphics.
      </p>

      <p>
        <b>6.7)</b> Neither BRCGS nor our service providers and/or licensors
        shall be liable for any claim made against you by a third party for
        infringement of its intellectual property or other rights arising out of
        or in connection with your use of the Directory or Services. If a
        third-party claims that your use of any part of the Directory or
        Services infringes its intellectual property or other rights, our
        relevant licensor shall have sole control in addressing and defending
        such claim. Should your licence to use the Applications, Directory or
        Directory Data be lost as a result of any such claim, the relevant
        licensor may, at its sole option and discretion (i) promptly procure a
        licence of the necessary intellectual property rights to continue your
        licence (at no extra cost to you) or (ii) modify the Applications,
        Directory or Directory Data so that it does not infringe.
      </p>

      <h3>7. Liability</h3>

      <p>
        <b>7.1)</b> You acknowledge and agree that we provide the Directory and
        Services &#34;as is&#34; and that we have no control over the content,
        accuracy or completeness of any information that Users upload to the
        Directory. For this reason, you agree that the limitations on liability
        set out in this clause 7 are reasonable allocations of risk and will
        apply even if you or we have been advised of the possibility of such
        liability arising. You are responsible at all times for the content,
        accuracy or completeness of the User Data that you provide to us.
      </p>

      <p>
        <b>7.2)</b> We shall not be liable to you for:
      </p>

      <ol type="a">
        <li>loss of profit, income, revenue or savings;</li>
        <li>loss of goodwill or reputation;</li>
        <li>
          loss of business opportunity, business interruption or downtime;
        </li>
        <li>special, indirect or consequential loss or damages;</li>
        <li>
          the content, accuracy and/or completeness of any information contained
          in the Directory or Directory Data; and
        </li>
        <li>
          any issues or difficulties associated with the online exchange or
          transfer of User Data or Directory Data.
        </li>
      </ol>

      <p>
        <b>7.3)</b> We disclaim any and all warranties relating to the Directory
        or Services, whether express or implied, and including any implied
        warranties of merchantability or fitness for a particular purpose to the
        fullest extent authorised by law. Without limitation to the foregoing,
        we do not warrant that the Directory or Services will meet your
        requirements, or that operation of the Directory or Services will be
        uninterrupted or error free, or that use of the Directory or Services
        will enable you to comply with relevant law or regulation.
      </p>

      <p>
        <b>7.4)</b> Subject to clauses 7.2 and 7.6, our total liability (whether
        in contract, tort (including negligence), breach of statutory duty, or
        otherwise) for all claims arising out of or in connection with these
        Terms and Conditions shall not exceed one hundred pounds sterling
        (&#163;100).
      </p>

      <p>
        <b>7.5)</b> The foregoing limitations, exclusions and disclaimers shall
        apply, regardless of whether the claim for such damages is based in
        contract, warranty, strict liability, negligence, tort or otherwise.
        Insofar as applicable law prohibits any limitation set out in this
        clause 7, we both agree that such limitation will be automatically
        modified, but only to the extent so as to make the limitation permitted
        to the fullest extent possible under such law.
      </p>

      <p>
        <b>7.6)</b> The exclusions and limitations of liability set out in this
        clause 7 do not apply to liability arising from (i) death or personal
        injury resulting from negligence; (ii) fraud or fraudulent
        misrepresentation; and/or (iii) anything else that cannot be excluded or
        limited by law.
      </p>

      <h3>8. Indemnity</h3>

      <p>
        You, or the Authorised Entity which you represent, shall indemnify BRCGS
        and its suppliers and licensors against all liabilities, costs,
        expenses, damages and losses suffered or incurred by us, or our
        suppliers or licensors, arising out of or in connection with (i) any
        claim made against us by a third party for infringement of its
        intellectual property or other rights which arises out of, or is in
        connection with, our access or use of your User Data; and (ii) any
        breach by you of these Terms and Conditions.
      </p>

      <h3>9. Termination</h3>

      <p>
        <b>9.1)</b> These Terms and Conditions are entered into between us when
        you request access to and then use the Directory and/or Services.
        Thereafter, these Terms and Conditions shall remain in force between us
        for as long as you use the Directory or Services, subject to early
        termination in accordance with this clause.
      </p>

      <p>
        <b>9.2)</b> Either of us can terminate these Terms and Conditions at any
        time by giving not less than thirty (30) days&#39; notice in writing to
        the other.
      </p>

      <p>
        <b>9.3)</b> We may terminate these Terms and Conditions and your access
        to the Directory and Services at any time on written notice with
        immediate effect if:
      </p>

      <ol type="a">
        <li>
          you breach any of the provisions of these Terms and Conditions, unless
          you can remedy that breach within thirty (30) days to our
          satisfaction; or
        </li>
        <li>
          you or the Authorised Entity you represent experience any of the
          following: (i) the institution of insolvency, receivership or
          bankruptcy proceedings or any other proceedings for the settlement of
          debts, (ii) the making of an assignment for the benefit of creditors
          or (iii) dissolution (in the case of corporate entities).
        </li>
      </ol>

      <p>
        <b>9.4)</b> We reserve the right to retain, remove and/or destroy any
        User Data you have provided or uploaded to the Directory, as well as
        your account information, without prior notice, in the event of (i) your
        breach of these Terms and Conditions and/or (ii) the termination (for
        any reason) of these Terms and Conditions.
      </p>

      <p>
        <b>9.5)</b> All provisions of these Terms and Conditions that by their
        nature are intended to survive termination shall remain in effect.
      </p>

      <h3>10. Miscellaneous</h3>

      <p>
        <b>10.1)</b> <i>Modifications to these Terms and Conditions:</i> We
        reserve the right, in our discretion and at any time, to:
      </p>

      <ol type="a">
        <li>supplement, improve or expand the Directory or Services; and/or</li>
        <li>
          modify all or part of these Terms and Conditions if such modification
          is required by law or otherwise required in order to improve the
          Directory or Services for our Users. In such case, a new effective
          date shall be assigned to the revised Terms and Conditions which shall
          be posted online for Users to access. Your use of the Directory or
          Services after the modified Terms and Conditions have been posted will
          be deemed acceptance of such modified Terms and Conditions.
        </li>
      </ol>

      <p>
        <b>10.2)</b> <i>Force Majeure:</i> We are not liable to you if we cannot
        comply with our contractual obligations as a result of any unavoidable
        and unforeseen event which is not within our control (or the control of
        our suppliers or licensors) and which prevents us from performing our
        obligations (a &#34;Force Majeure Event&#34;). A Force Majeure Event may
        include: (i) fires, floods, or other natural disasters, service failure
        by a national telecommunications operator (in the UK or abroad), (ii)
        labour disputes leading to a national or regional strike, (iii)
        involuntary bankruptcy of a supplier or partner, subject to proof that
        such bankruptcy was involuntary or (iv) the modification of any
        regulation applicable at the time of signing these Terms and Conditions
        bringing about such events. If a Force Majeure Event happens, we may
        give you notice of the event and suspend our contractual obligations.
        After the effects of the Force Majeure Event have ended, such
        obligations shall recommence. If a Force Majeure Event lasts for a
        period exceeding sixty (60) days, we may terminate these Terms and
        Conditions on notice.
      </p>

      <p>
        <b>10.3)</b> <i>Assignment:</i> You may not sell, assign, or otherwise
        transfer or sub-contract, in whole or in part, any of your rights and
        obligations arising under these Terms and Conditions, whether or not for
        compensation, without our express prior authorisation. We may freely
        transfer, assign or otherwise grant our rights and obligations under
        these Terms and Conditions to any third party. In addition, we may
        freely subcontract all or a part of the Services and of the associated
        service provisions to any third party.
      </p>

      <p>
        <b>10.4)</b> <i>Entire Agreement:</i> Neither of us shall have any
        remedy in respect of any statement, representation, assurance or
        warranty (whether made innocently or negligently) not set out in these
        Terms and Conditions and upon which either of us relied upon in entering
        into these Terms and Conditions. Nothing in these Terms and Conditions
        shall limit or exclude any liability for fraud.
      </p>

      <p>
        <b>10.5)</b> <i>Severability:</i> If any provision of these Terms and
        Conditions is void or unenforceable, the remainder of these Terms and
        Conditions shall remain in full force and effect and the parties shall
        act in good faith to substitute a valid and binding provision having a
        materially similar effect as the void or unenforceable provision, making
        as little modification as possible to these Terms and Conditions.
      </p>

      <p>
        <b>10.6)</b> <i>Rights of third parties:</i> Unless expressly provided
        in these Terms and Conditions, no party who is not a party to these
        Terms and Conditions has any rights pursuant to the Contract (Rights of
        Third Parties) Act 1999.
      </p>

      <p>
        <b>10.7)</b> <i>Waiver:</i> It is expressly agreed that any waiver of
        enforcement of a provision of these Terms and Conditions by either party
        shall not be construed as an amendment or elimination of such provision
        or as creating any right. Any failure to exercise, or any delay in
        exercising, a right or remedy by either party shall not constitute a
        waiver of that right or remedy, or of any other rights or remedies.
      </p>

      <p>
        <b>10.8)</b> <i>Governing law:</i> These Terms and Conditions shall be
        governed by and construed in accordance with English law and the parties
        submit to the exclusive jurisdiction of the English courts.
      </p>
    </div>
  );
}

export default TermsConditions;
