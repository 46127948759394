import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import SortListIcon from "@material-ui/icons/Sort";
import { useDispatch, useSelector } from "react-redux";
import TabsBar from "../../components/Common/Tab";
import SearchBox from "../../components/SearchBox";
import styles from "./PublicDirectory.module.scss";
import FilterGroup, { Filter } from "../../components/FilterGroup";
import { RootState } from "../../store/reducers";
import swapOrder from "../../store/public-directory/actions/swapOrder";
import setSearchTerm from "../../store/public-directory/actions/setSearchTerm";
import setFilters from "../../store/public-directory/actions/setFilters";
import { PublicFilters } from "../../store/public-directory";
import reset from "../../store/public-directory/actions/reset";
import resetResults from "../../store/public-directory/actions/resetResults";
import TabConfigs from "./utils/TabConfigs";
import useGetFilters, { PublicFilter } from "./utils/useGetPublicFilters";
import LoadingOverlay from "./components/LoadingOverlay";
import setTab from "../../store/public-directory/actions/setTab";
import MainContent from "./components/MainContent";
import Option from "../../Models/forms/Option";

let searchDelay: NodeJS.Timeout | null = null;

function PublicDirectory() {
  const {
    tab,
    order,
    pageNumber,
    searchTerm,
    filters,
    delay,
    isLoading,
  } = useSelector((state: RootState) => state.pd);
  const dispatch = useDispatch();
  const [initiated, setInitiated] = useState(false);

  const config = TabConfigs[tab];
  const allFilters = useGetFilters(filters);
  const tabFilters = config.filters.map((filterName) =>
    allFilters.find((filter) => filter.name === filterName)
  );

  const onFilterChange = (key: keyof PublicFilters, values: Option[]) => {
    dispatch(setFilters(key, values));
    if (key === "Standard") dispatch(setFilters("Category", []));
  };

  const hasValidFilter =
    searchTerm || Object.values(filters).some((filter) => filter.length > 0);

  useEffect(() => {
    if (!hasValidFilter) {
      dispatch(resetResults());
    }
  }, [hasValidFilter]);

  const search = () => {
    if (searchDelay) clearTimeout(searchDelay);
    if (!hasValidFilter) return;

    searchDelay = setTimeout(() => {
      dispatch(config.getData({ searchTerm, pageNumber, order }, filters));
    }, delay);
  };

  useEffect(() => {
    if (initiated) {
      search();
    } else {
      setInitiated(true);
    }
  }, [filters, pageNumber, order, searchTerm]);

  return (
    <>
      <TabsBar
        labels={["CERTIFICATED SITE", "CERTIFICATION BODY"]}
        value={tab}
        onChange={(val) => dispatch(setTab(val))}
        centered={false}
        className={styles.publicDirectoryTabs}
      />
      <div className={styles.publicDirectory}>
        <LoadingOverlay isLoading={isLoading} />
        <SearchBox
          searchString={searchTerm}
          handleClick={(val) => dispatch(setSearchTerm(val))}
          placeHolderString={config.searchPlaceholder}
          className={styles.search}
        />
        <IconButton
          className={styles.sort}
          onClick={() => dispatch(swapOrder())}
        >
          {order === "asc" ? "Ascending" : "Descending"} <SortListIcon />
        </IconButton>

        <div className={styles.main}>
          <FilterGroup<PublicFilter>
            filters={tabFilters as Filter<PublicFilter>[]}
            handleChange={onFilterChange}
            reset={() => {
              dispatch(reset());
            }}
            className={styles.filters}
          />
          <div className={styles.content}>
            <MainContent config={config} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicDirectory;
