import React from "react";
import moment from "moment";
import DataTable from "../../../../../components/DataTable";
import AssociatedRecall from "../../../../../Models/site/AssociatedRecall";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";
import PermissionArea from "../../../../../Authentication/model/PermissionArea";
import AssociatedRecallRow from "./models/AssociatedRecallRow";
import { RecallUrls } from "../../../../RecallManagement";
import associatedRecallColumn from "./utils/AssociatedRecallColumns";

const AssociatedRecallsTable = ({
  recalls,
}: {
  readonly recalls: AssociatedRecall[];
}) => {
  const fetch = (): Promise<AssociatedRecallRow[]> =>
    Promise.resolve<AssociatedRecallRow[]>(
      recalls.map((recall) => ({
        id: recall.id,
        country: recall.country,
        recallCategory: recall.recallCategory,
        certificationBody: recall.certificationBody,
        recallStatus: recall.recallStatus,
        initialNotificationDate: recall.initialNotificationDate
          ? moment(recall.initialNotificationDate).format(DEFAULT_DATE_FORMAT)
          : "",
      }))
    );

  return (
    <DataTable<AssociatedRecallRow>
      columns={associatedRecallColumn}
      fetchData={fetch}
      toolBarOptions={{
        searchField: { enable: false },
        newEntityLink: {
          label: "Add new recall",
          link: RecallUrls.create(),
          permissionAreas: [PermissionArea.RECALL],
        },
      }}
    />
  );
};

export default AssociatedRecallsTable;
