import React from "react";
import GenericRoutes from "../../routing/GenericRoutes";
import { idParam, privateArea } from "../../components/Paths/paths";

import GenericLinks from "../../routing/GenericLinks";
import CBSearchTable from "../../components/CertificationBodyManagement/CBSearchTable";
import PermissionArea from "../../Authentication/model/PermissionArea";
import CBDetails from "./CBDetails";
import CBNew from "./CBNew";
import CBOfficeEdit from "./CBOfficeEdit";
import CBEdit from "./CBEdit";

const PATH_CB_MANAGEMENT = `${privateArea}/cb`;

class CertificationBodyLinks extends GenericLinks {
  editCb(id: string | number) {
    return `${this.root}/edit-cb/${id}`;
  }
}

export const CBUrls = new CertificationBodyLinks(PATH_CB_MANAGEMENT);

const CBRouting = GenericRoutes({
  root: PATH_CB_MANAGEMENT,
  urls: CBUrls,
  entityType: "cb",
  permissionArea: PermissionArea.CB,
  components: {
    editComponent: CBOfficeEdit,
    detailsComponent: CBDetails,
    searchComponent: CBSearchTable,
    createComponent: CBNew,
  },
  rootRoutes: [{ path: `edit-cb${idParam}`, component: CBEdit }],
});

const CBRoutes = () => <CBRouting />;

export default CBRoutes;
