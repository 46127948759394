interface ValidationType {
  name: string;
  message: string;
}

export const required: ValidationType = {
  name: "required",
  message: "Field is required",
};

export const email: ValidationType = {
  name: "isEmail",
  message: "Incorrect email format",
};

export const number: ValidationType = {
  name: "isNumber",
  message: "Is not a number",
};

export const max1000Chars: ValidationType = {
  name: "maxStringLength:1000",
  message: "Max 1000 characters allowed",
};
