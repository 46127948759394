import React from "react";
import DataTable from "../../../../../components/DataTable";
import AssociatedSiteRow from "./models/AssociatedSiteRow";
import associatedSiteColumns from "./util/associatedSiteColumns";
import axiosApi from "../../../../../components/Api/Axios";
import BackendServices from "../../../../../components/Api/BackendService";
import AssociatedSite from "./models/AssociatedSite";
import { Sites } from "../../../../../components/Paths/paths";
import { SiteUrls } from "../../../../SiteManagement";

const SiteSearchTable = ({
  companyId,
  companyName,
  isShared,
}: {
  companyId: string;
  companyName: string;
  isShared: boolean;
}) => {
  const fetch = (): Promise<AssociatedSiteRow[]> =>
    axiosApi
      .get<AssociatedSite[]>(
        `${BackendServices.COMPANY_SERVICE.COMPANY}/${companyId}${Sites}`
      )
      .then(({ data }) =>
        data.map(
          (associatedSite: AssociatedSite): AssociatedSiteRow => ({
            siteName: associatedSite.name,
            status: associatedSite.status,
            id: associatedSite.id,
            country: associatedSite.location.country?.name ?? "",
          })
        )
      );

  return (
    <DataTable<AssociatedSiteRow>
      columns={associatedSiteColumns}
      fetchData={fetch}
      toolBarOptions={
        isShared
          ? {
              newEntityLink: {
                label: "Add Associated Site",
                link: SiteUrls.create(),
                stateInfo: { companyId, companyName },
              },
            }
          : undefined
      }
    />
  );
};

export default SiteSearchTable;
