import { useEffect, useState } from "react";
import NamedEntity from "../../../../../../Models/APIGetData/NamedEntity";
import getCertificationStatuses from "../../../../../../http/audit/getCertificationStatuses";

export default () => {
  const [certificationStatuses, setCertificationStatuses] = useState<
    NamedEntity[]
  >([]);

  useEffect(() => {
    getCertificationStatuses()
      .then(({ data }) => setCertificationStatuses(data))
      .catch(() => setCertificationStatuses([]));
  }, []);
  return { certificationStatuses };
};
