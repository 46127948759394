export const ACTION_SET_PAGE_NUMBER = "ACTION_SET_PAGE_NUMBER";

export interface ActionSetPageNumber {
  readonly type: typeof ACTION_SET_PAGE_NUMBER;
  readonly payload: number;
}

export default (page: number): ActionSetPageNumber => {
  return { type: ACTION_SET_PAGE_NUMBER, payload: page };
};
