import React from "react";
import { Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import {
  about,
  contact,
  eula,
  idParam,
  office,
  privacyPolicy,
  publicPage,
  site,
  termsConditions,
  trainingCourses,
} from "../components/Paths/paths";
import PublicFooter from "../components/Footer/publicFooter";
import About from "../views/PublicDirectory/content-pages/About";
import Contact from "../views/PublicDirectory/content-pages/contact";
import TrainingCourses from "../views/PublicDirectory/content-pages/trainingcourses";
import TermsConditions from "../views/PublicDirectory/content-pages/termsconditions";
import PrivacyPolicy from "../views/PublicDirectory/content-pages/privacypolicy";
import PublicDirectory from "../views/PublicDirectory";
import PublicSite from "../views/PublicDirectory/components/SiteDetails";
import PublicCB from "../views/PublicDirectory/components/CBDetails";
import PublicHeader from "../views/PublicDirectory/components/Header";
import Eula from "../views/PublicDirectory/content-pages/eula";

export default function PublicDirectoryPages() {
  return (
    <>
      <div className="page-container">
        <Container>
          <PublicHeader />
          <Switch>
            <Route exact path={publicPage} component={PublicDirectory} />
            <Route path={`${office}${idParam}`} component={PublicCB} />
            <Route path={`${site}${idParam}`} component={PublicSite} />
            <Route path={about} component={About} />
            <Route path={trainingCourses} component={TrainingCourses} />
            <Route path={eula} component={Eula} />
            <Route path={contact} component={Contact} />
            <Route path={termsConditions} component={TermsConditions} />
            <Route path={privacyPolicy} component={PrivacyPolicy} />
            <Route path={trainingCourses} component={TrainingCourses} />
          </Switch>
        </Container>
      </div>
      <PublicFooter />
    </>
  );
}
