import React, { PropsWithChildren } from "react";
import ContentPage from "../../../../components/ContentPage";
import { ParentContext } from "../../../../routing/GenericRoutes";
import { publicPage } from "../../../../components/Paths/paths";

interface PublicDetailsProps {
  readonly title: string;
}

const PublicDetails = ({
  title,
  children,
}: PropsWithChildren<PublicDetailsProps>) => (
  <ParentContext.Provider value={{ parentLink: publicPage }}>
    <ContentPage title={title}>{children}</ContentPage>
  </ParentContext.Provider>
);

export default PublicDetails;
