import React from "react";
import { getFullName } from "../../../../helpers/NameHelper";
import PublicContact from "../../models/PublicContact";
import InfoBox from "../../../../components/Infobox";

interface ContactInfoBoxProps {
  readonly contact?: PublicContact;
  readonly header: string;
}

const ContactInfoBox = ({ contact, header }: ContactInfoBoxProps) => {
  if (!contact) return null;
  return (
    <InfoBox
      header={header}
      key={contact.contactType}
      columns={2}
      items={[
        { label: "Name", value: getFullName(contact) },
        {
          label: "Telephone",
          value: contact.phoneNumber,
          required: true,
        },
        { label: "Email", value: contact.email, required: true },
        { label: "Fax", value: contact.faxNumber },
      ]}
    />
  );
};

export default ContactInfoBox;
