import {
  ACTION_SET_ERROR,
  ActionSetError,
  GlobalError,
} from "./actions/setError";
import { ACTION_CLEAR_ERROR, ActionClearError } from "./actions/clearError";

interface ErrorState {
  error?: GlobalError;
}

const initialState: ErrorState = { error: undefined };

type ErrorAction = ActionSetError | ActionClearError;

export default (state = initialState, action: ErrorAction): ErrorState => {
  switch (action.type) {
    case ACTION_SET_ERROR: {
      return { error: action.payload };
    }
    case ACTION_CLEAR_ERROR: {
      return initialState;
    }

    default:
      return state;
  }
};
