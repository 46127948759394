import React from "react";
import RecallCategoryView from "../../../../Models/Recall/RecallCategoryView";
import getRecallCategories from "../../../../http/recall/getRecallCategories";
import Option from "../../../../Models/forms/Option";
import SearchableDropdown from "../../../../components/SearchableDropdown";

interface RecallCategoryDropdownProps {
  value?: Option | null;
  required?: boolean;
  onChange: (recallCategory: Option | null) => void;
  label?: string;
}

export default class RecallCategoryDropdown extends React.Component<
  RecallCategoryDropdownProps,
  { options: Option[] }
> {
  constructor(props: RecallCategoryDropdownProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getCategoryOptions();
  }

  getCategoryOptions(): void {
    getRecallCategories()
      .then<Option[]>((response) =>
        RecallCategoryDropdown.mapCategoriesToOptions(response.data)
      )
      .then((data) => {
        this.setState(() => ({ options: data }));
      })
      .catch((error) => console.log(error));
  }

  private static mapCategoriesToOptions(
    response: RecallCategoryView[]
  ): Option[] {
    return response.map((element: RecallCategoryView) => ({
      value: element.id.toString(),
      label: element.name,
    }));
  }

  render() {
    const { required, value, onChange, label } = this.props;
    const { options } = this.state;

    return (
      <SearchableDropdown
        required={required}
        Options={options}
        label={label ?? "Select incident category"}
        value={value}
        name="selectRecallCategory"
        onChange={(e: React.ChangeEvent<{}>, option: Option | null) =>
          onChange?.(option)
        }
      />
    );
  }
}
