import React from "react";
import CoreAuditorSearchTable from "../core/CoreAuditorSearchTable";
import mainAuditorColumns from "./util/mainAuditorColumns";
import { SelectedFilters } from "../index";

interface MainAuditorSearchTableProps {
  readonly selectedFilters: SelectedFilters;
}

const MainAuditorSearchTable = ({
  selectedFilters,
}: MainAuditorSearchTableProps) => (
  <CoreAuditorSearchTable
    columns={mainAuditorColumns}
    endpoint="/search"
    selectedFilters={selectedFilters}
    isPending={false}
  />
);

export default MainAuditorSearchTable;
