import React from "react";
import PageHeaderMenu from "./PageHeaderMenu";
import PageHeaderProps from "../../../Models/CommonProps/ManagementPageHeader/PageHeaderProps";
import styles from "./PageHeaderMenu.module.scss";

export default ({
  nameOfEntity,
  additionalInfo,
  menuItems = [],
}: PageHeaderProps) => (
  <div className={styles.pageHeader}>
    <h1>{nameOfEntity}</h1>
    {additionalInfo && (
      <>
        <div className={styles.separator}>|</div>
        <div>
          <h1 className={styles.additionalInfo}>{additionalInfo}</h1>
        </div>
      </>
    )}
    {menuItems.length > 0 && (
      <div className={styles.menu}>
        <PageHeaderMenu menuItems={menuItems} />
      </div>
    )}
  </div>
);
