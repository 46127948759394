import React from "react";
import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";
import { H3Heading } from "./Heading";

export default function CheckBox({
  heading,
  checked,
  id,
  label,
  onChange,
  disabled,
}: CheckboxProps & { label?: string; heading?: string }) {
  return (
    <div>
      {heading && <H3Heading text={heading} />}
      <FormControlLabel
        control={
          <Checkbox
            className="input checkbox"
            checked={checked}
            id={id}
            name={label}
            onChange={(e, c) => onChange && onChange(e, c)}
            disabled={disabled}
          />
        }
        label={label}
      />
    </div>
  );
}
