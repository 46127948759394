export enum suspensionType {
  LIMITED = "Limited",
  FULL = "Full",
  ACTIVE = "Active",
}

export const suspensionOption = [
  {
    value: suspensionType.LIMITED,
    text: "Limited/Under Probation",
  },
  {
    value: suspensionType.FULL,
    text: "Full Suspension",
  },
];
