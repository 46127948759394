import React from "react";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { AddCircle, Clear } from "@material-ui/icons";
import { DatePicker } from "../../Common/Input/DatePicker";
import { formatDate } from "../../../util/dates/DateFormats";
import { GFSIExam } from "../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import InputField from "../../InputField";
import SelectField from "../../SelectField";
import {
  mapNamedEntityToOption,
  mapOptionToNamedEntity,
} from "./TrainingSection";
import NamedEntity from "../../../Models/APIGetData/NamedEntity";
import Option from "../../../Models/forms/Option";

interface IGFSIExams {
  newDate: string;
  languageOptions: Option[];
  selectedGFSIExams: GFSIExam[];
  setSelectedGFSIExams: React.Dispatch<React.SetStateAction<GFSIExam[]>>;
}

export default function GFSIExamsSection({
  newDate,
  languageOptions,
  selectedGFSIExams,
  setSelectedGFSIExams,
}: IGFSIExams) {
  const addGFSIExam = () => {
    const newGFSIExams = [
      ...selectedGFSIExams,
      {
        externalId: 0,
        updated: false,
        provider: "",
        date: newDate,
        language: null,
        certificateReference: "",
        titleOfExam: "",
      } as GFSIExam,
    ];
    setSelectedGFSIExams(newGFSIExams);
  };

  const handleChangeField = (
    GFSIExamIndex: number,
    field: string,
    input: string | NamedEntity
  ) => {
    const newGFSIExams = selectedGFSIExams.map(
      (gFSIExam: GFSIExam, index: number) => {
        if (GFSIExamIndex !== index) return gFSIExam;
        return { ...gFSIExam, updated: true, [field]: input };
      }
    );
    setSelectedGFSIExams(newGFSIExams);
  };

  const handleChangeLanguage = (GFSIExamIndex: number, newLanguage: Option) => {
    handleChangeField(
      GFSIExamIndex,
      "language",
      mapOptionToNamedEntity(newLanguage)
    );
  };

  const removeGFSIExam = (index: number) => {
    const newGFSIExams = selectedGFSIExams.filter(
      (gFSIExam: GFSIExam) => selectedGFSIExams.indexOf(gFSIExam) !== index
    );
    setSelectedGFSIExams(newGFSIExams);
  };

  return (
    <Grid container spacing={3} direction="column">
      {selectedGFSIExams.map((gFSIExam: GFSIExam, index: number) => (
        <Grid key={index.toString()} className="accordion-input" item>
          <h3>
            {`GFSI Exam ${index + 1}`}{" "}
            <Clear onClick={() => removeGFSIExam(index)} />
          </h3>
          <InputField
            name="TitleOfExam"
            label="Title of exam"
            required
            value={gFSIExam.titleOfExam}
            onChange={(value) => handleChangeField(index, "titleOfExam", value)}
          />
          <InputField
            name="Provider"
            label="Provider"
            value={gFSIExam.provider}
            onChange={(value) => handleChangeField(index, "provider", value)}
          />
          <DatePicker
            fullWidth
            value={gFSIExam.date}
            label="Exam date"
            onChange={(d) => handleChangeField(index, "date", formatDate(d))}
          />
          <SelectField
            name="Language"
            label="Select language"
            required
            value={
              gFSIExam.language
                ? mapNamedEntityToOption(gFSIExam.language)
                : null
            }
            options={languageOptions}
            onChange={(value) => value && handleChangeLanguage(index, value)}
          />
          <InputField
            name="CertificateReference"
            label="Certificate reference"
            value={gFSIExam.certificateReference}
            onChange={(value) =>
              handleChangeField(index, "certificateReference", value)
            }
          />
        </Grid>
      ))}
      <Divider />
      <Grid item>
        <IconButton onClick={addGFSIExam}>
          <AddCircle className="add" />{" "}
          <span className="add">Add GFSI exam</span>
        </IconButton>
      </Grid>
    </Grid>
  );
}
