import { useEffect, useState } from "react";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import Country from "../../views/VerifyManagement/models/Country";

const useCountries = () => {
  const [siteCountries, setSiteCountries] = useState<
  Country[]
  >([]);
  useEffect(() => {
    axiosApi
      .get<Country[]>(
        `${BackendServices.PUBLIC_SERVICE}/countries`
      )
      .then(({ data }) => {
        
        setSiteCountries(data);
      })
      .catch(() => setSiteCountries([]));
  }, []);
  return { siteCountries };
};

export default useCountries;
