import React from "react";
import Box, { BoxPadding } from "../../../../../components/Box";
import DataTable from "../../../../../components/DataTable";
import officeColumns from "./utils/officeColumns";
import OfficeRow from "./models/OfficeRow";
import axiosApi from "../../../../../components/Api/Axios";
import Office from "../../../../../Models/Office/Office";
import BackendServices from "../../../../../components/Api/BackendService";
import { getOfficeStatusDisplay } from "../../../../../components/CertificationBodyManagement/OfficeHelper";
import { cbCreateButtonLabel } from "../../../../../components/CertificationBodyManagement/CertificationBodyStrings";
import { CBUrls } from "../../../index";
import CreateCBLocationStateProps from "../../../../../Models/CBProps/CreateCBLocationStateProps";

interface OfficeTableProps {
  readonly cbId: number;
  readonly createLinkState: CreateCBLocationStateProps;
}

const OfficeTable = ({ cbId, createLinkState }: OfficeTableProps) => {
  const fetch = (): Promise<OfficeRow[]> =>
    axiosApi
      .get<Office[]>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/${cbId}/office`
      )
      .then(({ data }) =>
        data.map(
          (office): OfficeRow => ({
            name: office.name,
            countryName: office.location.country?.name ?? "",
            id: office.id,
            status: getOfficeStatusDisplay(office),
          })
        )
      )
      .catch((): OfficeRow[] => []);
  return (
    <Box header="Offices" padding={BoxPadding.None}>
      <DataTable<OfficeRow>
        columns={officeColumns}
        fetchData={fetch}
        toolBarOptions={{
          newEntityLink: {
            label: cbCreateButtonLabel,
            link: CBUrls.create(),
            stateInfo: createLinkState,
          },
        }}
      />
    </Box>
  );
};

export default OfficeTable;
