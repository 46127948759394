import SharingType from "../models/SharingType";
import getAuditDetails from "../../../http/audit/getAuditDetails";
import getCompanyDetails from "../../../http/companies/getCompanyDetails";
import getSiteDetails from "../../../http/site/getSiteDetails";
import Permission from "../../../Authentication/model/Permission";

export interface SharableDetails {
  readonly name: string;
  readonly isAbleToShare: boolean;
  readonly isAbleToRemoveShare: boolean;
}

export default (
  id: number | string,
  type: SharingType,
  permissions: Permission[]
): Promise<SharableDetails> => {
  const getNameAndPermission = <T extends { readonly name: string }>(
    data: T
  ): SharableDetails => ({
    name: data.name,
    isAbleToShare: permissions.includes(Permission.SHARE),
    isAbleToRemoveShare: permissions.includes(Permission.UNSHARE),
  });

  switch (type) {
    case SharingType.AUDIT:
      return getAuditDetails(id).then(({ data }) => ({
        name: data.site.name,
        isAbleToShare: data?.visuals.isAbleToShare,
        isAbleToRemoveShare: true,
      }));
    case SharingType.COMPANY:
      return getCompanyDetails(id).then(({ data }) =>
        getNameAndPermission(data)
      );
    case SharingType.SITE:
      return getSiteDetails(id).then(({ data }) => getNameAndPermission(data));

    default:
      return Promise.reject();
  }
};
