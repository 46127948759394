import { useEffect, useState } from "react";
import Option from "../../../../Models/forms/Option";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { SetFilterValue } from "../filterMappings";
import { FilterState } from "../../index";

export default (
  filterState: FilterState,
  setValue: SetFilterValue
): Option[] => {
  const [recordStatuses, setRecordStatuses] = useState<Option[]>([]);
  useEffect(() => {
    axiosApi
      .get<{ name: string; id: number }[]>(
        `${BackendServices.REPORT_SERVICE.REPORT}/data/recordstatuses`
      )
      .then(({ data }) => {
        const options: Option[] = data.map((val) => ({
          label: val.name,
          value: val.id,
        }));
        setRecordStatuses(options);
        const current = options.find((o) => o.label === "Current");
        if (current) setValue([current]);
      });
  }, []);

  return recordStatuses;
};
