import React from "react";
import { RouteComponentProps } from "react-router-dom";
import DetailsPage from "../../../components/DetailsPage";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import Box, { BoxPadding } from "../../../components/Box";
import AssociatedAuditsTable from "./components/AssociatedAuditsTable";
import useGetSiteDetails from "./http/useGetSiteDetails";
import SiteInfoBoxes from "./components/SiteInfoBoxes";
import EntityStatus from "../../../Enums/EntityStatus";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import getMenuItems from "./utils/getMenuItems";
import useGetData from "../../../http/useGetData";
import AssociatedAudit from "../../../Models/site/AssociatedAudit";
import AssociatedRecall from "../../../Models/site/AssociatedRecall";
import AssociatedRecallsTable from "./components/AssociatedRecallSearchTable";
import { isBRCGSUser, isCBUser } from "../../../Authentication/Auth";

const sharedSiteDetailsMenu: DetailsPageMenuItem[] = [
  DetailsPageMenuItem.EDIT,
  DetailsPageMenuItem.SHARE,
  DetailsPageMenuItem.ARCHIVE,
];

const SiteDetails = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { site, isLoading, refresh } = useGetSiteDetails(id);
  const isArchived = site?.status === EntityStatus.ARCHIVED;

  const archive = () => {
    axiosApi
      .post(
        `${BackendServices.COMPANY_SERVICE.SITES}/${id}/${
          isArchived ? "unarchive" : "archive"
        }`
      )
      .then(refresh)
      .catch(() => {});
  };

  const { data: auditData } = useGetData<AssociatedAudit[]>(() =>
    axiosApi.get(`${BackendServices.AUDIT_SERVICE.AUDIT}/Site/${id}/history`)
  );

  const { data: recallData } = useGetData<AssociatedRecall[]>(() => {
    if (isBRCGSUser() || (isCBUser() && site?.isShared)) {
      return axiosApi.get(
        `${BackendServices.RECALL_SERVICE.RECALL}/Site/${id}`
      );
    }
    return Promise.reject();
  });

  const contentTabs = [];
  if (auditData) {
    contentTabs.push({
      name: "Audits",
      content: (
        <Box padding={BoxPadding.None} header="Audits">
          <AssociatedAuditsTable audits={auditData ?? []} />
        </Box>
      ),
    });
  }
  if (isBRCGSUser() || (isCBUser() && site?.isShared)) {
    contentTabs.push({
      name: "Recalls",
      content: (
        <Box padding={BoxPadding.None} header="Recalls">
          <AssociatedRecallsTable recalls={recallData ?? []} />
        </Box>
      ),
    });
  }

  return (
    <DetailsPage
      id={site?.id}
      isLoading={isLoading}
      title={site?.name ?? ""}
      detailsPageMenuItems={[
        DetailsPageMenuItem.CHANGE_LOG,
        ...(site?.isShared ? sharedSiteDetailsMenu : []),
      ]}
      archiveConfig={{
        isArchived,
        entityName: "site",
        onClick: archive,
      }}
      menuItems={site?.isShared ? getMenuItems(id, site?.name) : []}
      contentTabs={contentTabs}
    >
      <SiteInfoBoxes site={site} />
    </DetailsPage>
  );
};

export default SiteDetails;
