import React, { Fragment } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import FilterComponentProps from "./FilterComponentProps";
import Option from "../../../Models/forms/Option";
import { H3Heading } from "../../Common/Heading";

const CheckboxFilter = ({
  onChange,
  selected,
  options,
  renderOption,
}: FilterComponentProps) => {
  const types = options
    .map((option) => option.type)
    .filter((type) => !!type)
    .filter((type, index, self) => self.indexOf(type) === index);
  const renderOptionList = (innerOptions: Option[]) =>
    innerOptions.map((elem: Option) => (
      <FormControlLabel
        key={elem.value}
        control={
          <Checkbox
            name={`${elem.value}`}
            checked={selected.map((s) => s.value).includes(elem.value)}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) =>
              onChange(
                checked
                  ? [...selected, elem]
                  : selected.filter((select) => select.value !== elem.value)
              )
            }
          />
        }
        label={renderOption ? renderOption(elem) : elem.label}
      />
    ));
  return (
    <FormGroup>
      {types.length > 1
        ? types.map((type) => (
            <Fragment key={type}>
              <H3Heading text={type} />
              {renderOptionList(
                options.filter((option) => option.type === type)
              )}
            </Fragment>
          ))
        : renderOptionList(options)}
    </FormGroup>
  );
};

export default CheckboxFilter;
