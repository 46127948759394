import React, { useEffect, useState } from "react";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { AddCircle, Clear } from "@material-ui/icons";
import BackendServices from "../../Api/BackendService";
import { getCBoptions } from "../../Paths/paths";
import axios from "../../Api/Axios";
import SearchableDropdown from "../../SearchableDropdown";
import Option from "../../../Models/forms/Option";
import { AuditorNamedEntity } from "../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import NamedEntity from "../../../Models/APIGetData/NamedEntity";
import { mapNamedEntityToOption } from "./TrainingSection";

type CertificationBody = AuditorNamedEntity | null;

interface ICertificationBody {
  selectedCertificationBodies: CertificationBody[];
  setSelectedCertificationBodies: React.Dispatch<
    React.SetStateAction<CertificationBody[]>
  >;
}

export default function CertificationBodySection({
  selectedCertificationBodies,
  setSelectedCertificationBodies,
}: ICertificationBody) {
  const [certificationBodyOptions, setCertificationBodyOptions] = useState<
    Option[]
  >([]);

  const getCertificationBodyOptions = () => {
    axios
      .get<NamedEntity[]>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}${getCBoptions}`
      )
      .then((response) =>
        setCertificationBodyOptions(response.data.map(mapNamedEntityToOption))
      )
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCertificationBodyOptions();
  }, []);

  const addCertificationBody = () => {
    const newCertificationBodies = [...selectedCertificationBodies, null];
    setSelectedCertificationBodies(newCertificationBodies);
  };

  const handleChangeCertificationBody = (
    prevIndex: number,
    newCertificationBody: Option | null
  ) => {
    const newCertificationBodies = selectedCertificationBodies.map(
      (certificationBody: CertificationBody, index: number) => {
        if (prevIndex !== index) return certificationBody;
        if (!newCertificationBody) return null;
        return {
          id:
            typeof newCertificationBody.value === "string"
              ? parseInt(newCertificationBody.value, 10)
              : newCertificationBody.value,
          name: newCertificationBody.label,
        };
      }
    );
    setSelectedCertificationBodies(newCertificationBodies);
  };

  const removeCertificationBody = (toBeRemoved: CertificationBody) => {
    const newCertificationBodies = selectedCertificationBodies.filter(
      (certificationBody: CertificationBody) =>
        certificationBody !== toBeRemoved
    );
    setSelectedCertificationBodies(newCertificationBodies);
  };

  return (
    <Grid container spacing={3} direction="column">
      {selectedCertificationBodies.map(
        (certificationBody: CertificationBody, index: number) => (
          <Grid
            key={certificationBody?.id ?? `empty${index}`}
            className="accordion-input"
            item
          >
            <h3>
              {`Certification body ${index + 1}`}{" "}
              {selectedCertificationBodies.length > 1 && (
                <Clear
                  onClick={() => removeCertificationBody(certificationBody)}
                />
              )}
            </h3>
            <SearchableDropdown
              value={
                certificationBody
                  ? mapNamedEntityToOption(certificationBody)
                  : null
              }
              required
              name="cb"
              Options={certificationBodyOptions}
              label="Select certification body"
              onChange={(e: React.ChangeEvent<{}>, value: Option | null) =>
                handleChangeCertificationBody(index, value)
              }
            />
          </Grid>
        )
      )}
      <Divider />
      <Grid item>
        <IconButton onClick={addCertificationBody}>
          <AddCircle className="add" />{" "}
          <span className="add">Add certification body</span>
        </IconButton>
      </Grid>
    </Grid>
  );
}
