import { useEffect, useState } from "react";
import DashboardMinified from "../../../models/DashboardMinified";
import getDashboards from "../../../../../http/dashboards/getDashboards";

export default () => {
  const [dashboards, setDashboards] = useState<DashboardMinified[]>([]);
  const [isLoadingDashboards, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDashboards()
      .then((data) => setDashboards(data))
      .finally(() => setLoading(false));
  }, []);

  return { dashboards, isLoadingDashboards };
};
