import React from "react";
import AuditDetailsView from "../../../../Models/Audit/AuditDetailsView";
import CreateAuditScheduleLocationProps from "../../../../Models/AuditProps/CreateAuditScheduleLocationProps";
import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import downloadCertificate from "../../http/downloadCertificate";
import downloadPDF from "../../http/downloadPDF";
import { VerifyUrls } from "../../index";
import {
  DropdownMenuItem,
  DropdownMenuItemType,
} from "../../../../components/DropdownMenu";
import Icon, { IconType } from "../../../../components/Icon";
import Permission from "../../../../Authentication/model/Permission";
import AuditStatus from "../../models/AuditStatus";
import VerifyAuditDetailsView from "../../../../Models/Verify/Models/VerifyAuditDetailsView";
import auditSharingPermission from "../../../../Enums/AuditSharingPermission";
import { dispatch } from "../../../../components/App/App";
import setError from "../../../../store/error/actions/setError";

export default (
  menuDetails: VerifyAuditDetailsView | undefined,
  refresh: () => void
): DropdownMenuItem[] => {
  if (!menuDetails) return [];
  return [
    getDownloadDocumentMenuItem(menuDetails),
    
  ].filter((item) => item !== null) as DropdownMenuItem[];
};


const getDownloadDocumentMenuItem = (
  mainDetails: VerifyAuditDetailsView
): DropdownMenuItem | null => {
 
  if( mainDetails.sharingPermissionName ==  auditSharingPermission.CertificateOnly){    
   return{
    icon: <Icon type={IconType.DownloadPDF} />,
    type: DropdownMenuItemType.BUTTON,
    label: "Certificate Unavailable_"+ mainDetails.sharingPermissionName ,
    onClick: () => {      
     
    },
    permissionRequired: [{ permission: Permission.VIEW }],
  } 
}
if( mainDetails.sharingPermissionName ==  auditSharingPermission.CertificatePDF && mainDetails.certificationStatus.name != "Certificated"){    
  return{
   icon: <Icon type={IconType.DownloadPDF} />,
   type: DropdownMenuItemType.BUTTON,
   label: "Certificate Unavailable_"+ mainDetails.sharingPermissionName+"_"+mainDetails.certificationStatus.name,
   onClick: () => {      
    
   },
   permissionRequired: [{ permission: Permission.VIEW }],
 } 
}
if( mainDetails.sharingPermissionName ==  auditSharingPermission.CertificatePDF && mainDetails.certificationStatus.name == "Certificated" && mainDetails.hasCertificate==false){    
  return{
   icon: <Icon type={IconType.DownloadPDF} />,
   type: DropdownMenuItemType.BUTTON,
   label: "Certificate Unavailable_"+ mainDetails.sharingPermissionName +"_"+ mainDetails.certificationStatus.name ,
   onClick: () => {      
    
   },
   permissionRequired: [{ permission: Permission.VIEW }],
 } 
}

  return {
    
    icon: <Icon type={IconType.DownloadPDF} />,
    type: DropdownMenuItemType.BUTTON,
    label: "Download Certificate",
    onClick: () => {      
      if(mainDetails.hasCertificate)
      {
      downloadCertificate(mainDetails.id);
      }
      else
      {
        dispatch(
          setError(
            "No certificate is currently available for this audit record"
          )
        );
      }
    },
    permissionRequired: [{ permission: Permission.VIEW }],
  };
};
