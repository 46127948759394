import React from "react";
import AuditRow from "../models/AuditRow";
import downloadCertificate from "../../http/downloadCertificate";
import ArchiveAudit from "../../../../Models/APIPostData/Audit/ArchiveAudit";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { archive } from "../../../../components/Paths/paths";
import history from "../../../../components/Common/History";
import { DataTableRowAction } from "../../../../components/DataTable/components/DataTableMoreActions";
import { AuditUrls } from "../..";
import Icon, { IconType } from "../../../../components/Icon";
import Permission from "../../../../Authentication/model/Permission";
import UserRole from "../../../../Models/user/UserRole";

type ActionReturn = Promise<{ readonly refreshOnResolve: boolean }>;

const onDownloadCertificate = (rowData?: AuditRow): ActionReturn => {
  if (!rowData) return Promise.reject();
  return downloadCertificate(rowData.Id).then(() => ({
    refreshOnResolve: false,
  }));
};

const onArchiveClick = (rowData?: AuditRow): ActionReturn => {
  if (!rowData) return Promise.reject();
  const archiveAudit: ArchiveAudit = {
    ExternalId: Number(rowData.Id),
    Archive: rowData.AuditStatus === "Current",
  };
  return axiosApi
    .post(`${BackendServices.AUDIT_SERVICE.AUDIT}${archive}`, archiveAudit)
    .then(() => ({ refreshOnResolve: true }));
};

const onShare = (rowData?: AuditRow): ActionReturn => {
  if (!rowData) return Promise.reject();
  history.push(AuditUrls.share(rowData.Id));
  return Promise.resolve({ refreshOnResolve: false });
};
const auditActions: (data?: AuditRow) => DataTableRowAction<AuditRow>[] = (
  data
) => {
  if (data?.ProgramOwner !== "BRCGS" || !UserRole.BRCGS_ADMIN) {
    return [
      {
        label: "Download Certificate",
        onClick: onDownloadCertificate,
        id: "dlc",
        icon: <Icon type={IconType.DOWNLOAD} />,
        permissionRequired: [{ permission: Permission.VIEW }],
      },
      {
        label: data?.AuditStatus === "Current" ? "Archive" : "Unarchive",
        id: "archive",
        onClick: onArchiveClick,
        permissionRequired: [{ permission: Permission.PERSIST }],
        icon: (
          <Icon
            type={
              data?.AuditStatus === "Current"
                ? IconType.ARCHIVE
                : IconType.UNARCHIVE
            }
          />
        ),
      },
    ];
  }

  return [
    {
      label: "Sharing",
      onClick: onShare,
      id: "share",
      icon: <Icon type={IconType.SHARE} />,
      permissionRequired: [],
    },
    {
      label: "Download Certificate",
      onClick: onDownloadCertificate,
      id: "dlc",
      icon: <Icon type={IconType.DOWNLOAD} />,
      permissionRequired: [{ permission: Permission.VIEW }],
    },
    {
      label: data?.AuditStatus === "Current" ? "Archive" : "Unarchive",
      id: "archive",
      onClick: onArchiveClick,
      permissionRequired: [{ permission: Permission.PERSIST }],
      icon: (
        <Icon
          type={
            data?.AuditStatus === "Current"
              ? IconType.ARCHIVE
              : IconType.UNARCHIVE
          }
        />
      ),
    },
  ];
};

export default auditActions;
