import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Option from "../../Models/forms/Option";
import { InputSpinner } from "../SearchableDropdown";

interface MultiSearchDropdownProps<T> {
  readonly label?: string;
  readonly options: Option<T>[];
  readonly value: Option<T>[];
  readonly onChange: (value: Option<T>[]) => void;
  readonly onTextChange?: (value: string) => void;
  readonly getOptionSelected?: () => boolean;
  readonly selectOnEnter?: boolean;
  readonly className?: string;
  readonly noOptionsText?: string;
  readonly isLoadingOptions?: boolean;
  readonly placeholder?: string;
}

const MultipleSelectDropdown = <T extends any = string>({
  label,
  options,
  value,
  onChange,
  onTextChange,
  getOptionSelected,
  selectOnEnter = false,
  className,
  isLoadingOptions,
  noOptionsText,
  placeholder,
}: MultiSearchDropdownProps<T>) => (
  <Autocomplete
    autoComplete={false}
    className={className}
    multiple
    options={options as Option<string>[]}
    getOptionLabel={(option: Option) => option.label}
    getOptionSelected={
      getOptionSelected || ((option, value1) => option.value === value1.value)
    }
    value={value as Option<string>[]}
    onChange={(event, newValue) => {
      onChange(newValue as Option<T>[]);
    }}
    defaultValue={[]}
    filterSelectedOptions
    noOptionsText={noOptionsText}
    renderInput={(params) => {
      return (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoadingOptions ? (
              <InputSpinner />
            ) : (
              params.InputProps.endAdornment
            ),
          }}
          autoComplete="off"
          variant="outlined"
          placeholder={placeholder ?? "Type to search"}
          label={label}
          onKeyDown={(e) => {
            if (selectOnEnter && e.keyCode === 13) {
              const { value: newValue } = params.inputProps as {
                value: string;
              };
              if (newValue.length > 0) {
                onChange([...value, { label: newValue, value: newValue }]);
              }
            }
          }}
          onChange={(e) => {
            if (onTextChange) {
              onTextChange(e.target.value);
            }
          }}
          className="select input"
        />
      );
    }}
  />
);

export default MultipleSelectDropdown;
