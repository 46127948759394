import React from "react";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { AddCircle, Clear } from "@material-ui/icons";
import CheckBox from "../../Common/CheckBox";
import { Education } from "../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import InputField from "../../InputField";

interface IEducation {
  selectedEducation: Education[];
  setSelectedEducation: React.Dispatch<React.SetStateAction<Education[]>>;
}

export default function EducationSection({
  selectedEducation,
  setSelectedEducation,
}: IEducation) {
  const addEducation = () => {
    const newEducations = [
      ...selectedEducation,
      {
        externalId: 0,
        updated: false,
        degree: "",
        eqiptTraining: false,
      } as Education,
    ];
    setSelectedEducation(newEducations);
  };

  const handleChangeField = (
    educationIndex: number,
    field: string,
    input: any
  ) => {
    const newEducations = selectedEducation.map(
      (education: Education, index: number) => {
        if (educationIndex !== index) return education;
        return { ...education, updated: true, [field]: input };
      }
    );
    setSelectedEducation(newEducations);
  };

  const removeEducation = (index: number) => {
    const newEducations = selectedEducation.filter(
      (education: Education) => selectedEducation.indexOf(education) !== index
    );
    setSelectedEducation(newEducations);
  };

  return (
    <Grid container spacing={3} direction="column">
      {selectedEducation.map((education: Education, index: number) => (
        <Grid key={index.toString()} className="accordion-input" item>
          <h3>
            {`Education ${index + 1}`}{" "}
            {selectedEducation.length > 1 && (
              <Clear onClick={() => removeEducation(index)} />
            )}
          </h3>
          <InputField
            name="Degree"
            label="Degree"
            value={education.degree}
            onChange={(value) => handleChangeField(index, "degree", value)}
            required
          />
          <CheckBox
            label="Training on EQIPT"
            checked={education.eqiptTraining}
            onChange={() =>
              handleChangeField(
                index,
                "eqiptTraining",
                !education.eqiptTraining
              )
            }
          />
        </Grid>
      ))}
      <Divider />
      <Grid item>
        <IconButton onClick={addEducation}>
          <AddCircle className="add" />{" "}
          <span className="add">Add education</span>
        </IconButton>
      </Grid>
    </Grid>
  );
}
