import useCategories from "../../../../http/public/useCategories";
import Option from "../../../../Models/forms/Option";
import { FilterState } from "../../index";

export default (filterState: FilterState): Option[] => {
  const standards = ((filterState.standards as Option[]) ?? []).map(
    (standard) => standard.label
  );

  return useCategories()
    .filter(
      (category) =>
        standards.length === 0 || standards.includes(category.standardName)
    )
    .map((category) => ({
      value: category.externalId,
      label: category.name,
    }));
};
