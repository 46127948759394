import axios from "axios";
import qs from "qs";
import ContentType from "./ContentType";
import HttpStatus from "./HttpStatus";
import { getAccessToken, verifyAuth } from "../../Authentication/Auth";
import { dispatch } from "../App/App";
import setError from "../../store/error/actions/setError";
import getErrorMessage from "./getErrorMessage";

declare module "axios" {
  export interface AxiosRequestConfig {
    readonly noGlobalError?: boolean;
  }
}

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_API,
  headers: {
    Accept: "application/json",
    "Content-Type": ContentType.APPLICATION_JSON,
  },
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false });
  },
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) return Promise.reject(error);
    if (error.config.noGlobalError) return Promise.reject(error);
    const status = error.response?.status;
    if (status === HttpStatus.UNAUTHORIZED) {
      verifyAuth();
    } else {
      const { method } = error.config ?? {};
      dispatch(setError(getErrorMessage(status, method)));
    }
    return Promise.reject(error);
  }
);

axiosApi.interceptors.request.use(async (config) => {
  const accessToken = await getAccessToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}` ?? "",
    },
  };
});

export default axiosApi;
