import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ScrollOnRouteChangeProps {
  readonly children: React.ReactNode;
}

const ScrollOnRouteChange = ({
  history,
  children,
}: RouteComponentProps & ScrollOnRouteChangeProps) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (window.scrollTo) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);
  return children;
};

export default withRouter<any, any>(ScrollOnRouteChange);
