import React from "react";
import { Link } from "react-router-dom";
import { IconButton as MaterialIconButton } from "@material-ui/core";
import clsx from "clsx";
import Icon, { IconProps } from "../Icon";
import styles from "./IconButton.module.scss";

export interface IconButtonProps extends IconProps {
  readonly label: string;
  readonly link?: string;
  readonly onClick?: () => void;
  readonly state?: object;
}

const IconButton = ({
  type,
  link,
  state,
  label,
  onClick,
  className,
}: IconButtonProps) => (
  <MaterialIconButton
    {...(onClick
      ? { onClick }
      : { component: Link, to: { pathname: link, state } })}
    className={clsx(styles.iconButton, className)}
  >
    <Icon type={type} /> {label}
  </MaterialIconButton>
);

export default IconButton;
