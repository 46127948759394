import {
  ACTION_GET_PERMISSION,
  ACTION_GET_PERMISSION_LOADING,
  ActionGetPermission,
  ActionGetPermissionsLoading,
} from "./actions/getPermissions";
import PermissionArea from "../../Authentication/model/PermissionArea";
import Permission from "../../Authentication/model/Permission";

export type UserPermissions = {
  [key in PermissionArea]: Permission[];
};

interface PermissionState {
  readonly userPermissions: UserPermissions;
  readonly isInitiated: boolean;
  readonly isLoading: boolean;
}

const initialPermissions = {
  [PermissionArea.SITE]: [],
  [PermissionArea.CB]: [],
  [PermissionArea.AUDIT]: [],
  [PermissionArea.ANALYTIC]: [],
  [PermissionArea.AUDITOR]: [],
  [PermissionArea.COMPANY]: [],
  [PermissionArea.USER]: [],
  [PermissionArea.SUB_OFFICE]: [],
  [PermissionArea.REPORT]: [],
  [PermissionArea.ADMINISTRATION]: [],
  [PermissionArea.RECALL]: [],
  [PermissionArea.IFS]: [],
  [PermissionArea.VERIFY]: []
};

const initialState: PermissionState = {
  isInitiated: false,
  userPermissions: initialPermissions,
  isLoading: false,
};

type PermissionAction = ActionGetPermission | ActionGetPermissionsLoading;

export default (
  state = initialState,
  actions: PermissionAction
): PermissionState => {
  switch (actions.type) {
    case ACTION_GET_PERMISSION: {
      return {
        isLoading: false,
        userPermissions: actions.payload,
        isInitiated: true,
      };
    }
    case ACTION_GET_PERMISSION_LOADING:
      return {
        userPermissions: initialPermissions,
        isLoading: true,
        isInitiated: false,
      };
    default:
      return state;
  }
};
