import React from "react";
import Spinner from "../../../../components/Spinner";
import styles from "../../PublicDirectory.module.scss";

interface LoadingOverlayProps {
  readonly isLoading: boolean;
}

const LoadingOverlay = ({ isLoading }: LoadingOverlayProps) =>
  isLoading ? (
    <div className={styles.isLoading}>
      <Spinner delay={0} />
    </div>
  ) : null;

export default LoadingOverlay;
