import { RouteComponentProps } from "react-router-dom";
import useGetVerifyAudit from "./util/useGetVerifyAudit";
import DetailsPage from "../../../components/DetailsPage";
import Entity from "../../../Models/APIGetData/Entity";
import React, { useEffect, useState } from "react";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import { getUserRole } from "../../../Authentication/Auth";
import UserRole from "../../../Models/user/UserRole";
import VerifyAuditDetails from "./Components";
import getMenuItems from "../AuditDetails/util/getMenuItems";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { Refresh } from "@material-ui/icons";
import auditSharingPermission from "../../../Enums/AuditSharingPermission";

const VerifyAuditDetailsMenuItems=[
    DetailsPageMenuItem.FAVOURITES,
];


const isFavouritedValue = async (id: string): Promise<boolean> => {
  
    const response = await axiosApi.get<boolean>(
      `${BackendServices.AUDIT_SERVICE.VERIFY}/GetFavourited/${id}`
    );
    return response.data; // Assuming response.data is the boolean value
    
};

const VerifyAuditDetailsPage = ({
    match: {
        params: { id },
      },
    }: RouteComponentProps<Entity>) => {

      const [isFavourited, setIsFavourited] = useState<boolean>(false);

  useEffect(() => {
    // Fetch favourite status when component mounts or id changes
    const fetchFavouriteStatus = async () => {
      const favourited = await isFavouritedValue(id);
      setIsFavourited(favourited);
    };

    fetchFavouriteStatus();
  }, [id]);
        const {
            mainDetails,
            isLoading,
            refresh,
          } = useGetVerifyAudit(id);
    
    return (
        <DetailsPage
          id={id}
          title={mainDetails?.sharingPermissionName==auditSharingPermission.BasicOnly?"":mainDetails?.site?.name ?? ""}
          className="audit-detail"
          detailsPageMenuItems={VerifyAuditDetailsMenuItems}
          detailsPageMenuConfig={{
             }}
          isLoading={isLoading}
          favouriteConfig={{
            isFavourited:isFavourited,
          }}
          menuItems={getMenuItems(mainDetails, refresh)}
        >
          <div className="audit-details__main">
            {mainDetails && (
              <div className="audit-details__main__details">
                <VerifyAuditDetails
                  mainDetails={mainDetails?.sharingPermissionName==auditSharingPermission.BasicOnly?null:mainDetails}
                  refresh={refresh}
                />
              </div>
            )}
          </div>
        </DetailsPage>
       );
    };
export default VerifyAuditDetailsPage;