import React, { useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import clsx from "clsx";
import FormGridItem from "../Common/FormGridItem";
import { CancelButton, SubmitButton } from "../Button/button";
import history from "../Common/History";
import { idParam } from "../Paths/paths";
import { Field } from "../CreatePage";
import CheckBox from "../Common/CheckBox";
import InputField from "../InputField";
import { ParentContext } from "../../routing/GenericRoutes";

interface FormProps {
  readonly onSubmit: () => Promise<any>;
  readonly fields: (Field | string)[];
  readonly className?: string;
  readonly actionButtonArea?: JSX.Element;
}

const Form = ({ onSubmit, fields, className, actionButtonArea }: FormProps) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const parentContext = useContext(ParentContext);

  const onFormSubmit = () => {
    setSubmitting(true);
    return onSubmit().finally(() => setSubmitting(false));
  };
  return (
    <ValidatorForm
      onSubmit={onFormSubmit}
      className={clsx("validator-form", className)}
    >
      <Grid container spacing={3} direction="column">
        {fields
          .filter((field) => (typeof field === "string" || field.show) ?? true)
          .map((field, index) =>
            typeof field === "string" ? (
              <h3 key={index.toString()}>{field}</h3>
            ) : (
              <FormGridItem key={field.name}>
                {field.header && <h3>{field.header}</h3>}
                {renderField(field)}
              </FormGridItem>
            )
          )}
        {actionButtonArea ?? (
          <FormGridItem>
            <SubmitButton disabled={isSubmitting} />
            {parentContext && (
              <CancelButton
                disabled={isSubmitting}
                onClick={() =>
                  history.push(
                    parentContext.parentLink.replace(
                      idParam,
                      `/${parentContext.id}`
                    )
                  )
                }
              />
            )}
          </FormGridItem>
        )}
      </Grid>
    </ValidatorForm>
  );
};

const renderField = (field: Field) => {
  if (field.render) {
    return field.render();
  }
  if (field.setBooleanValue) {
    return (
      <CheckBox
        checked={!!field.value}
        onChange={(e, checked) => field.setBooleanValue?.(checked)}
        name={field.name}
        label={field.label}
        disabled={field.disabled}
      />
    );
  }

  return (
    <InputField
      label={field.label}
      value={field.value as string}
      name={field.name}
      onChange={field.setTextValue}
      disabled={field.disabled}
      required={field.required}
      validators={field.validators}
    />
  );
};

export default Form;
