import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { FilterState } from "../index";
import { ReportFilter, ReportTemplate } from "../templates/templates";
import filterMappings from "../templates/filterMappings";

export default (
  template: ReportTemplate,
  filterValues: FilterState,
  format: "csv" | "xls"
) => {
  const params = Object.keys(filterValues).reduce<{}>((acc, curr) => {
    const mapper = filterMappings[curr as ReportFilter].mapToBody;
    return mapper ? { ...acc, ...mapper(filterValues) } : acc;
  }, {});

  const url = (() => {
    switch (format) {
      case "csv":
        return template.csvUrl;
      default:
        throw new Error("Error finding data");
    }
  })();

  return axiosApi
    .get(`${BackendServices.REPORT_SERVICE.REPORT}/${url}`, {
      params,
      responseType: "text",
      noGlobalError: true,
    })
    .then(({ statusText }) => {
      return statusText;
    })
    .catch(async (error) => {
      let errorText;
      try {
        errorText = await error.response.data.text();
      } catch (e) {
        errorText = null;
      }
      return Promise.reject(
        error.response?.status === 400 && errorText ? errorText : "Error"
      );
    });
};
