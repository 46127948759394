import React, { useEffect, useState } from "react";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { AddCircle, Clear } from "@material-ui/icons";
import axios from "../../Api/Axios";
import BackendServices from "../../Api/BackendService";
import { getTrainingTypes } from "../../Paths/paths";
import CountryDropdown from "../../Common/Input/CountryDropdown";
import { formatDate } from "../../../util/dates/DateFormats";
import {
  AuditorNamedEntity,
  Training,
} from "../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import SelectField from "../../SelectField";
import Option from "../../../Models/forms/Option";
import NamedEntity from "../../../Models/APIGetData/NamedEntity";
import InputField from "../../InputField";
import DatePicker from "../../DatePicker";

interface ITraining {
  newDate: string;
  selectedTraining: Training[];
  setSelectedTraining: React.Dispatch<React.SetStateAction<Training[]>>;
}

export const mapNamedEntityToOption = (entity: NamedEntity): Option => ({
  label: entity.name,
  value: entity.id,
});

export const mapOptionToNamedEntity = (option: Option): NamedEntity => ({
  id: +option.value,
  name: option.label,
});

export default function TrainingSection({
  newDate,
  selectedTraining,
  setSelectedTraining,
}: ITraining) {
  const [trainingTypesOptions, setTrainingTypesOptions] = useState<Option[]>(
    []
  );

  useEffect(() => {
    getTrainingTypesOptions();
  }, []);

  const getTrainingTypesOptions = () => {
    axios
      .get<NamedEntity[]>(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}${getTrainingTypes}`
      )
      .then((response) =>
        setTrainingTypesOptions(response.data.map(mapNamedEntityToOption))
      );
  };

  const addTraining = () => {
    const newTraining = [
      ...selectedTraining,
      {
        externalId: 0,
        updated: false,
        trainingType: mapOptionToNamedEntity(trainingTypesOptions[0]),
        title: "",
        country: undefined,
        startDate: newDate,
        endDate: newDate,
        trainerName: "",
        trainerType: "",
      } as Training,
    ];
    setSelectedTraining(newTraining);
  };

  const handleChangeTrainingType = (
    trainingIndex: number,
    trainingType: Option
  ) => {
    handleChangeField(
      trainingIndex,
      "trainingType",
      mapOptionToNamedEntity(trainingType)
    );
  };

  const handleChangeField = (
    trainingIndex: number,
    field: keyof Training,
    input: Option | AuditorNamedEntity | string
  ) => {
    const newTraining = selectedTraining.map(
      (training: Training, index: number) => {
        if (trainingIndex !== index) return training;
        return { ...training, updated: true, [field]: input };
      }
    );
    setSelectedTraining(newTraining);
  };

  const removeTraining = (index: number) => {
    const newTraining = selectedTraining.filter(
      (training: Training) => selectedTraining.indexOf(training) !== index
    );
    setSelectedTraining(newTraining);
  };

  return (
    <Grid container spacing={3} direction="column">
      {selectedTraining.map((training: Training, index: number) => (
        <Grid key={index.toString()} className="accordion-input" item>
          <h3>
            {`Training ${index + 1}`}{" "}
            {selectedTraining.length > 1 && (
              <Clear onClick={() => removeTraining(index)} />
            )}
          </h3>
          <SelectField
            label="Select training type"
            name="Training type"
            value={
              trainingTypesOptions.find(
                (o) => o.value === training.trainingType?.id
              ) ?? null
            }
            options={trainingTypesOptions}
            onChange={(value) =>
              value && handleChangeTrainingType(index, value)
            }
            required
          />
          <InputField
            name="Title"
            label="Title"
            value={training.title}
            onChange={(value) => handleChangeField(index, "title", value)}
            required
          />
          <CountryDropdown
            value={
              training.country ? mapNamedEntityToOption(training.country) : null
            }
            onChange={(country) =>
              country &&
              handleChangeField(
                index,
                "country",
                mapOptionToNamedEntity(country)
              )
            }
            required
          />
          <DatePicker
            value={training.startDate ? training.startDate : null}
            label="Start date"
            maxDate={training.endDate ?? new Date()}
            onChange={(d) =>
              handleChangeField(index, "startDate", formatDate(d))
            }
            required
            name="trainingStart"
          />
          <DatePicker
            value={training.endDate ? training.endDate : null}
            label="End date"
            minDate={training.startDate}
            maxDate={new Date()}
            onChange={(d) => handleChangeField(index, "endDate", formatDate(d))}
            required
            name="trainingEnd"
          />
          <InputField
            name="TrainerName"
            label="Trainer name"
            value={training.trainerName}
            onChange={(value) => handleChangeField(index, "trainerName", value)}
            required
          />
          <InputField
            name="TrainerType"
            label="Trainer type"
            value={training.trainerType}
            onChange={(value) => handleChangeField(index, "trainerType", value)}
            required
          />
        </Grid>
      ))}
      <Divider />
      <Grid item>
        <IconButton onClick={addTraining}>
          <AddCircle className="add" />
          <span className="add">Add training</span>
        </IconButton>
      </Grid>
    </Grid>
  );
}
