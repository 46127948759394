import SharingType from "../models/SharingType";
import axiosApi from "../../../components/Api/Axios";
import getShareRootUrl from "./getShareRootUrl";
import SharableTarget from "../models/SharableTarget";

export default (id: string, type: SharingType): Promise<SharableTarget[]> =>
  axiosApi
    .get<SharableTarget[]>(`${getShareRootUrl(type)}/getShares/${id}`)
    .then(({ data }) => data)
    .catch(() => []);
