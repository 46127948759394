import useGetData from "../../../../http/useGetData";
import axios from "../../../../components/Api/Axios";
import AuditorDetails from "../models/AuditorDetails";
import BackendServices from "../../../../components/Api/BackendService";
import { details } from "../../../../components/Paths/paths";

export default (id: string) => {
  const { isLoading, refresh, data } = useGetData(() =>
    axios.get<AuditorDetails>(
      `${BackendServices.AUDITOR_SERVICE.AUDITOR}${details}/${id}`
    )
  );

  return { isLoading, data, refresh };
};
