import React from "react";
import { Provider } from "react-redux";
import "../../css/style.css";
import { Route, Router, Switch } from "react-router-dom";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import PublicDirectoryPages from "../../routing/PublicDirectoryPages";
import { privateArea, publicPage } from "../Paths/paths";
import ReduxStateHandler from "../../store";
import history from "../Common/History";
import PrivateDirectoryPages from "../../routing/PrivateDirectoryPages";
import { requireAuth } from "../../Authentication/Auth";
import { RootState } from "../../store/reducers";
import ScrollOnRouteChange from "../../routing/ScrollOnRouteChange";

const reduxStateHandler = new ReduxStateHandler();
const store = reduxStateHandler.getStore();
export const dispatch: ThunkDispatch<
  RootState,
  void,
  AnyAction
> = store.dispatch as ThunkDispatch<RootState, void, AnyAction>;

function App() {
  const browserHistory = history;

  return (
    <Router history={browserHistory}>
      <ScrollOnRouteChange>
        <Provider store={store}>
          <Switch>
            <Route
              path={privateArea}
              component={requireAuth(PrivateDirectoryPages)}
            />
            <Route path={publicPage} component={PublicDirectoryPages} />
          </Switch>
        </Provider>
      </ScrollOnRouteChange>
    </Router>
  );
}

export default App;
