import React from "react";
import { RouteComponentProps } from "react-router-dom";
import DetailsPage from "../../../components/DetailsPage";
import useGetUserDetails from "./http/useGetUserDetails";
import { getFullName } from "../../../helpers/NameHelper";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import {
  DropdownMenuItem,
  DropdownMenuItemType,
} from "../../../components/DropdownMenu";
import UserStatus from "../../../Enums/UserStatus";
import Icon, { IconType } from "../../../components/Icon";
import Permission from "../../../Authentication/model/Permission";
import axios from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import UserInfoBox from "./components/UserInfoBox";

const UserDetails = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { isLoading, user, refresh } = useGetUserDetails(id);

  const isActive = user?.status === UserStatus.ACTIVE;
  const toggleActiveactivateUser = () => {
    axios
      .patch(
        `${BackendServices.USER_SERVICE.USER}/${
          isActive ? "deactivate" : "activate"
        }/${id}`
      )
      .then(refresh);
  };

  const getActivateMenuItem = (): DropdownMenuItem => {
    const text = isActive ? "Deactivate" : "Activate";
    const dialogHeader = `Are you sure you want to ${text.toLowerCase()} the user?`;

    return {
      icon: <Icon type={IconType.SUSPEND} />,
      label: text,
      type: DropdownMenuItemType.BUTTON_CONFIRM,
      dialogHeader,
      onConfirm: toggleActiveactivateUser,
      declineButtonText: "Cancel",
      acceptButtonText: text,
      permissionRequired: [{ permission: Permission.INITIALIZE }],
    };
  };

  return (
    <DetailsPage
      id={id}
      title={user ? getFullName(user) : "User Information"}
      detailsPageMenuItems={[
        DetailsPageMenuItem.EDIT,
        DetailsPageMenuItem.CHANGE_LOG,
      ]}
      menuItems={user?.primaryOrganisation ? [getActivateMenuItem()] : []}
      isLoading={isLoading}
    >
      {user && <UserInfoBox user={user} />}
    </DetailsPage>
  );
};

export default UserDetails;
