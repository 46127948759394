import React from "react";
import DataTable, { DataTableQueryParams } from "../../../components/DataTable";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import auditColumns from "./util/auditColumns";
import AuditRow from "./models/AuditRow";
import SearchResponses from "../../../Models/APIGetData/SearchResponses";
import useStandards from "../../../http/public/useStandards";
import mapFilter from "./util/mapFilter";
import useGetAuditStatuses from "../../../http/audit/useGetAuditStatuses";
import useGetCertificationStatuses from "../../../http/audit/useGetCertificationStatuses";
import AuditSummary from "../../../Models/Audit/AuditSummary";
import auditActions from "./util/auditActions";
import { AuditUrls } from "../index";
import ContentPage from "../../../components/ContentPage";
import useGetProgramOwners from "../../../http/audit/useGetProgramOwners";
import { isBRCGSUser, isCBUser } from "../../../Authentication/Auth";

const AuditSearchTable = () => {
  const standardsFilter = mapFilter(useStandards());
  const statusFilter = mapFilter(useGetAuditStatuses().auditStatuses);
  const programOwnerFilters = mapFilter(
    useGetProgramOwners().programOwners,
    true
  );

  const certificationFilter = mapFilter(
    useGetCertificationStatuses().certificationStatuses
  );

  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: AuditRow[]; totalPages: number }> => {
    return axiosApi
      .get<SearchResponses<AuditSummary>>(BackendServices.AUDIT_SERVICE.AUDIT, {
        params,
      })
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultsList.map(
          (audit): AuditRow => ({
            Id: audit.id.toString(),
            SiteName: audit.site.name,
            SiteId: audit.site.id,
            CertificationBody: audit.certificationBody.name,
            ProgramOwner: audit.programOwner,
            Standard: audit.standard.name,
            AuditOwner: audit.auditOwner,
            AuditStartDate: audit.startDate,
            AuditValidationDate: audit.validationDate,
            CertificationStatus: audit.certificationStatus,
            AuditStatus: audit.auditStatus,
          })
        ),
      }));
  };

  return (
    <ContentPage title="Audits">
      <DataTable<AuditRow>
        columns={auditColumns}
        fetchRemoteData={fetch}
        filters={{
          Standard: () => standardsFilter,
          ProgramOwner: () => programOwnerFilters,
          CertificationStatus: () => certificationFilter,
          AuditStatus: () => statusFilter,
        }}
        rowActions={auditActions}
        toolBarOptions={{
          newEntityLink: !isCBUser()? {
            label: "Add new audit",
            link: AuditUrls.create(),
          }:undefined,
        }}
        archiveFilter={{ enabled: isCBUser() || isBRCGSUser() }}
      />
    </ContentPage>
  );
};

export default AuditSearchTable;
