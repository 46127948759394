import React from "react";
import {
  DropdownMenuItem,
  DropdownMenuItemType,
} from "../../../../components/DropdownMenu";
import Icon, { IconType } from "../../../../components/Icon";
import { UserUrls } from "../../../UserManagement";
import UserParent from "../../../UserManagement/UserNew/util/UserParent";
import Permission from "../../../../Authentication/model/Permission";
import PermissionArea from "../../../../Authentication/model/PermissionArea";
import CreateAuditScheduleLocationProps from "../../../../Models/AuditProps/CreateAuditScheduleLocationProps";
import { AuditUrls } from "../../../AuditManagement";
import { SiteUrls } from "../../index";

export default (id: string, name: string = ""): DropdownMenuItem[] => {
  const state: CreateAuditScheduleLocationProps = {
    site: {
      id: +id,
      name,
    },
  };

  return [
    {
      icon: <Icon type={IconType.USER_ADD} />,
      label: "Create User",
      type: DropdownMenuItemType.LINK,
      link: UserUrls.create({ [UserParent.SITE]: id }),
      permissionRequired: [
        {
          permission: Permission.GENERATE,
          area: PermissionArea.USER,
        },
      ],
    },
    {
      icon: <Icon type={IconType.USERS} />,
      label: "View Users",
      type: DropdownMenuItemType.LINK,
      state: { name },
      link: SiteUrls.users(id),
      permissionRequired: [
        {
          permission: Permission.VIEW,
          area: PermissionArea.USER,
        },
      ],
    },
    {
      icon: <Icon type={IconType.SCHEDULE} />,
      label: "Audit Schedule",
      type: DropdownMenuItemType.LINK,
      state,
      link: AuditUrls.scheduleSite(id),
      permissionRequired: [
        {
          area: PermissionArea.AUDIT,
          permission: Permission.SCHEDULE,
        },
      ],
    },
  ];
};
