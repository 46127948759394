import { useEffect, useState } from "react";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";

export default () => {
  const [availableReports, setAvailableReports] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axiosApi
      .get<string[]>(`${BackendServices.REPORT_SERVICE.REPORT}/data/IFSreport`)
      .then(({ data }) =>
        setAvailableReports(data.sort((a, b) => (a < b ? -1 : 1)))
      )
      .finally(() => setLoading(false));
  }, []);
  return { availableReports, isLoading };
};
