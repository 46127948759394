import React from "react";
import DropdownMenu, { DropdownMenuItemType } from "../../../DropdownMenu";
import PermissionArea from "../../../../Authentication/model/PermissionArea";
import Permission from "../../../../Authentication/model/Permission";

export interface DataTableRowAction<T> {
  readonly id: number | string;
  readonly onClick: (
    rowData: T | undefined
  ) => Promise<{ readonly refreshOnResolve: boolean }>;
  readonly label: string;
  readonly icon: JSX.Element;
  readonly permissionRequired: {
    readonly target?: PermissionArea;
    readonly permission: Permission;
  }[];
}

interface DataTableMoreActionsProps<T> {
  readonly actions?: (data?: T) => DataTableRowAction<T>[];
  readonly onClose: () => void;
  readonly target: any;
  readonly rowData?: T | T[];
  readonly refresh: () => void;
}

const DataTableMoreActions = <T extends object>({
  actions = () => [],
  target,
  onClose,
  rowData,
  refresh,
}: DataTableMoreActionsProps<T>) => {
  const data = Array.isArray(rowData) ? rowData[0] : rowData;
  return (
    <DropdownMenu
      target={target}
      onClose={onClose}
      items={actions(data).map((action) => ({
        type: DropdownMenuItemType.BUTTON,
        label: action.label,
        icon: action.icon,
        permissionRequired: action.permissionRequired,
        onClick: () => {
          action.onClick(data).then(({ refreshOnResolve }) => {
            if (refreshOnResolve) {
              refresh();
            }
          });
          onClose();
        },
      }))}
    />
  );
};

export default DataTableMoreActions;
