import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import CreatePage, { Field } from "../../../components/CreatePage";
import AccreditationBodyDropdown from "../../../components/Common/Input/AccreditationBodyDropdown";
import { SelectValidation } from "../../../components/Common/Input/Select";
import { required } from "../../../Enums/ValidationRule";
import { RatingMenuItems } from "../../../constants/RatingMenuItems";
import { suspensionType } from "../../../Enums/SuspentionType";
import { Permission } from "../../../Authentication/model/Permission";
import { usePermissionContext } from "../../../routing/contexts/PermissionContext";
import getCBDetails from "../../../http/cb/getCBDetails";
import RadioGroup from "../../../components/RadioGroup";
import { auditSubmissionOption } from "../../../Enums/AuditSubmissions";
import useGetData from "../../../http/useGetData";
import { SpinnerCenter } from "../../../components/Spinner";
import Option from "../../../Models/forms/Option";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { CBUrls } from "../index";
import { UpdateCertificationBodyData } from "../CBNew/models/CBSubmission";
import Office from "../../../Models/Office/Office";
import { ParentContext } from "../../../routing/GenericRoutes";

const CBEdit = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { isLoading, data } = useGetData(() => getCBDetails(id));
  const [name, setName] = useState("");
  const [accreditationBody, setAccreditationBody] = useState<Option | null>(
    null
  );
  const [rating, setRating] = useState(0);
  const [ratingComments, setRatingComments] = useState("");
  const [visible, setVisible] = useState(false);
  const [auditSubmissionStatus, setAuditSubmissionStatus] = useState<string>(
    suspensionType.ACTIVE
  );
  const [mainOffice, setMainOffice] = useState<Office | undefined>(undefined);
  const permissions = usePermissionContext();
  const isSuspensionAdmin = permissions.includes(Permission.SUSPEND);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setName(data.mainOffice?.name ?? "");
      setAccreditationBody(
        data.accreditationBody
          ? {
              label: data.accreditationBody.name ?? "",
              value: data.accreditationBody.id,
            }
          : null
      );
      setMainOffice(data.mainOffice);
      setRating(data.rating ?? 0);
      setRatingComments(data.ratingComments ?? "");
      setVisible(!data.removeFromPublicView);
      setAuditSubmissionStatus(data.auditSubmissionStatus);
    }
  }, [data]);

  const fields: (Field | string)[] = [
    {
      name: "CertificationBody",
      label: "Certification Body",
      value: name,
      setTextValue: setName,
      disabled: true,
    },
    {
      name: "abId",
      render: () => (
        <AccreditationBodyDropdown
          required
          accreditationBody={accreditationBody}
          updateAccreditationBody={setAccreditationBody}
        />
      ),
    },
    {
      name: "rating",
      render: () => (
        <SelectValidation
          name="Rating"
          label="Rating"
          value={rating}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setRating(+e.target.value)
          }
          validators={[required.name]}
          errorMessages={[required.message]}
        >
          {RatingMenuItems()}
        </SelectValidation>
      ),
    },
    {
      name: "ratingComments",
      label: "Rating Comments",
      value: ratingComments,
      setTextValue: setRatingComments,
    },
    ...(data?.suspensionStatus !== suspensionType.ACTIVE && isSuspensionAdmin
      ? [
          "Other",

          {
            name: "visible",
            label: "Visible in public directory",
            value: visible,
            setBooleanValue: setVisible,
          },
          {
            name: "auditSubmissionStatus",
            render: () => (
              <RadioGroup
                label="Audit submissions"
                value={auditSubmissionStatus}
                onChange={(val) => setAuditSubmissionStatus(val)}
                options={auditSubmissionOption.map((option) => ({
                  label: option.text,
                  value: option.value,
                }))}
              />
            ),
          },
        ]
      : []),
  ];

  const onSubmit = () => {
    return axiosApi
      .put(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/${id}`,
        getPutBody()
      )
      .then(() => history.push(CBUrls.details(mainOffice?.id ?? "")));
  };

  const getPutBody = (): UpdateCertificationBodyData => ({
    ratingComments,
    removeFromPublicView: !visible,
    rating,
    accreditationBodyId: +(accreditationBody?.value ?? -1),
    auditSubmissionStatus,
  });

  if (isLoading) return <SpinnerCenter />;
  return (
    <ParentContext.Provider
      value={{
        parentLink: mainOffice
          ? CBUrls.details(mainOffice?.id)
          : CBUrls.searchTable,
        confirm: true,
      }}
    >
      <CreatePage
        title="Edit Certification body details"
        fields={fields}
        onSubmit={onSubmit}
      />
    </ParentContext.Provider>
  );
};

export default CBEdit;
