import React from "react";
import CompanySiteTabs from "../../../components/CompanyManagement/Company/Tab Pages/CompanySiteTabs";
import ContentPage from "../../../components/ContentPage";
import { TITLE_COMPANY_MANAGEMENT } from "../../../components/CompanyManagement/CompanyManagementSearchArea";

const TypeSelecting = () => (
  <ContentPage title={TITLE_COMPANY_MANAGEMENT}>
    <CompanySiteTabs defaultTab={1} />
  </ContentPage>
);

export default TypeSelecting;
