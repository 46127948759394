enum PermissionArea {
  AUDIT = "Audit",
  AUDITOR = "Auditor",
  CB = "CertificationBody",
  SUB_OFFICE = "SubOffice",
  COMPANY = "Company",
  SITE = "Site",
  RECALL = "Recall",
  USER = "User",
  REPORT = "Reports",
  ANALYTIC = "Analytics",
  ADMINISTRATION = "Administration",
  IFS = "IFSAudit",
  VERIFY = "Verify"
}

export default PermissionArea;
