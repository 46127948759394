import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import Option from "../../Models/forms/Option";
import withValidation, { ValidationFieldProps } from "../FieldValidator";

interface SelectFieldOuterProps extends ValidationFieldProps {
  readonly value: Option | null;
  readonly onChange?: (value: Option | null) => void;
  readonly options: Option[];
}

const SelectField = ({
  label,
  name,
  value,
  onChange = () => {},
  options,
  disabled,
}: SelectFieldOuterProps) => (
  <TextField
    select
    onChange={(event) =>
      onChange(
        options.find((option) => option.value === event.target.value) ?? null
      )
    }
    label={label}
    disabled={disabled}
    name={name ?? ""}
    value={value?.value ?? ""}
    autoComplete="off"
    className="input text text-field"
    variant="outlined"
  >
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
);

export default withValidation(SelectField);
