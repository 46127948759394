import React from "react";
import Office from "../../../../../Models/Office/Office";
import InfoBox from "../../../../../components/Infobox";
import StarRating from "../../../../../components/StarRating/StarRating";
import { officeType } from "../../../../../Enums/officeType";
import { getOfficeStatusDisplay } from "../../../../../components/CertificationBodyManagement/OfficeHelper";
import mapLocationToEntries from "../../../../../components/Infobox/utils/mapLocationToEntries";
import mapContactToEntries from "../../../../../components/Infobox/utils/mapContactToEntries";
import { CBUrls } from "../../../index";

interface CBDetailBoxesProps {
  readonly office: Office | null;
}

const CBDetailsBoxes = ({ office }: CBDetailBoxesProps) => {
  if (!office) return null;
  const { certificationBody } = office;
  return (
    <>
      {certificationBody && (
        <InfoBox
          header="Certification Body"
          columns={2}
          items={[
            ...(!office.isMainOffice && certificationBody.mainOffice
              ? [
                  {
                    label: "Main Office",
                    value: {
                      label: certificationBody.mainOffice.name,
                      link: CBUrls.details(certificationBody.mainOffice.id),
                    },
                    required: true,
                  },
                ]
              : [
                  {
                    label: "Main Office",
                    value: certificationBody.mainOffice?.name,
                    required: true,
                  },
                ]),
            {
              label: "Accreditation Body",
              value: certificationBody.accreditationBody?.name,
              required: true,
            },
            {
              label: "Rating",
              value: () => (
                <StarRating value={certificationBody.rating} size="small" />
              ),
              required: true,
            },
            {
              label: "Rating Comments",
              value: certificationBody.ratingComments,
            },
          ]}
        />
      )}
      <InfoBox
        header="Office Details"
        columns={2}
        items={[
          { label: "ID", value: office.id },
          { label: "Name", value: office.name },
          {
            label: "Office Type",
            value: office.isMainOffice ? officeType.MAIN : officeType.SUB,
          },
          { label: "Status", value: getOfficeStatusDisplay(office) },
          {
            label: "Visibility",
            value: office.visibility
              ? "Visible in public directory"
              : "Not visible in public directory",
          },
          {
            label: "Standards",
            value: office.standards?.map((s) => s.name),
          },
          ...mapLocationToEntries(office.location),
          ...mapContactToEntries(office.contact),
        ]}
      />
    </>
  );
};

export default CBDetailsBoxes;
