import moment from "moment";
import React, { useState } from "react";
import { isBRCGSUser } from "../../../../../Authentication/Auth";
import axiosApi from "../../../../../components/Api/Axios";
import BackendServices from "../../../../../components/Api/BackendService";
import CertificationBodySelector from "../../../../../components/CertificationBodySelector";
import { LinkText } from "../../../../../components/Common/hyperlinks";
import { DatePicker } from "../../../../../components/Common/Input/DatePicker";
import EditButtons from "../../../../../components/EditButtons";
import InfoBox from "../../../../../components/Infobox";
import { InputField } from "../../../../../components/InputField";
import ListEntry from "../../../../../Models/CommonProps/ListEntry";
import Option from "../../../../../Models/forms/Option";
import { ReasonForNotificationOption } from "../../../../../Models/Recall/ReasonForNotification";
import RecallDetailsView from "../../../../../Models/Recall/RecallDetailsView";
import { SiteOrSupplierOption } from "../../../../../Models/Recall/SiteOrSupplierIssue";
import {
  DEFAULT_DATE_FORMAT,
  formatDate,
} from "../../../../../util/dates/DateFormats";
import { CBUrls } from "../../../../CBManagement";
import { SiteUrls } from "../../../../SiteManagement";
import ReasonForNotificationDropdown from "../../../RecallNew/components/ReasonForNotificationDropdown";
import RecallCategoryDropdown from "../../../RecallNew/components/RecallCategoryDropdown";
import SiteOrSupplierDropdown from "../../../RecallNew/components/SiteOrSupplierDropdown";
import RecallStatusDropdown from "../../../RecallNew/components/RecallStatusDropdown";
import RecallEditData from "../../models/RecallEditData";
import { AuditUrls } from "../../../../AuditManagement";
import RecallCertificationStatusDropdown from "../../../RecallNew/components/RecallCertificationStatusDropdown";

interface RecallDetailsProps {
  readonly mainDetails?: RecallDetailsView | null;
  readonly refresh: () => void;
}

function RecallDetails({ mainDetails, refresh }: RecallDetailsProps) {
  const [isEditing, setEditing] = useState(false);

  const [
    newReasonForNotification,
    setNewReasonForNotification,
  ] = useState<Option | null>(
    mainDetails?.reasonForNotification
      ? ReasonForNotificationOption.find(
          (o) => o.value === mainDetails?.reasonForNotification
        ) ?? null
      : null
  );

  const [newRecallCategory, setNewRecallCategory] = useState<Option | null>(
    mainDetails?.recallCategory?.id && mainDetails?.recallCategory?.name
      ? {
          value: mainDetails?.recallCategory?.id,
          label: mainDetails?.recallCategory?.name,
        }
      : null
  );

  const [
    newSiteOrSupplierIssue,
    SetNewSiteOrSupplierIssue,
  ] = useState<Option | null>(
    mainDetails?.siteOrSupplierIssue
      ? SiteOrSupplierOption.find(
          (o) => o.value === mainDetails?.siteOrSupplierIssue
        ) ?? null
      : null
  );

  const [newRecallStatus, SetNewRecallStatus] = useState<Option | null>(
    mainDetails?.recallStatus?.id && mainDetails?.recallStatus?.name
      ? {
          value: mainDetails.recallStatus.id,
          label: mainDetails.recallStatus.name,
        }
      : null
  );

  const [
    newCertificationStatus,
    SetNewCertificationStatus,
  ] = useState<Option | null>(
    mainDetails?.recallCertificationStatus?.id &&
      mainDetails?.recallCertificationStatus?.name
      ? {
          value: mainDetails.recallCertificationStatus.id,
          label: mainDetails.recallCertificationStatus.name,
        }
      : null
  );

  const [
    newCertificationBody,
    setNewCertificationBody,
  ] = useState<Option | null>(
    mainDetails?.certificationBody?.id && mainDetails?.certificationBody?.name
      ? {
          value: mainDetails?.certificationBody?.id,
          label: mainDetails?.certificationBody?.name,
        }
      : null
  );

  const [
    newCertificationStatusLastUpdated,
    setNewCertificationStatusLastUpdated,
  ] = useState(mainDetails?.certificationStatusLastUpdated);
  const [newRecallDate, setNewRecallDate] = useState(mainDetails?.recallDate);
  const [newOutlineOfIncident, setNewOutlineOfIncident] = useState(
    mainDetails?.outlineOfIncident
  );
  const [newCorrection, setNewCorrection] = useState(mainDetails?.correction);
  const [newProductsRecalled, setNewProductsRecalled] = useState(
    mainDetails?.productRecalled
  );
  const [newAdditionalInformation, setNewAdditionalInformation] = useState(
    mainDetails?.additionalInformation
  );
  const [newRootCauseAnalysis, setNewRootCauseAnalysis] = useState(
    mainDetails?.rootCauseAnalysis
  );
  const [newPreventiveActionTaken, setNewPreventiveActionTaken] = useState(
    mainDetails?.preventiveActionTaken
  );
  const [newFinalSubmissionDate, setNewFinalSubmissionDate] = useState(
    mainDetails?.finalSubmissionDate
  );
  const [newBrcgsComments, setNewBrcgsComments] = useState(
    mainDetails?.brcgsComments
  );
  const [newBrcgsKeyWords, setNewBrcgsKeyWords] = useState(
    mainDetails?.brcgsKeyWords
  );
  const [newInitialNotificationDate, setNewInitialNotificationDate] = useState(
    mainDetails?.initialNotificationDate
  );

  const submitChanges = (): undefined | Promise<any> => {
    if (!mainDetails) return undefined;

    const editedValues: RecallEditData = {
      id: parseInt(mainDetails?.id, 10),
      reasonForNotification: newReasonForNotification?.value?.toString() ?? "",
      recallCategoryId: newRecallCategory?.value
        ? parseInt(newRecallCategory.value.toString(), 10)
        : mainDetails?.recallCategory.id,
      siteOrSupplierIssue: newSiteOrSupplierIssue?.value?.toString() ?? "",
      recallStatusId: newRecallStatus?.value
        ? parseInt(newRecallStatus?.value?.toString(), 10)
        : mainDetails?.recallStatus?.id,
      certificationBodyId: newCertificationBody?.value
        ? parseInt(newCertificationBody?.value?.toString(), 10)
        : mainDetails?.certificationBody?.id,
      certificationStatusId: newCertificationStatus?.value
        ? parseInt(newCertificationStatus?.value?.toString(), 10)
        : mainDetails?.recallCertificationStatus?.id,
      recallDate: newRecallDate,
      outlineOfIncident: newOutlineOfIncident ?? "",
      correction: newCorrection ?? "",
      productRecalled: newProductsRecalled ?? "",
      additionalInformation: newAdditionalInformation ?? "",
      certificationStatusLastUpdated: newCertificationStatusLastUpdated,
      rootCauseAnalysis: newRootCauseAnalysis,
      preventiveActionTaken: newPreventiveActionTaken,
      finalSubmissionDate: newFinalSubmissionDate,
      brcgsComments: newBrcgsComments,
      brcgsKeyWords: newBrcgsKeyWords,
      initialNotificationDate: newInitialNotificationDate,
    };

    return axiosApi
      .put(
        `${BackendServices.RECALL_SERVICE.RECALL}/${mainDetails.id}`,
        editedValues
      )
      .then(() => {
        refresh();
      });
  };

  const ReasonForNotificationComponent = () => {
    if (isEditing) {
      return (
        <>
          <ReasonForNotificationDropdown
            value={newReasonForNotification}
            onChange={setNewReasonForNotification}
            label=""
          />
        </>
      );
    }
    return mainDetails?.reasonForNotification ?? "";
  };

  const SiteOrSupplierIssueComponent = () => {
    if (isEditing) {
      return (
        <SiteOrSupplierDropdown
          value={newSiteOrSupplierIssue}
          onChange={SetNewSiteOrSupplierIssue}
          label=""
        />
      );
    }
    return mainDetails?.siteOrSupplierIssue;
  };

  const RecallStatusComponent = () => {
    if (isEditing && isBRCGSUser()) {
      return (
        <RecallStatusDropdown
          value={newRecallStatus}
          onChange={SetNewRecallStatus}
          label=""
        />
      );
    }
    return mainDetails?.recallStatus?.name ?? "";
  };

  const CertificationBodyComponent = () => {
    if (isEditing && isBRCGSUser()) {
      return (
        <CertificationBodySelector
          value={newCertificationBody}
          onChange={setNewCertificationBody}
          label=""
        />
      );
    }
    if (mainDetails?.certificationBody?.id) {
      return (
        <LinkText
          link={CBUrls.details(mainDetails?.certificationBody?.id)}
          text={mainDetails.certificationBody?.name}
        />
      );
    }
    return mainDetails?.certificationBody?.name ?? "";
  };

  const RecallCategoryComponent = () => {
    if (isEditing) {
      return (
        <>
          <RecallCategoryDropdown
            value={newRecallCategory}
            onChange={setNewRecallCategory}
            label=""
          />
        </>
      );
    }
    return mainDetails?.recallCategory?.name ?? "";
  };

  const OutLineOfRecallComponent = () => {
    if (isEditing) {
      return (
        <InputField
          label=""
          value={newOutlineOfIncident}
          multiline
          onChange={setNewOutlineOfIncident}
          required
        />
      );
    }
    return mainDetails?.outlineOfIncident ?? "";
  };

  const ProductsRecalledComponent = () => {
    if (isEditing) {
      return (
        <InputField
          label=""
          value={newProductsRecalled}
          multiline
          onChange={setNewProductsRecalled}
          required
        />
      );
    }
    return mainDetails?.productRecalled ?? "";
  };

  const CorrectionComponent = () => {
    if (isEditing) {
      return (
        <InputField
          label=""
          value={newCorrection}
          multiline
          onChange={setNewCorrection}
          required
        />
      );
    }
    return mainDetails?.correction ?? "";
  };

  const AdditionalInformationComponent = () => {
    if (isEditing) {
      return (
        <InputField
          label=""
          value={newAdditionalInformation}
          multiline
          onChange={setNewAdditionalInformation}
          required
        />
      );
    }
    return mainDetails?.additionalInformation ?? "";
  };

  const RootCauseAnalysisComponent = () => {
    if (isEditing) {
      return (
        <InputField
          label=""
          value={newRootCauseAnalysis}
          multiline
          onChange={setNewRootCauseAnalysis}
          required
        />
      );
    }
    return mainDetails?.rootCauseAnalysis ?? "";
  };

  const PreventiveActionTakenComponent = () => {
    if (isEditing) {
      return (
        <InputField
          label=""
          value={newPreventiveActionTaken}
          multiline
          onChange={setNewPreventiveActionTaken}
          required
        />
      );
    }
    return mainDetails?.preventiveActionTaken ?? "";
  };

  const FinalSubmissionDateComponent = () => {
    if (isEditing) {
      return (
        <DatePicker
          value={newFinalSubmissionDate || null}
          label=""
          onChange={(d: any) => setNewFinalSubmissionDate(formatDate(d))}
          onClear={() => setNewFinalSubmissionDate("")}
        />
      );
    }
    return mainDetails?.finalSubmissionDate
      ? moment(mainDetails?.finalSubmissionDate).format(DEFAULT_DATE_FORMAT)
      : "";
  };

  const InitialNotificationDateComponent = () => {
    if (isEditing) {
      return (
        <DatePicker
          value={newInitialNotificationDate || null}
          label=""
          onChange={(d: any) => setNewInitialNotificationDate(formatDate(d))}
          onClear={() => setNewInitialNotificationDate("")}
        />
      );
    }
    return mainDetails?.initialNotificationDate
      ? moment(mainDetails?.initialNotificationDate).format(DEFAULT_DATE_FORMAT)
      : "";
  };

  const BrcgsCommentsComponent = () => {
    if (isEditing && isBRCGSUser()) {
      return (
        <InputField
          label=""
          value={newBrcgsComments}
          multiline
          onChange={setNewBrcgsComments}
        />
      );
    }
    return mainDetails?.brcgsComments ?? "";
  };

  const BrcgsKeyWordsComponent = () => {
    if (isEditing && isBRCGSUser()) {
      return (
        <InputField
          label=""
          value={newBrcgsKeyWords}
          multiline
          onChange={setNewBrcgsKeyWords}
        />
      );
    }
    return mainDetails?.brcgsKeyWords ?? "";
  };

  const DateOfRecallComponent = () => {
    if (isEditing) {
      return (
        <DatePicker
          value={newRecallDate || null}
          label=""
          onChange={(d: any) => setNewRecallDate(formatDate(d))}
          onClear={() => setNewRecallDate("")}
        />
      );
    }
    return mainDetails?.recallDate
      ? moment(mainDetails?.recallDate).format(DEFAULT_DATE_FORMAT)
      : "";
  };

  const CertificationStatusComponent = () => {
    if (isEditing) {
      return (
        <RecallCertificationStatusDropdown
          value={newCertificationStatus}
          onChange={SetNewCertificationStatus}
          label=""
        />
      );
    }
    return mainDetails?.recallCertificationStatus?.name ?? "";
  };

  const CertificationStatusLastUpdatedComponent = () => {
    if (isEditing) {
      return (
        <DatePicker
          value={newCertificationStatusLastUpdated || null}
          label=""
          onChange={(d: any) =>
            setNewCertificationStatusLastUpdated(formatDate(d))
          }
          onClear={() => setNewCertificationStatusLastUpdated("")}
        />
      );
    }
    return mainDetails?.certificationStatusLastUpdated
      ? moment(mainDetails?.certificationStatusLastUpdated).format(
          DEFAULT_DATE_FORMAT
        )
      : "";
  };

  const recallDetailsColumn1: ListEntry[] = [
    {
      label: "Site Code",
      value: mainDetails?.siteCode ? (
        <LinkText
          link={SiteUrls.details(mainDetails.siteCode)}
          text={mainDetails.siteCode}
        />
      ) : (
        ""
      ),
      required: true,
    },
    {
      label: "Site Name",
      value: mainDetails?.recaller?.name,
      required: true,
    },
    {
      label: "Standard",
      value: mainDetails?.standard,
      required: true,
    },
    {
      label: "Audit",
      value: mainDetails?.auditId ? (
        <LinkText
          link={AuditUrls.details(mainDetails.auditId)}
          text={mainDetails.auditId}
        />
      ) : (
        ""
      ),
      required: true,
    },
    {
      label: "Site Certification Status",
      value: CertificationStatusComponent(),
      required: true,
    },
    {
      label: "Certification Status Last Updated",
      value: CertificationStatusLastUpdatedComponent(),
      required: false,
    },
    {
      label: "Date of Initial Notification to BRCGS",
      value: InitialNotificationDateComponent(),
      required: true,
    },
    {
      label: "Country",
      value: mainDetails?.country?.name ?? "",
      required: true,
    },
    {
      key: "recallCategory",
      label: "Incident Category",
      value: RecallCategoryComponent(),
      required: true,
    },
    {
      label: "Certification Body",
      value: CertificationBodyComponent(),
      required: true,
    },
    {
      label: "Completed By",
      value: mainDetails?.completedBy ?? "",
      required: true,
    },
    {
      key: "email",
      label: "Email",
      value: mainDetails?.email,
      required: true,
    },
  ];

  const recallDetailsColumn2: ListEntry[] = [
    {
      label: "Submission ID",
      value: mainDetails?.id ?? "",
      required: true,
    },
    {
      label: "Submission Status",
      value: RecallStatusComponent(),
      required: true,
    },
    {
      key: "productsRecalled",
      label: "Products Affected",
      value: ProductsRecalledComponent(),
      required: true,
    },
    {
      key: "reasonForNotification",
      label: "Reason for notification",
      value: ReasonForNotificationComponent(),
      required: true,
    },
    {
      key: "siteOrSupplierIssue",
      label: "Site or Supplier Issue",
      value: SiteOrSupplierIssueComponent(),
      required: true,
    },
    {
      key: "dateOfRecall",
      label: "Date of Incident",
      value: DateOfRecallComponent(),
      required: true,
    },
    {
      label: "Correction",
      key: "correction",
      value: CorrectionComponent(),
      required: true,
    },
    {
      label: "Additional Information",
      key: "additionalInformation",
      value: AdditionalInformationComponent(),
      required: true,
    },
    {
      key: "outlineOfRecall",
      label: "Outline of Incident",
      value: OutLineOfRecallComponent(),
      required: true,
    },
    {
      key: "rootCauseAnalysis",
      label: "Root Cause Analysis",
      value: RootCauseAnalysisComponent(),
      required: false,
    },
    {
      key: "preventiveActionTaken",
      label: "Preventive Action Taken",
      value: PreventiveActionTakenComponent(),
      required: false,
    },
    {
      key: "finalSubmissionDate",
      label: "Final Submission Date",
      value: FinalSubmissionDateComponent(),
      required: false,
    },
    {
      key: "BrcgsComments",
      label: "BRCGS Comments",
      value: BrcgsCommentsComponent(),
      required: false,
    },
    {
      key: "BrcgsKeyWords",
      label: "BRCGS Keywords",
      value: BrcgsKeyWordsComponent(),
      required: false,
    },
  ];

  const recallDetails = [];
  recallDetails.push(recallDetailsColumn1);
  recallDetails.push(recallDetailsColumn2);

  return (
    <>
      <InfoBox
        header="Details"
        items={recallDetails}
        columns={2}
        actionRow={
          mainDetails?.visuals.isAbleToEdit && (
            <EditButtons
              isEditing={isEditing}
              setEditing={setEditing}
              submit={submitChanges}
              onComplete={() => {}}
            />
          )
        }
      />
    </>
  );
}

export default RecallDetails;
