import React from "react";
import DataTable from "../../../../components/DataTable";
import DashboardRow from "./models/DashboardRow";
import dashboardColumns from "./models/DashboardColumns";
import Icon, { IconType } from "../../../../components/Icon";
import PermissionArea from "../../../../Authentication/model/PermissionArea";
import { PATH_ADMIN_DASHBOARD_NEW } from "../DashboardNew";
import getDashboardsDetailed from "../http/getDashboardsDetailed";
import deleteDashboard from "../http/deleteDashboard";

const DashboardTable = () => (
  <div>
    <DataTable<DashboardRow>
      fetchData={() =>
        getDashboardsDetailed().then((dashboards) =>
          dashboards.map((dashboard) => ({
            id: dashboard.id,
            roles: dashboard.roles.join(", "),
            title: dashboard.name,
            url: dashboard.webUrl,
          }))
        )
      }
      toolBarOptions={{
        searchField: { enable: true },
        newEntityLink: {
          link: PATH_ADMIN_DASHBOARD_NEW,
          label: "New dashboard",
          permissionAreas: [PermissionArea.ADMINISTRATION],
        },
      }}
      columns={dashboardColumns}
      rowActions={(data) => [
        {
          label: "Delete",
          onClick: () => {
            if (data?.id) {
              return deleteDashboard(data?.id).then(() => ({
                refreshOnResolve: true,
              }));
            }
            return Promise.reject();
          },
          id: "delete",
          permissionRequired: [],
          icon: <Icon type={IconType.SUSPEND} />,
        },
      ]}
    />
  </div>
);

export default DashboardTable;
