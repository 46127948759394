import React from "react";
import { Grid } from "@material-ui/core";

interface IFormGridItem {
  key?: string;
  className?: string;
  children: any;
}

export default function FormGridItem({
  key,
  className,
  children,
}: IFormGridItem) {
  return (
    <Grid item xs={12} md={6} key={key} className={className}>
      {children}
    </Grid>
  );
}
