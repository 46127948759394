import React from "react";
import moment from "moment";
import DataTable, { DataTableQueryParams } from "../../../components/DataTable";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import SearchResponses from "../../../Models/APIGetData/SearchResponses";
import ContentPage from "../../../components/ContentPage";
import RecallRow from "./models/RecallRow";
import RecallSummary from "../../../Models/Recall/RecallSummary";
import recallColumns from "./util/recallColumns";
import { RecallUrls } from "..";
import { DEFAULT_DATE_FORMAT } from "../../../util/dates/DateFormats";

const RecallSearchTable = () => {
  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: RecallRow[]; totalPages: number }> => {
    return axiosApi
      .get<SearchResponses<RecallSummary>>(
        BackendServices.RECALL_SERVICE.RECALL,
        {
          params,
        }
      )
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultsList.map(
          (recall): RecallRow => ({
            id: recall.id,
            siteCode: recall.siteCode,
            siteName: recall.siteName,
            country: recall.country,
            recallCategory: recall.recallCategory,
            certificationBody: recall.certificationBody,
            initialNotificationDate: moment(
              recall.initialNotificationDate
            ).format(DEFAULT_DATE_FORMAT),
            recallStatus: recall.recallStatus,
          })
        ),
      }));
  };

  return (
    <ContentPage title="Recalls">
      <DataTable<RecallRow>
        columns={recallColumns}
        fetchRemoteData={fetch}
        toolBarOptions={{
          newEntityLink: {
            label: "Add new recall",
            link: RecallUrls.create(),
          },
        }}
      />
    </ContentPage>
  );
};

export default RecallSearchTable;
