import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Column from "../../../../../components/DataTable/models/Column";
import AuditDocumentRow from "../models/AuditDocumentRow";
import downloadAuditFile from "../../http/downloadAuditFile";
import downloadPDF from "../../../http/downloadPDF";

const auditDocumentsColumns: Column<AuditDocumentRow>[] = [
  {
    title: "File Name",
    field: "fileName",
  },
  { title: "File Type", field: "fileType" },
  {
    title: "Upload Date",
    field: "uploadDate",
    type: "date",
  },
  { title: "Uploaded By", field: "userId" },
  {
    title: "Download",
    field: "extDocLink",
    render: (rowData: AuditDocumentRow) => (
      <IconButton
        onClick={
          rowData.fileType === "Audit"
            ? () => downloadPDF(rowData.auditId)
            : () => downloadAuditFile(rowData.extDocLink, rowData.fileName)
        }
      >
        <FontAwesomeIcon icon={faDownload} />
      </IconButton>
    ),
  },
];

export default auditDocumentsColumns;
