import React, { PropsWithChildren } from "react";
import PageHeadingWithMenu from "../Common/ManagementPageHeader/PageHeadingWithMenu";
import BackButtonComponent from "./components/BackButton";
import { DropdownMenuItem } from "../DropdownMenu";

export interface ContentPageProps {
  readonly title?: string;
  readonly subtitle?: string;
  readonly className?: string;
  readonly menuItems?: DropdownMenuItem[];
}

const ContentPage = ({
  children,
  title,
  subtitle,
  className,
  menuItems,
}: PropsWithChildren<ContentPageProps>) => (
  <div className={className}>
    <BackButtonComponent />
    {title && (
      <PageHeadingWithMenu
        nameOfEntity={title}
        additionalInfo={subtitle}
        menuItems={menuItems}
      />
    )}
    {children}
  </div>
);

export default ContentPage;
