import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DropdownMenu, { DropdownMenuItem } from "../../DropdownMenu";

interface PageHeaderMenuProps {
  readonly menuItems: DropdownMenuItem[];
}

function PageHeaderMenu({ menuItems }: PageHeaderMenuProps) {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchor(event.currentTarget)}
      >
        <MoreVertIcon />
        Menu
      </Button>
      <DropdownMenu
        target={anchor}
        onClose={() => setAnchor(null)}
        items={menuItems}
      />
    </div>
  );
}

export default PageHeaderMenu;
