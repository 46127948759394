import React from "react";
import moment from "moment";
import Site from "../../../../../Models/site/Site";
import mapLocationToEntries from "../../../../../components/Infobox/utils/mapLocationToEntries";
import mapContactToEntries from "../../../../../components/Infobox/utils/mapContactToEntries";
import InfoBox from "../../../../../components/Infobox";
import Contact from "../../../../../Models/CommonProps/Contact";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";
import { CompanyUrls } from "../../../../CompanyManagement";
import mapNamelessContactToEntries from "../../../../../components/Infobox/utils/mapNamelessContactToEntries";

interface SiteInfoBoxesProps {
  readonly site: Site | null;
}

type ContactKey = keyof Contact;
const contactFields: ContactKey[] = [
  "faxNumber",
  "email",
  "phoneNumber",
  "name",
];

const SiteInfoBoxes = ({ site }: SiteInfoBoxesProps) => {
  if (!site) return null;
  const contacts: { readonly title: string; readonly contact?: Contact }[] = [
    { title: "Technical Contact", contact: site.technicalContact },
    { title: "Commercial Contact", contact: site.commercialContact },
  ].filter((contact) => contact);
  return (
    <>
      <InfoBox
        header="Site Detail"
        columns={2}
        items={[
          { label: "Site code", value: site.id, required: true },
          { label: "Status", value: site.status, required: true },
          {
            label: "Website",
            value: site.website
              ? {
                  link: site.website.includes("https://")
                    ? site.website
                    : `https://${site.website}`,
                  label: site.website,
                  external: true,
                }
              : "",
          },
          ...mapLocationToEntries(site.location),
          ...mapNamelessContactToEntries(site.generalContact),
        ]}
      />
       <InfoBox
        header="Company Detail"
        columns={2}
        items={[
          {
            label: "Company Name",
            value: {
              link: CompanyUrls.details(site.companyId),
              label: site.companyName,
            },
            required: true,
          },
          { label: "Company ID", value: site.companyId, required: true },
          {label:"Status", value: site.companyStatus},
          ...mapLocationToEntries(site.companyLocation),
          ...mapNamelessContactToEntries(site.companyContact),
        ]}
      />
      {site.scheduledAudits?.map(
        ({
          nextScheduledAuditDate,
          additionalModules,
          scheduleStatus,
          standard,
          scheduleCertificationBody,
        }) => (
          <InfoBox
            header={`Schedule for next ${standard.name} audit`}
            key={new Date(nextScheduledAuditDate).toLocaleTimeString()}
            items={[
              {
                label: "Schedule status",
                value: scheduleStatus,
              },
              {
                label: "Date scheduled for",
                value: moment(nextScheduledAuditDate).format(
                  DEFAULT_DATE_FORMAT
                ),
              },
              {
                label: "Additional modules with next audit",
                value:
                  additionalModules.length > 0
                    ? additionalModules.map((module) => module.name)
                    : "No Modules scheduled for next audit",
              },
              {
                label: "Certification Body",
                value: scheduleCertificationBody,
              },
            ]}
          />
        )
      )}
      {contacts
        .filter(({ contact }) =>
          contactFields.some((field) => !!contact?.[field])
        )
        .map(({ contact, title }) => (
          <InfoBox
            key={title}
            header={title}
            items={mapContactToEntries(contact)}
          />
        ))}
    </>
  );
};

export default SiteInfoBoxes;
