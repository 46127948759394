import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CreatePage, { Field } from "../../../components/CreatePage";
import Option from "../../../Models/forms/Option";
import PostCompany from "./models/PostCompany";
import setCompanyPostBody from "../../../store/company/actions/setCompanyPostBody";
import { UserUrls } from "../../UserManagement";
import Location, {
  defaultLocation,
} from "../../../Models/CommonProps/Location";
import useLocationInput from "../../../util/location/useLocationInput";
import CompanyDetails from "../../../Models/companies/Company";
import Contact, { defaultContact } from "../../../Models/CommonProps/Contact";
import useContactInput from "../../../util/contact/useContactInput";
import CertificationBodySelector from "../../../components/CertificationBodySelector";
import { isCompanyUser } from "../../../Authentication/Auth";

export const COMPANY_USER_ORIGIN = "company";

interface CompanyNewProps {
  readonly company?: CompanyDetails | null;
  readonly editOnlyFields?: Field[];
  readonly onSubmit?: (postBody: PostCompany) => Promise<any>;
}

const CompanyNew = ({
  company,
  editOnlyFields = [],
  onSubmit,
}: CompanyNewProps) => {
  const isEditing = !!company;
  const [name, setName] = useState(company?.name ?? "");

  const [website, setWebsite] = useState(company?.website ?? "");
  const [contact, setContact] = useState<Contact>(
    company?.contact ?? { ...defaultContact }
  );
  const [certificationBody, setCertificationBody] = useState<Option | null>(
    null
  );
  const [location, setLocation] = useState<Location>(
    company?.location ?? { ...defaultLocation }
  );
  const locationFields = useLocationInput(location, setLocation);
  const contactFields = useContactInput(contact, setContact);

  const [blockSharing, setBlockSharing] = useState(
    company?.blockSharing ?? false
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const fields: (Field | string)[] = [
    {
      name: "name",
      value: name,
      setTextValue: setName,
      label: "Company name",
      required: true,
      disabled: isCompanyUser(),
    },
    ...editOnlyFields,
    ...locationFields,
    ...contactFields,
    {
      name: "website",
      label: "Website",
      value: website,
      setTextValue: setWebsite,
      disabled: isCompanyUser(),
    },
    {
      name: "cb",
      show: !isEditing,
      render: () => (
        <CertificationBodySelector
          value={certificationBody}
          onChange={setCertificationBody}
        />
      ),
    },
    {
      name: "blockSharing",
      label: "Block Sharing",
      value: blockSharing,
      setBooleanValue: setBlockSharing,
      disabled: isCompanyUser(),
    },
  ];

  const mapToPostBody = (): PostCompany => {
    const cbId = certificationBody?.value;
    return {
      company: {
        name,
        blockSharing,
        website,
        location,
      },
      contact: { contactDetails: contact },
      certificationBodyExternalId: cbId ? parseInt(cbId as string, 10) : 0,
    };
  };

  const onPostSubmit = () => {
    if (!location?.country) return Promise.reject();
    dispatch(setCompanyPostBody(mapToPostBody()));
    history.push(UserUrls.create({ origin: COMPANY_USER_ORIGIN }));
    return Promise.resolve();
  };

  return (
    <CreatePage
      title={!isEditing ? "Create Company" : "Edit Company"}
      fields={fields}
      onSubmit={onSubmit ? () => onSubmit(mapToPostBody()) : onPostSubmit}
    />
  );
};

export default CompanyNew;
