import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./Box.module.scss";
import { H2Heading } from "../Common/Heading";

export enum BoxPadding {
  Normal,
  None,
}

export interface BoxProps {
  readonly header?: string;
  readonly actionRow?: React.ReactNode;
  readonly className?: string;
  readonly padding?: BoxPadding;
}

const Box = ({
  children,
  header,
  className,
  actionRow,
  padding = BoxPadding.Normal,
}: PropsWithChildren<BoxProps>) => (
  <div className={clsx(styles.box, className)}>
    {(!!header || !!actionRow) && (
      <div className={styles.titleRow}>
        {header && <H2Heading text={header} />}
        {actionRow && <div className={styles.actions}>{actionRow}</div>}
      </div>
    )}
    <div
      className={clsx(styles.children, {
        [styles.childPadding]: padding === BoxPadding.Normal,
      })}
    >
      {children}
    </div>
  </div>
);

export default Box;
