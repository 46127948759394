import React from "react";
import axiosApi from "../Api/Axios";
import BackendServices from "../Api/BackendService";
import DebouncedSearchSelector from "../DebouncedSearchSelector";
import Option from "../../Models/forms/Option";
import Company from "../../Models/companies/Company";
import isNumber from "../../util/number/isNumber";

interface CompanySelectorProps {
  readonly onSelect: (result: Option | null) => void;
  readonly label?: string;
  readonly value?: Option | null;
  readonly sharedOnly?: boolean;
  readonly required?: boolean;
  readonly disabled?: boolean;
}

interface CompanyResults {
  totalPages: number;
  resultList: Company[];
}

const CompanySelector = ({
  onSelect,
  label,
  value,
  sharedOnly = false,
  required = false,
  disabled = false,
}: CompanySelectorProps) => (
  <DebouncedSearchSelector
    disabled={disabled}
    label={label}
    validateInput={(input: any) =>
      input.length === 0 || isNumber(input) || input === value?.label
    }
    getOptions={(input) =>
      axiosApi
        .get<CompanyResults>(
          `${BackendServices.COMPANY_SERVICE.COMPANY}/search`,
          {
            params: { searchTerm: input, sharedOnly },
          }
        )
        .then(({ data }) =>
          data.resultList.map((i): Option => ({ value: i.id, label: i.name }))
        )
    }
    onSelect={onSelect}
    value={value}
    required={required}
  />
);

export default CompanySelector;
