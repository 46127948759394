import React from "react";
import { MenuItem } from "@material-ui/core";
import { H1Heading } from "../../../../components/Common/Heading";
import { Select } from "../../../../components/Common/Input/Select";

interface ReportHeaderProps {
  readonly currentReport: string | null;
  readonly options: string[];
  readonly onChange: (value: string) => void;
}

const ReportHeader = ({
  currentReport,
  onChange,
  options,
}: ReportHeaderProps) => {
  return (
    <div className="report-management__header">
      <H1Heading text="Generate reports" />
      <Select
        label="Template"
        value={currentReport}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((template) => {
          return (
            <MenuItem key={template} value={template}>
              {template}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default ReportHeader;
