import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MaterialRadioGroup,
} from "@material-ui/core";
import Option from "../../Models/forms/Option";
import { H3Heading } from "../Common/Heading";

interface RadioGroupProps {
  readonly label?: string;
  readonly options: Option[];
  readonly value: string | number | undefined;
  readonly onChange?: (value: string) => void;
  readonly onClick?: (value: string) => void;
}

const RadioGroup = ({
  label,
  options,
  value,
  onChange,
  onClick,
}: RadioGroupProps) => (
  <FormControl>
    {label && <H3Heading text={label} />}
    <MaterialRadioGroup
      value={value}
      onChange={(event, newValue) => onChange?.(newValue)}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
          onClick={(event) =>
            onClick?.((event.target as HTMLInputElement).value)
          }
        />
      ))}
    </MaterialRadioGroup>
  </FormControl>
);

export default RadioGroup;
