import React from "react";
import Column from "../../../../components/DataTable/models/Column";
import CompanyRow from "../models/CompanyRow";
import { LinkText } from "../../../../components/Common/hyperlinks";
import { CompanyUrls } from "../../index";

const companyColumns: Column<CompanyRow>[] = [
  {
    title: "ID",
    field: "id",
  },
  {
    title: "Company",
    field: "name",
    render: (rowData: CompanyRow) => (
      <LinkText link={CompanyUrls.details(rowData.id)} text={rowData.name} />
    ),
  },
  {
    title: "Country",
    field: "country",
    filtering: true,
  },
  {
    title: "City",
    field: "city",
  },
  {
    title: "Related Sites",
    field: "sites",
  },
  {
    title: "Status",
    field: "status",
  },
];

export default companyColumns;
