import DashboardSearchResponse from "../models/DashboardSearchResponse";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (reportId: string): Promise<DashboardSearchResponse> =>
  axiosApi
    .get<DashboardSearchResponse>(
      `${BackendServices.DASHBOARD_SERVICE.DASHBOARD}/search/${reportId}`
    )
    .then(({ data }) => data);
