import React from "react";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { AddCircle, Clear } from "@material-ui/icons";
import { formatDate } from "../../../util/dates/DateFormats";
import { WitnessedAudit } from "../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import InputField from "../../InputField";
import DatePicker from "../../DatePicker";

interface IWitnessedAudits {
  newDate: string;
  selectedWitnessedAudits: WitnessedAudit[];
  setSelectedWitnessedAudits: React.Dispatch<
    React.SetStateAction<WitnessedAudit[]>
  >;
}

export default function WitnessedAuditsSection({
  newDate,
  selectedWitnessedAudits,
  setSelectedWitnessedAudits,
}: IWitnessedAudits) {
  const addWitnessedAudit = () => {
    const newWitnessedAudits: WitnessedAudit[] = [
      ...selectedWitnessedAudits,
      {
        siteCode: 0,
        witnessingAuditorName: "",
        witnessingAuditorNumber: 0,
        date: newDate,
      },
    ];
    setSelectedWitnessedAudits(newWitnessedAudits);
  };

  const handleChangeField = (
    witnessedAuditIndex: number,
    field: string,
    input: any
  ) => {
    const newWitnessedAudits = selectedWitnessedAudits.map(
      (witnessedAudit: WitnessedAudit, index: number) => {
        if (witnessedAuditIndex !== index) return witnessedAudit;
        return { ...witnessedAudit, updated: true, [field]: input };
      }
    );
    setSelectedWitnessedAudits(newWitnessedAudits);
  };

  const removeWitnessedAudit = (index: number) => {
    const newWitnessedAudits = selectedWitnessedAudits.filter(
      (witnessedAudit: WitnessedAudit) =>
        selectedWitnessedAudits.indexOf(witnessedAudit) !== index
    );
    setSelectedWitnessedAudits(newWitnessedAudits);
  };

  return (
    <Grid container spacing={3} direction="column">
      {selectedWitnessedAudits.map(
        (witnessedAudit: WitnessedAudit, index: number) => (
          <Grid key={index.toString()} className="accordion-input" item>
            <h3>
              {`Witnessed audit ${index + 1}`}{" "}
              <Clear onClick={() => removeWitnessedAudit(index)} />
            </h3>
            <InputField
              name="SiteCode"
              label="Site code"
              type="number"
              value={
                witnessedAudit.siteCode === 0
                  ? null
                  : `${witnessedAudit.siteCode}`
              }
              onChange={(value) => handleChangeField(index, "siteCode", +value)}
              required
            />
            <DatePicker
              value={witnessedAudit.date}
              label="Date"
              onChange={(d) => handleChangeField(index, "date", formatDate(d))}
              required
              name="witnessedDate"
            />
            <InputField
              name="WitnessingAuditorNumber"
              label="Witnessing auditor number"
              type="number"
              value={
                witnessedAudit.witnessingAuditorNumber === 0
                  ? null
                  : `${witnessedAudit.witnessingAuditorNumber}`
              }
              onChange={(value) =>
                handleChangeField(index, "witnessingAuditorNumber", +value)
              }
              required
            />
            <InputField
              name="WitnessingAuditorName"
              label="Witnessing auditor name"
              value={witnessedAudit.witnessingAuditorName}
              onChange={(value) =>
                handleChangeField(index, "witnessingAuditorName", value)
              }
            />
          </Grid>
        )
      )}
      <Divider />
      <Grid item>
        <IconButton onClick={addWitnessedAudit}>
          <AddCircle className="add" />{" "}
          <span className="add">Add witnessed audit</span>
        </IconButton>
      </Grid>
    </Grid>
  );
}
