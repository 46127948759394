import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { H6Heading } from "../Common/Heading";
import Spinner from "../Spinner";
import styles from "./SearchableDropdown.module.scss";
import Option from "../../Models/forms/Option";
import { InputField } from "../InputField";
import withValidation, { ValidationFieldProps } from "../FieldValidator";

interface SearchableDropdownProps<T> extends ValidationFieldProps {
  readonly Options: Option<T>[];
  readonly value?: Option<T> | null;
  readonly Heading?: string;
  readonly onChange?: (
    e: React.ChangeEvent<{}>,
    newValue: Option<T> | null
  ) => void;
  readonly onInputChange?: (value: string) => void;
  readonly groupBy?: (option: Option<T>) => string;
  readonly placeholder?: string;
  readonly noOptionsText?: string;
  readonly isLoadingOptions?: boolean;
  readonly inputValue?: string;
  readonly disableOptionFilter?: boolean;
}

export const InputSpinner = () => (
  <Spinner className={styles.spinner} delay={0} />
);

function SearchableDropdown<T = string>({
  label,
  value,
  Heading,
  Options,
  disabled,
  onChange,
  onInputChange,
  placeholder,
  groupBy,
  noOptionsText,
  isLoadingOptions = false,
  inputValue,
  disableOptionFilter = false,
}: SearchableDropdownProps<T>) {
  return (
    <div className="fullWidth searchable-dropdown">
      {Heading && <H6Heading text={Heading} />}
      <Autocomplete
        placeholder={placeholder}
        className="input select country-search"
        options={Options}
        value={value}
        disabled={disabled}
        getOptionSelected={(option, optionValue) =>
          option.value === optionValue.value
        }
        filterOptions={disableOptionFilter ? (val) => val : undefined}
        onInputChange={(_, _inputValue) => onInputChange?.(_inputValue)}
        getOptionLabel={(option) => option.label}
        onChange={onChange}
        renderInput={(params) => (
          <InputField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoadingOptions ? (
                <InputSpinner />
              ) : (
                params.InputProps.endAdornment
              ),
            }}
          />
        )}
        groupBy={groupBy}
        noOptionsText={noOptionsText}
        autoComplete={false}
        inputValue={inputValue}
      />
    </div>
  );
}

export default withValidation(SearchableDropdown);
