import React from "react";
import DataTable, { DataTableQueryParams } from "../../../components/DataTable";
import CompanyRow from "./models/CompanyRow";
import companyColumns from "./util/companyColumns";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import SearchResponse from "../../../Models/APIGetData/SearchResponse";
import { CompanyUrls } from "../index";
import Company from "../../../Models/companies/Company";
import { isCBUser } from "../../../Authentication/Auth";

const CompanySearchTable = () => {
  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: CompanyRow[]; totalPages: number }> =>
    axiosApi
      .get<SearchResponse<Company>>(
        `${BackendServices.COMPANY_SERVICE.COMPANY}/search`,
        { params }
      )
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultList.map(
          (company: Company): CompanyRow => ({
            id: company.id,
            name: company.name,
            city: company.location.city,
            country: company.location.country?.name ?? "",
            sites: company.siteCount,
            status: company.status,
          })
        ),
      }));

  return (
    <DataTable<CompanyRow>
      columns={companyColumns}
      fetchRemoteData={fetch}
      toolBarOptions={{
        newEntityLink: !isCBUser()
        ? {
          label: "Add Company",
          link: CompanyUrls.create(),
        }
        :undefined,
      }}
      archiveFilter={{ enabled: true }}
    />
  );
};

export default CompanySearchTable;
