import React from "react";
import { LinkText } from "../../../../../../components/Common/hyperlinks";
import Column from "../../../../../../components/DataTable/models/Column";
import { RecallUrls } from "../../../../../RecallManagement";
import AssociatedRecallRow from "../models/AssociatedRecallRow";

const associatedRecallColumns: Column<AssociatedRecallRow>[] = [
  {
    title: "Id",
    field: "id",
    render: (rowData: AssociatedRecallRow) => (
      <LinkText link={RecallUrls.details(rowData.id)} text={rowData.id} />
    ),
  },
  { title: "Country", field: "country" },
  { title: "Certification Body", field: "certificationBody" },
  { title: "Incident Category", field: "recallCategory" },
  { title: "Initial Notification Date", field: "initialNotificationDate" },
  { title: "submission Status", field: "recallStatus" },
];

export default associatedRecallColumns;
