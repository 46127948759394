import { UserOrganisation } from "../../models/UserDetails";
import { MinifiedOrganisation } from "../constants";

const mapMinifiedOrgToOrg = (org: MinifiedOrganisation): UserOrganisation => ({
  externalId: org.externalId ?? -1,
  name: org.name ?? "No name available",
  type: org.type || org.type === 0 ? org.type : -1,
});

export default mapMinifiedOrgToOrg;
