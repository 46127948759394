import useGetData from "../../../../http/useGetData";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import Office from "../../../../Models/Office/Office";

export default (id: string) => {
  const { data, isLoading, refresh } = useGetData(() =>
    axiosApi.get<Office>(
      `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/office/${id}`
    )
  );
  return { office: data, isLoading, refresh };
};
