import React from "react";
import EditableText from "../../EditableFields/EditableText";
import { AuditEntry, AuditEntryValue } from "../../../models/AuditTabs";
import { setEditingValue, useEditContext } from "../utils/editReducer";

interface TabInfoBoxProps {
  readonly entries: AuditEntry[];
}

const TabInfoBox = ({ entries }: TabInfoBoxProps) => {
  const items = entries.filter((item) => item.label || item.data);
  const [editState, editDispatch] = useEditContext();
  if (items.length === 0) return null;

  return (
    <div className="tab-info-box">
      {items.map(({ id, label, data, type, xmlTag }) => (
        <div key={id}>
          <p className="tab-info-box__label">{label}</p>
          {label === "BRCGS Site Code" ? (
            data ?? ""
          ) : (
            <EditableText
              xmlTag={xmlTag}
              isEditing={editState.isEditing}
              type={type}
              value={
                (editState.values[xmlTag]?.value as AuditEntryValue) ??
                data ??
                ""
              }
              setUpdateValue={(_, value) =>
                editDispatch(setEditingValue(xmlTag, value, type))
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default TabInfoBox;
