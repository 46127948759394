import React from "react";
import { Link } from "react-router-dom";

export function LinkText({
  link,
  text,
  className,
  external,
}: {
  link: string;
  text: any;
  className?: string;
  external?: boolean;
}): JSX.Element {
  return external ? (
    <a rel="noreferrer" target="_blank" href={link} className={className}>
      {text}
    </a>
  ) : (
    <Link to={link} className={className}>
      {text}
    </Link>
  );
}

export function LinkIcon({
  link,
  className,
  icon,
  external,
}: {
  link: string;
  icon: any;
  external?: boolean;
  className?: string;
}): JSX.Element {
  return external ? (
    <a rel="noreferrer" target="_blank" href={link} className={className}>
      {icon}
    </a>
  ) : (
    <Link to={link} className={className}>
      {icon}
    </Link>
  );
}
