import React from "react";
import { Divider, Grid } from "@material-ui/core";
import moment from "moment";
import Box from "../../../../../components/Box";
import GridPair from "../../../../../components/Common/GridPair";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";
import ListEntry from "../../../../../Models/CommonProps/ListEntry";
import AuditorAccordion from "../AuditorAccordion";
import AuditorStatus from "../../../../../Enums/AuditorStatus";
import styles from "./AuditorStandards.module.scss";
import rejectCategory from "../../http/rejectQualification";
import validateCategory from "../../http/validateQualification";
import { AuditorStandard } from "../../models/AuditorDetails";
import { NOT_AVAILABLE } from "../../constants";

interface AuditorStandardsProps {
  readonly standards?: AuditorStandard[];
  readonly addValidateButtons: (
    resourceName: string,
    onValidate: (auditorId: number) => Promise<any>,
    onReject: (auditorId: number, rejectReason: string) => Promise<any>
  ) => JSX.Element | null;
}

const AuditorStandards = ({
  standards = [],
  addValidateButtons,
}: AuditorStandardsProps) => {
  return (
    <>
      {standards.map((standard) => (
        <Box header={standard.name} key={standard.id}>
          {standard.qualifications.map((qualification) => {
            const items: ListEntry[] = [
              { label: "Type", value: qualification.entityType },
              {
                label: "Justifications and exceptions",
                value: () => (
                  <p className={styles.justification}>
                    {qualification.justificationsAndExceptions}
                  </p>
                ),
              },
              {
                label: "Status",
                value:
                  qualification.status === AuditorStatus.IN_REVISION
                    ? "Pending validation"
                    : qualification.status,
              },
              {
                label: "Last status update",
                value: moment(qualification.lastStatusUpdate).format(
                  DEFAULT_DATE_FORMAT
                ),
              },
              {
                label: "Submitted date",
                value: moment(qualification.submittedDate).format(
                  DEFAULT_DATE_FORMAT
                ),
              },
              {
                label: "Requested modification date",
                value: qualification.requestedModificationDate
                  ? moment(qualification.requestedModificationDate).format(
                      DEFAULT_DATE_FORMAT
                    )
                  : null,
              },
              {
                label: "Rejection comment",
                value:
                  qualification.status === AuditorStatus.NOT_VALIDATED
                    ? qualification.rejectionComment
                    : null,
              },
            ];
            return (
              <AuditorAccordion
                key={qualification.qualificationId}
                expanded={qualification.status === AuditorStatus.IN_REVISION}
                heading={qualification.entityName}
                secondaryHeading={
                  qualification.status === AuditorStatus.IN_REVISION
                    ? "Pending validation"
                    : qualification.status
                }
                status={qualification.status}
              >
                <div className={styles.qualificationContent}>
                  <Grid container spacing={3} direction="row">
                    {items
                      .filter((item) => item.value !== null)
                      .map((item) => (
                        <GridPair
                          key={item.label}
                          label={item.label}
                          value={item.value ?? NOT_AVAILABLE}
                        />
                      ))}
                  </Grid>
                  {qualification.status === AuditorStatus.IN_REVISION && (
                    <>
                      <Divider />
                      {addValidateButtons(
                        "category",
                        (auditorId) =>
                          qualification.qualificationId
                            ? validateCategory(
                                auditorId,
                                qualification.qualificationId
                              )
                            : Promise.reject(),
                        (auditorId, rejectReason) =>
                          qualification.qualificationId
                            ? rejectCategory(
                                auditorId,
                                qualification.qualificationId,
                                rejectReason
                              )
                            : Promise.reject()
                      )}
                    </>
                  )}
                </div>
              </AuditorAccordion>
            );
          })}
        </Box>
      ))}
    </>
  );
};

export default AuditorStandards;
