import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NotificationAuditCategories from "../../models/NotificationAuditCategories";
import styles from "./AuditStandards.module.scss";
import RadioGroup from "../../../../components/RadioGroup";
import CheckBox from "../../../../components/Common/CheckBox";
import { AuditStandardStatus } from "./index";
import Standard from "../../../../Models/common/Standard";
import Category from "../../../../Models/common/Category";
import Option from "../../../../Models/forms/Option";

interface StandardOptionProps {
  readonly onChange: (categories?: number[]) => void;
  readonly standard: Standard;
  readonly selected?: NotificationAuditCategories;
  readonly categoryOptions: Category[];
}

const StandardOption = ({
  onChange,
  standard,
  selected,
  categoryOptions,
}: StandardOptionProps) => {
  const [showCustomCategories, setShowCustomCategories] = useState(
    selected?.status === AuditStandardStatus.CUSTOM ?? false
  );

  const statusText = (() => {
    if (!selected) return "All categories";
    switch (selected.status) {
      case AuditStandardStatus.ALL:
        return "All categories";
      case AuditStandardStatus.CUSTOM:
        return `${selected.categories.length} / ${categoryOptions.length} categories`;
      default:
        return "";
    }
  })();

  const options: Option[] = [
    { value: AuditStandardStatus.NONE, label: "None" },
    {
      value: AuditStandardStatus.ALL,
      label: "All",
    },
    { label: "Custom categories", value: AuditStandardStatus.CUSTOM },
  ];

  const getCategorySelected = (val: string) => {
    const status = val as AuditStandardStatus;
    setShowCustomCategories(status === AuditStandardStatus.CUSTOM);
    switch (status) {
      case AuditStandardStatus.NONE:
      case AuditStandardStatus.CUSTOM:
        return [];
      case AuditStandardStatus.ALL:
        return undefined;

      default:
        return selected?.categories;
    }
  };

  return (
    <Accordion key={standard.id} className={styles.main}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.summary}
      >
        <p>{standard.name}</p>
        <p className={styles.selectedStatus}>{statusText}</p>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        <RadioGroup
          options={options}
          value={(() => {
            if (showCustomCategories) return AuditStandardStatus.CUSTOM;
            return selected?.status ?? AuditStandardStatus.ALL;
          })()}
          onClick={(v) => {
            if (!selected && v === AuditStandardStatus.ALL) {
              onChange(undefined);
            }
          }}
          onChange={(val) => onChange(getCategorySelected(val))}
        />
        {(selected?.status === AuditStandardStatus.CUSTOM ||
          showCustomCategories) && (
          <>
            <Divider className={styles.divider} />
            {categoryOptions.map((category) => (
              <div key={category.externalId} className={styles.checkbox}>
                <CheckBox
                  label={category.name}
                  checked={(selected?.categories ?? []).includes(
                    category.externalId
                  )}
                  onChange={(event, checked) => {
                    const currentCategories = selected?.categories ?? [];
                    onChange(
                      checked
                        ? [...currentCategories, category.externalId]
                        : currentCategories.filter(
                            (c) => c !== category.externalId
                          )
                    );
                  }}
                />
              </div>
            ))}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default StandardOption;
