import React from "react";
import Option from "../../../../Models/forms/Option";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import { SiteOrSupplierOption } from "../../../../Models/Recall/SiteOrSupplierIssue";

interface SiteOrSupplierDropdownProps {
  value?: Option | null;
  required?: boolean;
  onChange: (siteOrSupplier: Option | null) => void;
  label?: string;
}

export default class SiteOrSupplierDropdown extends React.Component<
  SiteOrSupplierDropdownProps,
  { options: Option[] }
> {
  constructor(props: SiteOrSupplierDropdownProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getSiteOrSupplierOptions();
  }

  getSiteOrSupplierOptions(): void {
    this.setState({ options: SiteOrSupplierOption });
  }

  render() {
    const { required, value, onChange, label } = this.props;
    const { options } = this.state;

    return (
      <SearchableDropdown
        required={required}
        Options={options}
        label={label ?? "Site or Supplier"}
        value={value}
        name="Site or Supplier"
        onChange={(e: React.ChangeEvent<{}>, option: Option | null) =>
          onChange?.(option)
        }
      />
    );
  }
}
