import axiosApi from "../.././../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { details as detailsPath } from "../../../components/Paths/paths";
import VerifyDetailsView from "../../../Models/Verify/Models/VerifyAuditDetailsView";
import useGetVerifyAudit from "../VerifyAuditDetails/util/useGetVerifyAudit";
import VerifyAuditDetailsPage from "../VerifyAuditDetails";

export default (id: string | number): () => void =>{
    return () => {
        axiosApi.post(
            `${BackendServices.AUDIT_SERVICE.VERIFY}/detailFavouriteUpdate/${id}`
          );
          
          window.location.reload();
    }
};
  