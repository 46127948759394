import AuditorStatus from "../../../../Enums/AuditorStatus";

interface AuditorQualification {
  readonly entityId: number;
  readonly entityName: string;
  readonly entityType: QualificationType;
  readonly justificationsAndExceptions: string;
  readonly lastStatusUpdate?: string;
  readonly modificationComment?: string;
  readonly qualificationId?: number;
  readonly rejectionComment?: string;
  readonly requestedModificationDate?: string;
  readonly standardName?: string;
  readonly status?: AuditorStatus;
  readonly submittedDate?: string;
}

export enum QualificationType {
  TECHNOLOGY = "Technology",
  CATEGORY = "Category",
}

export default AuditorQualification;
