import { AxiosResponse } from "axios";
import axiosApi from "../../../../components/Api/Axios";
import FileUpload from "../../../../Models/APIPostData/FileUpload";
import BackendServices from "../../../../components/Api/BackendService";
import { uploadDocument } from "../../../../components/Paths/paths";

export default async (
  id: string,
  files: File[],
  fileType: string | null,
  setMissingType: () => void
): Promise<AxiosResponse[]> => {
  if (!fileType) {
    setMissingType();
    return Promise.reject();
  }
  if (files.length === 0) return Promise.reject();

  return Promise.all(
    files.map((file) => {
      const formData = new FormData();
      formData.append("File", file);
      formData.append("Name", file.name);
      formData.append("Type", fileType);
      formData.append("AuditId", id);
      return axiosApi.post<FileUpload>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${uploadDocument}`,
        formData
      );
    })
  );
};
