import React from "react";
import moment from "moment";
import InfoBox from "../../../../../components/Infobox";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";
import { LinkText } from "../../../../../components/Common/hyperlinks";
import { CBUrls } from "../../../../CBManagement";
import AuditorDetails from "../../models/AuditorDetails";
import validateProfile from "../../http/validateProfile";
import rejectProfile from "../../http/rejectProfile";
import AuditorStatus from "../../../../../Enums/AuditorStatus";
import styles from "../../AuditorDetails.module.scss";

interface AuditorInfoBox {
  readonly auditor: AuditorDetails | null;
  readonly addValidateButtons: (
    resourceName: string,
    onValidate: (auditorId: number) => Promise<any>,
    onReject: (auditorId: number, rejectReason: string) => Promise<any>
  ) => JSX.Element | null;
}

const AuditorInfoBox = ({ auditor, addValidateButtons }: AuditorInfoBox) => {
  if (!auditor) return null;

  const items = [
    {
      label: "Auditor number",
      value: auditor.externalId,
    },
    {
      label: "Name",
      value: `${auditor.firstName} ${auditor.lastName}`,
    },
    {
      label: "Auditor start date",
      value: moment(auditor.startDate).format(DEFAULT_DATE_FORMAT),
    },
    {
      label: "Country",
      value: auditor.country.name,
    },
    {
      label: "Languages",
      value: auditor.languages.map((language) => language.name).join(", "),
    },
    {
      label: "Certification bodies",
      value: () => (
        <div className={styles.cbField}>
          {auditor.certificationBodies?.map((cb) => (
            <LinkText key={cb.id} link={CBUrls.details(cb.id)} text={cb.name} />
          ))}
        </div>
      ),
    },
    {
      label: "Shared profile",
      value: auditor.isShared ? "Yes" : "No",
    },
    {
      label: "Justification for not being shared",
      value: !auditor.isShared ? auditor.sharedReason?.name : undefined,
    },
    {
      label: "Creation date",
      value: moment(auditor.creationDate).format(DEFAULT_DATE_FORMAT),
    },
    {
      label: "Status",
      value:
        auditor.status === AuditorStatus.IN_REVISION
          ? "Pending validation"
          : auditor.status,
    },
    {
      label: "Last status update",
      value: moment(auditor.lastStatusUpdateDate).format(DEFAULT_DATE_FORMAT),
    },
    {
      label: "Submitted date",
      value: moment(auditor.creationDate).format(DEFAULT_DATE_FORMAT),
    },
    {
      label: "Requested modification date",
      value: auditor.requestedModificationDate
        ? moment(auditor.requestedModificationDate).format(DEFAULT_DATE_FORMAT)
        : undefined,
    },
    {
      label: "Requested modification comment",
      value: auditor.requestedModificationComment,
    },
  ];
  return (
    <InfoBox header="Details" items={items} columns={2}>
      {auditor.status === AuditorStatus.IN_REVISION &&
        addValidateButtons("auditor", validateProfile, rejectProfile)}
    </InfoBox>
  );
};

export default AuditorInfoBox;
