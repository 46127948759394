import PostCompany from "../../views/CompanyManagement/CompanyNew/models/PostCompany";
import {
  ACTION_SET_COMPANY_BODY,
  ActionSetCompanyBody,
} from "./actions/setCompanyPostBody";
import {
  ACTION_CLEAR_COMPANY_BODY,
  ActionClearCompanyBody,
} from "./actions/clearCompanyPostBody";

interface CompanyState {
  readonly postBody: PostCompany | null;
}

const initialState: CompanyState = {
  postBody: null,
};

type CompanyActions = ActionSetCompanyBody | ActionClearCompanyBody;

export default (state = initialState, action: CompanyActions): CompanyState => {
  switch (action.type) {
    case ACTION_SET_COMPANY_BODY:
      return { ...state, postBody: action.payload };
    case ACTION_CLEAR_COMPANY_BODY:
      return { ...state, postBody: null };
    default:
      return state;
  }
};
