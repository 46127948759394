import React, { ChangeEvent } from "react";
import { TextField } from "@material-ui/core";
import withValidation, { ValidationFieldProps } from "../FieldValidator";

interface InputFieldProps extends ValidationFieldProps {
  readonly value?: string | null;
  readonly onChange?: (value: string) => void;
  readonly groupBy?: any;
  readonly InputProps?: any;
  readonly placeholder?: string;
  readonly type?: string;
  readonly InputLabelProps?: any;
  readonly multiline?: boolean;
  readonly className?: string;
}

export const InputField: (props: InputFieldProps) => JSX.Element = ({
  label = "",
  name,
  value,
  onChange = () => {},
  disabled = false,
  className,
  multiline = false,
  placeholder,
  type = "text",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  required,
  ...rest
}: InputFieldProps) => {
  return (
    <TextField
      label={label}
      disabled={disabled}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      name={name}
      value={value ?? ""}
      autoComplete="off"
      className={`input text text-field ${className}`}
      variant="outlined"
      type={type}
      placeholder={placeholder}
      required={required ?? false}
      {...rest}
      multiline={multiline}
    />
  );
};

export default withValidation<InputFieldProps>(InputField);
