import React from "react";
import getRecallStatuses from "../../../../http/recall/getRecallStatuses";
import Option from "../../../../Models/forms/Option";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import RecallStatusView from "../../../../Models/Recall/RecallStatusView";

interface RecallStatusDropdownProps {
  value?: Option | null;
  required?: boolean;
  onChange: (recallStatus: Option | null) => void;
  label?: string;
}

export default class RecallStatusDropdown extends React.Component<
  RecallStatusDropdownProps,
  { options: Option[] }
> {
  constructor(props: RecallStatusDropdownProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getStatusOptions();
  }

  getStatusOptions(): void {
    getRecallStatuses()
      .then<Option[]>((response) =>
        RecallStatusDropdown.mapStatusesToOptions(response.data)
      )
      .then((data) => {
        this.setState(() => ({ options: data }));
      })
      .catch((error) => console.log(error));
  }

  private static mapStatusesToOptions(response: RecallStatusView[]): Option[] {
    return response.map((element: RecallStatusView) => ({
      value: element.id.toString(),
      label: element.name,
    }));
  }

  render() {
    const { required, value, onChange, label } = this.props;
    const { options } = this.state;

    return (
      <SearchableDropdown
        required={required}
        Options={options}
        label={label ?? "Select submission status"}
        value={value}
        name="selectRecallStatus"
        onChange={(e: React.ChangeEvent<{}>, option: Option | null) =>
          onChange?.(option)
        }
      />
    );
  }
}
