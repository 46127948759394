import React, { useEffect, useState } from "react";
import { isBRCGSUser } from "../../Authentication/Auth";
import DataTable, { DataTableQueryParams } from "../DataTable";
import { UserRow } from "./models/UserRow";
import axiosApi from "../Api/Axios";
import SearchResponses from "../../Models/APIGetData/SearchResponses";
import { User } from "../../Models/APIGetData/User/User";
import BackendServices from "../Api/BackendService";
import ContentPage from "../ContentPage";
import userColumns from "./utils/userColumns";
import { UserUrls } from "../../views/UserManagement";

interface UserTableProps {
  readonly queryParams?: { [key: string]: any };
  readonly createUrl?: string;
  readonly subtitle?: string;
}

const UserTable = ({
  queryParams = {},
  createUrl = UserUrls.create(),
  subtitle,
}: UserTableProps) => {
  const [roles, setRoles] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    axiosApi
      .get<string[]>(`${BackendServices.USER_SERVICE.USER}/getRoles`)
      .then((response) => response.data)
      .then((roleData) =>
        roleData.reduce<{ [key: string]: string }>((acc, curr) => {
          acc[curr] = curr;
          return acc;
        }, {})
      )
      .then((roleData) => setRoles(roleData));
  }, []);


 
   const fetch = (
    params: DataTableQueryParams,
  ): Promise<{ data: UserRow[]; totalPages: number }> => {
    if ( params.searchTerm == '' ) {
      return Promise.resolve({ data: [], totalPages: 0 });
    }
    return axiosApi
    .get<SearchResponses<User>>(`${BackendServices.USER_SERVICE.USER}/list`, {
      params: { ...params, ...queryParams },
    })
    .then(({ data }) => ({
      totalPages: data.totalPages,
      data: data.resultsList.map(
        (user: User): UserRow => ({
          email: user.email,
          identificator: user.identificator,
          name: user.name,
          organisationName: user.organisations,
          roleName: user.roleName,
          status: user.status,
        })
      ),
    }));
  }


  const filterEnable: boolean = isBRCGSUser();


  return (
    <ContentPage title="Users" subtitle={subtitle}>
      <DataTable<UserRow>
        columns={userColumns()}
        fetchRemoteData={fetch}
        filters={{
          roleName: () => roles,
        }}
        toolBarOptions={{
          newEntityLink: {
            label: "Add User",
            link: createUrl,
          },
        }}
        archiveFilter={{
          enabled: filterEnable,
          title: "inactive",
          queryParam: "showInactive",
        }}
      />
    </ContentPage>
  );
};

export default UserTable;
