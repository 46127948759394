/* eslint-disable react/no-unused-prop-types */
import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import withValidation from "../FieldValidator";

interface DatePickerProps {
  readonly onClear?: () => void;
  readonly onChange: (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => void;
  readonly value: ParsableDate;
  readonly label: string;
  readonly minDate?: ParsableDate;
  readonly maxDate?: ParsableDate;

  readonly required?: boolean;
  readonly name?: string;
}

const DatePicker = ({
  onChange,
  onClear,
  value,
  label,
  minDate,
  maxDate,
}: DatePickerProps) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        className="date-field"
        variant="inline"
        inputVariant="outlined"
        disableToolbar
        format="YYYY-MM-DD"
        autoOk
        fullWidth
        label={label}
        margin="normal"
        minDateMessage="Date must be after the minimum date"
        maxDateMessage="Date must be before the maximum date"
        InputProps={
          onClear
            ? {
                startAdornment: (
                  <IconButton onClick={onClear}>
                    <ClearIcon />
                  </IconButton>
                ),
              }
            : {}
        }
      />
    </MuiPickersUtilsProvider>
  );
};

export default withValidation<DatePickerProps>(DatePicker);
