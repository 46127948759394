import React, { useEffect, useState } from "react";
import useStandards from "../../../../http/public/useStandards";
import { SelectedFilters } from "../index";
import useCategories from "../../../../http/public/useCategories";
import useQualificationStatus from "../../../../http/public/useQualificationStatus";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import styles from "../AuditorSearchTable.module.scss";
import FilterGroup from "../../../../components/FilterGroup";
import CertificationBodyFilter from "./CertificationBodyFilter";
import CertificationBody from "../../../../Models/Office/CertificationBody";
import Option from "../../../../Models/forms/Option";
import sortNameAlphanumerical from "../../../../util/sorting/sortNameAlphanumerical";

interface AuditorSearchTableFilterProps {
  readonly setSelectedFilters: (val: SelectedFilters) => void;
  readonly reset: () => void;
  readonly isPendingTable: boolean;
  readonly selectedFilters: SelectedFilters;
}

type AuditorFilters = "Standard" | "Category" | "CertificationBody" | "Status";

const AuditorSearchTableFilter = ({
  selectedFilters,
  reset,
  isPendingTable = false,
  setSelectedFilters,
}: AuditorSearchTableFilterProps) => {
  const standards = useStandards().map(
    ({ name }): Option => ({ value: name, label: name })
  );
  const status = useQualificationStatus().map(
    ({ name }): Option => ({ value: name, label: name })
  );
  const categories = useCategories()
    .filter((category) =>
      selectedFilters.Standard.map((s) => s.value).includes(
        category.standardName
      )
    )
    .sort(sortNameAlphanumerical)
    .map(
      (category): Option => ({
        value: category.externalId,
        label: category.name,
        type: category.standardName,
      })
    );
  const [certificationBodies, setCertificationBodies] = useState<Option[]>([]);

  useEffect(() => {
    axiosApi
      .get<CertificationBody[]>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/all`
      )
      .then(({ data }) => {
        setCertificationBodies(
          data
            .filter((cb) => cb.mainOffice?.name)
            .map(
              (cb): Option => ({
                value: cb.id,
                label: cb.mainOffice?.name ?? "",
              })
            )
        );
      });
  }, []);

  const handleChange = (key: AuditorFilters, values: Option[]) => {
    const nextState: SelectedFilters = {
      ...selectedFilters,
      [key]: values,
    };

    const categoriesFiltered: SelectedFilters = {
      ...nextState,
      Category: nextState.Category.filter((category) => {
        if (!category.type) return false;
        return nextState.Standard.map((_s) => _s.label).includes(category.type);
      }),
    };

    setSelectedFilters(categoriesFiltered);
  };
  if (!isPendingTable) {
    return (
      <FilterGroup<AuditorFilters>
        reset={reset}
        className={styles.filter}
        filters={[
          {
            name: "Standard",
            options: standards,
            selected: selectedFilters.Standard,
            title: "Standard",
          },
          {
            name: "Category",
            title: "Category",
            selected: selectedFilters.Category,
            options: categories,
            enabled: selectedFilters.Standard.length > 0,
          },
          {
            name: "CertificationBody",
            title: "Certification Body",
            component: CertificationBodyFilter,
            options: certificationBodies,
            selected: selectedFilters.CertificationBody,
          },
          {
            name: "Status",
            options: status.filter((option) => option.value !== "In Revision"),
            selected: selectedFilters.Status,
            title: "Status",
          },
        ]}
        handleChange={handleChange}
      />
    );
  }
  return (
    <FilterGroup<AuditorFilters>
      reset={reset}
      className={styles.filter}
      filters={[
        {
          name: "Standard",
          options: standards,
          selected: selectedFilters.Standard,
          title: "Standard",
        },
        {
          name: "Category",
          title: "Category",
          selected: selectedFilters.Category,
          options: categories,
          enabled: selectedFilters.Standard.length > 0,
        },
        {
          name: "CertificationBody",
          title: "Certification Body",
          component: CertificationBodyFilter,
          options: certificationBodies,
          selected: selectedFilters.CertificationBody,
        },
        {
          name: "Status",
          options: status
            .filter((option) => option.value !== "Validated")
            .filter((option) => option.value !== "Archived")
            .filter((option) => option.value !== "In Progress"),
          selected: selectedFilters.Status,
          title: "Status",
        },
      ]}
      handleChange={handleChange}
    />
  );
};

export default AuditorSearchTableFilter;
