import { Checkbox, Snackbar } from "@material-ui/core";
import axios from "../../../components/Api/Axios";
import React, { useEffect, useState } from "react";
import BackendServices from "../../../components/Api/BackendService";
import { getCompanyId } from "../../../Authentication/Auth";
import { number } from "../../../Enums/ValidationRule";
import GlobalError from "../../../routing/GlobalError";
import setError from "../../../store/error/actions/setError";
import { dispatch } from "../../../components/App/App";
import HttpStatus from "../../../components/Api/HttpStatus";

interface FavProps {
  id?: string|number
  fav: boolean
}
const FavCheckbox = ({ id, fav }: FavProps) => {

  const [isChecked, setIsChecked] = useState(fav);
  const auditId = Number(id);
  const companyId = Number(getCompanyId());
  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxStatus = event.target.checked;
    setIsChecked(checkboxStatus);
    if (checkboxStatus) {
      axios
        .post(`${BackendServices.AUDIT_SERVICE.VERIFY}/updateFavouriteAudits`,
          { auditId, companyId }
        ).then(({ data }) => {
          const dataString = JSON.stringify(data);
          if (dataString.includes("-1")) {
            setIsChecked(false);
            console.log(dataString);
            dispatch(
              setError(
                "Maximum number of favourited audits reached"
              )
            );
          }
        }
        )
        .catch((error) => {
          console.error("Error updating favourite audits:", error);
        });
    }
    else {
      axios
        .post(`${BackendServices.AUDIT_SERVICE.VERIFY}/removeFavouriteAudits`,
          { auditId, companyId }
        )
    }

    // collect data to store

  }
  useEffect(() => {
    setIsChecked(fav)
  }, [id]);


  return (
    <><Checkbox
      key={id}
      checked={isChecked}
      onChange={handleCheckboxChange} /> <GlobalError /></>
  )
}

export default FavCheckbox;