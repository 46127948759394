import React, { useContext, useState } from "react";
import useStandards from "../../../../http/public/useStandards";
import useCategories from "../../../../http/public/useCategories";
import styles from "./AuditStandards.module.scss";
import { SecondaryButton } from "../../../../components/Button/button";
import NotificationAuditCategories from "../../models/NotificationAuditCategories";
import { NotificationCategoryContext } from "../../index";
import StandardOption from "./StandardOption";
import Standard from "../../../../Models/common/Standard";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { SpinnerCenter } from "../../../../components/Spinner";

export enum AuditStandardStatus {
  ALL = "ALL",
  NONE = "NONE",
  CUSTOM = "CUSTOM",
}

export interface AuditStandardsProps {
  readonly refresh: () => void;
}

const AuditStandards = ({ refresh }: AuditStandardsProps) => {
  const standards = useStandards();
  const categories = useCategories();
  const [isLoading, setLoading] = useState(false);

  const { standards: selectedStandards, isLoading: isLoadingData } = useContext(
    NotificationCategoryContext
  );

  const isDefault = selectedStandards.length === 0;

  const isSameStandard = (
    standard: Standard,
    _standard: NotificationAuditCategories
  ) => _standard.standardId === standard.id;

  const getPostBody = (standard: Standard, newCategories?: number[]) => {
    const initialStandards: NotificationAuditCategories[] = isDefault
      ? standards.map((s) => ({
          standardId: s.id,
          categories: [],
        }))
      : selectedStandards;
    if (!newCategories) {
      return initialStandards.filter((s) => !isSameStandard(standard, s));
    }
    const newStandard: NotificationAuditCategories = {
      standardId: standard.id,
      categories: newCategories,
    };
    return initialStandards.some((s) => isSameStandard(standard, s))
      ? initialStandards.map((s) =>
          isSameStandard(standard, s) ? newStandard : s
        )
      : [...initialStandards, newStandard];
  };

  const postChange = (newStandards: NotificationAuditCategories[]) => {
    setLoading(true);
    axiosApi
      .post(
        `${BackendServices.NOTIFICATION_SERVICE.NOTIFICATION}/UpdateNotifications`,
        { standards: newStandards }
      )
      .then(refresh)
      .finally(() => setLoading(false));
  };

  const onOptionChange = (standard: Standard, newCategories?: number[]) => {
    const body: NotificationAuditCategories[] = getPostBody(
      standard,
      newCategories
    );
    postChange(body);
  };

  return (
    <div className={styles.root}>
      {(isLoading || isLoadingData) && (
        <div className={styles.loadingOverlay}>
          <SpinnerCenter delay={0} />
        </div>
      )}
      <h3>Standards</h3>
      {standards.map((standard) => (
        <StandardOption
          key={standard.id}
          onChange={(val) => {
            onOptionChange(standard, val);
          }}
          standard={standard}
          selected={selectedStandards.find((s) => s.standardId === standard.id)}
          categoryOptions={categories.filter(
            (c) => c.standardName === standard.name
          )}
        />
      ))}
      <SecondaryButton
        className={styles.action}
        onClick={() => {
          postChange([]);
        }}
        text="Reset to defaults"
      />
    </div>
  );
};

export default AuditStandards;
