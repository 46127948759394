import React from "react";
import { useHistory } from "react-router-dom";
import { H2Heading } from "../../../../components/Common/Heading";
import { SecondaryButton } from "../../../../components/Button/button";
import styles from "./SiteCard.module.scss";
import { PublicSearchItem } from "../../../../store/public-directory/actions/getData";
import StarRating from "../../../../components/StarRating/StarRating";

interface SiteCardProps {
  readonly item: PublicSearchItem;
}

const DataItem = ({ label, content }: { label: string; content: string }) =>
  content ? (
    <div className={styles.dataItem}>
      <p>{label}</p>
      <p>{content}</p>
    </div>
  ) : null;

const RowCard = ({ item }: SiteCardProps) => {
  const history = useHistory();

  return (
    <div className={styles.siteCard}>
      <div className={styles.top}>
        <div className={styles.left}>
          <H2Heading text={item.name} />
          {item.rating !== undefined && <StarRating value={item.rating} />}
          <DataItem label="Standard: " content={item.standard.join(", ")} />
        </div>
        <div className={styles.right}>
          <SecondaryButton
            onClick={() => history.push(item.openLink)}
            text="View details"
          />
        </div>
      </div>
      {item.additional.length > 0 && (
        <div className={styles.bottom}>
          {item.additional.map((info) => (
            <DataItem
              key={info.label}
              label={info.label}
              content={info.value}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RowCard;
