import React, { PropsWithChildren } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import MainButton, { SecondaryButton } from "../Button/button";
import styles from "./DialogBox.module.scss";
import DuoSpacing from "../DuoSpacing";

export interface CoreDialogBoxProps {
  readonly dialogHeader?: string;
  readonly onConfirm: (e: React.MouseEvent) => void;
  readonly acceptButtonText: string;
  readonly declineButtonText?: string;
  readonly disabled?: boolean;
  readonly children?: React.ReactNode;
}

interface DialogBoxProps extends CoreDialogBoxProps {
  readonly onCancel: (e: React.MouseEvent) => void;
  readonly open: boolean;
}

const DialogBox = ({
  dialogHeader,
  onConfirm,
  onCancel,
  open,
  acceptButtonText,
  declineButtonText,
  children,
  disabled = false,
}: PropsWithChildren<DialogBoxProps>) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>{dialogHeader}</DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <DuoSpacing className={styles.actions}>
        <MainButton
          onClick={onConfirm}
          text={acceptButtonText}
          className={styles.button}
          disabled={disabled}
        />
        {declineButtonText && (
          <SecondaryButton
            onClick={onCancel}
            text={declineButtonText}
            className={styles.button}
          />
        )}
      </DuoSpacing>
    </DialogActions>
  </Dialog>
);

export default DialogBox;
