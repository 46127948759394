import React from "react";
import { FilterProps } from "../templates/filterMappings";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import Option from "../../../Models/forms/Option";
import DebouncedMultipleSelector from "../../../components/DebouncedMultipleSelector";

const ProgramOwnerFilter = ({ name, value, setValue }: FilterProps) => {
  const getOptions = (input: string): Promise<Option[]> => {
    const url = `${BackendServices.REPORT_SERVICE.REPORT}/data/programowners?query=${input}`;
    return axiosApi
      .get<{ externalId: number; ownerName: string }[]>(url)
      .then(({ data }) =>
        data.map(
          (result): Option => ({
            label: result.ownerName,
            value: result.externalId,
          })
        )
      );
  };

  return (
    <DebouncedMultipleSelector
      getOptions={getOptions}
      value={value as Option[]}
      onSelect={setValue}
      label={name}
    />
  );
};

export default ProgramOwnerFilter;
