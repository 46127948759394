import React from "react";
import GenericRoutes from "../../routing/GenericRoutes";
import { privateArea } from "../../components/Paths/paths";
import GenericLinks from "../../routing/GenericLinks";
import PermissionArea from "../../Authentication/model/PermissionArea";
import RecallSearchTable from "./RecallSearchTable";
import RecallNew from "./RecallNew";
import RecallDetailsPage from "./RecallDetails";

const PATH_RECALL_MANAGEMENT = `${privateArea}/recall`;
export const RecallUrls = new GenericLinks(PATH_RECALL_MANAGEMENT);

const RecallRouting = GenericRoutes({
  root: PATH_RECALL_MANAGEMENT,
  permissionArea: PermissionArea.RECALL,
  urls: RecallUrls,
  entityType: "recall",
  components: {
    editComponent: RecallNew,
    searchComponent: RecallSearchTable,
    detailsComponent: RecallDetailsPage,
    createComponent: RecallNew,
  },
});

const RecallRoutes = () => <RecallRouting />;

export default RecallRoutes;
