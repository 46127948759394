import { useEffect, useState } from "react";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import { Module } from "../../Models/APIGetData/Module";

export default () => {
  const [modules, setModules] = useState<Module[]>([]);
  useEffect(() => {
    axiosApi
      .get<Module[]>(`${BackendServices.PUBLIC_SERVICE}/modules`)
      .then(({ data }) => setModules(data));
  }, []);

  return modules;
};
