import React, { ChangeEvent, useEffect, useState } from "react";
import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import ContentPage from "../../../../components/ContentPage";
import AuditSharingResult from "../../models/AuditSharing";
import SharingOptionsData from "../../models/SharingOptionsData";
import getSharedOptionsWith from "../../http/getSharingOptionsData";
import CheckBox from "../../../../components/Common/CheckBox";
import SharingOptionsList from "./SharingOptionsToggles";
import { CustomTooltips } from "../../../../components/Tooltips/CustomTooltips";
import { FormGroup, MuiThemeProvider, Tooltip, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from "@material-ui/core";
import { NotificationLabel } from "../../../NotificationManagement/Components/NotificationList/NotificationLabel";
import Box, { BoxPadding } from "../../../../components/Box";
import sharingOptionsGroup from "../../models/SharingOptionsList";
import { getCompanyId} from "../../../../Authentication/Auth";

export interface ShareOptionsProps {
  readonly id: string;
  readonly setShowConfirmation: (data: {
    shared: boolean;
    name: string;
  }) => void;
}
export const SharingContext = React.createContext<{
  id: string;
  sharingPermissions: sharingOptionsGroup[]
  isLoading: boolean;
}>({ isLoading: false, id: "", sharingPermissions: [] });


const ShareOptions = ({ id, setShowConfirmation }: ShareOptionsProps) => {

  const [sharingData, setSharingData] = useState<SharingOptionsData[]>([]);
  const [value, setValue] = React.useState<number|null|string>(null);
  
  const loadDataFromService = () => {
    
    axios
      .get<SharingOptionsData[]>(`${BackendServices.AUDIT_SERVICE.AUDIT}/SharingOptionsData/${id}`)
      .then((response) => {
        //setSharingData(response.data); -- previous code 
        setSharingData(response.data);
        const checkedRadio = response.data.find(item => item.value)?.sharingExternalId || null;
        setValue(checkedRadio);

        // Update state with the array data
        //console.log(response.data);
        // Log the array data
      }).
      catch((error) => {
        console.error(
          "Error fetching sharing options data:", error);
      }).
      finally(() => {  });
  };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

      const auditExternalId =id;
      const sharingExternalId=event.target.value;
      const value=true;
        
      const companyId=Number(getCompanyId());
       
      setValue(event.target.value);     
      axios
      .post(`${BackendServices.AUDIT_SERVICE.AUDIT}/SetSharingOptionsData`,
        { auditExternalId,sharingExternalId,value,companyId}
      )
      .then(loadDataFromService);
    };
    useEffect(loadDataFromService, []);
    return (

      <Box header="" className="notification-group">
      <FormGroup className="notifications">
     
      <FormControl>

        <RadioGroup
          value={value}
          onChange={handleChange}
        >

             {sharingData.map((data: SharingOptionsData) => (
                    <>
                    <div style={{padding:10}}>
                    <FormControlLabel key={data.sharingExternalId} value={data.sharingExternalId}  label={data.name} control={<Radio />} labelPlacement="start"  />
                    </div>
                      
                    </>

                  )
                  )}
                  
          {/* <FormControlLabel value="female" control={<Radio />} label="Female" />
          <FormControlLabel value="male" control={<Radio />} label="Male" /> */}
        </RadioGroup>
      </FormControl>
      
      </FormGroup>
      </Box>
    );
  }
      // const [sharingData, setSharingData] = useState<SharingOptionsData[]>([]);
      // const [isLoading, setLoading] = useState(false);
      // const [value, setValue] = React.useState<number | null>(null);

      // data from backend

      // const loadDataFromService = () => {
      //   setLoading(true);
      //   axios
      //     .get<SharingOptionsData[]>(`${BackendServices.AUDIT_SERVICE.AUDIT}/SharingOptionsData/${id}`)
      //     .then((response) => {
      //       //setSharingData(response.data); -- previous code 
      //       setSharingData(response.data);
      //       const checkedRadio = sharingData.find(item => item.value)?.sharingExternalId || null;
      //       setValue(checkedRadio);

      //       // Update state with the array data
      //       //console.log(response.data);
      //       // Log the array data
      //     }).
      //     catch((error) => {
      //       console.error(
      //         "Error fetching sharing options data:", error);
      //     }).
      //     finally(() => { setLoading(false); });
      // };

      // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      //   // const auditExternalId = sharingData;
      //   // const sharingExternalId = Number(event.target.value);
      //   const value = event.target.checked;
      //   console.log(value + "radio")
      //   // debugger;
      //   // if (value) {
      //   //   event.target.checked = false;
      //   // }
      //   // else {
      //   //   axios
      //   //     .post(`${BackendServices.AUDIT_SERVICE.AUDIT}/SetSharingOptionsData`,
      //   //       { auditExternalId, sharingExternalId, value }
      //   //     )
      //   //     .then(refresh);
      //   // }
      // }


      // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      //   // console.log(checkedRadio);
      //   // setValue(event.target.value);
      //   console.log(event.target.value);
      // };

  //     useEffect(loadDataFromService, []);
  //     return (

  //       <>
  //         <ContentPage>
  //           <MuiThemeProvider theme={CustomTooltips}>
  //             <FormControl>
  //               <RadioGroup
  //                 aria-labelledby="demo-radio-buttons-group-label"
  //                 name="radio-buttons-group"
  //                 value={value}
  //                 onChange={handleChange}
  //               >
  //                 {sharingData.map((data: SharingOptionsData) => (
  //                   <>
  //                     <FormControlLabel key={data.sharingExternalId} value={data.sharingExternalId} control={<Radio />} label={data.name} />
  //                   </>

  //                 )
  //                 )}

  //                 {/* <SharingOptionLabel
  //                   label={updatingToggle.name}
  //                   handleChange={handleChange}
  //                   checked={updatingToggle.value}
  //                   Sharingid={updatingToggle.sharingExternalId}
  //                 /> */}
  //               </RadioGroup>

  //             </FormControl>
  //           </MuiThemeProvider>
  //         </ContentPage>
  //       </>
  //     )
  //   }



  // }

  export default ShareOptions;


