import React from "react";
import { privateArea } from "../../components/Paths/paths";
import UserNew from "./UserNew";
import UserEdit from "./UserEdit";
import GenericRoutes from "../../routing/GenericRoutes";
import GenericLinks from "../../routing/GenericLinks";
import UserSearchTable from "./UserSearchTable";
import PermissionArea from "../../Authentication/model/PermissionArea";
import UserDetails from "./UserDetails";

const PATH_USER_MANAGEMENT = `${privateArea}/user`;
export const UserUrls = new GenericLinks(PATH_USER_MANAGEMENT);

const UserRouting = GenericRoutes({
  root: PATH_USER_MANAGEMENT,
  urls: UserUrls,
  entityType: "user",
  permissionArea: PermissionArea.USER,
  components: {
    editComponent: UserEdit,
    createComponent: UserNew,
    detailsComponent: UserDetails,
    searchComponent: UserSearchTable,
  },
});

const UserRoutes = () => <UserRouting />;

export default UserRoutes;
