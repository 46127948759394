import Option from "../../../../Models/forms/Option";

export interface PrimaryOrganisations {
  readonly typeName: string;
  readonly items: MinifiedOrganisation[];
}

export interface MinifiedOrganisation {
  readonly externalId: number;
  readonly type: MinifiedOrganisationType;
  readonly name: string;
}

export enum MinifiedOrganisationType {
  CertificationBody = 0,
  Company = 1,
  Office = 2,
  Site = 3,
  AccreditationBody = 4,
}

export const minifiedOrganisationToString = (
  orgOption: Option<MinifiedOrganisationType>
) => {
  const { type } = orgOption;
  if (!type && type !== 0) return "Unknown type ";
  if (type === MinifiedOrganisationType.Office) return "Sub-office";
  if (type === MinifiedOrganisationType.CertificationBody)
    return "Certification body";
  return MinifiedOrganisationType[type];
};
