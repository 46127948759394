import React from "react";
import Column from "../../../../../components/DataTable/models/Column";
import { LinkText } from "../../../../../components/Common/hyperlinks";

import DashboardRow from "./DashboardRow";
import { PATH_ADMINISTRATION } from "../../../../../components/Paths/paths";

const dashboardColumns: Column<DashboardRow>[] = [
  {
    field: "title",
    title: "Title",
    render: (data) => {
      return (
        <LinkText
          text={data.title}
          link={`${PATH_ADMINISTRATION}/edit/${data.id}`}
        />
      );
    },
  },
  { field: "url", title: "URL" },
  { field: "roles", title: "Roles" },
];
export default dashboardColumns;
