import React from "react";
import SearchableDropdown from "../SearchableDropdown";
import useDebouncedOptionSearch from "./utils/useDebouncedOptionSearch";
import Option from "../../Models/forms/Option";

export interface DebouncedSearchSelectorCoreProps<T> {
  readonly getOptions: (input: string) => Promise<Option<T>[]>;
  readonly label?: string;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly sorter?: (a: Option<T>, b: Option<T>) => number;
  readonly groupBy?: (option: Option<T>) => string;
  readonly noOptionsHintText?: string;
  readonly validateInput?: (input: string) => boolean;
  readonly name?: string;
}

interface DebouncedSearchSelectorProps<T>
  extends DebouncedSearchSelectorCoreProps<T> {
  readonly value?: Option<T> | null;
  readonly onSelect: (result: Option<T> | null) => void;
}

const DebouncedSearchSelector = <T extends any>({
  getOptions,
  sorter = (a: Option<T>, b: Option<T>) => (a.label < b.label ? -1 : 1),
  onSelect,
  label,
  value,
  disabled,
  required,
  name,
  groupBy,
  noOptionsHintText,
  validateInput = () => true,
}: DebouncedSearchSelectorProps<T>) => {
  const {
    setSearchTerm,
    isLoading,
    initialSearchPerformed,
    searchResult,
    searchTerm,
  } = useDebouncedOptionSearch<T>(getOptions, value, sorter, groupBy);

  return (
    <SearchableDropdown<T>
      required={required}
      label={label}
      Options={searchResult}
      onChange={(_, newValue) => {
        onSelect(newValue);
      }}
      onInputChange={(_value) => {
        if (validateInput(_value)) {
          setSearchTerm(_value);
        }
      }}
      name={name}
      placeholder="Start typing to search"
      value={value}
      disabled={disabled}
      groupBy={groupBy}
      isLoadingOptions={isLoading}
      noOptionsText={
        initialSearchPerformed
          ? "No options - try changing the search input"
          : noOptionsHintText ??
            "No options yet - type two characters minimum to search"
      }
      inputValue={searchTerm}
      disableOptionFilter
    />
  );
};

export default DebouncedSearchSelector;
