import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddBox";
import {
  AuditEntryValue,
  AuditTable,
  AuditTableRow,
  AuditTableType,
} from "../../../models/AuditTabs";
import { H6Heading } from "../../../../../../components/Common/Heading";
import TableSimple from "../TabTable/EditableTable";
import AccordionRow from "../AccordionTable/AccordionRow";
import styles from "./CoreTable.module.scss";
import { setEditingValue, useEditContext } from "../utils/editReducer";

const shortid = require("shortid");

export interface CoreTableChildProps {
  readonly updateRow: (
    rowId: string,
    xmlTag: string,
    value: AuditEntryValue
  ) => void;
  readonly deleteRow: (id: string) => void;
  readonly isEditing: boolean;
}

interface CoreTableProps {
  readonly table: AuditTable;
}

const CoreTable = ({ table }: CoreTableProps) => {
  const [editState, editDispatch] = useEditContext();

  const editedData = editState.values[table.xmlTag]?.value;
  const rows: AuditTableRow[] = (editedData as AuditTableRow[]) ?? table.rows;

  const update = (newRows: AuditTableRow[]) => {
    editDispatch(setEditingValue(table.xmlTag, newRows, table.type));
  };

  const updateRow = (rowId: string, xmlTag: string, value: AuditEntryValue) => {
    update(
      rows.map((row) =>
        row.id === rowId
          ? {
              id: rowId,
              cells: row.cells.map((cell) =>
                cell.xmlTag === xmlTag ? { ...cell, data: value } : cell
              ),
            }
          : row
      )
    );
  };

  const deleteRow = (id: string) => {
    update(rows.filter((row) => row.id !== id));
  };
  const addRow = () => {
    update([
      ...rows,
      {
        id: shortid.generate(),
        cells: table.columns.map((col) => ({
          ...col,
          id: shortid.generate(),
          data: "",
        })),
      },
    ]);
  };
  const updateFunctions = {
    deleteRow,
    addRow,
    updateRow,
  };

  const renderTable = () => (
    <TableSimple
      isEditing={editState.isEditing}
      columns={table.columns}
      rows={rows}
      {...updateFunctions}
    />
  );

  const renderAccordion = () => {
    if (rows.length === 0) return null;
    return rows.map(({ id: rowId, cells }) => {
      const visibleProps = cells.filter((entry) => entry.visible);
      const hiddenProps = cells.filter((entry) => !entry.visible);
      return (
        <AccordionRow
          isEditing={editState.isEditing}
          key={rowId}
          rowId={rowId}
          visible={visibleProps}
          hidden={hiddenProps}
          {...updateFunctions}
        />
      );
    });
  };

  const renderType = () => {
    switch (table.type) {
      case "Accordion":
        return renderAccordion();
      case "Table":
        return renderTable();
      default:
        throw new Error("Unsupported audit table type");
    }
  };

  return (
    <div className={classNames[table.type]}>
      <H6Heading text={table.label} />
      {renderType()}
      <div className={styles.finalRow}>
        {rows.length === 0 && <p>No data</p>}
        {editState.isEditing && (
          <IconButton className={styles.addRow} onClick={addRow}>
            <AddIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

const classNames: { [key in AuditTableType]: string } = {
  Table: "",
  Accordion: "accordion-tables",
};

export default CoreTable;
