import { Snackbar as MaterialSnackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React from "react";

type SnackbarType = Color;

interface SnackbarProps {
  readonly open: boolean;
  readonly message: string;
  readonly type?: SnackbarType;
  readonly close: () => void;
  readonly hideAfter?: number | null;
}

const Snackbar = ({
  open,
  message,
  type = "success",
  close,
  hideAfter = 5000,
}: SnackbarProps) => (
  <MaterialSnackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    open={open}
    autoHideDuration={hideAfter}
    onClose={close}
  >
    <Alert onClose={close} severity={type}>
      {message}
    </Alert>
  </MaterialSnackbar>
);

export default Snackbar;
