/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import Heading from "../Heading";

function orEmpty(value?: string | null) {
  return value || "";
}

export function Text(props: TextFieldProps & { titleText?: string }) {
  const { titleText, variant, ...rest } = props;
  return (
    <div>
      {titleText && <Heading text={titleText} />}
      <TextField
        className="input text text-field"
        variant="outlined"
        autoComplete="off"
        {...rest}
      />
    </div>
  );
}

export class TextValidation extends TextValidator {
  render() {
    const { titleText, variant, value, textWrapClass, ...rest } = this.props;

    return (
      <div className={textWrapClass}>
        {titleText && <Heading text={titleText} />}
        <TextValidator
          className="input text text-field"
          variant="outlined"
          value={orEmpty(value)}
          autoComplete="off"
          {...rest}
        />
      </div>
    );
  }
}
