import React from "react";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { AddCircle, Clear } from "@material-ui/icons";
import SearchableDropdown from "../../SearchableDropdown";
import Option from "../../../Models/forms/Option";
import { AuditorNamedEntity } from "../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";

type Language = AuditorNamedEntity | null;
interface ILanguage {
  languageOptions: Option[];
  selectedLanguages: Language[];
  setSelectedLanguages: React.Dispatch<React.SetStateAction<Language[]>>;
}

export default function LanguageSection({
  languageOptions,
  selectedLanguages,
  setSelectedLanguages,
}: ILanguage) {
  const availableLanguageOptions = languageOptions
    .filter(
      (language) =>
        !selectedLanguages
          .filter((sl) => !!sl)
          .some((sl) => sl!.id === language.value)
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const addLanguage = () => {
    const newLanguages = [...selectedLanguages, null];
    setSelectedLanguages(newLanguages);
  };

  const handleChangeLanguage = (
    prevIndex: number,
    newLanguage: Option | null
  ) => {
    const newLanguages = selectedLanguages.map(
      (language: Language, index: number) => {
        if (prevIndex !== index) return language;
        if (!newLanguage) return null;
        return {
          id:
            typeof newLanguage.value === "string"
              ? parseInt(newLanguage.value, 10)
              : newLanguage.value,
          name: newLanguage.label,
        };
      }
    );
    setSelectedLanguages(newLanguages);
  };

  const removeLanguage = (toBeRemoved: Language) => {
    const newLanguages = selectedLanguages.filter(
      (language: Language) => language !== toBeRemoved
    );
    setSelectedLanguages(newLanguages);
  };

  const mapLanguageToOption = (language: AuditorNamedEntity): Option => ({
    value: language.id,
    label: language.name,
  });

  return (
    <Grid container spacing={3} direction="column">
      {selectedLanguages.map((language: Language, index: number) => {
        const value = language ? mapLanguageToOption(language) : null;
        const options = [
          ...(value ? [value] : []),
          ...availableLanguageOptions,
        ];
        return (
          <Grid
            key={language?.id ?? `empty${index}`}
            className="accordion-input"
            item
          >
            <h3>
              {`Language ${index + 1}`}{" "}
              {selectedLanguages.length > 1 && (
                <Clear
                  className="clickableClear"
                  onClick={() => removeLanguage(language)}
                />
              )}
            </h3>
            <SearchableDropdown
              value={value}
              required
              Options={options}
              label="Select language"
              name="languageSelect"
              onChange={(e: React.ChangeEvent<{}>, _value: Option | null) =>
                handleChangeLanguage(index, _value)
              }
            />
          </Grid>
        );
      })}
      {availableLanguageOptions.length > 0 && (
        <>
          <Divider />
          <Grid item>
            <IconButton onClick={addLanguage}>
              <AddCircle className="add" />{" "}
              <span className="add">Add language</span>
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}
