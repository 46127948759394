import React, { useContext } from "react";
import ContentPage from "../ContentPage";
import PermissionContext from "../../routing/contexts/PermissionContext";
import { H1Heading } from "../Common/Heading";
import Permission from "../../Authentication/model/Permission";

export const PermissionDeniedPage = ({
  permission,
}: {
  permission?: Permission;
}) => {
  const permissionArea = useContext(PermissionContext);
  return (
    <ErrorPage
      header="Permission denied"
      description={`Can't perform action for ${permissionArea} - Operation: ${permission}`}
    />
  );
};

interface ErrorPageProps {
  readonly header: string;
  readonly description: string;
}

const ErrorPage = ({ description, header }: ErrorPageProps) => (
  <ContentPage title="Error">
    <H1Heading text={header} />
    <p>{description}</p>
  </ContentPage>
);

export default ErrorPage;
