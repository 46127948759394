enum PostAuditStatus {
  Success = 0,
  SuccessWithWarnings = 1,
  Validation = 2,
  Virus = 3,
  Error = 4,
  Confirm = 5,
  NotFound = 6,
  OverrideWarning = 7,
}
export const AUDIT_UPDATE_SUCCESS_STATUSES = [
  PostAuditStatus.Success,
  PostAuditStatus.SuccessWithWarnings,
];

export default PostAuditStatus;
