import React from "react";
import DataTable, {
  DataTableQueryParams,
} from "../../../../../components/DataTable";
import AuditorRow from "./models/AuditorRow";
import axiosApi from "../../../../../components/Api/Axios";
import SearchResponse from "../../../../../Models/APIGetData/SearchResponse";
import BackendServices from "../../../../../components/Api/BackendService";
import Auditor from "../../../../../Models/Auditor/Auditor";
import Status from "../../../../../Enums/AuditorStatus";
import Column from "../../../../../components/DataTable/models/Column";
import { SelectedFilters } from "../../index";
import { AuditorUrls } from "../../../index";
import Option from "../../../../../Models/forms/Option";

interface AuditorSearchTableProps {
  readonly columns: Column<AuditorRow>[];
  readonly endpoint: string;
  readonly selectedFilters: SelectedFilters;
  readonly isPending: boolean;
}
const CoreAuditorSearchTable = ({
  columns,
  endpoint,
  selectedFilters,
  isPending,
}: AuditorSearchTableProps) => {
  const fetch = (
    params: DataTableQueryParams
  ): Promise<{ data: AuditorRow[]; totalPages: number }> =>
    axiosApi
      .get<SearchResponse<Auditor>>(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}${endpoint}`,
        { params }
      )
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultList.map((auditor) => ({
          Id: auditor.auditorNumber.toString(),
          archived: auditor.archived,
          auditorNumber: auditor.auditorNumber,
          name: auditor.name,
          status: auditor.archived ? Status.ARCHIVED : auditor.status,
          lastEditedDate: auditor.lastEditedDate,
          submittedDate: auditor.submittedDate,
          shared: auditor.shared,
        })),
      }));
  return (
    <DataTable<AuditorRow>
      fetchRemoteData={fetch}
      columns={columns}
      archiveFilter={{ enabled: !isPending }}
      toolBarOptions={{
        newEntityLink: {
          label: "Add new auditor",
          link: AuditorUrls.create(),
        },
      }}
      additionalFilters={Object.entries(selectedFilters).reduce<{
        [key: string]: string[];
      }>((acc, [key, options]) => {
        acc[key] = options.map((option: Option) => option.label);
        return acc;
      }, {})}
    />
  );
};

export default CoreAuditorSearchTable;
