import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import ContentPage from "../../../components/ContentPage";
import "./audit-new.scss";
import UpdateWarning from "./components/UpdateWarning";
import UpdateWarning2 from "./components/UpdateWarning2";
import PostAuditStatus from "./http/PostAuditStatus";
import postAudit, { PostAuditBody } from "./http/postAudit";
import UploadError from "./components/UploadError";
import UploadSuccess from "./components/UploadSuccess";
import getUploadStateConfig from "./util/getUploadStateConfig";
import Spinner from "../../../components/Spinner";
import FileDropZone from "../../../components/FileDropZone";
import { SubmitButton } from "../../../components/Button/button";
import { VerifyUrls } from "../index";
import Option from "../../../Models/forms/Option";
import { AuditUploadMessage } from "./constants";
import Box from "../../../components/Box";
import CompanySiteSelector, {
  CompanySiteOptionType,
} from "../../../components/CompanySiteSelector";
import CertificationBodySelector from "../../../components/CertificationBodySelector";
import { getUserRole, isBRCGSUser, isCBUser } from "../../../Authentication/Auth";

const AuditNew = ({
  match: {
    params: { id: auditId },
  },
  location: { state },
}: RouteComponentProps<{ id: string }, {}, { titleName: string }>) => {
  const isEditing = !!auditId;
  const [status, setStatus] = useState<PostAuditStatus | null>(null);
  const [certificationBody, setCertificationBody] = useState<Option | null>(
    null
  );
  const [messages, setMessages] = useState<string[]>([]);
  const [createdId, setCreatedId] = useState<number | null>(
    isEditing ? parseInt(auditId, 10) : null
  );
  const [isSubmitting, setSubmitting] = useState(false);

  const [auditOwner, setAuditOwner] = useState<Option<
    CompanySiteOptionType
  > | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const history = useHistory();

  const [programowner, setProgramOwner] = useState<string | null>(null);
  const [sitename, setSiteName] = useState<string | null>(null);
  const [address1, setAddress1] = useState<string | null>(null);
  const [address2, setAddress2] = useState<string | null>(null);
  const [address3, setAddress3] = useState<string | null>(null);
  const [sitecity, setSiteCity] = useState<string | null>(null);
  const [sitecountry, setSiteCountry] = useState<string | null>(null);
  const [auditauditor, setAuditAuditor] = useState<string | null>(null);
  const [auditauditorno, setAuditAuditorNo] = useState<string | null>(null);

  const urlSearchString = window.location.search;
  const paramSitecode = new URLSearchParams(urlSearchString);

  let siteCode=paramSitecode.get('SiteCode');
  //let siteCode='1896291';

  const postData: PostAuditBody = {
    id: createdId,
    auditOwner: auditOwner?.value,
    file,
    confirmed: false,
    overrideWarning: false,
    certificationBodyId: certificationBody?.value,
    siteCode: siteCode,
  };

  const confirmSubmit = () => {
    submitData({ ...postData, confirmed: true });
  };

  const confirmOverride = () => {
    submitData({ ...postData, confirmed: true, overrideWarning: true });
  };

  const onSubmit = () => {
    submitData(postData);
  };

  const submitData = (auditProperties: PostAuditBody) => {
    if (!auditProperties.file) return;
    setSubmitting(true);
    postAudit(auditProperties).then((response) => {
      setStatus(response.status);
      setMessages(response.messages ?? []);
      setProgramOwner(response.programOwner);
      setSiteName(response.siteName);
      setAddress1(response.address1);
      setAddress2(response.address2);
      setAddress3(response.address3);
      setSiteCity(response.siteCity);
      setSiteCountry(response.siteCountry);
      setAuditAuditor(response.auditorName);
      setAuditAuditorNo(response.auditorNo);
      setSubmitting(false);
      if (!isEditing) {
        setCreatedId(response.auditExternalId);
      }
    });
  };

  if (isSubmitting)
    return (
      <div className="audit-new__spinner-wrap">
        <Spinner />
      </div>
    );

  const endUpload = () => history.push(VerifyUrls.searchTable);

  const returnToDetails = () => history.push(VerifyUrls.details(auditId));

  const statusConfig = getUploadStateConfig(
    status,
    isEditing ? { titleName: state?.titleName, auditId } : null
  );
  const renderComponent = () => {
    switch (status) {
      case PostAuditStatus.Error:
      case PostAuditStatus.Validation:
      case PostAuditStatus.Virus:
        return (
          <UploadError
            endUploading={endUpload}
            returnUserToDetails={returnToDetails}
            isEditing={isEditing}
            messages={messages}
            closeError={() => {
              setStatus(null);
              setAuditOwner(null);
              setFile(null);
            }}
          />
        );
      case PostAuditStatus.Success:
      case PostAuditStatus.SuccessWithWarnings:
        console.log(postData.id);
        return (
          <UploadSuccess
            endUploading={endUpload}
            id={createdId}
            messages={messages}
            successSiteName={sitename}
            successOwner={programowner}
            successAddress1={address1}
            successAddress2={address2}
            successAddress3={address3}
            successSiteCity={sitecity}
            successSiteCountry={sitecountry}
            successAuditor={auditauditor}
            successAuditorNo={auditauditorno}
          />
        );
      default:
        return (
          <ValidatorForm onSubmit={onSubmit}>
            <FileDropZone
              filesLimit={1}
              files={file ? [file] : []}
              onChange={(newFile) => setFile(newFile[0])}
              dropzoneText={statusConfig.fileDropText}
            />
            <Box className="audit-new__audit-owner">
              {!isCBUser() && !isEditing && (
                <CertificationBodySelector
                  value={certificationBody}
                  onChange={setCertificationBody}
                  className="audit-new__audit-owner__cb"
                  required
                />
              )}
              <p>{AuditUploadMessage}</p>
              <CompanySiteSelector
                value={auditOwner}
                onChange={setAuditOwner}
                entities={["Company"]}
              />
            </Box>

            <SubmitButton text="Upload" />
          </ValidatorForm>
        );
    }
  };

  return (
    <ContentPage
      title={statusConfig.title}
      subtitle={statusConfig.subtitle}
      className="audit-new"
    >
      <UpdateWarning
        isOpen={status === PostAuditStatus.Confirm}
        close={() => setStatus(null)}
        onSubmit={confirmSubmit}
        messages={messages}
      />

      <UpdateWarning2
        isOpen={status === PostAuditStatus.OverrideWarning}
        close={() => setStatus(null)}
        onSubmit={confirmOverride}
        messages={messages}
      />
      {renderComponent()}
    </ContentPage>
  );
};

export default AuditNew;
