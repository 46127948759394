import React from "react";
import styles from "./InfoCard.module.scss";

interface InfoCardProps {
  readonly mainText?: string;
  readonly bulletPoints?: string[];
  readonly Message?: string[];
}

const InfoCard = ({
  mainText = "You can search by:",
  bulletPoints,
  Message,
}: InfoCardProps) => (
  <div className={styles.infoCard}>
    <p>{mainText}</p>
    {bulletPoints?.map((text, index) => (
      <p key={text}>
        <span className="dot">{index + 1}</span> {text}
      </p>
    ))}
    <p>{Message}</p>
  </div>
);

export default InfoCard;
