import React, { useContext } from "react";
import IconButton, { IconButtonProps } from "../../../IconButton";
import PermissionContext, {
  useUserPermission,
} from "../../../../routing/contexts/PermissionContext";
import Permission from "../../../../Authentication/model/Permission";
import PermissionArea from "../../../../Authentication/model/PermissionArea";

interface DataTableAddButtonProps extends IconButtonProps {
  readonly permissionTargets?: PermissionArea[];
}

const DataTableAddButton = ({
  label,
  link,
  type,
  state,
  permissionTargets,
}: DataTableAddButtonProps) => {
  const permissionContext = useContext(PermissionContext);
  let selectedPermissionTargets;
  if (permissionTargets) {
    selectedPermissionTargets = permissionTargets;
  } else {
    selectedPermissionTargets = permissionContext ? [permissionContext] : [];
  }
  const userPermissions = useUserPermission();
  const show =
    permissionTargets?.length === 0
      ? true
      : selectedPermissionTargets.some((target) => {
          if (!target) return false;
          return userPermissions[target].includes(Permission.GENERATE);
        });

  if (!show) return null;
  return <IconButton label={label} link={link} type={type} state={state} />;
};

export default DataTableAddButton;
