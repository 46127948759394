import { createMuiTheme } from "@material-ui/core";

export const CustomTooltips = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "10",
      },
    },
  },
});
