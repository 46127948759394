import React from "react";
import GenericRoutes from "../../routing/GenericRoutes";
import { privateArea } from "../../components/Paths/paths";
import CompanySelecting from "./TypeSelecting";
import GenericLinks from "../../routing/GenericLinks";
import CompanyDetails from "./CompanyDetails";
import PermissionArea from "../../Authentication/model/PermissionArea";
import CompanyNew from "./CompanyNew";
import CompanyUsers from "./CompanyUsers";
import CompanyEdit from "./CompanyEdit";

const companyManagement = `${privateArea}/company`;

class CompanyLinks extends GenericLinks {
  users(id: string | number) {
    return `${this.details(id)}/users`;
  }
}
export const CompanyUrls = new CompanyLinks(companyManagement);

const CompanyRouting = GenericRoutes({
  root: companyManagement,
  urls: CompanyUrls,
  entityType: "company",
  permissionArea: PermissionArea.COMPANY,
  detailsRoutes: [
    {
      path: "users",
      component: CompanyUsers,
      confirmOnLeave: false,
    },
  ],
  components: {
    createComponent: CompanyNew,
    editComponent: CompanyEdit,
    detailsComponent: CompanyDetails,
    searchComponent: CompanySelecting,
  },
});

const CompanyManagement = () => <CompanyRouting />;

export default CompanyManagement;
