import React from "react";
import MultipleSelectDropdown from "../MultipleSelectDropdown";
import useDebouncedOptionSearch from "../DebouncedSearchSelector/utils/useDebouncedOptionSearch";
import Option from "../../Models/forms/Option";
import { DebouncedSearchSelectorCoreProps } from "../DebouncedSearchSelector";

const DebouncedMultipleSelector = <T extends any = string>({
  label,
  getOptions,
  value,
  sorter,
  groupBy,
  onSelect,
}: DebouncedSearchSelectorCoreProps<T> & {
  readonly value: Option<T>[];
  readonly onSelect: (value: Option<T>[]) => void;
}) => {
  const {
    searchResult,
    initialSearchPerformed,
    isLoading,
    setSearchTerm,
  } = useDebouncedOptionSearch(getOptions, value, sorter, groupBy);
  return (
    <MultipleSelectDropdown
      label={label}
      value={value ?? []}
      options={searchResult}
      onChange={onSelect}
      onTextChange={(val) => setSearchTerm(val)}
      placeholder="Start typing to search"
      noOptionsText={
        initialSearchPerformed
          ? "No options - try changing the search input"
          : "No options yet - type two characters minimum to search"
      }
      isLoadingOptions={isLoading}
    />
  );
};

export default DebouncedMultipleSelector;
