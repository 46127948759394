import React, { PropsWithChildren, useState } from "react";
import clsx from "clsx";
import styles from "./EditButtons.module.scss";
import IconButton from "../IconButton";
import { IconType } from "../Icon";
import { usePermissionContext } from "../../routing/contexts/PermissionContext";
import Permission from "../../Authentication/model/Permission";

interface EditButtonProps {
  readonly isEditing: boolean;
  readonly setEditing: (status: boolean) => void;
  readonly submit: () => Promise<any> | undefined;
  readonly onComplete: () => void;
  readonly direction?: "left" | "right";
  readonly className?: string;
}

const EditButtons = ({
  setEditing,
  isEditing,
  submit,
  onComplete,
  direction = "right",
  className,
}: EditButtonProps) => {
  const hasEditPermissions = usePermissionContext().includes(Permission.REVISE);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  if (!hasEditPermissions) return null;
  const Wrap = ({ children }: PropsWithChildren<{}>) => (
    <div
      className={clsx(styles.editButtons, className, {
        [styles.toLeft]: direction === "left",
      })}
    >
      {children}
    </div>
  );

  if (error) {
    return <Wrap>Could not save changes</Wrap>;
  }

  if (isLoading) {
    return <Wrap>Editing...</Wrap>;
  }

  return (
    <Wrap>
      {isEditing ? (
        <>
          <IconButton
            type={IconType.CLOSE}
            label="Close"
            onClick={() => setEditing(false)}
          />
          <IconButton
            type={IconType.SAVE}
            label="Save"
            onClick={() => {
              const request = submit();
              if (request) {
                setLoading(true);
                setError(false);
                request
                  .then(onComplete)
                  .catch(() => setError(true))
                  .finally(() => setLoading(false));
              }
            }}
          />
        </>
      ) : (
        <IconButton
          type={IconType.EDIT}
          label="Edit"
          onClick={() => setEditing(true)}
        />
      )}
    </Wrap>
  );
};

export default EditButtons;
