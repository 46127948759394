export interface ReportTemplate {
  readonly name: string;
  readonly csvUrl?: string;
  readonly xlsUrl?: string;
  readonly filters: ReportFilter[];
}

export type ReportFilter =
  | "companyIDs"
  | "programOwners"
  | "standards"
  | "categories"
  | "countryIds"
  | "countryNames"
  | "certificationBodyIds"
  | "certificationBodyNames"
  | "sites"
  | "expiryDate"
  | "auditStart"
  | "certificationStatuses"
  | "archived"
  | "severity"
  | "clause"
  | "organisation"
  | "auditor"
  | "auditorStatus"
  | "roles"
  | "services"
  | "action"
  | "actionDate"
  | "submissionDate"
  | "modificationDate"
  | "recordStatus"
  | "privateSiteCode"
  | "recallStatusIds"
  | "dateOfNotification"
  | "dateOfRecall"
  | "HaviSiteCode";

const templates: ReportTemplate[] = [
  {
    name: "Company",
    csvUrl: "GetCompanyReport",
    xlsUrl: "GetCompanyReportXls",
    filters: [
      "companyIDs",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Certification Status",
    csvUrl: "GetCertificationStatusReport",
    xlsUrl: "GetCertificationStatusReportXls",
    filters: [
      "programOwners",
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "submissionDate",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Non Conformities",
    csvUrl: "GetNonConformitiesReport",
    xlsUrl: "GetNonConformitiesReportXls",
    filters: [
      "programOwners",
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "auditStart",
      "severity",
      "clause",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Sharing",
    csvUrl: "GetAuditSharingReport",
    xlsUrl: "GetAuditSharingReportXls",
    filters: [
      "programOwners",
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "organisation",
      "recordStatus",
    ],
  },
  {
    name: "Auditor profile",
    csvUrl: "GetAuditorProfileReport",
    xlsUrl: "GetAuditorProfileReportXls",
    filters: [
      "certificationBodyIds",
      "auditor",
      "countryIds",
      "auditorStatus",
      "standards",
    ],
  },
  {
    name: "Contacts",
    csvUrl: "GetAuditContactReport",
    xlsUrl: "GetAuditContactReportXls",
    filters: [
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
    ],
  },
  {
    name: "Scope",
    csvUrl: "GetScopeReport",
    xlsUrl: "GetScopeReportXls",
    filters: [
      "programOwners",
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Audit History",
    csvUrl: "GetAuditHistoryReport",
    xlsUrl: "GetAuditHistoryReportXls",
    filters: [
      "programOwners",
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Audit duration",
    csvUrl: "GetAuditDurationReport",
    xlsUrl: "GetAuditDurationReportXls",
    filters: [
      "programOwners",
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Agents and brokers",
    csvUrl: "GetAgentsAndBrokersReport",
    xlsUrl: "GetAgentsAndBrokersReportAsXls",
    filters: [
      "categories",
      "countryIds",
      "certificationBodyIds",
      "roles",
      "services",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Additional Modules",
    csvUrl: "GetAdditionalModulesReport",
    xlsUrl: "GetAdditionalModulesReportXls",
    filters: [
      "programOwners",
      "standards",
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
      "submissionDate",
    ],
  },
  {
    name: "Gluten Free",
    csvUrl: "GetGlutenFreeReport",
    xlsUrl: "GetGlutenFreeReportXls",
    filters: [
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "ETRS",
    csvUrl: "GetETRSReport",
    xlsUrl: "GetETRSReportXls",
    filters: [
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Risk Assessment",
    csvUrl: "GetRiskAssessmentReport",
    xlsUrl: "GetRiskAssessmentReportXls",
    filters: [
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Audit Schedule",
    csvUrl: "GetAuditScheduleReport",
    xlsUrl: "GetAuditScheduleReportXls",
    filters: [
      "programOwners",
      "standards",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "action",
      "actionDate",
    ],
  },
  {
    name: "Submission Date",
    csvUrl: "GetSubmissionDateReport",
    xlsUrl: "GetSubmissionDateReportXls",
    filters: [
      "programOwners",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "submissionDate",
      "certificationStatuses",
    ],
  },
  {
    name: "Plant Based",
    csvUrl: "GetPlantBasedReport",
    xlsUrl: "GetPlantBasedReportXls",
    filters: [
      "countryIds",
      "certificationBodyIds",
      "sites",
      "expiryDate",
      "auditStart",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Specifier Reporting",
    csvUrl: "GetSpecifierReportingReport",
    xlsUrl: "GetSpecifierreportingReportXls",
    filters: [
      "categories",
      "countryIds",
      "certificationBodyIds",
      "sites",
      "privateSiteCode",
      "auditStart",
      "expiryDate",
      "submissionDate",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Recall",
    csvUrl: "GetRecallReport",
    xlsUrl: "GetRecallReportXls",
    filters: [
      "sites",
      "certificationBodyIds",
      "recallStatusIds",
      "dateOfNotification",
      "dateOfRecall",
    ],
  },
  {
    name: "HAVI GQS",
    csvUrl: "GetHAVIReport",
    xlsUrl: "GetHAVIReportXls",
    filters: [
      "countryIds",
      "certificationBodyIds",
      "HaviSiteCode",
      "sites",
      "expiryDate",
      "auditStart",
      "submissionDate",
      "certificationStatuses",
      "recordStatus",
    ],
  },
  {
    name: "Compliance",
    csvUrl: "GetComplianceReport",
    xlsUrl: "GetComplianceReportXls",
    filters: [
      "certificationBodyIds",
      "standards",
      "categories",
      "countryIds",
      "sites",
      "auditor",
      "submissionDate",
      "auditStart",
      "expiryDate",
      "recordStatus",
      "certificationStatuses",
    ],
  },
  {
    name: "Audit Status",
    csvUrl: "GetAuditStatusReport",
    xlsUrl: "GetAuditStatusReportXls",
    filters: ["certificationBodyIds", "submissionDate"],
  },
];

export default templates;
