import React from "react";
import { Rating } from "@material-ui/lab";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import "./star-rating.scss";

interface StarRatingProps {
  readonly value?: number;
  readonly size?: "small" | "medium" | "large";
}

const StarRating = ({ size = "medium", value }: StarRatingProps) => {
  return (
    <Rating
      className="star-rating"
      value={value}
      size={size}
      emptyIcon={<StarBorderIcon fontSize="inherit" />}
      readOnly
    />
  );
};

export default StarRating;
