import { AxiosResponse } from "axios";
import AuditTabbedDetailsSource, {
  AuditField,
  AuditSectionSource,
} from "../models/AuditTabbedDetailsSource";
import AuditTab, {
  AuditInfoBox,
  AuditSection,
  AuditTable,
  AuditTableType,
} from "../models/AuditTabs";
import sortNameAlphanumerical from "../../../../util/sorting/sortNameAlphanumerical";

const shortid = require("shortid");

const sortSections = (a: AuditSectionSource, b: AuditSectionSource) =>
  a.fields[0]?.order - b.fields[0]?.order;

function mapSection({ fields, name }: AuditSectionSource): AuditSection {
  const [infoboxData, tables] = fields.reduce<AuditField[][]>(
    ([infobox, _tables], curr) => {
      if (curr.type === "Table" || curr.type === "Accordion") {
        return [infobox, [..._tables, curr]];
      }
      return [[...infobox, curr], _tables];
    },
    [[], []]
  );
  const infobox = mapInfobox(infoboxData);
  return {
    id: shortid.generate(),
    name,
    content: [
      infobox,
      ...tables
        .filter((table) => table.type === "Table" || table.type === "Accordion")
        .map(mapTable),
    ],
  };
}

const createID = (): string => shortid.generate();

export default (result: AxiosResponse<AuditTabbedDetailsSource>): AuditTab[] =>
  result.data.tabs
    .map((tab) => ({
      id: createID(),
      name:
        (tab.name === "undefined" ? null : tab.name) ??
        "Additional information",
      sections: tab.sections.sort(sortSections).map(mapSection),
    }))
    .sort(sortNameAlphanumerical);

const mapInfobox = (fields: AuditField[]): AuditInfoBox => ({
  id: createID(),
  type: "text",
  entries: fields.map(({ xmlTag, data, label, order, type }) => {
    return {
      id: createID(),
      xmlTag,
      data: data ?? "",
      label,
      order,
      type,
    };
  }),
});

const mapTable = (table: AuditField): AuditTable => {
  const columns = table.columns ?? [];
  const data = table.columnData ?? [];
  return {
    id: createID(),
    type: table.type as AuditTableType,
    xmlTag: table.xmlTag,
    columns: columns.map((column) => ({
      id: createID(),
      ...column,
    })),
    rows: data.map((row) => ({
      id: shortid.generate(),
      cells: row.map((cell, index) => {
        return {
          id: createID(),
          ...columns[index],
          data: cell,
        };
      }),
    })),
    label: table.label,
  };
};
