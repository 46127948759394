import Option from "../../../../Models/forms/Option";

export default (): Option[] =>
  [
    "Storage",
    "Distribution",
    "Importation",
    "Export",
    "Product Development",
    "Product Testing",
    "Repacking",
    "Further Processing",
    "Other",
  ].map((option) => ({ label: option, value: option }));
