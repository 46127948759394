import React, { PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import TabsBar from "../../../components/Common/Tab";
import { AdminPageProps, AdminPages } from "../index";
import { PATH_ADMINISTRATION } from "../../../components/Paths/paths";

const AdminPage = ({ children, index }: PropsWithChildren<AdminPageProps>) => {
  const history = useHistory();
  return (
    <div>
      <TabsBar
        value={index}
        onChange={(value) => {
          history.push(`${PATH_ADMINISTRATION}/${AdminPages[value]?.path}`);
        }}
        labels={AdminPages.map((page) => page.name)}
        centered={false}
      />
      {children}
    </div>
  );
};

export default AdminPage;
