import { useEffect, useState } from "react";
import UserDetails from "../../models/UserDetails";
import UserRole from "../../../../Models/user/UserRole";
import { UserParentIds } from "../index";
import getRoles from "../../../../http/user/getRoles";

export default (
  userData: UserDetails | null,
  parentIds: UserParentIds,
  isCompanyCreation: boolean
) => {
  const { companyId, siteId } = parentIds;
  const [isLoading, setLoading] = useState(true);
  const [roles, setRoles] = useState<string[]>([]);

  const isPreset = isCompanyCreation || !!companyId || !!siteId;
  const [role, setRole] = useState(
    (() => {
      if (isCompanyCreation || !!companyId) return UserRole.COMPANY_USER;
      if (siteId) return UserRole.SITE_USER;
      if (userData) return userData.roleName;
      return "";
    })()
  );

  useEffect(() => {
    getRoles()
      .then(({ data }) => setRoles(data))
      .finally(() => setLoading(false));
  }, []);

  return { isLoading, roles, role, setRole, isPreset };
};
