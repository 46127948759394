import { suspensionType } from "../../../../Enums/SuspentionType";
import { auditSubmissionType } from "../../../../Enums/AuditSubmissions";

export interface SuspendWindowState {
  type: suspensionType;
  visible: boolean;
  auditSubmissions: auditSubmissionType;
}

export const emptySuspendWindow: SuspendWindowState = {
  type: suspensionType.FULL,
  visible: true,
  auditSubmissions: auditSubmissionType.ALLOW,
};
