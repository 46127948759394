import React from "react";
import GetSharableResponse from "../../models/GetSharableResponse";
import SharingType from "../../models/SharingType";
import DataTable, {
  DataTableQueryParams,
} from "../../../../components/DataTable";
import shareColumns from "./util/shareColumns";
import ShareRow from "./util/ShareRow";
import axiosApi from "../../../../components/Api/Axios";
import getShareRootUrl from "../../http/getShareRootUrl";
import ShareTypeFilters from "../../utils/ShareTypeFilters";

export interface ShareProps {
  readonly id: string;
  readonly type: SharingType;
  readonly setShowConfirmation: (data: {
    shared: boolean;
    name: string;
  }) => void;
}

const Share = ({ id, type, setShowConfirmation }: ShareProps) => {
  const shareTypeFilters = ShareTypeFilters(type);

  const fetch = (
    params: DataTableQueryParams
  ): Promise<{
    data: ShareRow[];
    totalPages: number;
  }> => {
    const { searchTerm, pageNumber, typeName } = params;
    const types = Array.isArray(typeName) ? typeName : [typeName];
    return axiosApi
      .get<GetSharableResponse>(
        `${getShareRootUrl(type)}/searchEntitiesToShareWith/${id}`,
        { params: { query: searchTerm, pageNumber, types } }
      )
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.results.map(
          (target): ShareRow => ({
            id: target.id,
            name: target.name,
            typeName: target.typeName,
            type: target.type,
            country: target.location.country?.name ?? "",
            shared: target.isShared,
            address: target.location.addressLine1,
          })
        ),
      }));
  };

  return (
    <DataTable<ShareRow>
      columns={shareColumns(id, type, setShowConfirmation, false)}
      fetchRemoteData={fetch}
      filters={
        Object.keys(shareTypeFilters).length > 0
          ? { typeName: () => shareTypeFilters }
          : undefined
      }
    />
  );
};

export default Share;
