import React from "react";
import { Filter } from "../../../components/FilterGroup";
import SearchFilter from "../../../components/FilterGroup/components/SearchFilter";
import { PublicFilters } from "../../../store/public-directory";
import useCountryNames from "../filter-values/useCountryNames";
import useStandardNames from "../filter-values/useStandardNames";
import useCategoryNames from "../filter-values/useCategoryNames";
import RadioFilter from "../../../components/FilterGroup/components/RadioFilter";
import SiteGrade from "../models/Grade";
import StarRating from "../../../components/StarRating/StarRating";
import useAdditionalModuleNames from "../filter-values/useAdditionalModuleNames";
import Option from "../../../Models/forms/Option";

const grades = Object.values(SiteGrade)
  .reverse()
  .map((grade): Option => ({ value: grade, label: grade }));

export type PublicFilter =
  | "Standard"
  | "Category"
  | "Grade"
  | "AdditionalModules"
  | "Country"
  | "Rating";

export default (
  selectedFilterValues: PublicFilters
): Filter<PublicFilter>[] => {
  const countries = useCountryNames().map((country) => ({
    label: country,
    value: country,
  }));
  const standards = useStandardNames();
  const categories = useCategoryNames(selectedFilterValues.Standard);
  const modules = useAdditionalModuleNames();

  return [
    {
      name: "Country",
      title: "Country",
      selected: selectedFilterValues.Country,
      options: countries,
      component: SearchFilter,
    },

    {
      name: "Grade",
      options: grades,
      selected: selectedFilterValues.Grade,
      title: "Grade",
    },
    {
      name: "AdditionalModules",
      options: modules,
      selected: selectedFilterValues.AdditionalModules,
      title: "Additional Module",
    },
    {
      name: "Standard",
      options: standards,
      selected: selectedFilterValues.Standard,
      title: "Standard",
      component: RadioFilter,
    },
    {
      name: "Rating",
      options: new Array(6).fill(0).map(
        (_, index): Option => {
          const value = `${5 - index}`;
          return {
            value,
            label: value,
          };
        }
      ),
      selected: selectedFilterValues.Rating,
      title: "Rating",
      renderOption: (option) => (
        <StarRating size="small" value={parseInt(option.value as string, 10)} />
      ),
    },
    {
      name: "Category",
      options: categories,
      selected: selectedFilterValues.Category,
      title: "Category",
      enabled: selectedFilterValues.Standard.length > 0,
    },
  ];
};
