import Standard from "../common/Standard";

export default interface AuditExpiryReasonFormState {
  expiryReason: string;
  comments: string;
  assessmentDate?: Date;
  site: { id: number; name: string };
  standard: Standard;
  auditId: string;
  fromAuditPage: boolean;
}

export const expiryDefaultState: AuditExpiryReasonFormState = {
  expiryReason: "",
  comments: "",
  site: { id: 0, name: "" },
  standard: { id: 0, name: "" },
  auditId: "",
  fromAuditPage: false,
};
