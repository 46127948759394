import Option from "../forms/Option";

export enum ReasonForNotification {
  PRODUCT_RECALL = "Product Recall",
  WITHDRAWAL = "Withdrawal",
  REGULATORYNOTICE = "Regulatory notice",
  OTHER = "Other",
}

export const ReasonForNotificationOption: Option[] = [
  {
    value: ReasonForNotification.WITHDRAWAL,
    label: ReasonForNotification.WITHDRAWAL,
  },
  {
    value: ReasonForNotification.REGULATORYNOTICE,
    label: ReasonForNotification.REGULATORYNOTICE,
  },
  {
    value: ReasonForNotification.OTHER,
    label: ReasonForNotification.OTHER,
  },
  {
    value: ReasonForNotification.PRODUCT_RECALL,
    label: ReasonForNotification.PRODUCT_RECALL,
  },
];
