import React, { useState } from "react";
import "./accordion-table.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import EditableText from "../../EditableFields/EditableText";
import { AuditEntry } from "../../../models/AuditTabs";
import { CoreTableChildProps } from "../CoreTable";

interface AccordionTableProps extends CoreTableChildProps {
  readonly visible: AuditEntry[];
  readonly hidden: AuditEntry[];
  readonly rowId: string;
}

const AccordionRow = ({
  visible,
  hidden,
  rowId,
  updateRow,
  deleteRow,
  isEditing,
}: AccordionTableProps) => {
  const [expanded, setExpanded] = useState(false);

  const parseEntryList = (entryList: AuditEntry[]) =>
    entryList.map(({ id, label, data, type, xmlTag }) => (
      <li key={id}>
        <p>{label}</p>
        <EditableText
          isEditing={isEditing}
          type={type}
          xmlTag={xmlTag}
          value={data ?? ""}
          setUpdateValue={(_xmlTag, _value) =>
            updateRow(rowId, _xmlTag, _value)
          }
        />
      </li>
    ));

  const isExpandable = hidden.length > 0;

  return (
    <div className={`accordion-table ${expanded ? " expanded" : ""}`}>
      <div className="accordion-table__content">
        <Accordion
          expanded={expanded}
          onChange={() => {
            if (isExpandable) setExpanded(!expanded);
          }}
        >
          <AccordionSummary
            className={
              isExpandable ? "" : "accordion-table__content__no-expand"
            }
            expandIcon={isExpandable ? <ExpandIcon /> : null}
          >
            <ul className="visible">{parseEntryList(visible)}</ul>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="hidden">{parseEntryList(hidden)}</ul>
          </AccordionDetails>
        </Accordion>
      </div>

      {isEditing && (
        <IconButton
          className="accordion-table__delete-row"
          onClick={() => deleteRow(rowId)}
        >
          <RemoveIcon />
        </IconButton>
      )}
    </div>
  );
};

export default AccordionRow;
