import React from "react";
import { Pagination as MaterialPagination } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

interface PaginationProps {
  readonly onChange: (e: React.ChangeEvent<unknown>, p: number) => void;
  readonly page: number;
  readonly pageCount: number;
}

export default function Pagination({
  pageCount,
  page,
  onChange,
}: PaginationProps) {
  return (
    <Grid
      style={{ paddingBottom: "20px" }}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <MaterialPagination
        className="pagination"
        variant="outlined"
        color="primary"
        count={pageCount}
        page={page}
        onChange={(e: React.ChangeEvent<unknown>, p: number) => onChange(e, p)}
      />
    </Grid>
  );
}
