export const ACTION_SET_SEARCH_TERM = "ACTION_SET_SEARCH_TERM";

export interface ActionSetSearchTerm {
  readonly type: typeof ACTION_SET_SEARCH_TERM;
  readonly payload: string;
}

export default (searchTerm: string): ActionSetSearchTerm => ({
  type: ACTION_SET_SEARCH_TERM,
  payload: searchTerm,
});
