import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

export default <T extends Object>(get: () => Promise<AxiosResponse<T>>) => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetch = () => {
    setLoading(true);
    setError(false);
    get()
      .then(({ data: responseData }) => setData(responseData))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(fetch, []);

  return { data, isLoading, error, refresh: fetch };
};
