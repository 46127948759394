export enum ContactType {
  Default,
  General = "General",
  Technical = "Technical",
  Commercial = "Commercial",
}

export default interface Contact {
  readonly type?: ContactType;
  readonly id?: number;
  readonly email: string;
  readonly phoneNumber?: string;
  readonly faxNumber?: string;
  readonly name?: string;
}

export const defaultContact: Contact = {
  type: ContactType.General,
  email: "",
};
