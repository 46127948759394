import React from "react";
import MainButton from "../../../../../components/Button/button";
import { LinkText } from "../../../../../components/Common/hyperlinks";
import { AuditUrls } from "../../../index";

interface UploadSuccess {
  readonly endUploading: () => void;
  readonly id: number | null;
  readonly messages: string[];
  readonly successSiteName: string | null;
  readonly successOwner: string | number | null | undefined;
  readonly successAddress1: string | null;
  readonly successAddress2: string | null;
  readonly successAddress3: string | null;
  readonly successSiteCity: string | null;
  readonly successSiteCountry: string | null;
  readonly successAuditor: string | null;
  readonly successAuditorNo: string | null;
}

const UploadSuccess = ({
  endUploading,
  id,
  messages,
  successSiteName,
  successOwner,
  successAddress1,
  successAddress2,
  successAddress3,
  successSiteCity,
  successSiteCountry,
  successAuditor,
  successAuditorNo,
}: UploadSuccess) => (
  <div className="audit-new__result">
    <h1>Record has been successfully uploaded</h1>
    <span id="sitename">
      <table>
        <tr>
          <td>Audit assigned to</td>
          <td />
        </tr>
        <tr>
          <td>Auditor:</td>
          <td>{successAuditor}</td>
        </tr>
        <tr>
          <td>Auditor Number:</td>
          <td>{successAuditorNo}</td>
        </tr>
        <tr>
          <td>Company:</td>
          <td>{successOwner}</td>
        </tr>
        <tr>
          <td>Site Name:</td>
          <td>{successSiteName}</td>
        </tr>
        <tr>
          <td>Site Address:</td>
          <td>{successAddress1}</td>
        </tr>
        <tr>
          <td />
          <td>{successAddress2}</td>
        </tr>
        <tr>
          <td />
          <td>{successAddress3}</td>
        </tr>
        <tr>
          <td />
          <td>{successSiteCity}</td>
        </tr>
        <tr>
          <td />
          <td>{successSiteCountry}</td>
        </tr>
      </table>
    </span>
    {messages.length > 0 && (
      <ul>
        {messages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    )}
    <MainButton text="Return to audit management" onClick={endUploading} />
    {id && (
      <>
        <LinkText link={AuditUrls.details(id)} text="View Audit Record" />
        <LinkText
          link={AuditUrls.uploadDocument(id)}
          text="Upload additional documents"
        />
      </>
    )}
  </div>
);

export default UploadSuccess;
