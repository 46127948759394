enum SiteGrade {
  UNGRADED = "No Grade",
  D = "D",
  DPlus = "D+",
  C = "C",
  CPlus = "C+",
  B = "B",
  BPlus = "B+",
  A = "A",
  APlus = "A+",
  AA = "AA",
  AAPlus = "AA+",
}

export default SiteGrade;
