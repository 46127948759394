import { DefaultThunkAction } from "../../reducers";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { PublicFilters } from "../index";
import SearchResponses from "../../../Models/APIGetData/SearchResponses";
import PublicSiteSearch from "../../../views/PublicDirectory/models/Site/PublicSiteSearch";
import SiteSearchParam from "../../../views/PublicDirectory/models/Site/SiteSearchParam";
import formatDate from "../../../views/PublicDirectory/utils/formatDate";
import PublicCBSearch from "../../../views/PublicDirectory/models/CB/PublicCBSearch";
import { PublicTab } from "../../../views/PublicDirectory/utils/TabConfigs";

export const ACTION_GET_ITEM = "ACTION_GET_ITEM";
export const ACTION_GET_ITEM_LOADING = "ACTION_GET_ITEM_LOADING";

export interface ActionGetItems {
  readonly type: typeof ACTION_GET_ITEM;
  readonly payload: {
    readonly data: SearchResponses<PublicSearchItem>;
    readonly tab: PublicTab;
  };
}

export interface ActionGetSitesLoading {
  readonly type: typeof ACTION_GET_ITEM_LOADING;
}

export interface PublicMetaFilters {
  readonly searchTerm: string;
  readonly pageNumber: number;
  readonly order: "asc" | "desc";
}

export interface PublicSearchItem {
  readonly key: number;
  readonly id: number;
  readonly name: string;
  readonly standard: string[];
  readonly rating?: number;
  readonly openLink: string;
  readonly additional: { label: string; value: string }[];
}

const getData = <T>(
  url: string,
  mapping: (val: T) => PublicSearchItem,
  tab: PublicTab,
  metaFilters: PublicMetaFilters,
  selectedFilters: PublicFilters
): DefaultThunkAction => async (dispatch) => {
  const params: Partial<SiteSearchParam> = {
    ...Object.keys(selectedFilters).reduce<{ [key: string]: string[] }>(
      (acc, curr) => {
        acc[curr] = selectedFilters[curr as keyof PublicFilters].map(
          (option) => `${option.label}`
        );
        return acc;
      },
      {}
    ),
    ...metaFilters,
  };
  dispatch({ type: ACTION_GET_ITEM_LOADING });
  axiosApi
    .get<SearchResponses<T>>(url, {
      params,
    })
    .then(({ data: responseData }) => {
      const data: SearchResponses<PublicSearchItem> = {
        totalPages: responseData.totalPages,
        resultsList: responseData.resultsList.map(mapping),
      };
      return dispatch({ type: ACTION_GET_ITEM, payload: { data, tab } });
    });
};

export const getPublicSites = (
  metaFilters: PublicMetaFilters,
  selectedFilters: PublicFilters
) =>
  getData<PublicSiteSearch>(
    `${BackendServices.PUBLIC_SERVICE.PUBLIC_DIRECTORY}/site`,
    (value: PublicSiteSearch): PublicSearchItem => {
      return {
        key: value.auditId,
        name: value.name,
        openLink: `/site/${value.id}`,
        id: value.id,
        standard: [value.auditDetails.standard],
        additional: [
          {
            label: "Country: ",
            value: value.location?.country,
          },
          { label: "Region/State: ", value: value.location.county },
          { label: "Grade: ", value: value.grade },
          {
            label: "Expiry: ",
            value: formatDate(value.auditDetails.expiryDate),
          },
        ],
      };
    },
    PublicTab.SITE,
    metaFilters,
    selectedFilters
  );

export const getPublicCBs = (
  metaFilters: PublicMetaFilters,
  selectedFilters: PublicFilters
) =>
  getData<PublicCBSearch>(
    `${BackendServices.PUBLIC_SERVICE.PUBLIC_DIRECTORY}/CertificationBody`,
    (value: PublicCBSearch): PublicSearchItem => {
      return {
        key: value.externalId,
        standard: value.standards,
        id: value.externalId,
        openLink: `/office/${value.externalId}`,
        name: value.name,
        rating: value.rating,
        additional: [
          { label: "Country: ", value: value.location.country },
          { label: "City: ", value: value.location.city },
        ],
      };
    },
    PublicTab.CB,
    metaFilters,
    selectedFilters
  );
