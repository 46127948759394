import Option from "../../../../Models/forms/Option";
import { MinifiedOrganisationType } from "../constants";

function removeBadChars(str: string) {
  for (let index = 0; index < str.length; index += 1) {
    if (str[index].match(/[0-9a-zA-Z]/i)) {
      return str.slice(index);
    }
  }
  return str;
}

export default function sortPrimaryOrgs(
  orgs: Option<MinifiedOrganisationType>[]
): Option<MinifiedOrganisationType>[] {
  return orgs
    .sort(
      (a, b) => -removeBadChars(b.label).localeCompare(removeBadChars(a.label))
    )
    .sort((a, b) => (a.type ?? -1) - (b.type ?? -1));
}
