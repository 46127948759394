import { Grid, IconButton } from "@material-ui/core";
import React from "react";
import { AddCircle, Clear } from "@material-ui/icons";
import AuditorQualification, {
  QualificationType,
} from "../models/AuditorQualification";
import { AuditorStandard } from "../../../../views/AuditorManagement/AuditorDetails/models/AuditorDetails";
import QualificationSelector from "./QualificationSelector";
import Standard from "../../../../Models/common/Standard";
import SelectField from "../../../SelectField";
import Option from "../../../../Models/forms/Option";

interface StandardProps {
  readonly standard: AuditorStandard;
  readonly standardOptions: Standard[];
  readonly qualificationOptions: AuditorQualification[];
  readonly isEditAfterValidation: boolean;
  readonly deleteQualification: (qualificationId: number) => void;
  readonly suspendQualification: (qualificationId: number) => void;
  readonly unsuspendQualification: (qualificationId: number) => void;
  readonly removeStandard: () => void;
  readonly updateStandard: (next: AuditorStandard) => void;
}

interface Entity {
  readonly entityType: QualificationType;
  readonly entityId: number;
}
export const isSameQualification = (a: Entity, b: Entity) =>
  a.entityId === b.entityId && a.entityType === b.entityType;

const StandardSelector = ({
  standard,
  standardOptions,
  qualificationOptions,
  deleteQualification,
  suspendQualification,
  unsuspendQualification,
  isEditAfterValidation,
  removeStandard,
  updateStandard,
}: StandardProps) => {
  const { qualifications = [] } = standard;

  const groupedQualificationOptions: {
    readonly groupType: QualificationType;
    readonly qualifications: AuditorQualification[];
  }[] = Object.values(QualificationType)
    .map((groupType) => ({
      groupType,
      qualifications: qualificationOptions.filter(
        (q) => q.entityType === groupType
      ),
    }))
    .filter((group) => group.qualifications.length > 0);

  const currentStandardOption: Option = {
    value: standard.id,
    label: standard.name,
  };
  return (
    <>
      <Grid key={standard.id} className="auditor-standard-section" item xs={12}>
        <SelectField
          name="Standard"
          label="Select standard"
          value={currentStandardOption}
          onChange={(option) => {
            const nextStandard = standardOptions.find(
              (s) => s.id === option?.value
            );
            if (!nextStandard) return;
            updateStandard({ ...nextStandard, qualifications: [] });
          }}
          options={[
            ...standardOptions.map((s) => ({ value: s.id, label: s.name })),
            { label: standard.name, value: standard.id },
          ]}
          required
        />
        {qualifications.length === 0 && (
          <Clear className="clickableClear" onClick={removeStandard} />
        )}
      </Grid>

      {groupedQualificationOptions.map((qualificationGroup) => {
        const availableQualificationOptions = qualificationGroup.qualifications.filter(
          (c) => !qualifications.some((q) => isSameQualification(q, c))
        );
        return (
          <div key={qualificationGroup.groupType}>
            {qualifications
              .filter((q) => q.entityType === qualificationGroup.groupType)
              .map((qualification: AuditorQualification) => {
                const updateQualification = (
                  next: Partial<AuditorQualification>
                ) =>
                  updateStandard({
                    ...standard,
                    qualifications: standard.qualifications.map((q) =>
                      isSameQualification(q, qualification)
                        ? { ...qualification, ...next }
                        : q
                    ),
                  });

                return (
                  <QualificationSelector
                    key={qualification.entityType + qualification.entityId}
                    groupType={qualificationGroup.groupType}
                    qualification={qualification}
                    availableQualificationOptions={
                      availableQualificationOptions
                    }
                    updateQualification={updateQualification}
                    deleteQualification={deleteQualification}
                    suspendQualification={suspendQualification}
                    unsuspendQualification={unsuspendQualification}
                    removeQualification={() =>
                      updateStandard({
                        ...standard,
                        qualifications: qualifications.filter((q) => {
                          return !isSameQualification(q, qualification);
                        }),
                      })
                    }
                    isEditAfterValidation={isEditAfterValidation}
                  />
                );
              })}
            {availableQualificationOptions.length > 0 && (
              <IconButton
                onClick={() => {
                  const next = availableQualificationOptions[0];
                  if (!next) return;
                  updateStandard({
                    ...standard,
                    qualifications: [...standard.qualifications, next],
                  });
                }}
              >
                <AddCircle className="add" />
                <span className="add">Add {qualificationGroup.groupType}</span>
              </IconButton>
            )}
          </div>
        );
      })}
    </>
  );
};
export default StandardSelector;
