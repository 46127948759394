import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import React from "react";
import "../../notifications.scss";

interface NotificationLabelProps {
  label: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  id: number;
}

const AuditSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#EA7600",
    },
    "&$checked + $track": {
      backgroundColor: "#EA7600",
    },
  },
  checked: {},
  track: {},
})(Switch);

export function NotificationLabel(props: NotificationLabelProps) {
  const { label, handleChange, checked, id } = props;

  return (
    <FormControlLabel
      control={
        <AuditSwitch checked={checked} onChange={handleChange} id={`${id}`} />
      }
      label={label}
      labelPlacement="start"
    />
  );
}
