import useGetData from "../../../../http/useGetData";
import getUserDetails from "../../../../http/user/getUserDetails";
import UserDetails from "../../models/UserDetails";

export default (id: string) => {
  const { isLoading, data, refresh } = useGetData<UserDetails>(() =>
    getUserDetails(id)
  );

  return { isLoading, user: data, refresh };
};
