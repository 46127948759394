import { Configuration } from "msal";

const authority = `${process.env.REACT_APP_AUTH_INSTANCE}${process.env.REACT_APP_AUTH_TENANT}/${process.env.REACT_APP_AUTH_POLICY}/`;
const redirectUri = process.env.REACT_APP_AUTH_REDIRECT;

const config: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_APPLICATION as string,
    authority,
    redirectUri,
    validateAuthority: false,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    // @ts-ignore
    cacheLocation: process.env.REACT_APP_AUTH_CACHE,
    storeAuthStateInCookie: false,
  },
};

export default config;
