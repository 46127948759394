import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import "./file-drop-zone.scss";

interface FileDropZone {
  readonly showFileNames?: boolean;
  readonly filesLimit?: number;
  readonly onChange: (file: File[]) => void;
  readonly files: File[];
  readonly dropzoneText?: string;
}

const FileDropZone = ({
  showFileNames = true,
  filesLimit,
  onChange,
  dropzoneText,
  files,
}: FileDropZone) => (
  <div className="file-drop-zone">
    <DropzoneArea
      initialFiles={files}
      showFileNames={showFileNames}
      filesLimit={filesLimit}
      onChange={onChange}
      dropzoneText={dropzoneText}
    />
  </div>
);

export default FileDropZone;
