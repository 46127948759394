import { Field } from "../../components/CreatePage";
import Contact, { ContactType } from "../../Models/CommonProps/Contact";
import { FieldValidator } from "../../components/FieldValidator";
import { isCompanyUser } from "../../Authentication/Auth";

interface InputConfig {
  readonly contactType?: ContactType;
  readonly phoneRequired?: boolean;
  readonly isSiteForm?: boolean;
}

export default (
  contactState: Contact,
  setContact: (value: ((prevState: Contact) => Contact) | Contact) => void,
  config: Partial<InputConfig> = {}
): (Field | string)[] => {
  const { contactType = ContactType.General, phoneRequired } = config;
  const isGeneralContact = contactType === ContactType.General;
  return [
    ...(!isGeneralContact
      ? [
          `${contactType} contact`,
          {
            name: `${contactType}name`,
            label: "Contact name",
            value: contactState.name,
            setTextValue: (e: string) =>
              setContact((prev) => ({ ...prev, name: e })),
            disabled: isCompanyUser(),
          },
        ]
      : []),
    {
      name: `${contactType}email`,
      label: "Email",
      required: isGeneralContact,
      validators: [FieldValidator.EMAIL],
      value: contactState.email,
      setTextValue: (e) => setContact((prev) => ({ ...prev, email: e })),
      disabled: isCompanyUser() && !isGeneralContact,
    },
    {
      name: `${contactType}phone`,
      label: "Phone",
      required: phoneRequired ?? isGeneralContact,
      validators: [FieldValidator.PHONE_NUMBER],
      value: contactState.phoneNumber,
      setTextValue: (e) => setContact((prev) => ({ ...prev, phoneNumber: e })),
      disabled: isCompanyUser() && !isGeneralContact,
    },
    ...(isGeneralContact
      ? [
          {
            name: `${contactType}fax`,
            label: "Fax",
            value: contactState.faxNumber,
            setTextValue: (e: string) =>
              setContact((prev) => ({ ...prev, faxNumber: e })),
            disabled: isCompanyUser(),
          },
        ]
      : []),
  ];
};
