import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (auditorId: number, comment: string) =>
  axios.patch(
    `${BackendServices.AUDITOR_SERVICE.AUDITOR}/rejectAuditorProfile`,
    {
      comment,
      auditorId,
    }
  );
