import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (auditorId: number) =>
  axios.patch(
    `${BackendServices.AUDITOR_SERVICE.AUDITOR}/validateAuditorProfile`,
    {
      auditorId,
    }
  );
