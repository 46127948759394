import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import templates, { ReportFilter } from "./templates/templates";
import ReportHeader from "./components/ReportHeader";
import filterMappings, {
  FilterMapping,
  SetFilterValue,
} from "./templates/filterMappings";
import "./report-management.scss";
import Option from "../../Models/forms/Option";
import { SubmitButton } from "../../components/Button/button";
import exportReport from "./http/exportReport";
import ContentPage from "../../components/ContentPage";
import { DateRangeValue } from "../../components/DateRangePicker";
import FormGridItem from "../../components/Common/FormGridItem";
import Snackbar from "../../components/Snackbar";
import { SpinnerCenter } from "../../components/Spinner";
import LoadingOverlay from "../PublicDirectory/components/LoadingOverlay";
import useGetAvailableReports from "./http/useGetAvailableReports";

type CheckboxValue = boolean;
type RadioValue = string;
export type FilterValues =
  | CheckboxValue
  | Option[]
  | RadioValue
  | DateRangeValue;

export type FilterState = Partial<
  {
    [type in ReportFilter]: FilterValues;
  }
>;

const ReportManagement = () => {
  const [{ reportName, initiated }, setSelectedTemplateIndex] = useState<{
    reportName: string | null;
    initiated: boolean;
  }>({ reportName: null, initiated: false });

  const [isExporting, setExporting] = useState(false);

  const currentReportTemplate = templates.find(
    (t) => t.name.toLowerCase() === reportName?.toLowerCase()
  );
  const getInitialState = (): FilterState => {
    if (!currentReportTemplate) return {};
    return currentReportTemplate.filters.reduce<FilterState>(
      (acc: FilterState, curr) => {
        const mappings: FilterMapping = filterMappings[curr];
        if (mappings) {
          acc[curr] = mappings.initialValue;
        }
        return acc;
      },
      {}
    );
  };

  const [filterValues, setFilterValues] = useState<FilterState>({});
  const [error, setError] = useState<string | null>(null);

  const { isLoading, availableReports } = useGetAvailableReports();

  useEffect(() => {
    setSelectedTemplateIndex({
      reportName: availableReports[0],
      initiated: false,
    });
  }, [availableReports]);
  const onTemplateChange = () => {
    setFilterValues(getInitialState());
    setSelectedTemplateIndex({ reportName, initiated: true });
  };

  useEffect(() => {
    onTemplateChange();
  }, [reportName]);

  const content = (() => {
    if (isLoading || !initiated || !currentReportTemplate)
      return (
        <FormGridItem>
          <SpinnerCenter />
        </FormGridItem>
      );
    if (availableReports.length === 0)
      return (
        <FormGridItem>
          <div>No reports available</div>
        </FormGridItem>
      );

    return (
      <>
        <FormGridItem>
          <ReportHeader
            currentReport={reportName}
            options={availableReports}
            onChange={(newReport) => {
              setSelectedTemplateIndex({
                reportName: newReport,
                initiated: false,
              });
            }}
          />
        </FormGridItem>
        <FormGridItem>
          <div className="report-management__filters">
            {reportName && <h3>{reportName}</h3>}
            {Object.keys(filterValues).map((filter) => {
              const filterConfig = filterMappings[filter as ReportFilter];
              if (!filterConfig) return null;
              const setValue: SetFilterValue = (value) =>
                setFilterValues({ ...filterValues, [filter]: value });
              return (
                <filterConfig.component
                  key={filterConfig.name}
                  name={filterConfig.name ?? ""}
                  value={filterValues[filter as ReportFilter] as FilterValues}
                  setValue={setValue}
                  optionSupplier={() =>
                    filterConfig.optionSupplier?.(filterValues, setValue) ?? []
                  }
                />
              );
            })}
          </div>
        </FormGridItem>
        <FormGridItem>
          <div className="report-management__actions">
            <SubmitButton
              text="Export - CSV"
              disabled={!currentReportTemplate.csvUrl}
              onClick={() => {
                setExporting(true);
                exportReport(currentReportTemplate, filterValues, "csv")
                  .catch((_error) => setError(_error))
                  .finally(() => setExporting(false));
              }}
            />
            <SubmitButton
              text="Export - XLS"
              disabled={!currentReportTemplate.xlsUrl}
              onClick={() => {
                setExporting(true);
                exportReport(currentReportTemplate, filterValues, "xls")
                  .catch((_error) => setError(_error))
                  .finally(() => setExporting(false));
              }}
            />
          </div>
        </FormGridItem>
      </>
    );
  })();

  return (
    <ContentPage title="Reports" className="report-page">
      <LoadingOverlay isLoading={isExporting} />
      <Grid container spacing={3} direction="column">
        {content}
      </Grid>
      <Snackbar
        type="error"
        open={!!error}
        close={() => setError(null)}
        message={error ?? ""}
      />
    </ContentPage>
  );
};

export default ReportManagement;
