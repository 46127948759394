import React, { useEffect, useState } from "react";
import Notifications from "./Components/NotificationList/Notifications";
import "./notifications.scss";
import axios from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import ContentPage from "../../components/ContentPage";
import AuditStandards, {
  AuditStandardsProps,
  AuditStandardStatus,
} from "./Components/AuditStandards";
import NotificationResult from "./models/NotificationResult";
import NotificationAuditCategories from "./models/NotificationAuditCategories";

const additionalAreaContent: {
  [key: string]: React.FC<AuditStandardsProps>;
} = {
  "Audit Notifications": AuditStandards,
};

export const NotificationCategoryContext = React.createContext<{
  standards: NotificationAuditCategories[];
  isLoading: boolean;
}>({ isLoading: false, standards: [] });

export default function NotificationManagement() {
  const [notifications, setNotifications] = useState<NotificationResult>({
    groups: [],
    standards: [],
  });
  const [isLoading, setLoading] = useState(false);
  const loadDataFromService = () => {
    setLoading(true);
    axios
      .get<NotificationResult>(
        `${BackendServices.NOTIFICATION_SERVICE.NOTIFICATION}/GetNotifications`
      )
      .then(({ data: responseData }) => {
        setNotifications({
          groups: responseData.groups,
          standards: responseData.standards.map(
            ({ standardId, categories }) => ({
              standardId,
              categories,
              status:
                categories.length > 0
                  ? AuditStandardStatus.CUSTOM
                  : AuditStandardStatus.NONE,
            })
          ),
        });
      })
      .finally(() => setLoading(false));
  };
  useEffect(loadDataFromService, []);
  return (
    <ContentPage title="Notifications">
      <NotificationCategoryContext.Provider
        value={{ isLoading, standards: notifications.standards }}
      >
        {notifications.groups.map((notification) => (
          <Notifications
            key={notification.name}
            notification={notification}
            refresh={loadDataFromService}
            additionalContent={additionalAreaContent[notification.name]}
          />
        ))}
      </NotificationCategoryContext.Provider>
    </ContentPage>
  );
}
