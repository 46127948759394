import React from "react";
import Column from "../../../../../../components/DataTable/models/Column";
import AuditHistoryRow from "../models/AuditHistoryRow";
import { LinkText } from "../../../../../../components/Common/hyperlinks";
import { AuditUrls } from "../../../../index";

const auditHistoryColumns: Column<AuditHistoryRow>[] = [
  {
    title: "AUDIT HISTORY ID",
    field: "id",
    render: (rowData: AuditHistoryRow) => (
      <LinkText link={AuditUrls.details(rowData.id)} text={rowData.id} />
    ),
  },
  {
    title: "AUDIT START DATE",
    field: "auditStartDate",
    type: "date",
  },
  {
    title: "CERTIFICATION BODY",
    field: "certificationBodyName",
  },
  {
    title: "CERTIFICATION STATUS",
    field: "certificationStatus",
  },
  {
    title: "DATE OF VALIDATION",
    field: "auditValidationDate",
    type: "date",
  },
  {
    title: "AUDIT UPLOAD DATE",
    field: "uploadDate",
    type: "date",
  },
];
export default auditHistoryColumns;
