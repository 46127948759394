import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MinifiedOrganisation, MinifiedOrganisationType } from "../constants";
import getCompanyDetails from "../../../../http/companies/getCompanyDetails";
import getSiteDetails from "../../../../http/site/getSiteDetails";
import { mapOrgToMinifiedOrg } from "../util/mapOrgsToMinifiedOrgs";
import UserDetails from "../../models/UserDetails";
import { RootState } from "../../../../store/reducers";
import { UserParentIds } from "../index";

export default (
  userData: UserDetails | null,
  parentIds: UserParentIds,
  isCompanyCreation: boolean
) => {
  const companyName = useSelector(
    (state: RootState) => state.company.postBody?.company.name
  );
  const { companyId, siteId } = parentIds;

  const initialState: MinifiedOrganisation | null = (() => {
    if (isCompanyCreation) {
      return {
        externalId: -1,
        name: companyName ?? "",
        type: MinifiedOrganisationType.Company,
      };
    }
    if (userData?.primaryOrganisation) {
      return mapOrgToMinifiedOrg(userData.primaryOrganisation);
    }
    return null;
  })();

  const [
    primaryOrganisation,
    setPrimaryOrganisation,
  ] = useState<MinifiedOrganisation | null>(initialState);

  const [isLoadingParent, setLoadingParent] = useState(false);
  const [isPreset, setPreset] = useState(isCompanyCreation);

  useEffect(() => {
    const fetchFunction: (() => Promise<MinifiedOrganisation>) | null = (() => {
      if (companyId) {
        return () =>
          getCompanyDetails(companyId).then(
            ({ data }): MinifiedOrganisation => {
              return {
                externalId: data.id,
                type: MinifiedOrganisationType.Company,
                name: data.name,
              };
            }
          );
      }
      if (siteId) {
        return () =>
          getSiteDetails(siteId).then(
            ({ data }): MinifiedOrganisation => ({
              name: data.name,
              externalId: data.id,
              type: MinifiedOrganisationType.Site,
            })
          );
      }
      return null;
    })();
    if (fetchFunction) {
      setLoadingParent(true);
      fetchFunction()
        .then((org) => {
          setPrimaryOrganisation(org);
          setPreset(true);
        })
        .finally(() => setLoadingParent(false));
    }
  }, []);

  return {
    primaryOrganisation,
    setPrimaryOrganisation,
    isLoadingParent,
    isPreset,
  };
};
