import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import { ParentContext } from "../../../../routing/GenericRoutes";
import styles from "./BackButton.module.scss";
import { idParam } from "../../../Paths/paths";
import DialogBoxButton from "../../../DialogBoxButton";

function BackButtonComponent() {
  const history = useHistory();
  const parentContext = useContext(ParentContext);

  if (!parentContext) return null;

  const onBack = () => {
    history.push(
      parentContext.parentLink.replace(idParam, `/${parentContext.id}`)
    );
  };
  const renderButton = (openDialog: () => void) => (
    <button type="button" onClick={openDialog} className={styles.backButton}>
      <ChevronLeft className={styles.backButtonChevron} />
      Back
    </button>
  );

  return (
    <>
      {parentContext.confirm ? (
        <DialogBoxButton
          onConfirm={onBack}
          acceptButtonText="Leave Page"
          declineButtonText="Cancel"
          openButton={renderButton}
        >
          Are you sure you want to leave this page? Any unsaved changes will be
          lost.
        </DialogBoxButton>
      ) : (
        renderButton(onBack)
      )}
    </>
  );
}

export default BackButtonComponent;
