import { FormGroup, MuiThemeProvider, Tooltip } from "@material-ui/core";
import React from "react";
import { NotificationLabel } from "./NotificationLabel";
import "../../notifications.scss";
import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { postToggleNotification } from "../../../../components/Paths/paths";
import { userText } from "../../../../constants/ToolTipsText";
import Box from "../../../../components/Box";
import NotificationGroup from "../../models/NotificationGroup";
import { CustomTooltips } from "../../../../components/Tooltips/CustomTooltips";

interface NotificationListProps {
  readonly notification: NotificationGroup;
  readonly refresh: () => void;
  readonly additionalContent?: React.FC<{ refresh: () => void }>;
}

const NotificationList = ({
  notification,
  refresh,
  additionalContent,
}: NotificationListProps) => {
  const { name = "", notifications = [] } = notification ?? {};
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const externalId = Number(event.target.id);
    const value = event.target.checked;
    axios
      .post(
        `${BackendServices.NOTIFICATION_SERVICE.NOTIFICATION}${postToggleNotification}`,
        { externalId, value }
      )
      .then(refresh);
  };

  return (
    <Box header={name} className="notification-group">
      <FormGroup className="notifications">
        {notifications.map((element) => (
          <MuiThemeProvider theme={CustomTooltips}>
            <Tooltip
              key={element.externalId}
              title={`${userText}${element.name}`}
              placement="right"
            >
              <div>
                <NotificationLabel
                  label={element.name}
                  checked={element.value}
                  id={element.externalId}
                  handleChange={handleChange}
                />
              </div>
            </Tooltip>
          </MuiThemeProvider>
        ))}
        {additionalContent &&
          React.createElement(additionalContent, { refresh })}
      </FormGroup>
    </Box>
  );
};

export default NotificationList;
