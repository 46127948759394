import React from "react";
import FilterComponentProps from "./FilterComponentProps";
import RadioGroup from "../../RadioGroup";

const RadioFilter = ({ onChange, selected, options }: FilterComponentProps) => (
  <RadioGroup
    value={selected[0]?.value ?? null}
    onChange={(val) => {
      const selectedOption = options.find((op) => op.value === val);
      if (selectedOption) onChange([selectedOption]);
    }}
    options={options}
  />
);

export default RadioFilter;
