import { Action, ThunkAction } from "@reduxjs/toolkit";
import { UserPermissions } from "../index";
import { RootState } from "../../reducers";
import PermissionArea from "../../../Authentication/model/PermissionArea";
import Permission from "../../../Authentication/model/Permission";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";

export const ACTION_GET_PERMISSION = "ACTION_GET_PERMISSION";
export const ACTION_GET_PERMISSION_LOADING = "ACTION_GET_PERMISSION_LOADING";

export interface ActionGetPermission {
  readonly type: typeof ACTION_GET_PERMISSION;
  readonly payload: UserPermissions;
}

export interface ActionGetPermissionsLoading {
  readonly type: typeof ACTION_GET_PERMISSION_LOADING;
}

export default (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch({ type: ACTION_GET_PERMISSION_LOADING });
  axiosApi
    .get<{ readonly area: PermissionArea; readonly actions: Permission[] }[]>(
      `${BackendServices.USER_SERVICE.USER}/getRolePermissions`
    )
    .then(({ data }) => {
      const permissions: Partial<UserPermissions> = data.reduce<
        Partial<UserPermissions>
      >((acc, curr) => {
        acc[curr.area.replace(/\s/g, "") as PermissionArea] = curr.actions;
        return acc;
      }, {});
      const action: ActionGetPermission = {
        type: ACTION_GET_PERMISSION,
        payload: permissions as UserPermissions,
      };
      dispatch(action);
    });
};
