import React from "react";
import CheckBox from "../../../components/Common/CheckBox";
import { FilterProps } from "../templates/filterMappings";

const CheckboxFilter = ({ name, setValue, value }: FilterProps) => (
  <CheckBox
    label={name}
    checked={value as boolean}
    onChange={(event, newValue) => {
      setValue(newValue);
    }}
  />
);

export default CheckboxFilter;
