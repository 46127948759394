import React from "react";
import moment from "moment";
import DataTable from "../../../../../components/DataTable";
import AssociatedAudit from "../../../../../Models/site/AssociatedAudit";
import associatedAuditColumns from "./utils/AssociatedAuditColumns";
import AssociatedAuditRow from "./models/AssociatedAuditRow";
import { AuditUrls } from "../../../../AuditManagement";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";
import PermissionArea from "../../../../../Authentication/model/PermissionArea";

const AssociatedAuditsTable = ({
  audits,
}: {
  readonly audits: AssociatedAudit[];
}) => {

  const str = window.location.pathname
  const pieces = str.split('/')
  const siteID = pieces[pieces.length - 1];

  const fetch = (): Promise<AssociatedAuditRow[]> =>
    Promise.resolve<AssociatedAuditRow[]>(
      audits.map((audit) => ({
        Standard: audit.standard?.name,
        CertStatus: audit.certificationStatus,
        ExpiryDate: moment(audit.expiryDate).format(DEFAULT_DATE_FORMAT),
        Id: audit.id,
        IssueDate: moment(audit.issueDate).format(DEFAULT_DATE_FORMAT),
        RecordStatus: audit.auditStatus,
        AuditStartDate: moment(audit.auditStartDate).format(
          DEFAULT_DATE_FORMAT
        ),
      }))
    );

  return (
    <DataTable<AssociatedAuditRow>
      columns={associatedAuditColumns}
      fetchData={fetch}
      toolBarOptions={{
        searchField: { enable: false },
        newEntityLink: {
          label: "Add new audit",
          link: AuditUrls.create({SiteCode:siteID}),
          permissionAreas: [PermissionArea.AUDIT],
        },
      }}
    />
  );
};

export default AssociatedAuditsTable;
