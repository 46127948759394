import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (auditorId: number, qualificationId: number, comment: string) =>
  axios.patch(
    `${BackendServices.AUDITOR_SERVICE.AUDITOR}/rejectQualification`,
    {
      comment,
      qualificationId,
      auditorId,
    }
  );
