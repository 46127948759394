import React from "react";
import { LinkText } from "../../../components/Common/hyperlinks";

function Contact() {
  return (
    <div>
      <h1>Contact</h1>

      <h2>Suppliers, Companies and BRCGS audited sites</h2>

      <h2>For updates to audit & site data, including contacts</h2>

      <p>
        Please contact your auditing Certification Body directly to discuss;
      </p>

      <ul>
        <li>Audit document availability, accuracy and content</li>
        <li>
          To request adjustments to previously administrated audit reports and
          certificates
        </li>
        <li>For updates to contact names and associated details</li>
      </ul>

      <h2>Directory user account requests</h2>

      <p>
        To request a company username and password to access the Directory,
        please
        <LinkText
          link="https://form.jotform.com/61322554434955"
          text=" complete this form"
          external
        />
      </p>

      <p>
        Please do not use this form if you are a retailer, non-certified
        manufacturer, certification Body, government function or accreditation
        body.
      </p>

      <h2>Logos</h2>

      <p>
        To request a BRCGS Supplier Logo please
        <LinkText
          link="https://form.jotform.com/61323530244949"
          text=" complete this form"
          external
        />
      </p>

      <h2>Assistance & Queries</h2>

      <p>
        If you require assistance using the Directory, or would like to ask a
        question about it, please
        <LinkText
          link="https://form.jotform.com/210703256820043"
          text=" complete this form"
          external
        />
      </p>

      <p>
        Please do not use this form if you represent a BRCGS approved
        Certification Body.
      </p>

      <h2>Directory service level and procedure</h2>

      <p>
        Please use the forms provided above in all instances as emails cannot be
        tracked to completion and may not reach the correct team.
      </p>

      <p>
        All Directory access and logo requests must be submitted via the forms
        listed above, and will not be accepted via email.
      </p>

      <p>
        Requests for Directory access and certificated site logos are assessed
        for identity verification prior to issuing credentials.
      </p>

      <p>
        If you are not named on an audit document, your access request must be
        endorsed by someone who is.
      </p>

      <p>
        The Directory services team cannot amend any audit or certificate
        content and will defer any requests of that nature to the auditing
        certification body.
      </p>

      <p>
        The Directory services Team opens Monday to Friday, 09.00am to 17.00pm
        GMT. Requests received outside of those hours will be processed the next
        working day and in order of receipt.
      </p>

      <p>
        All requests are assessed and fulfilled where possible by 17.00pm on the
        working day of receipt. Please do not re-submit requests within a
        24-hour period.
      </p>
    </div>
  );
}

export default Contact;
