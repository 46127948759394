import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./InfoBox.module.scss";
import ListEntry from "../../Models/CommonProps/ListEntry";
import EntryPair from "../EntryPair";
import Box, { BoxProps } from "../Box";

interface InfoBoxProps extends BoxProps {
  readonly items: ListEntry[] | ListEntry[][];
  readonly columns?: number;
}

const shortid = require("shortid");

const InfoBox = ({
  header,
  items,
  actionRow,
  className,
  columns = 1,
  children,
}: PropsWithChildren<InfoBoxProps>) => {
  let contentColumns = new Array<Array<ListEntry>>(columns);

  if (!Array.isArray(items[0])) {
    const listOfItems = items as ListEntry[];
    const filteredItems = listOfItems.filter(
      (entry) => entry.required || !!entry.value
    );

    const colSize = Math.ceil(filteredItems.length / columns);
    contentColumns = contentColumns.fill([]).map((_, index) => {
      const startIndex = index * colSize;
      return filteredItems.slice(startIndex, startIndex + colSize);
    });
  } else {
    contentColumns = items as ListEntry[][];
  }

  return (
    <Box
      header={header}
      actionRow={actionRow}
      className={clsx(styles.infoBox, className)}
    >
      <div className={styles.content}>
        {contentColumns.map((col, index) => (
          <ul className={styles.entryList} key={index.toString()}>
            {col.map((entry) => (
              <EntryPair
                className={styles.entryPair}
                key={entry.key ?? shortid.generate()}
                label={entry.label}
                value={entry.value}
              />
            ))}
          </ul>
        ))}
      </div>
      <div>{children}</div>
    </Box>
  );
};

export default InfoBox;
