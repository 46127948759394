import React from "react";
import { connect } from "react-redux";
import SearchableDropdown from "../../SearchableDropdown";
import StandardDropdownProps from "../../../Models/CommonProps/StandardDropdownProps";
import { RootState } from "../../../store/reducers";
import Option from "../../../Models/forms/Option";
import Standard from "../../../Models/common/Standard";

class StandardDropdown extends React.Component<StandardDropdownProps> {
  private static standardToOption(standard: Standard): Option {
    return { value: standard.id, label: standard.name };
  }

  private static optionToStandard(option: Option | null): Standard {
    return {
      name: option ? option.label : "",
      id: option ? +option.value : -1,
    };
  }

  render() {
    const { required, disabled, value, onChange, standards } = this.props;
    const options: Option[] = standards.map(({ name, id }) => ({
      value: id,
      label: name,
    }));

    return (
      <SearchableDropdown
        disabled={disabled}
        required={required}
        Options={options}
        label="Select standard"
        name="standardSelect"
        value={
          value?.id
            ? StandardDropdown.standardToOption({
                id: +value.id,
                name: value.name,
              })
            : null
        }
        onChange={(e: React.ChangeEvent<{}>, option: Option | null) =>
          onChange?.(
            option ? { name: option?.label, id: +option?.value } : null
          )
        }
      />
    );
  }
}

export default connect((state: RootState) => ({
  standards: state.details.standards,
}))(StandardDropdown);
