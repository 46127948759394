enum AuditorStatus {
  IN_PROGRESS = "In Progress",
  IN_REVISION = "In Revision",
  VALIDATED = "Validated",
  NOT_VALIDATED = "Not Validated",
  ARCHIVED = "Archived",
  SUSPENDED = "Suspended",
}

export default AuditorStatus;
