import React from "react";
import getEntitiesSharedWith from "../../http/getEntitiesSharedWith";
import { ShareProps } from "../Share";
import DataTable from "../../../../components/DataTable";
import shareColumns from "../Share/util/shareColumns";
import ShareRow from "../Share/util/ShareRow";

const Unshare = ({ id, type, setShowConfirmation }: ShareProps) => {
  const fetch = (): Promise<ShareRow[]> =>
    getEntitiesSharedWith(id, type).then((targets) =>
      targets.map((target) => ({
        id: target.id,
        name: target.name,
        type: target.type,
        typeName: target.typeName,
        country: target.location.country?.name ?? "",
        address: target.location.addressLine1,
        shared: target.isShared,
      }))
    );

  return (
    <DataTable
      columns={shareColumns(id, type, setShowConfirmation, true, true)}
      fetchData={fetch}
    />
  );
};

export default Unshare;
