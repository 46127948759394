import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import getUserDetails from "../../../http/user/getUserDetails";
import UserDetails from "../models/UserDetails";
import Spinner from "../../../components/Spinner";
import UserNew from "../UserNew";

const UserEdit = ({ match }: RouteComponentProps<{ id: string }>) => {
  const {
    params: { id },
  } = match;

  const [userData, setUserData] = useState<UserDetails | null>();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!id) return;
    getUserDetails(id)
      .then(({ data }) => setUserData(data))
      .catch(() => setError(true));
  }, []);

  if (error) {
    return <p>We were unable to load user</p>;
  }
  if (!userData) {
    return <Spinner />;
  }
  return <UserNew userId={id} userData={userData} isEditMode />;
};

export default UserEdit;
