import DashboardMinified from "../../views/Dashboards/models/DashboardMinified";
import sortNameAlphanumerical from "../../util/sorting/sortNameAlphanumerical";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";

export default (): Promise<DashboardMinified[]> =>
  axiosApi
    .get<DashboardMinified[]>(BackendServices.DASHBOARD_SERVICE.DASHBOARD)
    .then(({ data }) => data.sort(sortNameAlphanumerical))
    .catch(() => []);
