import React from "react";
import moment from "moment";
import AuditDetailsView from "../../../../../Models/Audit/AuditDetailsView";
import InfoBox from "../../../../../components/Infobox";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";

interface AuditScheduleDetailsProps {
  mainDetails?: AuditDetailsView | null;
}

function AuditScheduleDetails({ mainDetails }: AuditScheduleDetailsProps) {
  const scheduleDetails = [];
  const expiryDetails = [];

  let dateString = "";

  dateString = mainDetails?.nextScheduledAuditDate
    ? moment(mainDetails.nextScheduledAuditDate).format(DEFAULT_DATE_FORMAT)
    : "";

  const expiryDateString = mainDetails?.expiryAssessmentDate
    ? moment(mainDetails.expiryAssessmentDate).format(DEFAULT_DATE_FORMAT)
    : "";

  scheduleDetails.push({
    label: "Status of scheduled audit",
    value: mainDetails?.scheduleType ?? "",
  });
  scheduleDetails.push({
    label: "Next audit date",
    value: dateString,
  });
  scheduleDetails.push({
    label: "Modules for next audit",
    value:
      mainDetails?.scheduledAdditionalModules === undefined ||
      mainDetails?.scheduledAdditionalModules.length > 0
        ? mainDetails?.scheduledAdditionalModules?.map((mod) => mod.name)
        : "No Modules scheduled for next audit",
  });
  scheduleDetails.push({
    label: "Certification Body",
    value: mainDetails?.scheduleCertificationBody,
  });

  expiryDetails.push({
    label: "Re-Audit Status",
    value: mainDetails?.expiryReason ?? "",
  });
  expiryDetails.push({
    label: "Comments",
    value: mainDetails?.expiryComments ?? "",
  });
  expiryDetails.push({
    label: "Assessment Date",
    value: expiryDateString,
  });

  return (
    <>
      {mainDetails?.scheduleType && (
        <InfoBox
          header="Next Scheduled Audit Details"
          items={scheduleDetails}
        />
      )}
      {mainDetails?.expiryReason && (
        <InfoBox header="Re-Audit Status" items={expiryDetails} />
      )}
    </>
  );
}

export default AuditScheduleDetails;
