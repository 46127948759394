import React, { useState } from "react";
import { Column, EditCellColumnDef } from "material-table";
import TextField from "@material-ui/core/TextField/TextField";
import { Moment } from "moment/moment";
import DialogBox from "../../../DialogBox";
import DateRangePicker from "../../../DateRangePicker";
import { DEFAULT_DATE_FORMAT } from "../../../../util/dates/DateFormats";

interface DateRangeFilterProps<T extends object> {
  readonly columnDef: Column<T> & EditCellColumnDef;
  readonly onFilterChanged: (rowId: string, value: any) => void;
}

const formatDateToDisplay = (date: Moment | null) =>
  date ? date.format(DEFAULT_DATE_FORMAT) : "∞";

const formatDateToFilter = (date: Moment | null) =>
  date ? date.format(DEFAULT_DATE_FORMAT) : "";

const DateRangeFilter = ({
  columnDef,
  onFilterChanged,
}: DateRangeFilterProps<any>) => {
  const [isOpen, setOpen] = useState(false);
  const [from, setFrom] = useState<Moment | null>(null);
  const [to, setTo] = useState<Moment | null>(null);
  const [changesPerformed, setChangesPerformed] = useState(false);

  const valueString =
    !from && !to
      ? ""
      : `${formatDateToDisplay(from)} - ${formatDateToDisplay(to)}`;

  const onClose = () => {
    setOpen(false);

    if (changesPerformed) {
      onFilterChanged(
        `${columnDef.tableData.id}`,
        `${formatDateToFilter(from)}&${formatDateToFilter(to)}`
      );
      setChangesPerformed(false);
    }
  };

  return (
    <div>
      <TextField value={valueString} onClick={() => setOpen(true)} />
      {isOpen && (
        <DialogBox
          onCancel={onClose}
          open={isOpen}
          onConfirm={onClose}
          acceptButtonText="Close"
        >
          <DateRangePicker
            onChange={(value) => {
              setChangesPerformed(true);
              setFrom(value[0]);
              setTo(value[1]);
            }}
            fromValue={from}
            toValue={to}
            label={columnDef.title}
          />
        </DialogBox>
      )}
    </div>
  );
};

export default DateRangeFilter;
