import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../store/reducers";
import getQualificationStatus from "../../store/details/actions/getQualificationStatus";
import QualificationStatus from "../../Models/common/QualificationStatus";

export default () => {
  const qualificationStatus: QualificationStatus[] = useSelector(
    (state: RootState) => state.details.qualificationStatus
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (qualificationStatus.length === 0) {
      dispatch(getQualificationStatus());
    }
  }, []);

  return qualificationStatus;
};
