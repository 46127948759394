import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Entity from "../../../Models/APIGetData/Entity";
import CreateAuditScheduleLocationProps from "../../../Models/AuditProps/CreateAuditScheduleLocationProps";
import CreateAuditScheduleState, {
  defaultCreateAuditScheduleState as defaultState,
} from "../../../Models/AuditProps/CreateAuditScheduleState";
import {
  Modules,
  schedules,
  scheduleTypes as scheduleTypesString,
} from "../../../components/Paths/paths";
import { SubmitButton } from "../../../components/Button/button";
import StandardDropdown from "../../../components/Common/Input/StandardDropdown";
import CheckBoxList from "../../../components/Common/CheckBoxList";
import { Module } from "../../../Models/APIGetData/Module";
import CheckBoxItemProps from "../../../Models/CommonProps/CheckBoxItemProps";
import { auditScheduleStatus } from "../../../Enums/AuditScheduleStatus";
import InsertAuditScheduleData from "../../../Models/APIPostData/Audit/InsertAuditScheduleData";
import history from "../../../components/Common/History";
import ContentPage from "../../../components/ContentPage";
import AuditScheduleTypeData from "../../../Models/APIGetData/Audits/AuditScheduleTypeData";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import { VerifyUrls } from "../index";
import { SiteUrls } from "../../SiteManagement";
import Standard from "../../../Models/common/Standard";
import { DatePicker } from "../../../components/Common/Input/DatePicker";
import CertificationBodySelector from "../../../components/CertificationBodySelector";
import { isCBUser } from "../../../Authentication/Auth";

class AuditSchedule extends React.Component<
  RouteComponentProps<{}, {}, CreateAuditScheduleLocationProps>,
  CreateAuditScheduleState
> {
  constructor(
    props: RouteComponentProps<Entity, {}, CreateAuditScheduleLocationProps>
  ) {
    super(props);
    this.state = { ...defaultState, ...props.location.state };
  }

  componentDidMount() {
    this.getAllModules();
    this.getScheduleTypes();
  }

  getAllModules() {
    axiosApi
      .get<Module[]>(`${BackendServices.AUDIT_SERVICE.AUDIT}${Modules}`)
      .then<CheckBoxItemProps[]>((response) =>
        AuditSchedule.mapModulesToItems(response.data)
      )
      .then((data) => this.setState(() => ({ modules: data })))
      .catch(() => console.log("err"));
  }

  getScheduleTypes() {
    axiosApi
      .get<AuditScheduleTypeData[]>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${scheduleTypesString}`
      )
      .then(({ data }) => {
        this.setState({ scheduleTypes: data, chosenStatus: data[0]?.id ?? 0 });
      });
  }

  private static mapModulesToItems(modules: Module[]): CheckBoxItemProps[] {
    return modules.map((module: Module) => ({
      id: module.id.toString(),
      name: module.name,
    }));
  }

  onSubmit() {
    this.postAuditSchedule();
  }

  mapStateToPost(): InsertAuditScheduleData {
    const {
      site,
      standard,
      scheduledDate,
      chosenStatus,
      chosenModules,
      certificationBody,
    } = this.state;
    return {
      SiteId: site ? +site.id : 0,
      StandardId: standard ? +standard.id : 0,
      ScheduledDate: scheduledDate,
      ScheduleTypeId: chosenStatus,
      AdditionalModules: chosenModules.map<number>((module: string) =>
        parseInt(module, 10)
      ),
      CertificationBodyId: certificationBody?.value
        ? +certificationBody.value
        : undefined,
    };
  }

  postAuditSchedule() {
    axiosApi
      .post<InsertAuditScheduleData>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${schedules}`,
        this.mapStateToPost()
      )
      .then(() => this.handlePostResponse())
      .catch((error) => {
        console.log(error);
      });
  }

  handlePostResponse() {
    const { fromAuditPage, auditId, site } = this.state;
    if (fromAuditPage) {
      history.push(VerifyUrls.details(auditId ?? ""));
    } else {
      history.push(SiteUrls.details(site?.id ?? ""));
    }
  }

  toggleModules(event: React.ChangeEvent<HTMLInputElement>, selected: boolean) {
    const { chosenModules } = this.state;
    const moduleId = event.target.id;
    this.setState({
      chosenModules: selected
        ? [...chosenModules, moduleId]
        : chosenModules.filter((cm) => cm !== moduleId),
    });
  }

  updateScheduledDate(value: Date) {
    this.setState(() => ({ scheduledDate: value }));
  }

  updateStatus(value: string) {
    this.setState(() => ({ chosenStatus: +value }));
  }

  updateStandard(value: Standard | null) {
    this.setState(() => ({ standard: value }));
  }

  renderForm() {
    const {
      scheduleTypes,
      chosenStatus,
      scheduledDate,
      standard,
      modules,
      fromAuditPage,
      chosenModules,
      certificationBody,
    } = this.state;

    return (
      <ValidatorForm onSubmit={() => this.onSubmit()}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <RadioGroup
              value={chosenStatus}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.updateStatus(e.target.value as auditScheduleStatus)
              }
            >
              {scheduleTypes.map((type) => (
                <FormControlLabel
                  key={type.id}
                  value={type.id}
                  control={<Radio />}
                  label={type.name}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item>
            <DatePicker
              value={scheduledDate}
              label="Audit Date"
              required
              onChange={(e: any) => this.updateScheduledDate(e)}
              name="auditDate"
            />
          </Grid>
          <Grid item>
            <StandardDropdown
              required
              value={standard}
              disabled={fromAuditPage}
              onChange={(selected) => this.updateStandard(selected)}
            />
          </Grid>
          {!isCBUser() && (
            <Grid item>
              <CertificationBodySelector
                required
                value={certificationBody}
                onChange={(value) =>
                  this.setState({ certificationBody: value })
                }
              />
            </Grid>
          )}
          <Grid item>
            <CheckBoxList
              CheckBoxArray={modules}
              checkedList={chosenModules}
              Heading="Additional Modules"
              handleToggleBox={(e, b) => this.toggleModules(e, b)}
            />
          </Grid>
        </Grid>
        <SubmitButton />
      </ValidatorForm>
    );
  }

  render() {
    const { site } = this.state;
    return (
      <ContentPage title="Audit Schedule" subtitle={site?.name}>
        {this.renderForm()}
      </ContentPage>
    );
  }
}

export default AuditSchedule;
