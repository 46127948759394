import React from "react";
import AuditDetailsView from "../../../../Models/Audit/AuditDetailsView";
import CreateAuditScheduleLocationProps from "../../../../Models/AuditProps/CreateAuditScheduleLocationProps";
import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import downloadCertificate from "../../http/downloadCertificate";
import downloadPDF from "../../http/downloadPDF";
import { AuditUrls } from "../../index";
import {
  DropdownMenuItem,
  DropdownMenuItemType,
} from "../../../../components/DropdownMenu";
import Icon, { IconType } from "../../../../components/Icon";
import Permission from "../../../../Authentication/model/Permission";
import AuditStatus from "../../models/AuditStatus";

export default (
  menuDetails: AuditDetailsView | undefined,
  refresh: () => void
): DropdownMenuItem[] => {
  if (!menuDetails) return [];
  return [
    getAuditScheduleMenuItem(menuDetails),
    getExpiryMenuItem(menuDetails),
    getValidationMenuItem(menuDetails, refresh),
    getDownloadDocumentMenuItem(menuDetails),
    getDownloadAsPDFMenuItem(menuDetails),
  ].filter((item) => item !== null) as DropdownMenuItem[];
};

const getAuditScheduleMenuItem = (
  details: AuditDetailsView
): DropdownMenuItem | null => {
  if (!details.visuals.isAbleToEdit) return null;

  const data: CreateAuditScheduleLocationProps = {
    fromAuditPage: true,
    auditId: details.id,
    standard: details.standard,
    site: details.site,
  };
  return {
    type: DropdownMenuItemType.LINK,
    state: data,
    link: AuditUrls.schedule(details.id),
    icon: <Icon type={IconType.SCHEDULE} />,
    label: "Audit Schedule",
    permissionRequired: [{ permission: Permission.SCHEDULE }],
  };
};

const getExpiryMenuItem = (
  details: AuditDetailsView
): DropdownMenuItem | null => {
  if (!details.visuals.isAbleToEdit) return null;

  const state: CreateAuditScheduleLocationProps = {
    fromAuditPage: true,
    auditId: details.id,
    standard: details?.standard,
    site: details?.site,
  };
  return {
    icon: <Icon type={IconType.EDIT} />,
    label: "Give Re-audit Status",
    type: DropdownMenuItemType.LINK,
    link: AuditUrls.expiry(details.id),
    state,
    permissionRequired: [{ permission: Permission.GENERATE }],
  };
};

const getDownloadAsPDFMenuItem = (
  mainDetails: AuditDetailsView
): DropdownMenuItem | null => {
  return {
    icon: <Icon type={IconType.DOWNLOAD} />,
    type: DropdownMenuItemType.BUTTON,
    label: "Download as PDF",
    onClick: () => {
      downloadPDF(mainDetails.id);
    },
    permissionRequired: [{ permission: Permission.VIEW }],
  };
};

const getValidationMenuItem = (
  details: AuditDetailsView,
  refresh: () => void
): DropdownMenuItem | null => {
  if (details.recordStatus !== AuditStatus.HOLDING) return null;
  const dialogHeader = `Sure you want to validate the Audit?`;

  return {
    icon: <Icon type={IconType.SUCCESS} />,
    label: "Validate Audit",
    type: DropdownMenuItemType.BUTTON_CONFIRM,
    dialogHeader,
    declineButtonText: "Cancel",
    acceptButtonText: "Validate",
    onConfirm: () =>
      axios
        .put(
          `${BackendServices.AUDIT_SERVICE.AUDIT}/validate/holding/${details.id}`
        )
        .then(refresh),
    permissionRequired: [{ permission: Permission.VERIFY }],
  };
};


const getDownloadDocumentMenuItem = (
  mainDetails: AuditDetailsView
): DropdownMenuItem | null => {
  if (!mainDetails.hasCertificate) return null;

  return {
    icon: <Icon type={IconType.DOWNLOAD} />,
    type: DropdownMenuItemType.BUTTON,
    label: "Download Certificate",
    onClick: () => {
      downloadCertificate(mainDetails.id);
    },
    permissionRequired: [{ permission: Permission.VIEW }],
  };
};
