import { UserOrganisation } from "../../models/UserDetails";
import { MinifiedOrganisation } from "../constants";

export const mapOrgToMinifiedOrg = (
  org: UserOrganisation
): MinifiedOrganisation => ({
  externalId: org.externalId,
  type: org.type,
  name: org.name,
});

export const mapOrgsToMinifiedOrgs = (
  orgs: UserOrganisation[]
): MinifiedOrganisation[] =>
  orgs.map((org: UserOrganisation) => mapOrgToMinifiedOrg(org));
