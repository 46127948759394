import React from "react";
import TabsBar, { TabPanel } from "../../../Common/Tab";

import SiteSearchTable from "../../Site/SiteTable";
import CompanySearchTable from "../../../../views/CompanyManagement/CompanySearchTable";

interface CompanySiteTabsProps {
  readonly defaultTab: number;
}

export default function CompanySiteTabs({ defaultTab }: CompanySiteTabsProps) {
  const [value, setValue] = React.useState(defaultTab);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabsBar
        labels={["COMPANY", "SITE"]}
        value={value}
        centered={false}
        onChange={(selected: number) => handleChange(selected)}
      />
      <TabPanel index={0} value={value}>
        <CompanySearchTable />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <SiteSearchTable />
      </TabPanel>
    </div>
  );
}
