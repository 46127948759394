import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";

export default async (id: string): Promise<boolean> => {
  
    const response = await axiosApi.get<boolean>(
      `${BackendServices.AUDIT_SERVICE.VERIFY}/GetFavourited/${id}`
    );
    return response.data; // Assuming response.data is the boolean value
  
};
