import { useEffect, useState } from "react";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import { AuditStatusEntity } from "../../views/AuditManagement/models/AuditStatus";

export default (): { auditStatuses: AuditStatusEntity[] } => {
  const [auditStatuses, setAuditStatuses] = useState<AuditStatusEntity[]>([]);
  useEffect(() => {
    axiosApi
      .get<AuditStatusEntity[]>(`${BackendServices.AUDIT_SERVICE.AUDIT}/status`)
      .then(({ data }) => {
        setAuditStatuses(data);
      })
      .catch(() => setAuditStatuses([]));
  }, []);
  return { auditStatuses };
};
