import { AnyAction, Reducer, Store } from "redux";
import { configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import reducers, { RootState } from "./reducers";
import getStandards from "./details/actions/getStandards";

class ReduxStateHandler {
  private readonly store: Store;

  private readonly rootReducer: Reducer;

  constructor() {
    this.rootReducer = ReduxStateHandler.getRootReducer();
    this.store = configureStore({
      reducer: this.rootReducer,
    });

    this.fetchInitialData();
  }

  public getStore() {
    return this.store;
  }

  protected static getRootReducer() {
    return reducers;
  }

  private fetchInitialData() {
    (this.store.dispatch as ThunkDispatch<RootState, void, AnyAction>)(
      getStandards()
    );
  }
}

export default ReduxStateHandler;
