import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../components/Form";
import getRoles from "../../../../http/user/getRoles";
import MultipleSelectDropdown from "../../../../components/MultipleSelectDropdown";
import Option from "../../../../Models/forms/Option";
import styles from "./DashboardNew.module.scss";
import DashboardExtended from "../models/DashboardExtended";
import { PATH_ADMINISTRATION } from "../../../../components/Paths/paths";
import { ParentContext } from "../../../../routing/GenericRoutes";
import { Field } from "../../../../components/CreatePage";
import searchReport from "../http/searchReport";
import { DashboardCreate } from "../models/DashboardCreate";
import postDashboard from "../http/postDashboard";

interface DashboardNewProps {
  readonly dashboard: DashboardExtended | null;
  readonly onSubmit?: (dashboard: DashboardCreate) => Promise<any>;
}

export const PATH_ADMIN_DASHBOARD_NEW = `${PATH_ADMINISTRATION}/new`;

const roleToOption = (role: string): Option => ({ value: role, label: role });

enum DashboardSearchStatus {
  INITIAL,
  SEARCHING,
  SUCCESS,
  FAILED,
}

const DashboardNew = ({
  dashboard,
  onSubmit = postDashboard,
}: DashboardNewProps) => {
  const isEditing = !!dashboard;

  const [status, setStatus] = useState(
    isEditing ? DashboardSearchStatus.SUCCESS : DashboardSearchStatus.INITIAL
  );
  const [title, setTitle] = useState(dashboard?.name ?? "");
  const [reportId, setReportId] = useState(dashboard?.reportId ?? "");
  const [webUrl, setWebUrl] = useState(dashboard?.webUrl ?? "");
  const [embedUrl, setEmbedUrl] = useState(dashboard?.embedUrl ?? "");
  const [dataSetId, setDatasetId] = useState(dashboard?.datasetId ?? "");

  const [roles, setRoles] = useState<Option[]>(
    dashboard?.roles.map(roleToOption) ?? []
  );
  const [roleOptions, setRoleOptions] = useState<Option[]>([]);
  const history = useHistory();

  useEffect(() => {
    getRoles().then(({ data }) => setRoleOptions(data.map(roleToOption)));
  }, []);

  const statusText = (() => {
    switch (status) {
      case DashboardSearchStatus.FAILED:
        return "Could not find the report";
      case DashboardSearchStatus.SEARCHING:
        return `Looking up report with ID ${reportId}`;
      default:
        return "";
    }
  })();

  const fields: (Field | string)[] = [
    {
      label: "Report ID",
      name: "reportId",
      setTextValue: setReportId,
      required: true,
      value: reportId,
    },
    statusText,
    ...(status === DashboardSearchStatus.SUCCESS
      ? [
          {
            label: "Title",
            name: "title",
            setTextValue: setTitle,
            required: true,
            value: title,
          },
          {
            label: "Web URL",
            name: "webUrl",
            setTextValue: setWebUrl,
            required: true,
            value: webUrl,
          },
          {
            label: "Embed URL",
            name: "embedUrl",
            setTextValue: setEmbedUrl,
            required: true,
            value: embedUrl,
          },
          {
            label: "Dataset ID",
            name: "datasetId",
            setTextValue: setDatasetId,
            required: true,
            value: dataSetId,
          },
          {
            name: "roles",
            required: true,
            render: () => (
              <MultipleSelectDropdown
                onChange={setRoles}
                value={roles}
                label="Roles"
                options={roleOptions}
              />
            ),
          },
        ]
      : []),
  ];

  const submit = () =>
    onSubmit({
      embedUrl,
      name: title,
      reportId,
      roles: roles.map((option) => option.value) as string[],
      webUrl,
      datasetId: dataSetId,
    }).then(() => history.push(PATH_ADMINISTRATION));

  const search = () => {
    setStatus(DashboardSearchStatus.SEARCHING);
    return searchReport(reportId)
      .then((result) => {
        setStatus(DashboardSearchStatus.SUCCESS);
        setReportId(result.reportId);
        setTitle(result.name);
        setWebUrl(result.weburl);
        setEmbedUrl(result.embedUrl);
        setDatasetId(result.datasetId);
      })
      .catch(() => setStatus(DashboardSearchStatus.FAILED));
  };

  return (
    <ParentContext.Provider value={{ parentLink: PATH_ADMINISTRATION }}>
      <Form
        className={styles.form}
        onSubmit={status === DashboardSearchStatus.SUCCESS ? submit : search}
        fields={fields}
      />
    </ParentContext.Provider>
  );
};

export default DashboardNew;
