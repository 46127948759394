import React, { useContext } from "react";
import { EntityTypeContext } from "../../../routing/GenericRoutes";
import { DropdownMenuItem, DropdownMenuItemType } from "../../DropdownMenu";
import Icon, { IconType } from "../../Icon";
import Permission from "../../../Authentication/model/Permission";
import PermissionArea from "../../../Authentication/model/PermissionArea";
import FavCheckbox from "../../../views/VerifyManagement/FavouritingAudits";
import updateVerifyFavouriteDetail from "../../../views/VerifyManagement/http/updateVerifyFavouriteDetail";

export enum DetailsPageMenuItem {
  CREATE = "CREATE",
  EDIT = "EDIT",
  ARCHIVE = "ARCHIVE",
  SHARE = "SHARE",
  CHANGE_LOG = "CHANGE_LOG",
  FAVOURITES ="FAVOURITE_AUDIT"
}

type MenuItems = {
  [key in DetailsPageMenuItem]: DropdownMenuItem;
};

export type MenuItemConfig = Partial<
  {
    [key in DetailsPageMenuItem]: Partial<DropdownMenuItem>;
  }
>;

export interface ArchiveMenuItemConfig {
  readonly entityName: string;
  readonly extraWarningText?: string;
  readonly isArchived: boolean;
  readonly onClick: () => void;
  readonly permissionArea?: PermissionArea;
};
export interface FavouriteMenuItemConfig{
  readonly isFavourited:boolean;
};

export default (
  id?: string | number,
  items: DetailsPageMenuItem[] = [],
  config: MenuItemConfig = {},
  archive?: ArchiveMenuItemConfig,
  favourite?:FavouriteMenuItemConfig,
): DropdownMenuItem[] => {
  const typeUrls = useContext(EntityTypeContext)?.urls;
  
  if (!typeUrls || !id) return [];
  const {
    isArchived,
    onClick,
    entityName,
    extraWarningText,
    permissionArea,
  }: ArchiveMenuItemConfig = archive ?? {
    entityName: "entity",
    isArchived: false,
    onClick: () => {},
  };
  const {
    isFavourited
  }:FavouriteMenuItemConfig = favourite ??{
    isFavourited:false,
  };
  const archiveText = !isArchived ? "Archive" : "Unarchive";

const favouriteText = !isFavourited? "Favourite Audit" : "Unfavourite Audit" ;
  const mappings: MenuItems = {
    [DetailsPageMenuItem.CREATE]: {
      label: "Create new",
      type: DropdownMenuItemType.LINK,
      link: typeUrls.create(),
      icon: <Icon type={IconType.ADD} />,
      permissionRequired: [{ permission: Permission.GENERATE }],
    },
    [DetailsPageMenuItem.EDIT]: {
      label: "Edit",
      type: DropdownMenuItemType.LINK,
      link: typeUrls.edit(id),
      icon: <Icon type={IconType.EDIT} />,
      permissionRequired: [{ permission: Permission.REVISE }],
    },
    [DetailsPageMenuItem.CHANGE_LOG]: {
      label: "Change Log",
      type: DropdownMenuItemType.LINK,
      link: typeUrls.changeLog(id),
      icon: <Icon type={IconType.CHANGE_LOG} />,
      permissionRequired: [{ permission: Permission.CHANGES }],
    },
    [DetailsPageMenuItem.SHARE]: {
      label: "Sharing",
      type: DropdownMenuItemType.LINK,
      link: typeUrls.share(id),
      icon: <Icon type={IconType.SHARE} />,
      permissionRequired: [],
    },
    [DetailsPageMenuItem.ARCHIVE]: {
      label: archiveText,
      type: DropdownMenuItemType.BUTTON_CONFIRM,
      dialogHeader: `Are you sure you want to ${archiveText.toLowerCase()} the ${entityName}? ${
        extraWarningText || ""
      }`,
      declineButtonText: "Cancel",
      acceptButtonText: !isArchived ? "Archive" : "Unarchive",
      onConfirm: onClick,
      icon: <Icon type={isArchived ? IconType.UNARCHIVE : IconType.ARCHIVE} />,
      permissionRequired: [
        { area: permissionArea, permission: Permission.PERSIST },
      ],
    },
    [DetailsPageMenuItem.FAVOURITES]:{
      label:favouriteText,
      type: DropdownMenuItemType.BUTTON,
      onClick:updateVerifyFavouriteDetail(id),
      icon: <Icon type={isFavourited? IconType.UNFAVOURITE : IconType.FAVOURTITE } />,
      permissionRequired: [],
    }
  };

  return Object.keys(mappings)
    .filter((menuItem) => items.includes(menuItem as DetailsPageMenuItem))
    .map((itemKey) => {
      const key = itemKey as DetailsPageMenuItem;
      const itemData = mappings[key];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, ...configRest } = config[key] ?? {};
      return {
        ...itemData,
        ...configRest,
      };
    });
};
