import React from "react";
import Column from "../../../../../../components/DataTable/models/Column";
import AuditorRow from "../models/AuditorRow";
import { LinkText } from "../../../../../../components/Common/hyperlinks";
import { AuditorUrls } from "../../../../index";

const auditorColumns: Column<AuditorRow>[] = [
  {
    title: "Auditor Number",
    field: "auditorNumber",
  },
  {
    title: "Name",
    field: "name",
    render: (rowData: AuditorRow) => (
      <LinkText link={AuditorUrls.details(rowData.Id)} text={rowData.name} />
    ),
  },
  {
    title: "Shared",
    field: "shared",
    render: (data) => (data.shared ? "Yes" : "No"),
  },
];

export default auditorColumns;
