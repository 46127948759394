import { AxiosResponse } from "axios";
import NamedEntity from "../../Models/APIGetData/NamedEntity";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import { certificationStatus } from "../../components/Paths/paths";

const getCertificationStatuses = (): Promise<AxiosResponse<NamedEntity[]>> => {
  return axiosApi.get(
    `${BackendServices.AUDIT_SERVICE.AUDIT}${certificationStatus}`
  );
};

export default getCertificationStatuses;
