import React from "react";
import { Route, Switch } from "react-router-dom";
import ContentPage from "../../components/ContentPage";
import AdministrationDashboard from "./Dashboard";
import { PATH_ADMINISTRATION } from "../../components/Paths/paths";

export interface AdminPageProps {
  readonly index: number;
}

export const AdminPages: {
  readonly name: string;
  readonly component: (props: AdminPageProps) => JSX.Element;
  readonly path: string;
}[] = [{ name: "Analytics", component: AdministrationDashboard, path: "" }];

const Administration = () => {
  return (
    <ContentPage title="Administration">
      <Switch>
        {AdminPages.map((route, index) => (
          <Route
            key={route.path}
            path={`${PATH_ADMINISTRATION}/${route.path}`}
            render={() => <route.component index={index} />}
          />
        ))}
      </Switch>
    </ContentPage>
  );
};

export default Administration;
