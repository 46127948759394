import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (link: string, fileName: string) =>
  axiosApi
    .get(`${BackendServices.AUDIT_SERVICE.AUDIT}/DownloadDocument`, {
      params: { link },

      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const innerLink = document.createElement("a");
      innerLink.href = url;

      innerLink.setAttribute("download", `${fileName}`);
      document.body.appendChild(innerLink);
      innerLink.click();
      if (innerLink.parentNode != null) {
        innerLink.parentNode.removeChild(innerLink);
      }
    })
    .catch((error) => console.log(error));
