import React from "react";
import { AxiosResponse } from "axios";
import Option from "../../../../Models/forms/Option";
import isNumber from "../../../../util/number/isNumber";
import DebouncedSearchSelector from "../../../../components/DebouncedSearchSelector";
import getAuditDetailsForRecall from "../../../../http/audit/getAuditDetailsForRecall";
import AuditDetailsView from "../../../../Models/Audit/AuditDetailsView";

interface AuditSelectorProps {
  readonly value: Option<string> | null;
  readonly onChange: (value: Option<string> | null) => void;
  readonly label?: string;
  readonly required?: boolean;
}

const mapResponse = (data: AuditDetailsView): Option<string>[] =>
  data ? [{ value: data.id, label: data.id?.toString() }] : [];

const AuditSelector = ({
  onChange,
  value,
  label = "Audit ID",
  required,
}: AuditSelectorProps) => {
  const fetchAudit = (
    fetchFunction: () => Promise<AxiosResponse<AuditDetailsView>>
  ): Promise<Option<string>[]> =>
    fetchFunction()
      .then(({ data }) => mapResponse(data))
      .catch(() => Promise.resolve([]));

  const getAudit: (input: string) => Promise<Option<string>[]> = (input) =>
    Promise.all([
      fetchAudit(() => getAuditDetailsForRecall(input)),
    ]).then((data) => data.flat());

  return (
    <DebouncedSearchSelector
      label={label}
      getOptions={getAudit}
      onSelect={onChange}
      name="AuditSelector"
      value={value}
      validateInput={(input: any) =>
        input.length === 0 || isNumber(input) || input === value?.label
      }
      required={required}
    />
  );
};

export default AuditSelector;
