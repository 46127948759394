import React, { ChangeEvent, useRef, useState } from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";
import { DatePicker } from "../../../../../components/Common/Input/DatePicker";
import styles from "./EditableField.module.scss";
import { AuditEntryValue, AuditFieldType } from "../../models/AuditTabs";

interface EditableTextProps {
  readonly value: AuditEntryValue;
  readonly xmlTag: string;
  readonly isEditing: boolean;
  readonly type?: AuditFieldType;
  readonly setUpdateValue: (xmlTag: string, value: AuditEntryValue) => void;
}
const EditableText = ({
  value,
  xmlTag,
  setUpdateValue,
  isEditing,
  type = "Text Field",
}: EditableTextProps) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const textArea = useRef<HTMLTextAreaElement>(null);
  const formattedValue = Array.isArray(value)
    ? (value as string[]).join(";")
    : value;
  if (isEditing && type !== "Locked Field") {
    const onChange: (
      event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => void = ({ target: { value: nextValue } }) =>
      setUpdateValue(
        xmlTag,
        Array.isArray(value) ? nextValue.split(";") : nextValue
      );
    switch (type) {
      case "Calendar": {
        return (
          <DatePicker
            minDateMessage=""
            maxDateMessage=""
            value={value}
            onChange={(event) => {
              setUpdateValue(xmlTag, event?.toISOString() ?? "");
              if (openCalendar) {
                setOpenCalendar(false);
              }
            }}
            open={openCalendar}
            onClick={() => setOpenCalendar(true)}
            onClose={() => setOpenCalendar(false)}
          />
        );
      }
      case "Text Field": {
        return (
          <textarea
            ref={textArea}
            className={styles.editableTextArea}
            value={formattedValue}
            onChange={onChange}
            onInput={() => {
              const element = textArea.current;
              if (element) {
                element.style.height = `5px`;
                element.style.height = `${element.scrollHeight}px`;
              }
            }}
          />
        );
      }
      default:
        return (
          <input
            className={styles.EditableField}
            value={formattedValue}
            type={type === "Numeric Field" ? "number" : "text"}
            onClick={(event) => event.stopPropagation()}
            onChange={onChange}
          />
        );
    }
  }

  if (type === "Calendar") {
    return <p>{moment(value).format(DEFAULT_DATE_FORMAT)}</p>;
  }

  const textContent = Array.isArray(value) ? value.join(", ") : `${value}`;
  if (textContent.length === 0)
    return <p className={styles.missingData}>Missing</p>;
  return <p className={styles.textContent}>{textContent}</p>;
};

export default EditableText;
