import React from "react";
import ContentPage from "../ContentPage";
import { H2Heading } from "../Common/Heading";
import { FieldValidator } from "../FieldValidator";
import Form from "../Form";

export interface Field {
  readonly name: string;
  readonly label?: string;
  readonly validators?: FieldValidator[];
  readonly value?: string | boolean;
  readonly setTextValue?: (value: string) => void;
  readonly setBooleanValue?: (value: boolean) => void;
  readonly render?: () => JSX.Element | null;
  readonly wrap?: boolean;
  readonly disabled?: boolean;
  readonly show?: boolean;
  readonly header?: string;
  readonly required?: boolean;
}

interface CreatePageProps {
  readonly title?: string;
  readonly subtitle?: string;
  readonly fields: (Field | string)[];
  readonly onSubmit: () => Promise<any>;
  readonly actionButtonArea?: JSX.Element;
}

const CreatePage = ({
  title,
  fields,
  onSubmit,
  subtitle,
  actionButtonArea,
}: CreatePageProps) => {
  return (
    <ContentPage title={title} subtitle={subtitle}>
      <H2Heading text="Details" />
      <Form
        onSubmit={onSubmit}
        fields={fields}
        actionButtonArea={actionButtonArea}
      />
    </ContentPage>
  );
};

export default CreatePage;
