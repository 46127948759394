import React, { useState } from "react";
import CreatePage, { Field } from "../../../components/CreatePage";
import PostRecall, { PostRecallBody } from "./http/PostRecall";
import Option from "../../../Models/forms/Option";
import RecallCategoryDropdown from "./components/RecallCategoryDropdown";
import { DatePicker } from "../../../components/Common/Input/DatePicker";
import { InputField } from "../../../components/InputField";
import SiteOrSupplierDropdown from "./components/SiteOrSupplierDropdown";
import { isBRCGSUser, isCBUser } from "../../../Authentication/Auth";
import CertificationBodySelector from "../../../components/CertificationBodySelector";
import ReasonForNotificationDropdown from "./components/ReasonForNotificationDropdown";
import AuditSelector from "./components/AuditSelector";
import RecallCertificationStatusDropdown from "./components/RecallCertificationStatusDropdown";

const RecallNew = () => {
  const [
    reasonForNotification,
    setReasonForNotification,
  ] = useState<Option | null>(null);
  const [recallCategory, setRecallCategory] = useState<Option | null>(null);
  const [certificationStatus, setCertificationStatus] = useState<Option | null>(
    null
  );
  const [productRecalled, setProductRecalled] = useState("");
  const [outlineOfIncident, setOutlineOfIncident] = useState("");
  const [recallDate, setRecallDate] = useState(undefined);
  const [correction, setCorrection] = useState("");
  const [rootCauseAnalysis, setRootCauseAnalysis] = useState("");
  const [preventiveActionTaken, setPreventiveActionTaken] = useState("");
  const [finalSubmissionDate, setFinalSubmissionDate] = useState(undefined);
  const [initialNotificationDate, setInitialNotificationDate] = useState(
    undefined
  );
  const [brcgsComments, setBrcgsComments] = useState("");
  const [brcgsKeyWords, setBrcgsKeyWords] = useState("");
  const [siteOrSupplierIssue, setSiteOrSupplierIssue] = useState<Option | null>(
    null
  );
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [certificationBody, setCertificationBody] = useState<Option | null>(
    null
  );
  const [
    certificationStatusLastUpdated,
    setCertificationStatusLastUpdated,
  ] = useState(undefined);

  const [audit, setAudit] = useState<Option<string> | null>(null);

  const handleSubmit = () => {
    const recall: PostRecallBody = {
      reasonForNotification:
        reasonForNotification?.value.toString() ?? undefined,
      recallCategoryId: recallCategory
        ? parseInt(recallCategory.value.toString(), 10)
        : undefined,
      outlineOfIncident,
      productRecalled,
      recallDate,
      correction,
      siteOrSupplierIssue: siteOrSupplierIssue?.value.toString() ?? undefined,
      additionalInformation,
      certificationStatusId: certificationStatus
        ? parseInt(certificationStatus.value.toString(), 10)
        : undefined,
      certificationBodyId: certificationBody
        ? parseInt(certificationBody.value.toString(), 10)
        : undefined,
      certificationStatusLastUpdated,
      auditId: audit ? parseInt(audit.value.toString(), 10) : undefined,
      rootCauseAnalysis,
      preventiveActionTaken,
      finalSubmissionDate,
      brcgsComments,
      brcgsKeyWords,
      initialNotificationDate,
    };

    return PostRecall(recall);
  };

  const fields: Field[] = [
    {
      name: "Audit",
      label: "Audit",
      render: () => (
        <>
          <AuditSelector value={audit} onChange={setAudit} required />
        </>
      ),
      required: true,
    },
    {
      name: "Reason For Notification",
      label: "Reason For Notification",
      render: () => (
        <>
          <ReasonForNotificationDropdown
            value={reasonForNotification}
            onChange={setReasonForNotification}
            required
          />
        </>
      ),
      required: true,
    },
    {
      name: "Initial Notification Date",
      label: "Initial Notification Date",
      render: () => (
        <>
          <DatePicker
            value={initialNotificationDate || null}
            label="Initial Notification Date"
            onChange={(d: any) => setInitialNotificationDate(d)}
            onClear={() => setInitialNotificationDate(undefined)}
          />
        </>
      ),
      required: true,
    },
    {
      name: "Recall Category",
      label: "Incident Category",
      render: () => (
        <>
          <RecallCategoryDropdown
            value={recallCategory}
            onChange={setRecallCategory}
            required
          />
        </>
      ),
      required: true,
    },
    {
      name: "Outline of Incident",
      label: "Outline of Incident",
      render: () => (
        <>
          <InputField
            label="Outline of Incident"
            value={outlineOfIncident}
            multiline
            onChange={(value: any) => setOutlineOfIncident(value)}
            required
          />
        </>
      ),
      required: true,
    },
    {
      name: "Products Recalled",
      label: "Products Recalled",
      render: () => (
        <>
          <InputField
            label="Products Recalled"
            value={productRecalled}
            multiline
            onChange={(value: any) => setProductRecalled(value)}
            required
          />
        </>
      ),
      required: true,
    },
    {
      name: "Date of Recall",
      label: "Date of Recall",
      render: () => (
        <>
          <DatePicker
            value={recallDate || null}
            label="Date of Recall"
            onChange={(d: any) => setRecallDate(d)}
            onClear={() => setRecallDate(undefined)}
          />
        </>
      ),
      required: true,
    },
    {
      name: "Correction",
      label: "Correction",
      render: () => (
        <>
          <InputField
            label="Correction"
            value={correction}
            multiline
            onChange={(value: any) => setCorrection(value)}
            required
          />
        </>
      ),
      required: true,
    },
    {
      name: "Site or Supplier Issue",
      label: "Site or Supplier Issue",
      render: () => (
        <>
          <SiteOrSupplierDropdown
            value={siteOrSupplierIssue}
            onChange={setSiteOrSupplierIssue}
            required
          />
        </>
      ),
    },
    {
      name: "Certification Status",
      label: "Certification Status",
      render: () => (
        <>
          <RecallCertificationStatusDropdown
            value={certificationStatus}
            onChange={setCertificationStatus}
            required
          />
        </>
      ),
      required: true,
    },
    {
      name: "Date of Certification Status Change",
      label: "Date of Certification Status Change",
      render: () => (
        <>
          <DatePicker
            value={certificationStatusLastUpdated || null}
            label="Date of Certification Status Change"
            onChange={(d: any) => setCertificationStatusLastUpdated(d)}
            onClear={() => setCertificationStatusLastUpdated(undefined)}
          />
        </>
      ),
      required: false,
    },
    {
      name: "Root Cause Analysis",
      label: "Root Cause Analysis",
      render: () => (
        <>
          <InputField
            label="Root Cause Analysis"
            value={rootCauseAnalysis}
            multiline
            onChange={(value: any) => setRootCauseAnalysis(value)}
          />
        </>
      ),
      required: true,
    },
    {
      name: "Preventive Action Taken",
      label: "Preventive Action Taken",
      render: () => (
        <>
          <InputField
            label="Preventive Action Taken"
            value={preventiveActionTaken}
            multiline
            onChange={(value: any) => setPreventiveActionTaken(value)}
          />
        </>
      ),
      required: true,
    },
    {
      name: "Final Submission Date",
      label: "Final Submission Date",
      render: () => (
        <>
          <DatePicker
            value={finalSubmissionDate || null}
            label="Final Submission Date"
            onChange={(d: any) => setFinalSubmissionDate(d)}
            onClear={() => setFinalSubmissionDate(undefined)}
          />
        </>
      ),
      required: false,
    },
    {
      name: "Additional Information",
      label: "Additional Information",
      render: () => (
        <>
          <InputField
            label="Additional Information"
            value={additionalInformation}
            multiline
            onChange={(value: any) => setAdditionalInformation(value)}
          />
        </>
      ),
      required: false,
    },
  ];

  if (!isCBUser()) {
    fields.push({
      name: "Certification Body",
      label: "Certification Body",
      render: () => (
        <>
          <CertificationBodySelector
            required
            value={certificationBody}
            onChange={setCertificationBody}
          />
        </>
      ),
    });
  }

  if (isBRCGSUser()) {
    fields.push({
      name: "Brcgs Comments",
      label: "Brcgs Comments",
      render: () => (
        <InputField
          label="Brcgs Comments"
          value={brcgsComments}
          multiline
          onChange={(value: any) => setBrcgsComments(value)}
        />
      ),
    });

    fields.push({
      name: "Brcgs Keywords",
      label: "Brcgs Keywords",
      render: () => (
        <InputField
          label="Brcgs Keywords"
          value={brcgsKeyWords}
          multiline
          onChange={(value: any) => setBrcgsKeyWords(value)}
        />
      ),
    });
  }

  return (
    <>
      <CreatePage
        title="Add new recall"
        onSubmit={handleSubmit}
        fields={fields}
      />
    </>
  );
};

export default RecallNew;
