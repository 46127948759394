import React from "react";
import Column from "../../../components/DataTable/models/Column";
import ChangeLogRow from "../models/ChangeLogRow";
import styles from "../ChangeLog.module.scss";

const mapEntries = (value: string, index: number) => (
  <li key={index}>{value}</li>
);

const changeLogColumns: Column<ChangeLogRow>[] = [
  {
    title: "Change Date",
    field: "changeDate",
    type: "datetime",
  },
  {
    title: "Change By",
    field: "changeBy",
  },
  {
    title: "Event",
    field: "event",
  },
  {
    title: "Fields Changed",
    field: "fieldsChanged",
    render: (rowData: ChangeLogRow) => (
      <ul className={styles.changeLogList}>
        {rowData.fieldsChanged.map(mapEntries)}
      </ul>
    ),
    sorting: false,
  },
  {
    title: "Old Value",
    field: "oldValues",
    render: (rowData: ChangeLogRow) => (
      <ul className={styles.changeLogList}>
        {rowData.oldValues.map(mapEntries)}
      </ul>
    ),
    sorting: false,
  },
  {
    title: "New Value",
    field: "newValues",
    render: (rowData: ChangeLogRow) => (
      <ul className={styles.changeLogList}>
        {rowData.newValues.map(mapEntries)}
      </ul>
    ),
    sorting: false,
  },
];
export default changeLogColumns;
