import React from "react";
import axios from "axios";
import axiosApi from "../../Api/Axios";
import AccreditationBodyData from "../../../Models/APIGetData/AccreditationBodyData";
import AccreditationBodyDropdownProps from "../../../Models/CommonProps/AccreditationBodyDropdownProps";
import { AccreditationBodies } from "../../Paths/paths";
import BackendServices from "../../Api/BackendService";
import SelectField from "../../SelectField";
import Option from "../../../Models/forms/Option";
import sortNameAlphanumerical from "../../../util/sorting/sortNameAlphanumerical";

export default class AccreditationBodyDropdown extends React.Component<
  AccreditationBodyDropdownProps,
  { options: Option[] }
> {
  signal = axios.CancelToken.source();

  constructor(props: AccreditationBodyDropdownProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getAccreditationBodyOptions();
  }

  componentWillUnmount() {
    this.signal.cancel("Accreditation body selector unmounted");
  }

  getAccreditationBodyOptions(): void {
    axiosApi
      .get<AccreditationBodyData[]>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}${AccreditationBodies}`,
        {
          cancelToken: this.signal.token,
        }
      )
      .then((response) => {
        this.setState(() => ({
          options: response.data.sort(sortNameAlphanumerical).map((ab) => ({
            value: ab.id,
            label: ab.name ?? "",
          })),
        }));
      })
      .catch((error) => console.log(error));
  }

  render() {
    const {
      required: isRequired,
      accreditationBody,
      updateAccreditationBody,
    } = this.props;
    const { options } = this.state;
    return (
      <SelectField
        required={isRequired}
        name="AccreditationBody"
        label="Accreditation Body"
        value={accreditationBody}
        onChange={updateAccreditationBody}
        options={options}
      />
    );
  }
}
