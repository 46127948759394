import React from "react";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import useGetDashboards from "./http/useGetDashboards";
import Spinner from "../../../../components/Spinner";

interface DashboardSelectorProps {
  readonly onDashboardSelect: (id: number) => void;
  readonly className?: string;
}

const DashboardSelector = ({
  onDashboardSelect,
  className,
}: DashboardSelectorProps) => {
  const { isLoadingDashboards, dashboards } = useGetDashboards();
  if (isLoadingDashboards) return <Spinner />;
  return (
    <SearchableDropdown
      className={className}
      Options={dashboards.map((dashboard) => ({
        value: dashboard.id,
        label: dashboard.name,
      }))}
      onChange={(e, newValue) =>
        newValue?.value && onDashboardSelect(+newValue.value)
      }
      label="Select a dashboard"
      required={null}
    />
  );
};

export default DashboardSelector;
