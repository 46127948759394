import React from "react";
import { LinkText } from "../../../components/Common/hyperlinks";

function PrivacyPolicy() {
  return (
    <div>
      <img
        src="images/PrivacyNotice.png"
        className="responsive"
        alt="Privacy Notice"
      />

      <h1>Privacy and Protection of Personal Information</h1>

      <p>
        This Privacy Notice explains how BRCGS obtains, uses and discloses your
        personal data and how you can contact us if you have any questions or
        want to exercise any of your privacy rights. References to &#34;we&#34;
        and &#34;us&#34; in this Privacy Notice are references to BRCGS.
      </p>

      <p>
        BRC Trading Limited (trading as BRCGS), is the controller of your data.
        BRCGS takes its privacy responsibilities seriously and has implemented
        measures designed to protect your personal data and ensure compliance
        with applicable laws. For information about how to contact us, please go
        to Section 15.
      </p>

      <h2>1. How we get personal data about you</h2>

      <p>We may get personal data directly from you, for example, if you:</p>

      <ul>
        <li>
          visit, register to use, or interact with any of our websites (such as
          the BRCGS Bookshop or our BRCGS e-Learning site)
        </li>
        <li>
          purchase any of our products or sign up to receive our services or
          attend our events
        </li>
        <li>
          create a profile to use our products or services (for example on our
          BRCGS Educate site)
        </li>
        <li>
          sign up to receive newsletters or other promotional information from
          us
        </li>
        <li>supply goods and services to us</li>
        <li>
          otherwise interact with us in any way (e.g. by giving us your business
          card in a meeting or downloading a whitepaper from our website)
        </li>
      </ul>

      <p>
        We may also get personal data about you indirectly from third parties,
        for example from:
      </p>

      <ul>
        <li>social media sites (such as LinkedIn)</li>
        <li>
          our Approved Training Partners (&#34;ATPs&#34;) or Approved Training
          Establishments (&#34;ATEs&#34;) if you participate in any of their
          training events
        </li>
        <li>third party organised events and conferences</li>
        <li>Certification Bodies (e.g. in connection with auditing)</li>
      </ul>

      <h2>2. What personal data do we get about you</h2>

      <p>
        The personal data we get about you may include any of the following
        (depending on the nature of our relationship):
      </p>

      <ul>
        <li>
          <b>Personal and Business Contact Details</b> &#8208; such as your
          name, physical address, email address, telephone/mobile phone
          (personal and/or business), company you work for, company address,
          your job title/position
        </li>
        <li>
          <b>Customer Details and Purchase History</b> &#8208; such as username,
          password (if you register to use any of our products and services you
          may be asked to create a profile), customer ID, details of products or
          services purchased, and order, shipping and billing details
        </li>
        <li>
          <b>Marketing Preferences</b> &#8208; such as whether or not you have
          asked to receive marketing communications from us and what type of
          information you are interested in receiving
        </li>
        <li>
          <b>Your Profile Details</b> &#8208; when you access some of our
          products or services (such as Educate) you may be asked to create a
          profile which will help us to track your learning activities, access
          to course materials, achievements (e.g. exam results or digital badges
          earned). Your profile may also enable you to gain access to other
          services we offer, such as BRCGS Professional
        </li>
        <li>
          <b>Interactions with our Online Services and Website(s)</b> &#8208;
          such as how many times you visit our site or use our services, which
          pages you go to, traffic data, location data and the originating
          domain name of your internet service provider (obtained through the
          use of cookies on this site &#8208; see further Section 10 for how we
          used cookies)
        </li>
        <li>
          <b>Data from Social Media Sources</b> &#8208; if you provide
          information about yourself on third party websites or social media
          sites, we may use that information to get to know you better and for
          our marketing purposes
        </li>
        <li>
          <b>Data from Third Parties</b> &#8208; if you have a relationship with
          third parties with whom we do business (for example, you have attended
          training courses provided by our ATEs or ATEPs, or you have
          participated in an audit carried out by a Certification Body, or you
          work for one of our suppliers of products and services), then we will
          obtain from those parties limited personal information about you, such
          as name, contact details, your location and the organisation for whom
          you work (if relevant)
        </li>
      </ul>

      <p>
        When you fill in one of our forms, we will indicate where the provision
        of specific personal data is mandatory in order for you to receive the
        product or service you are requesting. If you do not provide this
        mandatory information (e.g. your name and address when purchasing a
        product, or your email address when signing up for our newsletter) we
        will not be able to complete your request.
      </p>

      <h2>3. Purposes for which we use your personal data</h2>

      <p>
        We may use and disclosure your personal data (which we obtain as
        described above) for any of the following purposes:
      </p>

      <ul>
        <li>
          <b>Providing Products and Services to you</b> &#8208; including
          processing your personal data for the purpose of account
          administration and management, order fulfilment, delivery, managing
          customer relations, billing and payment administration, fraud
          detection and prevention, providing customer support services,
          notifying you of developments in procedures or products which we
          believe will assist you in the use of the product or service you have
          purchased, and handling complaints and enquiries
        </li>
        <li>
          <b>Direct Marketing, Including Profiling and Analytics</b> &#8208;
          including processing your personal data to send you direct marketing
          communications, profiling and analysing customer interests, behaviour
          and preferences (to help us better understand our customers, improve
          our products and services and provide more tailored marketing
          communications and enhance customer satisfaction), marketing research
        </li>
        <li>
          <b>Conducting our Business, Administration and Management</b> &#8208;
          including administering and managing our business activities,
          contracts and relationships with customers, suppliers and partners;
          maintaining our website(s); providing services to customers; managing
          and responding to data subject requests; and giving effect to customer
          marketing preferences
        </li>
        <li>
          <b>Online Tracking and Analysis</b> &#8208; including using cookies
          and similar technologies to track visitors to our sites and measure
          and analyse their use of our sites &#8208; see further Section 10
        </li>
      </ul>

      <h2>4. Disclosures of your personal data</h2>

      <p>We will only disclose your data to:</p>

      <ul>
        <li>
          other companies within the BRCGS group in connection with the purposes
          described in Section 3
        </li>
        <li>
          our third-party service providers in connection with the services they
          are providing on our behalf, which may include hosting, software as a
          service, delivery and logistics, electronic payments systems, IT
          support services, and marketing related services. If we disclose your
          data to our third -party service providers, we will ensure it is
          protected under an appropriate contract and only used by our providers
          in connection with the services
        </li>
      </ul>

      <p>
        If you are identified in an audit report (e.g. as a point of contact for
        a Certification Body or an auditor), your personal data may be disclosed
        to third parties (such as our customers) who request access to that
        report. Typically, your personal data is limited (e.g. an email or
        name). Rest assured that we will put in place safeguards to protect your
        personal data whenever we make such a disclosure.
      </p>

      <h2>5. How long we keep your personal data</h2>

      <p>
        We will keep your personal data for as long as necessary in connection
        with the purpose for which we have obtained it (see Section 3) and in
        line with our internal retention policy.
      </p>

      <h2>6. Legal basis for processing</h2>

      <p>
        As the controller of your personal data, BRCGS is responsible for
        complying with applicable data protection laws. When we collect, use and
        otherwise process your personal data (for the purposes described in
        Section 3) we do so based on the following legal grounds:
      </p>

      <ul>
        <li>
          where you purchase products or services from us, we process your
          personal data on the legal basis that it is necessary for the
          performance of the contract for the sale of those products and
          services, including taking payment, delivery and related after sales
          activities
        </li>
        <li>
          where we process your personal data for direct marketing, including
          profiling and analytics, we do so on the legal basis that you have
          either given us your consent (e.g. by ticking an opt in box) or it is
          in our legitimate interests to do so provided that our interests do
          not override your interests that require protection of your personal
          data
        </li>
        <li>
          where we process your personal data for administration and management,
          we do so on the legal basis that it is in our legitimate interests to
          do so provided that our interests do not override your interests that
          require protection of your personal data
        </li>
        <li>
          where we process your personal data in connection with the use of
          cookies and similar technologies, we will do so on the legal basis
          that we have obtained your consent (this is requested when you first
          land on our website)
        </li>
      </ul>

      <p>
        Where you have consented to the processing of your data, you may
        withdraw that consent at any time by contacting us &#8208; see Section
        14.
      </p>

      <h2>7. Managing your marketing preferences</h2>

      <p>
        When we process your personal data for marketing purposes, as described
        in Section 3 and Section 6, we may contact you by email, SMS and/or
        post, as well as by telephone (if you have provided us with your
        number). You can unsubscribe from marketing communications at any time
        by:
      </p>

      <ul>
        <li>contacting us using the details in Section 14</li>
        <li>
          clicking on the unsubscribe link in any marketing message you receive
          from us
        </li>
        <li>
          managing your marketing preferences via the preference centre
          accessible here &#8208; this will enable you to unsubscribe from all
          communications or select the ones you would prefer to receive
          (including the means by which we may contact you)
        </li>
      </ul>

      <p>
        Please note it can take up to 30 days for your unsubscribe request to be
        implemented and for future communications to cease, in that time you may
        receive messages that have already been scheduled for sending.
      </p>

      <p>Under EU data protection laws, you have the right to:</p>

      <ul>
        <li>
          <b>Access your Personal Data</b> &#8208; you have the right to receive
          a copy of the personal data we hold about you. We may require the
          request to be in writing, accompanied by proof of identity (to ensure
          we only provide the data to the right person)
        </li>
        <li>
          <b>Withdraw your Consent to Direct Marketing</b> &#8208; you can
          exercise your right to withdraw consent to marketing at any time by
          contacting us and telling us you no longer want to receive marketing
          from us
        </li>
        <li>
          <b>Rectification</b> &#8208; if you think any of the personal data, we
          hold about you is inaccurate, you can ask us to correct it. Simply
          contact us and include your name, address and/or email address (this
          will help us ensure we accept amendments only from the correct person
          &#8208; we may ask for proof of identity in some cases)
        </li>
        <li>
          <b>Restriction</b> &#8208; in limited circumstances you may be able to
          require us to restrict our processing of your personal data. For
          example, if you consider the data, we hold is inaccurate and we
          disagree with you, the processing of that data may be restricted until
          the accuracy has been verified
        </li>
        <li>
          <b>Erasure</b> &#8208; you may ask us to delete your data if you think
          that we no longer have a lawful basis for holding onto it, or if you
          just want to withdraw your consent to our use. Please contact us and
          tell us what data you want us to delete and why
        </li>
        <li>
          <b>Portability</b> &#8208; in limited circumstances you may be
          entitled to have the personal data you have provided to us sent
          electronically to you so that you can provide it to another
          organisation
        </li>
        <li>
          <b>Complain to the Information Commissioner&#39;s Office</b> &#8208;
          you have the right to lodge a complaint with the Information
        </li>
      </ul>

      <p>
        Commissioner&#39;s Officer if you think our processing of your personal
        data infringes applicable law. You can find information on how to do
        this at{" "}
        <LinkText
          link="https://www.ico.org.uk"
          className="url-links"
          text="https://www.ico.org.uk"
        />
      </p>

      <p>
        To exercise any of your rights, please contact us using the details set
        out in Section 14.
      </p>

      <h2>9. Security</h2>

      <p>
        We take the security of personal data very seriously. We employ security
        technology, including firewalls and encryption to safeguard personal
        data and have procedures in place to ensure that our systems and
        databases are protected against unauthorised disclosure, use, loss and
        damage.
      </p>

      <p>
        Personal data on our systems is only accessible by appropriately trained
        staff and approved third-party service providers who need to access your
        personal data as part of their job. All access is tracked through
        individual login credentials and audit trails.
      </p>

      <p>
        We only use third party service providers where we are satisfied that
        the security, they provide for your personal data is at least as
        protective as the security we use ourselves.
      </p>

      <h2>10. Transfers of your data out of the EU</h2>

      <p>
        We may sometimes make transfers of personal data to countries outside
        the European Union, for example, to our group companies and/or the third
        parties identified in Section 4 who may be located in countries such as
        the United States, Canada and India. If we transfer personal data out of
        the European Union, we will take appropriate measures to ensure that
        such data is protected in accordance with this Privacy Notice and
        applicable privacy laws.
      </p>

      <h2>11. Cookies</h2>

      <p>
        We use cookies when you visit our website(s). For more information,
        please see our Cookie Policy. If you do not want to accept cookies, you
        can change your browser settings so that cookies are not accepted. If
        you do this, please be aware that you may lose some of the functionality
        of this website. For further information about cookies, including how to
        disable them, please go to{" "}
        <LinkText
          link="https://www.AboutCookies.org"
          text="AboutCookies.org"
          external
        />
        .
      </p>

      <h2>12. Links to other websites</h2>

      <p>
        Please note, this website may contain links to other websites that are
        not controlled by us. These links are provided for your convenience. We
        are only responsible for our privacy practices and our security. We
        recommend you check the privacy policies for any other websites that you
        visit.
      </p>

      <h2>13. Changes to this privacy notice</h2>

      <p>
        Privacy laws and practice are constantly developing, and we aim to meet
        industry standards. Our policies and procedures are, therefore, under
        regular review. We may, from time to time, update our security and
        privacy policies. We will ensure our website has our most up to date
        Privacy Notice and suggest you check this page periodically to review
        our latest version.
      </p>

      <h2>14. Contact us</h2>

      <p>
        For any queries about this Privacy Notice or to exercise any of your
        privacy rights, please contact us at:
      </p>

      <ul>
        <li>
          <b>By Email:</b>{" "}
          <LinkText
            link="mailto:submissions@brcgs.com"
            text="submissions@brcgs.com"
            external
          />
        </li>
        <li>
          <b>By Post:</b> BRCGS Customer Service, 2nd floor, 7 Harp Lane, London
          EC3R 6DP
        </li>
      </ul>
    </div>
  );
}

export default PrivacyPolicy;
