import { useEffect, useState } from "react";
import Option from "../../../../Models/forms/Option";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (): Option[] => {
  const [clauses, setClauses] = useState<Option[]>([]);

  useEffect(() => {
    axiosApi
      .get<string[]>(`${BackendServices.REPORT_SERVICE.REPORT}/data/clauses`)
      .then(({ data }) =>
        setClauses(data.map((value) => ({ label: value, value })))
      );
  }, []);
  return clauses;
};
