import React from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AuditorQualification, {
  QualificationType,
} from "../models/AuditorQualification";
import DialogBoxButton, { DialogButtonProps } from "../../../DialogBoxButton";
import { SecondaryButton } from "../../../Button/button";
import Option from "../../../../Models/forms/Option";
import InputField from "../../../InputField";
import SelectField from "../../../SelectField";
import AuditorStatus from "../../../../Enums/AuditorStatus";

interface QualificationSelectorProps {
  readonly groupType: QualificationType;
  readonly qualification: AuditorQualification;
  readonly availableQualificationOptions: AuditorQualification[];
  readonly updateQualification: (next: Partial<AuditorQualification>) => void;
  readonly isEditAfterValidation: boolean;
  readonly deleteQualification: (qualificationId: number) => void;
  readonly suspendQualification: (qualificationId: number) => void;
  readonly unsuspendQualification: (qualificationId: number) => void;
  readonly removeQualification: () => void;
}

const QualificationSelector = ({
  groupType,
  qualification,
  availableQualificationOptions,
  updateQualification,
  deleteQualification,
  suspendQualification,
  unsuspendQualification,
  isEditAfterValidation,
  removeQualification,
}: QualificationSelectorProps) => {
  const mapQualificationToOption = (q: AuditorQualification): Option => ({
    value: q.entityId,
    label: q.entityName,
  });
  const selectedQualificationOption = mapQualificationToOption(qualification);

  const mapOptions = (): Option[] => [
    ...availableQualificationOptions.map(mapQualificationToOption),
    selectedQualificationOption,
  ];

  const deleteQualificationWarning = (qualificationId: number) => {
    const dialogHeader = `Are you sure you want to delete this ${groupType.toLowerCase()}?`;
    const deleteOption: DialogButtonProps = {
      dialogHeader,
      declineButtonText: "Cancel",
      acceptButtonText: "Delete",
      openButton: "Delete",
      onConfirm: () => deleteQualification(qualificationId),
    };
    return deleteOption;
  };

  const suspendQualificationWarning = (qualificationId: number) => {
    const dialogHeader = `Are you sure you want to suspend this ${groupType.toLowerCase()}?`;
    const suspendOption: DialogButtonProps = {
      dialogHeader,
      declineButtonText: "Cancel",
      acceptButtonText: "Suspend",
      openButton: "Suspend",
      onConfirm: () => suspendQualification(qualificationId),
    };
    return suspendOption;
  };

  return (
    <Accordion
      key={qualification.entityId}
      className="category-accordion"
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <SelectField
          name={groupType}
          required
          label={`Select ${groupType.toLowerCase()}`}
          value={selectedQualificationOption}
          onChange={(selectedOption) => {
            if (!selectedOption) return;
            const next = availableQualificationOptions.find(
              (c) => c.entityId === +selectedOption?.value
            );
            if (!next) return;
            updateQualification(next);
          }}
          options={mapOptions()}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3} direction="column">
          <Grid className="accordion-input" item>
            <InputField
              name="JustificationsAndExceptions"
              label="Justifications and exceptions"
              value={qualification.justificationsAndExceptions}
              onChange={(value) =>
                updateQualification({ justificationsAndExceptions: value })
              }
              limit={4000}
              required
              multiline
            />
            {qualification &&
              [
                { value: qualification.status, label: "Status" },
                {
                  value: qualification.lastStatusUpdate,
                  label: "Last status change",
                },
                {
                  value: qualification.submittedDate,
                  label: "Submitted date",
                },
                {
                  value: qualification.modificationComment,
                  label: "Requested modification",
                },
                {
                  value: qualification.requestedModificationDate,
                  label: "Requested date",
                },
                {
                  value: qualification.rejectionComment,
                  label: "Rejection comment",
                },
              ]
                .filter((item) => !!item.value)
                .map((item) => (
                  <InputField
                    name={item.label}
                    key={item.label + item.value}
                    label={item.label}
                    value={item.value ?? null}
                    disabled
                  />
                ))}
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {isEditAfterValidation && qualification.qualificationId ? (
          <DialogBoxButton
            {...deleteQualificationWarning(qualification.qualificationId)}
            openButton={(
              onClick:
                | ((event: React.MouseEvent<Element, MouseEvent>) => void)
                | undefined
            ) => <SecondaryButton onClick={onClick} text="Delete" />}
          />
        ) : (
          <SecondaryButton text="Delete" onClick={removeQualification} />
        )}
        {qualification.qualificationId &&
        qualification.status !== AuditorStatus.SUSPENDED ? (
          <DialogBoxButton
            {...suspendQualificationWarning(qualification.qualificationId)}
            openButton={(
              onClick:
                | ((event: React.MouseEvent<Element, MouseEvent>) => void)
                | undefined
            ) => <SecondaryButton onClick={onClick} text="Suspend" />}
          />
        ) : (
          <></>
        )}
        {qualification.qualificationId &&
        qualification.status === AuditorStatus.SUSPENDED ? (
          <SecondaryButton
            onClick={() =>
              unsuspendQualification(Number(qualification.qualificationId))
            }
            text="UnSuspend"
          />
        ) : (
          <></>
        )}
      </AccordionActions>
    </Accordion>
  );
};

export default QualificationSelector;
