import LinkProps from "./LinkModel";

export type ListEntryValue =
  | string
  | number
  | LinkProps
  | string[]
  | (() => string)
  | (() => JSX.Element)
  | JSX.Element
  | null;

interface ListEntry {
  label: string;
  value?: ListEntryValue;
  required?: boolean;
  key?: string;
}

export function isRenderFunction(
  value?: ListEntryValue
): value is () => JSX.Element {
  return !!value && typeof value === "function";
}

export function isLink(value?: ListEntryValue): value is LinkProps {
  return !!value && !!(value as LinkProps).link && !!(value as LinkProps).label;
}

export default ListEntry;
