import React from "react";
import DateRangePicker, {
  DateRangeValue,
} from "../../../components/DateRangePicker";
import { FilterProps } from "../templates/filterMappings";

const DatePickerFilter = ({ name, value, setValue }: FilterProps) => {
  const dateValues = value as DateRangeValue;
  return (
    <DateRangePicker
      label={name}
      fromValue={dateValues[0]}
      toValue={dateValues[1]}
      onChange={setValue}
    />
  );
};

export default DatePickerFilter;
