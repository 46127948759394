import { useEffect, useState } from "react";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { MinifiedOrganisation } from "../constants";

export default (primaryOrganisation: MinifiedOrganisation | null) => {
  const [secondaryOrganisations, setSecondaryOrganisations] = useState<
    MinifiedOrganisation[]
  >([]);

  useEffect(() => {
    setSecondaryOrganisations([]);
    if (primaryOrganisation) getSecondaryOrganisations(primaryOrganisation);
  }, [primaryOrganisation]);

  const getSecondaryOrganisations = ({
    externalId,
    type,
  }: MinifiedOrganisation) => {
    axiosApi
      .get(
        `${BackendServices.USER_SERVICE.USER}/secondaryOrganisations?primaryType=${type}&primaryId=${externalId}`
      )
      .then(({ data }) => setSecondaryOrganisations(data));
  };

  return { secondaryOrganisations };
};
