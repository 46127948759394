import React from "react";
import axios from "axios";
import { MenuItem } from "@material-ui/core";
import ExpiryReasonsDropdownProps from "../../../Models/AuditProps/ExpiryReasonsDropdownProps";
import ExpiryReasonsDropdownState from "../../../Models/AuditProps/ExpiryReasonsDropdownState";
import axiosApi from "../../../components/Api/Axios";
import ExpiryReasonsData from "../../../Models/APIGetData/Audits/ExpiryReasonsData";
import { expiryReasons } from "../../../components/Paths/paths";
import { SelectValidation } from "../../../components/Common/Input/Select";
import { expiryReasonDropdownLabel } from "../AuditDetails/components/Comments/constants";
import { required } from "../../../Enums/ValidationRule";
import BackendServices from "../../../components/Api/BackendService";

export default class ExpiryReasonsDropdown extends React.Component<
  ExpiryReasonsDropdownProps,
  ExpiryReasonsDropdownState
> {
  signal = axios.CancelToken.source();

  constructor(props: ExpiryReasonsDropdownProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getExpiryReasonOptions();
  }

  componentWillUnmount() {
    this.signal.cancel("Expiry reason selector unmounted");
  }

  getExpiryReasonOptions(): void {
    axiosApi
      .get<ExpiryReasonsData[]>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}${expiryReasons}`,
        {
          cancelToken: this.signal.token,
        }
      )
      .then((response) => {
        this.setState(() => ({
          options: response.data,
        }));
      })
      .catch((error) => console.log(error));
  }

  private static accreditationBodyToMenuItem(
    option: ExpiryReasonsData
  ): JSX.Element {
    return (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    );
  }

  render() {
    const { expiryReason, onChange } = this.props;
    const { options } = this.state;
    return (
      <SelectValidation
        required
        name={expiryReasonDropdownLabel}
        label={expiryReasonDropdownLabel}
        value={expiryReason}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        validators={[required.name]}
        errorMessages={[required.message]}
      >
        {options.map((option) => {
          return ExpiryReasonsDropdown.accreditationBodyToMenuItem(option);
        })}
      </SelectValidation>
    );
  }
}
