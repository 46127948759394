import Contact from "../../../Models/CommonProps/Contact";
import ListEntry from "../../../Models/CommonProps/ListEntry";

const GENERAL_CONTACT_TYPE = 1;

export default (contact?: Contact): ListEntry[] =>
  contact
    ? [
        { label: "Name", value: contact.name },
        { label: "Telephone", value: contact.phoneNumber },
        ...(contact.email
          ? [
              {
                label: "Email",
                value: {
                  link: `mailto:${contact.email}`,
                  label: contact.email,
                  external: true,
                },
                required: true,
              },
            ]
          : [{ label: "Email", value: "" }]),
        {
          label: "Fax",
          value:
            contact.type === GENERAL_CONTACT_TYPE
              ? contact.faxNumber
              : undefined,
        },
      ]
    : [];
