export interface ReportTemplate {
  readonly name: string;
  readonly csvUrl?: string;
  readonly xlsUrl?: string;
  readonly filters: ReportFilter[];
}

export type ReportFilter =
  | "standards"
  | "categories"
  | "countryIds"
  | "countryNames"
  | "certificationBodyIds"
  | "certificationBodyNames"
  | "sites"
  | "expiryDate"
  | "auditStart"
  | "certificationStatuses"
  | "archived"
  | "severity"
  | "clause"
  | "organisation"
  | "auditor"
  | "auditorStatus"
  | "roles"
  | "services"
  | "action"
  | "actionDate"
  | "submissionDate"
  | "modificationDate"
  | "recordStatus"
  | "privateSiteCode"
  | "recallStatusIds"
  | "dateOfNotification"
  | "dateOfRecall"
  | "HaviSiteCode";

const templates: ReportTemplate[] = [
  {
    name: "Audit Status",
    csvUrl: "SendAuditStatusReport",
    filters: ["certificationBodyIds", "submissionDate"],
  },
];

export default templates;
