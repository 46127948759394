import React from "react";
import clsx from "clsx";
import { Moment } from "moment";
import { DatePicker } from "../Common/Input/DatePicker";
import { H3Heading } from "../Common/Heading";
import styles from "./DateRangePicker.module.scss";
import DuoSpacing from "../DuoSpacing";

export type DateRangeValue = [Moment | null, Moment | null];

interface DateRangePickerProps {
  readonly label?: string;
  readonly fromValue: Moment | null;
  readonly toValue: Moment | null;
  readonly onChange: (value: DateRangeValue) => void;
}

const DateRangePicker = ({
  label,
  fromValue,
  toValue,
  onChange,
}: DateRangePickerProps) => (
  <div className={styles.dateRangePicker}>
    {label && <H3Heading text={label} />}
    <DuoSpacing className={clsx(styles.pickers, { [styles.noLabel]: !label })}>
      <DatePicker
        value={fromValue}
        label="Start date"
        {...(fromValue ? { onClear: () => onChange([null, toValue]) } : {})}
        {...(toValue ? { maxDate: toValue } : {})}
        onChange={(date) => {
          if (date) {
            onChange([date, toValue]);
          }
        }}
      />
      <DatePicker
        value={toValue}
        label="End date"
        {...(fromValue ? { minDate: fromValue } : {})}
        {...(toValue ? { onClear: () => onChange([fromValue, null]) } : {})}
        onChange={(date) => {
          if (date) {
            onChange([fromValue, date]);
          }
        }}
      />
    </DuoSpacing>
  </div>
);

export default DateRangePicker;
