import React from "react";
import AuditPostResponse from "../../../AuditNew/http/AuditPostResponse";
import DialogBox from "../../../../../components/DialogBox";
import PostAuditStatus from "../../../AuditNew/http/PostAuditStatus";

interface UpdatePromptProps {
  readonly response: AuditPostResponse | null;
  readonly onCancel: () => void;
  readonly confirmChange: () => void;
}

const dialogHeader = (status?: PostAuditStatus) => {
  switch (status) {
    case PostAuditStatus.Confirm:
      return "Confirm change";
    case PostAuditStatus.Validation:
      return "Invalid changes";
    case PostAuditStatus.Virus:
    default:
      return "The audit could not be updated";
  }
};

const UpdatePrompt = ({
  response,
  onCancel,
  confirmChange,
}: UpdatePromptProps) => {
  return (
    <DialogBox
      dialogHeader={dialogHeader(response?.status)}
      onCancel={onCancel}
      open={!!response}
      onConfirm={confirmChange}
      acceptButtonText="Update"
      declineButtonText="Cancel"
      disabled={response?.status !== PostAuditStatus.Confirm}
    >
      {response?.messages
        ?.map((message, index) => ({ id: index, message }))
        .map(({ id, message }) => (
          <p key={id}>{message}</p>
        ))}
    </DialogBox>
  );
};

export default UpdatePrompt;
