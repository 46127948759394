import { PublicTab } from "../../../views/PublicDirectory/utils/TabConfigs";

export const ACTION_SET_TAB = "ACTION_SET_TAB";

export interface ActionSetTab {
  readonly type: typeof ACTION_SET_TAB;
  readonly payload: PublicTab;
}

export default (tab: PublicTab): ActionSetTab => ({
  type: ACTION_SET_TAB,
  payload: tab,
});
