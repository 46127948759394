import React from "react";
import DialogBox from "../../../../../components/DialogBox";

interface UpdateWarning2Props {
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly onSubmit: () => void;
  readonly messages: string[];
}

const UpdateWarning2 = ({
  isOpen,
  close,
  onSubmit,
  messages,
}: UpdateWarning2Props) => (
  <DialogBox
    onCancel={close}
    open={isOpen}
    onConfirm={onSubmit}
    acceptButtonText="Proceed"
    declineButtonText="Quit"
  >
    {messages
      .map((message, index) => ({ id: index, message }))
      .map(({ id, message }) => (
        <p key={id}>{message}</p>
      ))}
  </DialogBox>
);

export default UpdateWarning2;
