import SharingType from "../models/SharingType";
import BackendServices from "../../../components/Api/BackendService";

export default (type: SharingType) => {
  switch (type) {
    case SharingType.SITE:
      return BackendServices.COMPANY_SERVICE.SITES;
    case SharingType.AUDIT:
      return BackendServices.AUDIT_SERVICE.AUDIT;
    case SharingType.COMPANY:
      return BackendServices.COMPANY_SERVICE.COMPANY;
    default:
      throw new Error();
  }
};
