import PostSite from "../model/PostSite";
import axios from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import CompanyUser from "../../../../Models/site/CompanyUser";

export default (
  payload: PostSite,
  selectedUsers: CompanyUser[] = []
): Promise<string> =>
  axios
    .post<string>(`${BackendServices.COMPANY_SERVICE.SITES}`, payload)
    .then(async ({ data: siteId }) => {
      await axios.put(
        `${BackendServices.USER_SERVICE.USER}/addSecondaryOrganisationToUsers`,
        {
          organisationExternalId: parseInt(siteId, 10),
          organisationType: "site",
          identifiers: selectedUsers.map((user) => user.identifier),
        }
      );
      return siteId;
    });
