import React from "react";
import { RouteComponentProps } from "react-router-dom";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import { site } from "../../../../components/Paths/paths";
import PublicSiteDetails from "../../models/Site/PublicSiteDetails";
import { SpinnerCenter } from "../../../../components/Spinner";
import locationToString from "../../../../util/address/locationToString";
import { ContactType } from "../../../../Models/CommonProps/Contact";
import ContactInfoBox from "./ContactInfoBox";
import PublicDetails from "../PublicDetails";
import formatDate from "../../utils/formatDate";
import useGetData from "../../../../http/useGetData";
import InfoBox from "../../../../components/Infobox";
import Box from "../../../../components/Box";

const PublicSite = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const fetch = () =>
    axiosApi.get<PublicSiteDetails>(
      `${BackendServices.PUBLIC_SERVICE.PUBLIC_DIRECTORY}${site}/${id}`
    );
  const { isLoading, error, data } = useGetData<PublicSiteDetails>(fetch);

  if (isLoading) return <SpinnerCenter delay={0} />;
  if (error || !data)
    return (
      <PublicDetails title="Error">
        <Box>Could not load details for site {id}</Box>
      </PublicDetails>
    );

  const [generalContact, technicalContact, commercialContact] = [
    ContactType.General,
    ContactType.Technical,
    ContactType.Commercial,
  ].map((type) =>
    data.contacts.find((contact) => contact.contactType === type)
  );

  return (
    <PublicDetails title={data.name}>
      <InfoBox
        columns={2}
        header="Details"
        items={[
          { label: "Site code", value: data.id },
          { label: "Trading as Name", value: data?.tradingAsName },
          { label: "Address", value: locationToString(data.location) },
          { label: "Telephone", value: generalContact?.phoneNumber },
          { label: "Email", value: generalContact?.email },
          { label: "Fax", value: generalContact?.faxNumber },
          { label: "Website", value: data.website },
        ]}
      />
      <ContactInfoBox contact={technicalContact} header="Technical Contact" />
      <ContactInfoBox contact={commercialContact} header="Commercial Contact" />
      {data.auditDetails
        .map((details, index) => ({ ...details, key: index }))
        .map((auditDetails) => (
          <InfoBox
            key={auditDetails.key}
            header="Certification Details"
            columns={2}
            items={[
              {
                label: "Standard",
                value: auditDetails.standard,
                required: true,
              },
              { label: "Category", value: auditDetails.categories.join(", ") },
              {
                label: "Scope",
                value: auditDetails.scope,
                required: true,
              },
              {
                label: "Certification Body",
                value: auditDetails.certificationBody,
                required: true,
              },
              {
                label: "Exclusion",
                value: auditDetails.exclusion,
                required: true,
              },
              { label: "Grade", value: auditDetails.grade, required: true },
              {
                label: "Issue Date",
                value: formatDate(auditDetails.issueDate),
                required: true,
              },
              {
                label: "Expiry Date",
                value: formatDate(auditDetails.expiryDate),
                required: true,
              },
              {
                label: "Additional Module",
                value: auditDetails.additionalModules.join(", "),
              },
              {
                label: "Audit Programme",
                value: auditDetails.auditProgramme,
              },
            ]}
          />
        ))}
    </PublicDetails>
  );
};

export default PublicSite;
