import React, { useState } from "react";
import MultipleSelectDropdown from "../../../components/MultipleSelectDropdown";
import { FilterProps } from "../templates/filterMappings";
import Option from "../../../Models/forms/Option";

const NumberInputFilter = ({ name, value, setValue }: FilterProps) => {
  const [options, setOptions] = useState<Option[]>([]);

  return (
    <MultipleSelectDropdown
      label={name}
      value={value as Option[]}
      options={options}
      onChange={setValue}
      selectOnEnter
      onTextChange={(textValue) =>
        setOptions([
          ...(value as Option[]),
          { label: textValue, value: textValue },
        ])
      }
    />
  );
};

export default NumberInputFilter;
