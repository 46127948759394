import { RecallUrls } from "../..";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import History from "../../../../components/Common/History";

export interface PostRecallBody {
  reasonForNotification?: string;
  recallCategoryId?: number;
  outlineOfIncident: string;
  productRecalled: string;
  recallDate?: Date;
  correction: string;
  siteOrSupplierIssue?: string;
  additionalInformation: string;
  certificationBodyId?: number;
  certificationStatusId?: number;
  certificationStatusLastUpdated?: Date;
  auditId?: number;
  rootCauseAnalysis: string;
  preventiveActionTaken: string;
  finalSubmissionDate?: Date;
  brcgsComments: string;
  brcgsKeyWords: string;
  initialNotificationDate?: Date;
}

export default (body: PostRecallBody) =>
  axiosApi
    .post(`${BackendServices.RECALL_SERVICE.RECALL}`, body, {
      noGlobalError: true,
    })
    .then((response: any) => History.push(RecallUrls.details(response.data)));
