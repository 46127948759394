import React from "react";
import { RouteComponentProps } from "react-router-dom";
import CBNew from "../CBNew";
import CreateCBLocationStateProps from "../../../Models/CBProps/CreateCBLocationStateProps";
import useGetData from "../../../http/useGetData";
import getOfficeDetails from "../../../http/cb/getOfficeDetails";
import Spinner from "../../../components/Spinner";

const CBOfficeEdit = ({
  match,
  history,
  location,
}: RouteComponentProps<
  { readonly id: string },
  {},
  CreateCBLocationStateProps
>) => {
  const { params: { id } = { id: "" } } = match;
  const { data, isLoading } = useGetData(() => getOfficeDetails(id));
  if (isLoading || !data) return <Spinner />;

  return (
    <CBNew
      history={history}
      location={location}
      match={match}
      office={data}
      title="Edit Certification body"
    />
  );
};

export default CBOfficeEdit;
