import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../store/reducers";
import getStandards from "../../store/details/actions/getStandards";
import Standard from "../../Models/common/Standard";

export default () => {
  const standards: Standard[] = useSelector(
    (state: RootState) => state.details.standards
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (standards.length === 0) {
      dispatch(getStandards());
    }
  }, []);

  return standards;
};
