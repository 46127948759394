import { combineReducers } from "redux";
import { Action, ThunkAction } from "@reduxjs/toolkit";
import detailsReducer from "./details";
import pdReducer from "./public-directory";
import permissionReducer from "./permissions";
import errorReducer from "./error";
import companyReducer from "./company";

export type DefaultThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
>;

const reducers = combineReducers({
  details: detailsReducer,
  pd: pdReducer,
  permissions: permissionReducer,
  error: errorReducer,
  company: companyReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
