import { Action, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import Category from "../../../Models/common/Category";
import sortNameAlphanumerical from "../../../util/sorting/sortNameAlphanumerical";

export const ACTION_GET_CATEGORIES = "ACTION_GET_CATEGORIES";

export interface ActionGetCategories {
  readonly type: typeof ACTION_GET_CATEGORIES;
  readonly payload: Category[];
}

export default (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  axiosApi
    .get<Category[]>(`${BackendServices.PUBLIC_SERVICE}/categories`)
    .then(({ data: payload }) => {
      const action: ActionGetCategories = {
        type: ACTION_GET_CATEGORIES,
        payload: payload.sort(sortNameAlphanumerical),
      };
      dispatch(action);
    })
    .catch(() => {
      dispatch({ type: ACTION_GET_CATEGORIES, payload: [] });
    });
};
