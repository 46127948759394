import React from "react";
import MultipleSelectDropdown from "../../../components/MultipleSelectDropdown";
import Option from "../../../Models/forms/Option";
import { FilterProps } from "../templates/filterMappings";

const SearchableDropdownFilter = ({
  name,
  optionSupplier,
  value,
  setValue,
}: FilterProps) => {
  const options = optionSupplier?.() ?? [];

  return (
    <MultipleSelectDropdown
      label={name}
      value={value as Option[]}
      options={options}
      onChange={setValue}
    />
  );
};

export default SearchableDropdownFilter;
