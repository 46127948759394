import PostCompany from "../../../views/CompanyManagement/CompanyNew/models/PostCompany";

export const ACTION_SET_COMPANY_BODY = "ACTION_SET_COMPANY_BODY";

export interface ActionSetCompanyBody {
  readonly type: typeof ACTION_SET_COMPANY_BODY;
  readonly payload: PostCompany;
}

export default (payload: PostCompany): ActionSetCompanyBody => ({
  payload,
  type: ACTION_SET_COMPANY_BODY,
});
