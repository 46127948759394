import { useEffect, useState } from "react";
import Option from "../../../../Models/forms/Option";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";

export default (): Option[] => {
  const [auditorOptions, setAuditorOptions] = useState<Option[]>([]);

  useEffect(() => {
    axiosApi
      .get<{ name: string; id: number }[]>(
        `${BackendServices.REPORT_SERVICE.REPORT}/data/auditordetails`
      )
      .then(({ data }) => {
        setAuditorOptions(
          data
            .sort((a, b) => a.id - b.id)
            .map((option) => ({
              label: `${option.id} - ${option.name}`,
              value: option.id,
            }))
        );
      });
  }, []);
  return auditorOptions;
};
