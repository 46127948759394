import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoCard from "../InfoCard";
import Pagination from "../../../../components/Pagination";
import setPageNumber from "../../../../store/public-directory/actions/setPageNumber";
import { RootState } from "../../../../store/reducers";
import { TabConfig } from "../../utils/TabConfigs";
import RowCard from "../RowCard";

interface MainContentProps {
  readonly config: TabConfig;
}

const MainContent = ({ config }: MainContentProps) => {
  const { response, pageNumber } = useSelector((state: RootState) => state.pd);
  const dispatch = useDispatch();
  if (!response) {
    return <InfoCard bulletPoints={config.searchHelpText} Message={config.Message}/>;
    
  }

  if (response.resultsList.length > 0) {
    return (
      <>
        {response.resultsList.map((item) => (
          <RowCard key={item.key} item={item} />
        ))}
        <Pagination
          pageCount={response?.totalPages ?? 0}
          page={pageNumber}
          onChange={(e: React.ChangeEvent<unknown>, p: number) =>
            dispatch(setPageNumber(p))
          }
        />
      </>
    );
  }

  return (
    <InfoCard mainText="No result has been found that matches your search, please try again" />
  );
};

export default MainContent;
