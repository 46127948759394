enum UploadFileType {
  CERTIFICATE = "Certificate",
  SCOPE = "Scope extension",
  FSMA = "FSMA report",
  PHOTOGRAPH = "Photograph",
  SHARING = "Sharing consent",
  FSCE = "FSCE assessment",
  ETRS = "ETRS scorecard",
  PHOTOREPORT = "Photographic report",
  CAPACITY = "Capacity & Capability report",
    EXTENSION_ASSESSMENT = "Covid-19 Extension",
  INTERIM_REPORT = "Interim Report",
  SCHEDULE_A = "Schedule A",
  CROSS_DOCK_REPORT = "Cross Dock Report",
  MEAT_SUPPLY_CHAIN_ASSURANCE_SUPPORT = "Meat Supply Chain Assurance report",
  ALTERNATIVE_LANGUAGE_AUDIT_REPORT = "Alternative language audit report",
  ALTERNATIVE_LANGUAGE_CERTIFICATE = "Alternative language certificate",
  ENVIRONMENTAL_AWARENESS_REPORT = "Environmental Awareness report",
  PLASTIC_PELLE_LOSS_PREVENTION_REPORT = "Plastic Pellet Loss Prevention report",
}

export default UploadFileType;
