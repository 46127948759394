import React, { useEffect, useState } from "react";
import TabInfoBox from "./TabInfoBox";
import "./tab-details.scss";
import { AuditSection } from "../../models/AuditTabs";
import postEdits from "./postEdits";
import CoreTable from "./CoreTable";
import EditButtons from "../../../../../components/EditButtons";
import AuditPostResponse from "../../../AuditNew/http/AuditPostResponse";
import { AUDIT_UPDATE_SUCCESS_STATUSES } from "../../../AuditNew/http/PostAuditStatus";
import UpdatePrompt from "../UpdatePromt";
import ErrorBoundary from "../../../../../components/ErrorBoundary";
import {
  AuditEditContextProvider,
  setEditing,
  useEditContext,
} from "./utils/editReducer";
import Box from "../../../../../components/Box";

interface TabbedDetailsProps {
  readonly sections: AuditSection[];
  readonly refresh: () => void;
  readonly auditId: string;
  readonly isAbleToEdit: boolean | undefined;
}

const TabbedDetails = ({
  sections,
  refresh,
  auditId,
  isAbleToEdit,
}: TabbedDetailsProps) => {
  const [editState, dispatchEdit] = useEditContext();

  const resetEditOnTabChange = () => dispatchEdit(setEditing(false));

  useEffect(resetEditOnTabChange, [sections]);

  const [
    updateResponse,
    setUpdateResponse,
  ] = useState<AuditPostResponse | null>(null);

  const onPostEdits = (confirm = false) =>
    postEdits(editState, auditId, confirm).then((response) => {
      if (AUDIT_UPDATE_SUCCESS_STATUSES.includes(response.status)) {
        refresh();
      } else {
        setUpdateResponse(response);
      }
    });
  return (
    <div className="tabbed-details">
      <UpdatePrompt
        onCancel={() => setUpdateResponse(null)}
        confirmChange={() => onPostEdits(true)}
        response={updateResponse}
      />
      {isAbleToEdit && (
        <EditButtons
          isEditing={editState.isEditing}
          onComplete={() => {}}
          submit={onPostEdits}
          setEditing={(status) => dispatchEdit(setEditing(status))}
          className="tabbed-details__edit"
        />
      )}
      <ErrorBoundary>
        {sections.map(({ id, name, content }) => (
          <Box key={id} header={name ?? ""}>
            {content.map((item) => {
              switch (item.type) {
                case "text":
                  return <TabInfoBox key={item.id} entries={item.entries} />;
                case "Accordion":
                case "Table":
                  return <CoreTable key={item.id} table={item} />;
                default:
                  return null;
              }
            })}
          </Box>
        ))}
      </ErrorBoundary>
    </div>
  );
};

const withAuditContext = (component: React.ReactNode) => (
  <AuditEditContextProvider>{component}</AuditEditContextProvider>
);

export default (props: TabbedDetailsProps) =>
  withAuditContext(<TabbedDetails {...props} />);
