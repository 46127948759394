import React from "react";
import { LinkText } from "../../../../../../components/Common/hyperlinks";
import Column from "../../../../../../components/DataTable/models/Column";
import { AuditUrls } from "../../../../../AuditManagement";
import AssociatedAuditRow from "../models/AssociatedAuditRow";

const associatedAuditColumns: Column<AssociatedAuditRow>[] = [
  {
    title: "Id",
    field: "Id",
    render: (rowData: AssociatedAuditRow) => (
      <LinkText link={AuditUrls.details(rowData.Id)} text={rowData.Id} />
    ),
  },
  {
    title: "Standard",
    field: "Standard",
  },
  {
    title: "Audit Start Date",
    field: "AuditStartDate",
  },
  {
    title: "Certification Status",
    field: "CertStatus",
  },
  {
    title: "Certification Issue Date",
    field: "IssueDate",
  },
  {
    title: "Expiry Date",
    field: "ExpiryDate",
  },
  {
    title: "Record Status",
    field: "RecordStatus",
  },
];

export default associatedAuditColumns;
