import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import useGetAuditorDetails from "./http/useGetAuditorDetails";
import { getFullName } from "../../../helpers/NameHelper";
import MainButton, { SubmitButton } from "../../../components/Button/button";
import DetailsPage from "../../../components/DetailsPage";
import AuditorInfoBox from "./components/AuditorInfoBox";
import DialogBoxButton, {
  DialogButtonProps,
} from "../../../components/DialogBoxButton";
import { TextValidation } from "../../../components/Common/Input/Text";
import { max1000Chars } from "../../../Enums/ValidationRule";
import { usePermissionContext } from "../../../routing/contexts/PermissionContext";
import Permission from "../../../Authentication/model/Permission";
import styles from "./AuditorDetails.module.scss";
import AuditorAccordions from "./components/AuditorAccordions";
import AuditorStandards from "./components/AuditorStandards";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import axios from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import AuditorStatus from "../../../Enums/AuditorStatus";
import validateQualifications from "./http/validateQualifications";
import { getUserRole } from "../../../Authentication/Auth";
import UserRole from "../../../Models/user/UserRole";

const auditorMenuItems: DetailsPageMenuItem[] = [
  DetailsPageMenuItem.EDIT,
  DetailsPageMenuItem.ARCHIVE,
  DetailsPageMenuItem.CHANGE_LOG,
];

const AuditorDetails = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { data: auditor, isLoading, refresh } = useGetAuditorDetails(id);
  const [rejectReason, setRejectReason] = useState<string>("");
  const isValidator = usePermissionContext().includes(Permission.VERIFY);
  const isArchived = auditor?.isArchived ?? false;

  const qualificationIdsInRevision = (auditor?.standards ?? [])
    .map((s) => s.qualifications)
    .flat()
    .filter(
      (q) => q.status === AuditorStatus.IN_REVISION && !!q.qualificationId
    )
    .map((q) => q.qualificationId) as number[];

  const rejectWarning = (
    resourceName: string,
    onReject: (auditorId: number, rejectReason: string) => Promise<any>
  ) => {
    const dialogHeader = `Are you sure you want to reject this ${resourceName}?`;
    const rejectOption: DialogButtonProps = {
      dialogHeader,
      declineButtonText: "Cancel",
      acceptButtonText: "Reject",
      openButton: "Reject",
      onConfirm: () => {
        if (!auditor) return;
        onReject(auditor.externalId, rejectReason).then(refresh);
      },
    };

    return rejectOption;
  };

  const archive = () => {
    axios
      .patch(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}/${
          isArchived ? "unarchive" : "archive"
        }/${id}`
      )
      .then(refresh);
  };

  const addValidateButtons = (
    resourceName: string,
    onValidate: (auditorId: number) => Promise<any>,
    onReject: (auditorId: number, rejectReason: string) => Promise<any>
  ) => {
    return isValidator ? (
      <div className={styles.validateButtons}>
        <SubmitButton
          onClick={() =>
            auditor &&
            onValidate(auditor.externalId)
              .then(refresh)
              .finally(() => setRejectReason(""))
          }
          text="Validate"
        />
        <DialogBoxButton
          {...rejectWarning(resourceName, onReject)}
          openButton={(
            onClick:
              | ((event: React.MouseEvent<Element, MouseEvent>) => void)
              | undefined
          ) => (
            <SubmitButton
              onClick={(e) => {
                // eslint-disable-next-line no-unused-expressions
                onClick?.(e);
                setRejectReason("");
              }}
              text="Reject"
            />
          )}
        >
          <ValidatorForm
            className={styles.rejectReasonInput}
            onSubmit={() => {}}
          >
            <TextValidation
              fullWidth
              name="Reason"
              label="Reason"
              value={rejectReason}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRejectReason(e.target.value)
              }
              validators={[max1000Chars.name]}
              errorMessages={[max1000Chars.message]}
            />
          </ValidatorForm>
        </DialogBoxButton>
      </div>
    ) : null;
  };
  return (
    <DetailsPage
      id={auditor?.externalId}
      title={auditor ? getFullName(auditor) : "Auditor Information"}
      detailsPageMenuItems={auditorMenuItems}
      detailsPageMenuConfig={{
        [DetailsPageMenuItem.ARCHIVE]: {
          hide: getUserRole() === UserRole.CB_AUDITOR_ADMIN,
        },
      }}
      archiveConfig={{
        isArchived,
        entityName: "auditor",
        onClick: archive,
      }}
      isLoading={isLoading}
    >
      <AuditorInfoBox
        addValidateButtons={addValidateButtons}
        auditor={auditor}
      />
      <AuditorAccordions auditor={auditor} />
      <AuditorStandards
        standards={auditor?.standards}
        addValidateButtons={addValidateButtons}
      />
      {isValidator && qualificationIdsInRevision.length > 0 && auditor && (
        <MainButton
          className={styles.validateAllButton}
          onClick={() =>
            validateQualifications(
              auditor.externalId,
              qualificationIdsInRevision
            ).then(refresh)
          }
          text="Validate All"
        />
      )}
    </DetailsPage>
  );
};

export default AuditorDetails;
