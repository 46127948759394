import PublicLocation from "../../views/PublicDirectory/models/PublicLocation";

export default (location: PublicLocation) =>
  [
    location.addressLine1,
    location.addressLine2,
    location.addressLine3,
    location.city,
    location.county,
    location.zipCode,
    location.country,
  ]
    .filter((val) => !!val)
    .join(", ");
