import React from "react";
import { Grid } from "@material-ui/core";
import ListEntry from "../../Models/CommonProps/ListEntry";
import EntryPair from "../EntryPair";

export default function GridPair({ label, value }: ListEntry) {
  return (
    <EntryPair
      label={label}
      value={value}
      wrapper={({ children }) => (
        <Grid item xs={12} md={6} lg={4}>
          {children}
        </Grid>
      )}
    />
  );
}
