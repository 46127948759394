import React from "react";
import { Switch, Route } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import AdminPage from "../AdminPage";
import { AdminPageProps } from "../index";
import { PATH_ADMINISTRATION } from "../../../components/Paths/paths";
import DashboardNew, { PATH_ADMIN_DASHBOARD_NEW } from "./DashboardNew";
import DashboardEdit from "./DashboardEdit";

export const PATH_ADMIN_DASHBOARDS = PATH_ADMINISTRATION;

const AdministrationDashboard = ({ index }: AdminPageProps) => (
  <AdminPage index={index}>
    <Switch>
      <Route
        path={`${PATH_ADMINISTRATION}/edit/:id`}
        component={DashboardEdit}
      />
      <Route path={PATH_ADMIN_DASHBOARD_NEW} component={DashboardNew} />
      <Route path={`${PATH_ADMINISTRATION}/`} component={DashboardTable} />
    </Switch>
  </AdminPage>
);

export default AdministrationDashboard;
