import React from "react";
import {
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { SelectValidation } from "../Common/Input/Select";
import { required } from "../../Enums/ValidationRule";
import CheckBox from "../Common/CheckBox";
import { yesText } from "../../constants/CBConstants";
import { suspensionOption, suspensionType } from "../../Enums/SuspentionType";
import {
  auditSubmissionOption,
  auditSubmissionType,
} from "../../Enums/AuditSubmissions";
import { SuspendWindowState } from "../../views/CBManagement/CBDetails/models/SuspendWindowState";

interface SuspendWindowProps {
  readonly value: SuspendWindowState;
  readonly setValues: (value: SuspendWindowState) => void;
}

const SuspendWindow = ({ value, setValues }: SuspendWindowProps) => {
  const { type, visible } = value;
  return (
    <ValidatorForm onSubmit={() => {}} className="SuspendDialogContent">
      <Grid container spacing={1} direction="column" />
      <Grid item>
        <SelectValidation
          name="SuspensionType"
          label="Suspension Type"
          value={type}
          onChange={(e: React.ChangeEvent<{ value: suspensionType }>) =>
            setValues({ ...value, type: e.target.value })
          }
          validators={[required.name]}
          errorMessages={[required.message]}
        >
          {suspensionOption.map((option) => (
            <MenuItem value={option.value}>{option.text}</MenuItem>
          ))}
        </SelectValidation>
      </Grid>

      <Grid item>
        <CheckBox
          label={yesText}
          heading="Visible in public directory?"
          checked={visible}
          onChange={(e, b) => setValues({ ...value, visible: b })}
        />
      </Grid>

      <Grid item>
        <FormLabel>Audit Submissions</FormLabel>
        <RadioGroup
          defaultValue={auditSubmissionType.ALLOW}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValues({
              ...value,
              auditSubmissions: e.target.value as auditSubmissionType,
            })
          }
        >
          {auditSubmissionOption.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.text}
            />
          ))}
        </RadioGroup>
      </Grid>
    </ValidatorForm>
  );
};

export default SuspendWindow;
