import BackendServices from "../../../../components/Api/BackendService";
import axiosApi from "../../../../components/Api/Axios";
import AuditPostResponse from "./AuditPostResponse";
import PostAuditStatus from "./PostAuditStatus";
import ContentType from "../../../../components/Api/ContentType";

export interface PostAuditBody {
  readonly auditOwner?: string | number | null;
  readonly file: File | null;
  readonly id: number | null;
  readonly confirmed: boolean;
  readonly overrideWarning: boolean;
  readonly certificationBodyId?: number | string;
  readonly siteCode:string|null;
}

export default ({
  auditOwner,
  confirmed,
  overrideWarning,
  file,
  id,
  certificationBodyId,
  siteCode
}: PostAuditBody): Promise<AuditPostResponse> => {
  if (!file) return Promise.reject();
  const formData = new FormData();
  if (auditOwner) {
    formData.append("owner", `${auditOwner}` ?? "");
  }
  formData.append("file", file);
  formData.append("confirmed", `${confirmed}`);
  formData.append("overrideWarning", `${overrideWarning}`);
  if (id) {
    formData.append("externalId", `${id}`);
  }
  if (certificationBodyId) {
    formData.append("certificationBodyId", `${certificationBodyId}`);
  }
  if (siteCode) {
    formData.append("siteCode", `${siteCode}`);
  }
  return axiosApi
    .post<AuditPostResponse>(
      `${BackendServices.AUDIT_SERVICE.AUDIT}/ImportReport`,
      formData,
      {
        headers: {
          "Content-Type": ContentType.MULTIPART_FORM_DATA,
        },
      }
    )
    .then(({ data }) => data)
    .catch(() => ({
      auditExternalId: null,
      messages: ["An unknown error has occurred"],
      status: PostAuditStatus.Error,
      programOwner: null,
      siteName: null,
      owner: null,
      address1: null,
      address2: null,
      address3: null,
      siteCity: null,
      siteCountry: null,
      auditorName: null,
      auditorNo: null,
    }));
};
