import React from "react";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Container } from "@material-ui/core";
import styles from "./PublicFooter.module.scss";
import { LinkIcon, LinkText } from "../Common/hyperlinks";
import {
  eula,
  privacyPolicy,
  termsConditions,
  about,
  contact,
} from "../Paths/paths";

interface LinkItem {
  readonly icon?: JSX.Element;
  readonly text?: string;
  readonly link?: string;
  readonly internal?: boolean;
}

const icons: LinkItem[] = [
  {
    icon: <LocalPhoneIcon />,
    text: "+44 20 3931 8150",
    link: "tel:+442039318150",
  },
  {
    icon: <EmailIcon />,
    link: "https://form.jotform.com/210703256820043",
    text: "Directory Queries",
  },
  {
    icon: <LocationOnIcon />,
    text: "Floor 2, 7 Harp Lane, London, EX3R 6DP",
    link:
      "https://www.google.com/maps/search/?api=1&query=Floor 2, 7 Harp Lane, London, EX3R 6DP",
  },
];

const largeIcons: LinkItem[] = [
  {
    link: "https://www.facebook.com/BRCGS.Standards/",
    icon: <FacebookIcon fontSize="large" />,
    internal: false,
  },
  {
    link: "https://twitter.com/BRCGS_Standards",
    icon: <TwitterIcon fontSize="large" />,
    internal: false,
  },
  {
    link: "https://www.linkedin.com/company/brcgs/",
    icon: <LinkedInIcon fontSize="large" />,
    internal: false,
  },
  {
    link: "https://www.youtube.com/channel/UCv_m8YKJjRYYDKaKS3F7Cuw",
    icon: <YouTubeIcon fontSize="large" />,
    internal: false,
  },
];

const moreInformation: LinkItem[] = [
  {
    link: "https://www.brcgs.com/directory/brcgs-training-courses/",
    text: "Training Courses",
    internal: false,
  },
  {
    link: "https://www.brcgs.com/directory/audit-and-site-sharing/",
    text: "Supplier Approval",
    internal: false,
  },
  {
    link: "https://www.brcgs.com/directory/access-and-logos/",
    text: "Performance",
    internal: false,
  },
];

const aboutUs: LinkItem[] = [
  { link: "https://www.brcgs.com", text: "BRCGS" },
  { link: about, text: "About" },
  { link: contact, text: "Contact" },
];

const secondFooter: LinkItem[] = [
  { link: eula, text: "End User Licence Agreement", internal: true },
  { text: "|" },
  { link: termsConditions, text: "Terms and Conditions", internal: true },
  { text: "|" },
  { link: privacyPolicy, text: "Privacy Policy", internal: true },
];

function Footer() {
  return (
    <div className={styles.footer}>
      <Container className={styles.links}>
        <div>
          <h3>BRCGS</h3>
          <ul>
            {icons.map((icon) => (
              <li key={icon.text}>
                {icon.icon} {renderItem(icon)}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3>More Information</h3>
          <ul>
            {moreInformation.map((icon) => (
              <li key={icon.text}>{renderItem(icon)}</li>
            ))}
          </ul>
        </div>
        <div>
          <h3>About Us</h3>
          <ul>
            {aboutUs.map((icon) => (
              <li key={icon.text}>{renderItem(icon)}</li>
            ))}
          </ul>
        </div>
        <div className={styles.social}>
          {largeIcons.map(({ link, icon, internal }) => (
            <LinkIcon
              external={!internal}
              link={link ?? ""}
              icon={icon}
              key={link}
            />
          ))}
        </div>
      </Container>
      <div className={styles.bottom}>
        <Container className={styles.bottomContent}>
          <p>
            &copy;
            {new Date().getFullYear()} BRCGS
          </p>
          <div className={styles.bottomLinks}>
            {secondFooter.map(renderItem)}
          </div>
        </Container>
      </div>
    </div>
  );
}

const renderItem = ({ link, text, internal }: LinkItem) =>
  link ? (
    <LinkText external={!internal} key={text} link={link} text={text} />
  ) : (
    <p key={text}>{text}</p>
  );

export default Footer;
