import { useEffect, useState } from "react";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import CertificationStatus from "../../Models/common/CertificationStatus";

const useGetCertificationStatuses = () => {
  const [certificationStatuses, setCertificationStatuses] = useState<
    CertificationStatus[]
  >([]);
  useEffect(() => {
    axiosApi
      .get<CertificationStatus[]>(
        `${BackendServices.AUDIT_SERVICE.AUDIT}/CertificationStatus`
      )
      .then(({ data }) => {
        setCertificationStatuses(data);
      })
      .catch(() => setCertificationStatuses([]));
  }, []);
  return { certificationStatuses };
};

export default useGetCertificationStatuses;
