import React, { useState } from "react";
import { FormControl, FormHelperText, MenuItem } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { Select } from "../../../components/Common/Input/Select";
import UploadFileType from "../../../Enums/UploadFileType";
import FileDropZone from "../../../components/FileDropZone";
import MainButton, {
  SecondaryButton,
  SubmitButton,
} from "../../../components/Button/button";
import history from "../../../components/Common/History";
import { H3Heading } from "../../../components/Common/Heading";
import ContentPage from "../../../components/ContentPage";
import { VerifyUrls } from "../index";
import postAuditFiles from "./http/postAuditFiles";

const AuditAdditionalDocumentUpload = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const [fileType, setFileType] = useState<UploadFileType | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [isSuccess, setSuccess] = useState(false);
  const [missingDataType, setMissingType] = useState(false);

  return (
    <ContentPage
      title={isSuccess ? "Upload file" : "Confirmation"}
      className="audit-doc-upload"
    >
      {!isSuccess ? (
        <form
          className="audit-doc-upload__form"
          onSubmit={(event) => {
            event.preventDefault();
            postAuditFiles(id, files, fileType, () => setMissingType(true))
              .then(() => setSuccess(true))
              .catch(() => {});
          }}
        >
          <FormControl error={missingDataType}>
            <Select
              label="Type of file"
              value={fileType}
              onChange={(e) => {
                setFileType(e.target.value as UploadFileType);
                setMissingType(false);
              }}
            >
              {Object.entries(UploadFileType).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {missingDataType && (
              <FormHelperText>Missing file type</FormHelperText>
            )}
          </FormControl>
          <FileDropZone files={files} onChange={setFiles} />
          <SubmitButton text="Upload" />
        </form>
      ) : (
        <div className="audit-doc-upload__success">
          <H3Heading text="This record will be uploaded to the system" />
          <MainButton
            text="Return to record"
            onClick={() => history.push(VerifyUrls.details(id))}
          />
          <SecondaryButton
            text="Upload more files"
            onClick={() => {
              setMissingType(false);
              setFileType(null);
              setFiles([]);
              setSuccess(false);
            }}
          />
        </div>
      )}
    </ContentPage>
  );
};

export default AuditAdditionalDocumentUpload;
