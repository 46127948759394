import { IconButton, InputAdornment } from "@material-ui/core";
import React, { useState } from "react";
import { Search } from "@material-ui/icons";
import clsx from "clsx";
import { Text } from "../Common/Input/Text";
import styles from "./SearchBox.module.scss";

const ENTER_KEY = 13;

interface SearchBoxProps {
  searchString?: string;
  onUpdateSearch?: (s: string) => void;
  handleClick: (val: string) => void;
  placeHolderString: string;
  className?: string;
}

export default function SearchBox({
  handleClick,
  searchString,
  onUpdateSearch,
  placeHolderString,
  className,
}: SearchBoxProps) {
  const [uncontrolledInput, setUncontrolledInput] = useState(
    searchString ?? ""
  );

  const finalString = onUpdateSearch ? searchString ?? "" : uncontrolledInput;
  return (
    <div className={clsx(styles.searchBox, className)}>
      <Text
        autoFocus
        className={clsx(styles.searchTextField, "input text-field")}
        placeholder={placeHolderString}
        value={finalString}
        onKeyDown={(event) => {
          if (event.keyCode === ENTER_KEY) {
            handleClick(finalString);
          }
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onUpdateSearch
            ? onUpdateSearch(event.target.value)
            : setUncontrolledInput(event.target.value)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => handleClick(finalString)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
