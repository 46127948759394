import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../store/reducers";
import Category from "../../Models/common/Category";
import getCategories from "../../store/details/actions/getCategories";

export default () => {
  const categories: Category[] = useSelector(
    (state: RootState) => state.details.categories
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategories());
    }
  }, []);

  return categories;
};
