import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import {
  AuditEntryValue,
  AuditFieldType,
  AuditTableRow,
} from "../../../models/AuditTabs";

export interface EditState {
  readonly isEditing: boolean;
  readonly values: {
    [xmlTag: string]: {
      readonly type?: AuditFieldType;
      readonly value: AuditEntryValue | AuditTableRow[];
    };
  };
}

interface SetEditing {
  readonly type: "ACTION_SET_EDITING";
  readonly payload: boolean;
}

interface SetEditingValue {
  readonly type: "ACTION_EDIT_AUDIT";
  readonly payload: {
    readonly xmlTag: string;
    readonly type?: AuditFieldType;
    readonly value: AuditEntryValue | AuditTableRow[];
  };
}

export const setEditing = (status: boolean): SetEditing => ({
  type: "ACTION_SET_EDITING",
  payload: status,
});

export const setEditingValue = (
  xmlTag: string,
  value: AuditEntryValue | AuditTableRow[],
  type?: AuditFieldType
): SetEditingValue => {
  return {
    type: "ACTION_EDIT_AUDIT",
    payload: { xmlTag, type, value },
  };
};

type EditAction = SetEditing | SetEditingValue;

const reducer = (state: EditState, action: EditAction): EditState => {
  switch (action.type) {
    case "ACTION_SET_EDITING": {
      return { isEditing: action.payload, values: {} };
    }
    case "ACTION_EDIT_AUDIT": {
      const { xmlTag, type, value } = action.payload;
      return {
        ...state,
        values: { ...state.values, [xmlTag]: { type, value } },
      };
    }
    default:
      return state;
  }
};

type EditReducer = [EditState, Dispatch<EditAction>];

// @ts-ignore
export const EditContext = createContext<EditReducer>();

export const useEditContext = (): EditReducer => {
  return useContext<EditReducer>(EditContext);
};

export const AuditEditContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const editReducer = useReducer(reducer, { isEditing: false, values: {} });
  return (
    <EditContext.Provider value={editReducer}>{children}</EditContext.Provider>
  );
};
