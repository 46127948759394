import React, { Fragment } from "react";
import moment from "moment";
import { Divider, Grid } from "@material-ui/core";
import AuditorDetails, {
  AuditorAccordionData,
  Education,
  GFSIExam,
  Training,
  WitnessedAudit,
} from "../../models/AuditorDetails";
import { DEFAULT_DATE_FORMAT } from "../../../../../util/dates/DateFormats";
import { LinkText } from "../../../../../components/Common/hyperlinks";
import { SiteUrls } from "../../../../SiteManagement";
import { AuditorUrls } from "../../../index";
import ListEntry from "../../../../../Models/CommonProps/ListEntry";
import GridPair from "../../../../../components/Common/GridPair";
import styles from "./AuditorAccordions.module.scss";
import AuditorAccordion from "../AuditorAccordion";
import { NOT_AVAILABLE } from "../../constants";

const shortid = require("shortid");

interface AuditorAccordionsProps {
  readonly auditor: AuditorDetails | null;
}

const AuditorAccordions = ({ auditor }: AuditorAccordionsProps) => {
  if (!auditor) return null;

  const items: AuditorAccordionData[] = [
    {
      title: "Education",
      summary: true,
      elements: auditor.education.map((education: Education) => {
        return [
          {
            label: "Education degree - Diploma",
            value: education.degree,
          },
          {
            label: "Training on EQIPT",
            value: education.eqiptTraining ? "Yes" : "No",
          },
        ];
      }),
    },
    {
      title: "Work Experience",
      elements: [
        [
          {
            label: "",
            value: auditor.workExperience,
          },
        ],
      ],
    },
    {
      title: "Audit Experience",
      elements: [
        [
          {
            label: "",
            value: auditor.auditExperience,
          },
        ],
      ],
    },
    {
      title: "Training",
      summary: true,
      elements: auditor.qualifications.map((training: Training) => {
        return [
          {
            label: "Training type",
            value: training.trainingType.name,
          },
          {
            label: "Title of the training",
            value: training.title,
          },
          {
            label: "Country",
            value: training.country?.name,
          },
          {
            label: "Start date",
            value: moment(training.startDate).format(DEFAULT_DATE_FORMAT),
          },
          {
            label: "End date",
            value: moment(training.endDate).format(DEFAULT_DATE_FORMAT),
          },
          {
            label: "Trainer",
            value: training.trainerName,
          },
          {
            label: "Type of trainer",
            value: training.trainerType,
          },
        ];
      }),
    },
    {
      title: "Witnessed Audits",
      summary: true,
      elements: auditor.witnessedAudits.map(
        (witnessedAudit: WitnessedAudit) => [
          {
            label: "Witnessed date",
            value: moment(witnessedAudit.date).format(DEFAULT_DATE_FORMAT),
          },
          {
            label: "Site code",
            value: witnessedAudit.siteCode
              ? () => (
                  <LinkText
                    link={SiteUrls.details(witnessedAudit.siteCode)}
                    text={witnessedAudit.siteCode}
                  />
                )
              : undefined,
          },
          {
            label: "Witnessing auditor full name",
            value: witnessedAudit.witnessingAuditorName,
          },
          {
            label: "Witnessing auditor number",
            value: witnessedAudit.witnessingAuditorNumber
              ? () => (
                  <LinkText
                    link={AuditorUrls.details(
                      witnessedAudit.witnessingAuditorNumber
                    )}
                    text={witnessedAudit.witnessingAuditorNumber}
                  />
                )
              : undefined,
          },
        ]
      ),
    },
    {
      title: "GFSI Exams",
      summary: true,
      elements: auditor.gfsiExams.map((gfsiExam: GFSIExam) => {
        return [
          {
            label: "Title of exam",
            value: gfsiExam.titleOfExam,
          },
          {
            label: "Provider",
            value: gfsiExam.provider,
          },
          {
            label: "Exam date",
            value: moment(gfsiExam.date).format(DEFAULT_DATE_FORMAT),
          },
          {
            label: "Language",
            value: gfsiExam.language?.name,
          },
          {
            label: "Certificate reference",
            value: gfsiExam.certificateReference,
          },
        ];
      }),
    },
  ];
  return (
    <>
      {items.map((category: AuditorAccordionData) =>
        category?.elements.length > 0 ? (
          <AuditorAccordion
            heading={category.title}
            secondaryHeading={
              category.summary
                ? `${category.elements.length} item${
                    category.elements.length >= 2 ? "s" : ""
                  }`
                : undefined
            }
            key={category.title}
          >
            <div className={styles.accordionContent}>
              {category.elements.map((element: ListEntry[]) => (
                <Fragment key={shortid.generate()}>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    className={styles.element}
                  >
                    {element.map((row: ListEntry) => (
                      <GridPair
                        key={row.label}
                        label={row.label}
                        value={row.value ?? NOT_AVAILABLE}
                      />
                    ))}
                  </Grid>
                  <Divider className={styles.divider} />
                </Fragment>
              ))}
            </div>
          </AuditorAccordion>
        ) : null
      )}
    </>
  );
};

export default AuditorAccordions;
