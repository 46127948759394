import { Action, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import QualificationStatus from "../../../Models/common/QualificationStatus";

export const ACTION_GET_QUALIFICATION_STATUS =
  "ACTION_GET_QUALIFICATION_STATUS";

export interface ActionGetQualificationStatus {
  readonly type: typeof ACTION_GET_QUALIFICATION_STATUS;
  readonly payload: QualificationStatus[];
}

export default (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  axiosApi
    .get<QualificationStatus[]>(
      `${BackendServices.AUDITOR_SERVICE.AUDITOR}/getQualificationStatus`
    )
    .then(({ data: payload }) => {
      const action: ActionGetQualificationStatus = {
        type: ACTION_GET_QUALIFICATION_STATUS,
        payload,
      };

      dispatch(action);
    })
    .catch(() => {
      dispatch({ type: ACTION_GET_QUALIFICATION_STATUS, payload: [] });
    });
};
