import { ACTION_SWAP_ORDER, ActionSwapOrder } from "./actions/swapOrder";
import {
  ACTION_SET_PAGE_NUMBER,
  ActionSetPageNumber,
} from "./actions/setPageNumber";
import {
  ACTION_SET_SEARCH_TERM,
  ActionSetSearchTerm,
} from "./actions/setSearchTerm";
import { ACTION_SET_FILTER, ActionSetFilter } from "./actions/setFilters";
import { ACTION_RESET, ActionReset } from "./actions/reset";
import {
  ACTION_GET_ITEM,
  ACTION_GET_ITEM_LOADING,
  ActionGetItems,
  ActionGetSitesLoading,
  PublicSearchItem,
} from "./actions/getData";
import SearchResponses from "../../Models/APIGetData/SearchResponses";
import {
  ACTION_RESET_RESULTS,
  ActionResetResult,
} from "./actions/resetResults";
import { PublicTab } from "../../views/PublicDirectory/utils/TabConfigs";
import { ACTION_SET_TAB, ActionSetTab } from "./actions/setTab";
import Option from "../../Models/forms/Option";

export interface PublicFilters {
  readonly Standard: Option[];
  readonly Category: Option[];
  readonly Grade: Option[];
  readonly AdditionalModules: Option[];
  readonly Country: Option[];
  readonly Rating: Option[];
}

interface PublicDirectoryState {
  readonly tab: PublicTab;
  readonly pageNumber: number;
  readonly order: "asc" | "desc";
  readonly searchTerm: string;
  readonly filters: PublicFilters;
  readonly delay: number;
  readonly isLoading: boolean;
  readonly response: SearchResponses<PublicSearchItem> | null;
}

const initialState: PublicDirectoryState = {
  filters: {
    AdditionalModules: [],
    Category: [],
    Country: [],
    Grade: [],
    Standard: [],
    Rating: [],
  },
  tab: PublicTab.SITE,
  searchTerm: "",
  pageNumber: 1,
  order: "asc",
  delay: 600,
  isLoading: false,
  response: null,
};

type PdActions =
  | ActionSwapOrder
  | ActionSetPageNumber
  | ActionSetSearchTerm
  | ActionSetFilter
  | ActionReset
  | ActionGetItems
  | ActionGetSitesLoading
  | ActionResetResult
  | ActionSetTab;

export default (
  state = initialState,
  action: PdActions
): PublicDirectoryState => {
  switch (action.type) {
    case ACTION_SET_TAB:
      return { ...initialState, tab: action.payload };
    case ACTION_GET_ITEM:
      if (state.tab === action.payload.tab) {
        return {
          ...state,
          isLoading: false,
          response: action.payload.data,
        };
      }
      return state;
    case ACTION_GET_ITEM_LOADING:
      return { ...state, isLoading: true };
    case ACTION_RESET_RESULTS:
      return { ...state, response: null };
    case ACTION_SWAP_ORDER:
      return {
        ...state,
        order: state.order === "asc" ? "desc" : "asc",
        delay: 0,
      };
    case ACTION_RESET:
      return { ...state, filters: initialState.filters, delay: 0 };
    case ACTION_SET_PAGE_NUMBER:
      return { ...state, pageNumber: action.payload, delay: 0 };
    case ACTION_SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload, pageNumber: 1, delay: 0 };
    case ACTION_SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: action.payload.filterValues,
        },
        pageNumber: 1,
        delay: 600,
      };
    default:
      return state;
  }
};
