import React from "react";
import { LinkText } from "../../../components/Common/hyperlinks";
import { privacyPolicy } from "../../../components/Paths/paths";

const Eula = () => (
  <div>
    <h1>End User Licence Agreement</h1>
    <p>This End User Licence Agreement is applicable as of 25 January 2021</p>
    <p>
      BRC Trading Limited (“BRCGS”) makes available the BRCGS Directory
      (“Directory” as defined below) to users in accordance with this End User
      Licence Agreement (“EULA”). Anyone wishing to use the Directory must (i)
      use a browser to gain access to it via the Internet and (ii) use a portal
      website to gain access to private and public areas of the Directory (an
      authorized account name and password are required in order to gain access
      to the private area).
    </p>

    <p>
      Please read this EULA carefully to be sure that you understand all its
      terms and conditions which govern your access and use of the Services (as
      defined below). By clicking on the “I agree” checkbox, you agree that your
      use of the Directory will be governed by and subject to this EULA, and you
      will have entered into a contract with BRCGS for the provision of the
      Services. Your use of other BRCGS services and websites (including your
      use of the Directory) are governed by the BRCGS Website Terms & Conditions
      (available on BRCGS’ website).
    </p>

    <h3>
      In consideration of the mutual promises and agreements contained in this
      EULA, you and we agree as follows:
    </h3>

    <h3>1. Definitions and Interpretation </h3>
    <p>In this EULA: </p>
    <ul>
      <li>
        &#34;Authorised User&#34; means an accreditation body, a certification
        body, a supplier, a retailer or similar user, who is granted access to
        the Services by BRCGS in accordance with this EULA;
      </li>
      <li>
        &#34;BRCGS&#34; means BRC Trading Limited (a limited company
        incorporated in England with its registered office at LGC, Queens Road,
        Teddington TW11 0LY whose registered company number is 4281617);
      </li>
      <li>
        &#34;Data&#34; means all data, information and/or documents provided or
        communicated by Authorised Users via the Portal when using the Services;
      </li>
      <li>
        &#34;EULA&#34; means these general terms and conditions of use of the
        Services that the Authorised User has accepted by clicking on the
        checkbox labelled “I agree”;
      </li>
      <li>
        &#34;Directory&#34; means the name of the website and database that form
        the directory of BRCGS’ Global Standards;
      </li>
      <li>
        &#34;Portal&#34; means the BRCGS website and related applications
        permitting a User to have access to the Directory and Services;
      </li>
      <li>
        &#34;Quickstart&#34; means the online manuals and/or guides put at the
        disposal of Users and containing basic procedures or instructions to
        facilitate use of the Services;
      </li>
      <li>
        &#34;Services&#34; means the on-line access to the Portal, access and
        use of any related applications and the right to use the Portal in
        accordance with this EULA;
      </li>
      <li>
        &#34;User&#34; means any person that uses the Services (including
        Authorised Users and individuals acting on behalf of Authorised Users).
      </li>
    </ul>
    <p>
      In this EULA “we” means BRCGS and “us” and “our” will be construed
      accordingly.
    </p>
    <p>
      Any reference in this EULA to “you” means the User or Authorised User (as
      applicable) and “your” will be construed accordingly.{" "}
    </p>
    <p>
      References in this EULA to “including” means including without limitation
      or prejudice to the generality of any description, definition, term or
      phrase preceding that word, and “include” and its derivatives will be
      construed accordingly.
    </p>
    <p>
      In this EULA, words importing the singular include the plural and vice
      versa and words importing a gender include every gender.
    </p>

    <h3>2. First access to the Services</h3>
    <p>
      To join the Services, you must have received a valid invitation and have
      accepted this EULA at the time of your first connection. In order to
      become an Authorised User you will also need an access code and a
      password.
    </p>
    <h3>3. Terms of the Services</h3>
    <p>By becoming an Authorised User, you have: </p>
    <ul>
      <li>
        the right to non-exclusive use of the Services, enabling you to use and
        query the Services;
      </li>
      <li>a right to access and use the Portal; and</li>
      <li>
        a right to access and use documentation made available via the Portal
        relating to the Services.
      </li>
    </ul>
    <h3>Connection</h3>
    <p>
      Your connection to the Services is authorized solely on the condition that
      you use the personal identifiers set out in your account. We reserve the
      right to have your personal identifiers changed for technical reasons,
      subject to providing you with three (3) business days’ prior notice, or
      without prior notice for security or emergency purposes.
    </p>
    <h3>Interruption</h3>
    <p>
      We will be entitled to interrupt your access to the Services for
      maintenance or upgrades, without liability to us.
    </p>
    <p>
      We will exercise no control over Data, or your account information,
      transmitted through the Services. However, we reserve the right to
      immediately terminate or suspend your access to the Services, without
      liability or compensation and without prejudice to any damages due under
      this EULA, if we discover or receive notification or allegations of acts
      of piracy, counterfeiting, or illegal (illicit) use of the Services
      attributable in whole or in part to you, or if it is so ordered by a
      competent authority.
    </p>
    <p>
      We do not warrant that the Services will be error free. Except as
      otherwise set out expressly in this EULA, we make no warranty, express or
      implied, including any warranty of merchantability, quality or fitness for
      purpose. You are responsible for having standard reasonable protection in
      relation to your computer systems and the use of the Internet, including
      standard anti-virus and firewall software.
    </p>
    <h3>4. Your Obligations</h3>
    <h3>Technical Configuration </h3>
    <p>
      The exchange of and collaborative work on electronic documents is
      accomplished by means of a system comprised of data processing hardware,
      associated software and private or public telecommunications networks. You
      are responsible for verifying the proper functioning of your data
      processing system in general and of your system’s technical capability to
      carry out electronic exchanges.
    </p>
    <p>
      You acknowledge to having consulted the technical pre-requisites for use
      of the Services, which are set out online on the Portal, prior to joining
      the Services and you agree to comply with such requirements and
      specifications during your use of the Services.
    </p>
    <h3>Use of the Services</h3>
    <p>
      You agree to use the Services in compliance with this EULA, the Quickstart
      recommendations and instructions and any complementary instructions that
      may be provided to you.
    </p>
    <p>
      You are solely responsible for your use of the Services and agree to
      respect and comply with all applicable laws and regulations relating to
      your use of the Services. You must not use the Services on behalf of any
      third party.
    </p>
    <p>
      You are only authorized to use the Services for your internal professional
      requirements and you must not:
    </p>
    <ol type="I">
      <li>
        send to any other User spam, unsolicited messages or any other message
        in violation of any applicable law;
      </li>
      <li>
        send, display, maintain or transmit illegal, obscene, menacing,
        defamatory or otherwise unlawful data or information, including
        information containing representations of violence, pornography, racial
        discrimination, religious discrimination, provocation of violence,
        incitement to express hatred or discrimination, incitement to commit
        reprehensible acts, as well as information infringing a third party’s
        intellectual property rights or data protection rights;
      </li>
      <li>
        knowingly insert or include, or knowingly permit or cause any User to
        insert or include any virus, computer worm, Trojan horse or other
        damaging device, data file, script, agent or program into the Services;
      </li>
      <li>
        interfere with or disrupt the integrity or functioning of the Services
        or the data contained therein; or
      </li>
      <li>
        try to obtain unauthorized access to any of the Services, Data, User
        profiles or any related systems or networks.
      </li>
    </ol>
    <h3>Data</h3>
    <p>
      BRCGS uses third party service providers to assist it in providing the
      Services. For the purposes of the General Data Protection Regulation 2016
      and the Data Protection Act 2018 (together “GDPR”), BRCGS is the
      controller (as defined in the GDPR) and responsible for complying with the
      GDPR in relation to personal data submitted to, and processed in
      connection with, the Services. BRCGS processes your personal data in
      accordance with our Privacy Policy which can be viewed at{" "}
      <LinkText
        link={privacyPolicy}
        text="https://www.brcgs.com/privacy-notice/"
      />
    </p>
    <p>
      You warrant that all of the information you enter on the Portal as part of
      the Data belongs to you, that you have the right to use it in connection
      with the Services and that it is accurate and complete and not misleading
      in any way.
    </p>
    <p>
      You are solely responsible for the content of the Data you transmit to and
      put online on the Portal and in the Directory itself. We will not, under
      any circumstance be liable for the content, accuracy or completeness of
      the Data submitted by you as part of the Services.
    </p>
    <p>
      You agree not to transmit Data obtained or transmitted by means of the
      Services to unauthorized third parties, including whether or not this is
      done free of charge.
    </p>
    <p>
      We reserve the right to retain, remove and/or destroy Data, without prior
      notice, in the event of a breach of this EULA. We also reserve the right
      (if reasonably practicable) to receive copies of the Data before it is
      destroyed or removed.
    </p>
    <p>
      Insofar as you hold a property right in the Data, you grant a right of
      usage of such Data for the benefit of other Users, BRCGS and its
      suppliers, for the purpose of using the Services in accordance with this
      EULA.
    </p>
    <p>
      By agreeing to this EULA, you agree that all Data will be available,
      within the framework of the Services, to other Users according to their
      access level, whether or not such Users are located outside the European
      Economic Area or the United Kingdom.
    </p>
    <p>
      In the event of termination of this EULA, your right of access to the Data
      or the right of utilization of the same within the framework of the
      Services will immediately cease, and we will not be required to archive or
      transmit any Data for you whatsoever.
    </p>
    <p>
      You agree to comply fully in all respects with the GDPR (to the extent it
      applies to you and any Data you put on the Directory) and all associated
      or related legislation in any applicable jurisdiction.
    </p>
    <h3>Access to Personal Data</h3>
    <p>
      In accordance with the GDPR and other laws relating to personal data, you
      will be entitled to request access to your personal data and to request
      that your personal data be changed or deleted. You may exercise these
      rights directly in your account or, if applicable, through the Services,
      by sending an email to the email address indicated on the Portal. See
      further BRCGS’ Privacy Policy{" "}
      <LinkText
        link={privacyPolicy}
        text="(https://www.brcgs.com/privacy-notice/)"
      />{" "}
      which contains additional information on your data subject rights.
    </p>
    <h3>5. Intellectual Property</h3>
    <h3>Your Rights</h3>
    <p>
      You are granted, for the duration (and in accordance with) this EULA, a
      non-transferable, non-exclusive right to access and utilize the Services
      and the related documentation (including the Quickstart documents).
    </p>
    <p>
      Only you are authorized to use the Services within the limitations of your
      access level.
    </p>
    <p>You are, in particular, not authorized to:</p>
    <ol type="I">
      <li>
        use the Directory, Portal or Services other than for the purposes
        expressly set out in this EULA;
      </li>
      <li>
        permit a third party to use the Directory, Portal or Services except as
        provided in this EULA;
      </li>
      <li>
        modify, translate, reverse engineer, reconfigure, disassemble or
        recreate any part of the Directory, Portal or Services, or attempt to do
        so, or permit a third party to carry out (or attempt to carry out) any
        such acts;
      </li>
      <li>
        modify, alter or delete notices of the copyright, trademark, or any
        other intellectual property right appearing in or on the Directory,
        Portal or Services, or prevent their identification, or attempt to do
        so, or permit a third party to carry out (or attempt to carry out) any
        such acts;
      </li>
      <li>
        sell, lease, sublicense, dispose of, transfer or share any of the rights
        granted to you under this EULA.
      </li>
    </ol>
    <p>
      The Directory, Portal or Services may contain one or components or
      applications belonging to a third party, to which you are granted a right
      of utilization based upon the rights granted to us by the relevant third
      party owner.
    </p>
    <h3>Intellectual Property Rights</h3>
    <p>
      The Directory, Portal and Services are protected by national and
      international law and are the exclusive property of BRCGS or its
      licensors.
    </p>
    <p>
      The intellectual property rights (including copyright) and confidentiality
      rights in the Directory and Portal are protected by national and
      international law and are the exclusive property of BRCGS or its
      licensors. The underlying software or applications incorporated in the
      Services are protected by intellectual property rights (including
      copyright) and confidentiality rights of BRCGS or its licensors.
    </p>
    <p>
      This EULA will not be construed as leading to the suspension or transfer
      of any intellectual property rights belonging to BRCGS or its licensors.
    </p>
    <p>
      You will not harm, in a direct or indirect manner, the legitimate rights
      and interests of BRCGS or its licensors in relation to the Directory,
      Portal or Services.
    </p>
    <p>
      You undertake to maintain intact any copyright or other intellectual
      property rights notices relating to the Directory, Portal or Services and
      to respect the distinctive marks belonging to BRCGS or its licensors, such
      as names, marks, acronyms, logos, symbols, slogans, colours, graphics and
      the like.
    </p>
    <h3>Indemnities</h3>
    <p>
      Neither BRCGS nor its suppliers/licensors will be liable for any claim
      made against you by a third party for infringement of its intellectual
      property rights or any other rights arising out of or in connection with
      your use of the Directory, Portal or Services.
    </p>
    <p>
      If a third party claims that the Directory, Portal or Services infringe
      its intellectual property or other rights, BRCGS or its licensors will
      have sole control in addressing and defending any such claim. Should your
      licence to use the Directory, Portal or Services be lost as a result of
      any such claim, BRCGS or our licensors may, at our sole option and
      discretion (i) promptly procure a licence of the necessary intellectual
      property rights to continue your licence (at no extra cost to you) or (ii)
      modify the Directory, Portal or Services so that they do not infringe.
    </p>
    <p>
      You will indemnify BRCGS, our suppliers and licensors (“Indemnified
      Parties”) against all liabilities, costs, expenses, damages and losses
      suffered or incurred by any of the Indemnified Parties arising out of or
      in connection with: (i) any claim made against an Indemnified Party by any
      person alleging infringement of its intellectual property or other rights
      which arises out of, or is in connection with the Indemnified Party’s use
      of your Data; and (ii) any breach by you of the terms of this EULA.
    </p>
    <h3>6. Term/Renewal/Termination</h3>
    <h3>Term</h3>
    <p>
      This EULA is entered into when you click the “I agree” box and will remain
      in force for as long as you use the Portal and Services.
    </p>
    <h3>Termination</h3>
    <p>
      We may terminate this EULA at any time on notice in the event of: (i) any
      breach of this EULA by you; and (ii) on the occurrence of an insolvency
      event affecting an Authorised User. (In this paragraph an insolvency event
      means: (a) other than for the purposes of a bona fide reconstruction or
      amalgamation, the passing of a resolution for the Authorised User to be
      wound up, or a court of competent jurisdiction making an order for the
      Authorised User to be wound up or dissolved, or the Authorised User
      otherwise being dissolved; or (b) the appointment of an administrator,
      receiver or administrative receiver of, or an encumbrancer taking
      possession of or selling, the whole or any part of the Authorised User’s
      undertaking, assets, rights or revenue; or (c) the Authorised User
      entering into an arrangement, compromise or composition in satisfaction of
      its debts with its creditors; or (d) the Authorised User being unable to
      pay its debts, or being deemed unable to pay its debts, within the meaning
      of section 123 of the Insolvency Act 1986; or (e) the Authorised User
      entering into any arrangement, compromise or composition in satisfaction
      of its debts with its creditors.)
    </p>
    <p>
      Termination will take effect without the need for legal or extra-judicial
      proceedings, without prejudice to any damages which BRCGS may be entitled
      to claim.
    </p>
    <p>In the event of termination of this EULA:</p>
    <ul>
      <li>your access to the Services will immediately cease; and</li>
      <li>
        we may remove, delete or destroy any Data or relevant account. We will
        be entitled (if reasonably practicable) to receive copies of the Data
        before it is destroyed or removed.
      </li>
    </ul>
    <h3>7. Security</h3>
    <h3>Your Access to the Services</h3>
    <p>
      In order to connect to the Services you will identify yourself by using
      the access code and the password which were attributed to you at your
      first connection in accordance with your account and access level. Such
      access code and password must be safeguarded by you and remain your
      responsibility. You will take all necessary precautions to control and
      prevent unauthorized access or use.
    </p>
    <p>
      Any connection to the Services using this secure access code and password
      will be deemed to be access by you and you will accordingly be liable for
      all such access and use. We assume no liability in the event of fraudulent
      or improper use of your secure access code and password.
    </p>
    <p>
      In the event of loss or theft of your access code and/or password, you
      will have the opportunity to request from us (via our service provider) by
      means of the link “I’ve forgotten my password” the sending by email of the
      forgotten password, and/or the re-establishment of your right of access
      with a new access code if possible.
    </p>
    <h3>Confidentiality</h3>
    <p>
      You will consider as strictly confidential, all data, information or
      knowledge, regardless of form, nature or support, to which you will be
      deemed to have gained knowledge as a result of, or in connection with, the
      Services (“Confidential Information”), and undertake not to communicate or
      divulge such Confidential Information to any third party not authorized to
      receive it, unless (i) you have obtained prior express written agreement
      for its disclosure from us, or (ii) the Confidential Information is in the
      public domain without breach by you of this EULA, or (iii) you are
      required by law to disclose the Confidential Information (in which case
      you will promptly notify us of this legal requirement to disclose (to the
      extent you are permitted) and only disclose the minimum amount of
      Confidential Information required to comply with law.
    </p>
    <p>
      You are only permitted to disclose Confidential Information to other Users
      (that have an appropriate access level and are known to you) where: (i)
      the disclosure is strictly necessary for the performance of the Services,
      and (ii) such Users are subject to, or agree to be subject to, the
      relevant provisions of the EULA.
    </p>
    <h3>8. Modifications</h3>
    <h3>Services</h3>
    <p>
      We reserve the right to supplement or expand the Services, in particular,
      to make improvements to the Services offered.
    </p>
    <h3>Changes to the EULA</h3>
    <p>
      We reserve the right, at our discretion and at any time, to modify all or
      part of the terms and conditions of this EULA so as to improve service to
      Users, adapt them to new regulatory requirements or otherwise. In such
      case, a new effective date will be assigned to the revised EULA.
    </p>
    <p>
      Such revised terms and conditions of this EULA will be communicated to you
      by using any online means.
    </p>
    <h3>9. Liability</h3>
    <p>
      BRCGS, its suppliers and/or licensors will not be responsible or liable
      for any issues or difficulties associated with the exchange or transfer of
      Data via the internet, or via a third-party operator (such as an internet
      service provider). You (or such third-party operator) will be solely
      responsible and liable for the exchange and transfer of your Data.
    </p>
    <p>
      You will be fully responsible for the data processing equipment connected
      to the Portal and Services, which must comply with the terms of this EULA
      (in particular section 4). To the extent permitted by law, BRCGS, its
      suppliers and/or licensors will not be liable for any damage to your
      hardware.
    </p>
    <p>
      BRCGS, ITS SUPPLIERS AND/OR LICENSORS PROVIDE THE SERVICES “AS IS” AND
      DISCLAIM ANY AND ALL OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED,
      INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY
      OR FITNESS FOR A PARTICULAR PURPOSE, TO THE FULLEST EXTENT AUTHORIZED BY
      LAW.
    </p>
    <p>
      WITHOUT LIMITATION TO THE FOREGOING, BRCGS, ITS SUPPLIERS AND/OR LICENSORS
      EXPRESSLY DO NOT WARRANT THAT THE DIRECTORY, PORTAL OR SERVICE WILL MEET
      YOUR REQUIREMENTS OR THAT OPERATION OF ANY OF THESE WILL BE UNINTERRUPTED
      OR ERROR FREE. YOU SHALL BE SOLELY RESPONSIBILE FOR SELECTING THE SERVICE
      TO ACHIEVE YOUR INTENDED RESULTS, AND FOR THE RESULTS OBTAINED FROM YOUR
      USE OF THE SERVICE. YOU SHALL BEAR THE ENTIRE RISK AS TO THE QUALITY AND
      THE PERFORMANCE OF THE PORTAL OR SERVICE. IN NO EVENT SHALL BRCGS, ITS
      SUPPLIERS AND/OR ITS LICENSORS BE LIABLE TO YOU OR ANY PARTY RELATED TO
      YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR
      PUNITIVE DAMAGES OR LOST PROFITS, EVEN IF ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES.
    </p>
    <p>
      BRCGS gives no warranty in respect of your compliance with any relevant
      law or regulation that is connected to the Directory, Portal or Services.
      BRCGS will not be liable for your compliance or non-compliance with any
      such applicable law or regulation.
    </p>
    <p>
      Notwithstanding the foregoing, BRCGS’ total aggregate liability for any
      claim(s) made by you under this EULA will be limited to £100.
    </p>
    <p>
      Any claim and/or complaint by you arising from the performance of the
      Directory, Portal or Services must be submitted in the form of a certified
      letter with acknowledgement of receipt within a period of thirty (30)
      business days from the date of the generating act, subject to waiver.
    </p>
    <p>
      Nothing in this EULA will limit or exclude the liability of any party for
      death or personal injury arising out of negligence or any other liability
      that cannot be excluded or limited by law.
    </p>
    <h3>10. Force Majeure</h3>
    <p>
      BRCGS is not liable to Users in the event of non-performance of its
      contractual obligations in the event that such performance is delayed by
      an event of force majeure.
    </p>
    <p>
      For the purposes of this EULA, force majeure will include any unavoidable
      and unforeseen event not within the control of BRCGS, its suppliers or
      licensors, preventing the performance of BRCGS’ obligations under this
      EULA including (i) fires, floods, contagion, pandemic or other natural
      disasters, service failure by a national telecommunications operator (in
      the UK or abroad), labour disputes leading to a national or regional
      strike, (ii) involuntary bankruptcy of a supplier or partner, subject to
      proof that such bankruptcy was involuntary and/or (iii) the modification
      of any regulation applicable at the time of signing of this EULA bringing
      about such events.
    </p>
    <p>
      In the event of force majeure, BRCGS may give you notice of the force
      majeure and may suspend its contractual obligations. After the effects of
      the event of force majeure have ended, such obligations will recommence
      for the remaining term as of the date of suspension of such event. In any
      event if that event of force majeure lasts for a period exceeding sixty
      (60) days, BRCGS may terminate this EULA on notice.
    </p>
    <h3>11. Miscellaneous</h3>
    <p>
      You may not sell, assign, or otherwise transfer or sub-contract, in whole
      or in part, any of your rights and obligations arising under this EULA,
      whether for or without compensation, without the express prior
      authorization of BRCGS.
    </p>
    <p>
      We may freely transfer, assign or otherwise grant our rights and
      obligations under this EULA to any third party. In addition, we may freely
      subcontract all or a part of the Services to any third party.
    </p>
    <p>
      In addition to the means of proof admissible by applicable law, the files,
      data, messages and data processing registers contained in the data
      processing systems of each party, under reasonable security conditions,
      will be admissible as proof of the communications and agreements entered
      into between the parties. The preservation of registers will be deemed, in
      the absence of indication to the contrary, to have taken place under
      reasonable security conditions if the messages, data and documents are
      systematically archived on durable and unalterable media.
    </p>
    <p>
      By accepting this EULA, you authorise us to publicise or mention, for
      purposes of internal and external communications, your use of the
      Services. You hereby license BRCGS to use your name (and logo if relevant)
      in such publication.
    </p>
    <p>
      The terms and conditions of this EULA represent the entire agreement
      between you and BRCGS in relation to the subject matter therein.
    </p>
    <p>
      Should one of (or part of one of) the provisions of this EULA be held to
      be invalid, illegal or unenforceable under applicable law, regulation or
      subject to a final determination, it will be deemed void and not part of
      this EULA and will not lead to invalidity of the other provisions, or of
      the EULA in its entirety, which will remain unaffected. The parties will
      in good faith substitute valid and binding provisions having a similar
      effect making as little modification as necessary.
    </p>
    <p>
      You and BRCGS are independent contractors, and no agency, partnership,
      joint venture, employee-employer or franchisor-franchisee relationship is
      intended or created by this EULA.
    </p>
    <p>
      Unless expressly stated, no party who is not a party to this EULA has any
      rights pursuant to the Contract (Rights of Third Parties) Act 1999.
    </p>
    <h3>12. Waiver – Severability</h3>
    <p>
      It is expressly agreed that any waiver of enforcement of a clause or
      condition set forth in this EULA by BRCGS (regardless of the frequency or
      duration thereof) will not be construed as an amendment or elimination of
      such clause or condition, or as creating any right. We may rescind such
      waiver at any time. In addition, any provision hereof which is contrary to
      mandatory provisions of law will be deemed withdrawn without affecting the
      validity of other clauses of the rule or of the rule in question.
    </p>
    <p>
      The rights and remedies provided by this EULA may be waived only in
      writing in a manner that expressly states that such waiver is intended
      for, and such waiver will only be operative with regard to, the specific
      circumstances referred to. Any failure to exercise, or any delay in
      exercising, a right or remedy by either party will not constitute a waiver
      of that right or remedy, or of any other rights or remedies.
    </p>
    <h3>13. Governing Law</h3>
    <p>
      This EULA will be governed by and construed in accordance with English law
      and the parties submit to the exclusive jurisdiction of the English
      courts.
    </p>
  </div>
);

export default Eula;
