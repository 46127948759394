import { useEffect, useState } from "react";
import Option from "../../../../Models/forms/Option";
import axiosApi from "../../../../components/Api/Axios";
import BackendServices from "../../../../components/Api/BackendService";
import sortNameAlphanumerical from "../../../../util/sorting/sortNameAlphanumerical";

export default (): Option[] => {
  const [certificationBodyOptions, setCertificationBodyOptions] = useState<
    Option[]
  >([]);

  useEffect(() => {
    axiosApi
      .get<{ name: string; id: number }[]>(
        `${BackendServices.REPORT_SERVICE.REPORT}/data/certificationbodies`
      )
      .then(({ data }) =>
        setCertificationBodyOptions(
          data
            .sort(sortNameAlphanumerical)
            .map((val) => ({ label: val.name, value: val.id }))
        )
      );
  }, []);

  return certificationBodyOptions;
};
