enum UserRole {
  COMPANY_USER = "Company User",
  CB_READ_ONLY = "CB Read Only User",
  CB_AUDIT_ADMIN = "CB Audit Admin",
  CB_FULL_ADMIN = "CB Full Admin",
  CB_AUDITOR_ADMIN = "CB Auditor Admin",
  BRCGS_USER = "BRCGS User",
  SITE_USER = "Site User",
  AB_USER = "AB User",
  BRCGS_ADMIN = "BRCGS Admin",
  BRCGS_AUDITOR_ADMIN = "BRCGS Auditor Admin",
  BRCGS_AUDIT_ADMIN = "BRCGS Audit Admin",
  COMPANY_BI_SC_USER = "Company BI SC User",
  COMPANY_BI_S_USER = "Company BI S User",
  CB_BI_USER = "CB BI User",
  COMPANY_BI_BO_USER = "Company BI BO User",
  CB_BI_USER_T = "CB BI User T",
  ANALYTICS_USER = "Analytics User",
  CB_COMMERCIAL_HORIZON="CB Commercial Horizon",
  UNKNOWN = "UNKNOWN",
}
export default UserRole;
