import { useEffect, useState } from "react";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import Technology from "../../Models/Auditor/Technology";
import sortNameAlphanumerical from "../../util/sorting/sortNameAlphanumerical";

export default () => {
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  useEffect(() => {
    axiosApi
      .get<Technology[]>(
        `${BackendServices.AUDITOR_SERVICE.AUDITOR}/Technologies`
      )
      .then(({ data }) => setTechnologies(data.sort(sortNameAlphanumerical)));
  }, []);
  return technologies;
};
