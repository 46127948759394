import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Add, Block, Edit, Undo } from "@material-ui/icons";
import DetailsPage from "../../../components/DetailsPage";
import useGetCbDetails from "./http/useGetCbDetails";
import { IsSuspended } from "../../../components/CertificationBodyManagement/OfficeHelper";
import { DetailsPageMenuItem } from "../../../components/DetailsPage/menu-items/useMenuItems";
import axios from "../../../components/Api/Axios";
import CertificationBody from "../../../Models/Office/CertificationBody";
import BackendServices from "../../../components/Api/BackendService";
import EntityStatus from "../../../Enums/EntityStatus";
import { CBUrls } from "../index";
import SuspendWindow from "../../../components/CertificationBodyManagement/SuspendWindow";
import {
  emptySuspendWindow,
  SuspendWindowState,
} from "./models/SuspendWindowState";
import SuspendData from "./models/SuspendData";
import OfficeTable from "./components/OfficeTable";
import CBDetailsBoxes from "./components/CBDetailBoxes";
import {
  DropdownMenuItem,
  DropdownMenuItemType,
} from "../../../components/DropdownMenu";
import Permission from "../../../Authentication/model/Permission";
import PermissionArea from "../../../Authentication/model/PermissionArea";
import PermissionContext from "../../../routing/contexts/PermissionContext";
import CreateCBLocationStateProps from "../../../Models/CBProps/CreateCBLocationStateProps";

const CBDetails = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const { isLoading, office, refresh } = useGetCbDetails(id);
  const [suspendState, setSuspendState] = useState<SuspendWindowState>(
    emptySuspendWindow
  );

  const { certificationBody } = office ?? {};

  const detailMenuItems =
    certificationBody && IsSuspended(certificationBody)
      ? [DetailsPageMenuItem.CHANGE_LOG]
      : [DetailsPageMenuItem.CHANGE_LOG, DetailsPageMenuItem.ARCHIVE];
  const isMainOffice = office?.isMainOffice ?? false;
  const isArchived = office?.status !== EntityStatus.ACTIVE;

  const mapToPostBody = (): SuspendData => ({
    CertificationBodyExternalId: certificationBody?.id,
    SuspensionStatus: suspendState.type,
    RemoveFromPublicView: !suspendState.visible,
    AuditSubmissionStatus: suspendState.auditSubmissions,
  });

  const postArchive = () => {
    axios
      .post<CertificationBody>(
        `${
          BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY
        }/office/${id}/${isArchived ? "unarchive" : "archive"}`
      )
      .catch(() => {})
      .finally(refresh);
  };

  const isSuspended = !!certificationBody && IsSuspended(certificationBody);

  const createSubOfficeLinkState: CreateCBLocationStateProps = {
    fromDetailsPage: true,
    cb: certificationBody
      ? {
          value: certificationBody.id,
          label: certificationBody.mainOffice?.name ?? "",
        }
      : null,
  };

  const postSuspend = () => {
    const cbId = certificationBody?.id;
    if (!cbId) return;
    axios
      .post<SuspendData>(
        `${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/${cbId}/${
          isSuspended ? "Unsuspend" : "Suspend"
        }`,
        mapToPostBody()
      )
      .catch(() => {})
      .finally(refresh);
  };

  const getSuspensionMenuItem = (): DropdownMenuItem | null => {
    if (!certificationBody) return null;
    const text = isSuspended ? "Unsuspend" : "Suspend";

    const icon = isSuspended ? <Undo /> : <Block />;
    return {
      icon,
      label: text,
      type: DropdownMenuItemType.BUTTON_CONFIRM,
      dialogHeader: `Sure you want to ${text.toLowerCase()}. This affects all offices of the CB`,
      children: !isSuspended && (
        <SuspendWindow value={suspendState} setValues={setSuspendState} />
      ),
      onConfirm: postSuspend,
      declineButtonText: "Cancel",
      acceptButtonText: "Accept",
      permissionRequired: [
        { permission: Permission.SUSPEND, area: PermissionArea.CB },
      ],
    };
  };

  const menuItems: { show: boolean; item: DropdownMenuItem | null }[] = [
    {
      show: true,
      item: {
        icon: <Edit />,
        type: DropdownMenuItemType.LINK,
        label: `Edit ${isMainOffice ? "Main " : "Sub "}Office`,
        link: CBUrls.edit(id),
        permissionRequired: [
          { area: PermissionArea.SUB_OFFICE, permission: Permission.REVISE },
        ],
      },
    },
    {
      show: isMainOffice,
      item: {
        icon: <Edit />,
        label: "Edit CB",
        type: DropdownMenuItemType.LINK,
        link: CBUrls.editCb(certificationBody?.id ?? -1),
        permissionRequired: [{ permission: Permission.REVISE }],
      },
    },
    {
      show: isMainOffice && !isArchived && !!certificationBody,
      item: getSuspensionMenuItem(),
    },
    {
      show: isMainOffice,
      item: {
        icon: <Add />,
        label: "Add office",
        type: DropdownMenuItemType.LINK,
        state: createSubOfficeLinkState,
        link: CBUrls.create(),
        permissionRequired: [
          { area: PermissionArea.CB, permission: Permission.GENERATE },
          { area: PermissionArea.SUB_OFFICE, permission: Permission.GENERATE },
        ],
      },
    },
  ];

  return (
    <PermissionContext.Provider
      value={isMainOffice ? PermissionArea.CB : PermissionArea.SUB_OFFICE}
    >
      <DetailsPage
        title={office?.name ?? ""}
        isLoading={isLoading}
        id={id}
        detailsPageMenuItems={detailMenuItems}
        menuItems={
          menuItems
            .filter((item) => item.show && item.item)
            .map(({ item }) => item) as DropdownMenuItem[]
        }
        archiveConfig={{
          isArchived,
          entityName: "office",
          extraWarningText: office?.isMainOffice
            ? "This will also apply to all other offices."
            : "",
          onClick: postArchive,
          permissionArea: PermissionArea.CB,
        }}
        contentTabs={[
          {
            content: office?.isMainOffice && certificationBody?.id && (
              <OfficeTable
                cbId={certificationBody.id}
                createLinkState={createSubOfficeLinkState}
              />
            ),
          },
        ]}
      >
        <CBDetailsBoxes office={office} />
      </DetailsPage>
    </PermissionContext.Provider>
  );
};

export default CBDetails;
