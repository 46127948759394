import React from "react";
import { CBUrls } from "../../../../views/CBManagement";
import { LinkText } from "../../../Common/hyperlinks";
import Column from "../../../DataTable/models/Column";
import CBRow from "../models/CBRow";

const cbColumns: Column<CBRow>[] = [
  {
    title: "ID",
    field: "OfficeId",
  },
  {
    title: "Certification Body",
    field: "OfficeName",
    render: (rowData: CBRow) => (
      <LinkText
        link={CBUrls.details(rowData.OfficeId)}
        text={rowData.OfficeName}
      />
    ),
  },
  {
    title: "Office Type",
    field: "officeTypes",
    render: (data) => data.officeTypes,
  },
  {
    title: "Country",
    field: "Country",
    filtering: true,
  },
  {
    title: "Status",
    field: "OfficeStatus",
    render: (data) => data.OfficeStatus,
  },
];

export default cbColumns;
