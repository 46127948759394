import PostAuditStatus from "../http/PostAuditStatus";

interface PostAuditStatusConfig {
  readonly title: string;
  readonly showBackButton: boolean;
  readonly subtitle?: string;
  readonly fileDropText: string;
}

const defaultConfig = {
  title: "Upload audit report",
  showBackButton: true,
  fileDropText: "Drag and drop your audit report here",
};

export default (
  status: PostAuditStatus | null,
  existingData: { titleName: string; auditId: string } | null
): PostAuditStatusConfig => {
  switch (status) {
    case PostAuditStatus.Success:
    case PostAuditStatus.SuccessWithWarnings:
      return {
        ...defaultConfig,
        showBackButton: false,
        title: "Confirmation",
      };
    case PostAuditStatus.Error:
    case PostAuditStatus.Virus:
    case PostAuditStatus.Validation:
      return {
        ...defaultConfig,
        title: "Error",
        showBackButton: false,
      };
    default:
      if (existingData) {
        return {
          ...defaultConfig,
          title: `Now updating data for audit ${existingData.auditId}`,
          subtitle: existingData.titleName,
          fileDropText: `Drag and drop your new audit report for audit ${
            existingData?.auditId ?? ""
          } here`,
        };
      }
      return defaultConfig;
  }
};
