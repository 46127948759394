import React from "react";
import { LinkText } from "../../../components/Common/hyperlinks";

function TrainingCourses() {
  return (
    <div>
      <h1>BRCGS Training Courses</h1>

      <p>
        BRCGS work with a global network of over 300 Approved Training Partners
        to develop and deliver BRCGS training in your location and in your
        language.
      </p>

      <p>
        With access to BRCGS technical specialists and officially endorsed
        resources, our trainers and courses are internationally recognized as
        the best way to develop a deep understanding of the Standards and how to
        implement them.
      </p>

      <p>
        BRCGS training courses are written by experts, delivered by experts and
        designed to create experts. Whether you are looking to develop your own
        personal skill-set or simply prepare for an upcoming audit, a BRCGS
        training course should be your first choice.
      </p>

      <h1>Course Enquiries</h1>

      <p>
        To enquire about course availability in your area and language, please
        complete{" "}
        <LinkText
          link="https://form.jotform.com/200374443408046"
          text="this form"
          external
        />
        .
      </p>

      <p>
        Once submitted BRCGS or an Approved Training Provider will contact you
        to discuss your requirements and course availability.
      </p>

      <h1>Why Choose Approved?</h1>

      <h2>
        The BRCGS trainer approval program ensures all officially recognised
        partners are;
      </h2>

      <p>Appropriately qualified training professionals</p>

      <p>Experienced specialists in your industry sector</p>

      <p>Calibrated to ensure training consistency, compliance & quality</p>

      <h2>Our Approved Training Partners have exclusive access to;</h2>

      <p>BRCGS developed training course content & supporting materials</p>

      <p>
        The BRCGS Technical Team, including the experts that develop and write
        the Standards
      </p>

      <p>
        First-look access to changes & update to the Standards as they happen
      </p>

      <p>
        Audit performance analytics, to identify common training needs specific
        to your industry
      </p>

      <p>
        BRCGS&#39;s approved training partner conferences, working groups &
        forums
      </p>
    </div>
  );
}

export default TrainingCourses;
