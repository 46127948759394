import React from "react";
import Column from "../../../../../components/DataTable/models/Column";
import ShareRow from "./ShareRow";
import SharingCell from "../../SharingTable/SharingCell";
import editShare from "../../../http/editShare";
import SharingType from "../../../models/SharingType";

const shareColumns: (
  id: string,
  sharingType: SharingType,
  onComplete: (value: {
    readonly shared: boolean;
    readonly name: string;
  }) => void,
  sorting: boolean,
  checkedDefaultValue?: boolean
) => Column<ShareRow>[] = (
  id,
  sharingType,
  onComplete,
  sorting,
  checkedDefaultValue = false
) => [
  { title: "ID", field: "id", sorting },
  { title: "Name", field: "name", sorting },
  {
    title: "Type",
    field: "typeName",
    sorting,
    render: (data) => data.typeName,
  },
  { title: "Country", field: "country", sorting },
  { title: "Address", field: "address", sorting },
  {
    title: "Share",
    field: "shared",
    sorting,
    type: "boolean",
    render: (rowData) => (
      <SharingCell
        id={rowData.id}
        onShare={(share) =>
          editShare(
            sharingType,
            {
              sharedWithType: rowData.type,
              sharedWithId: rowData.id,
              sharedId: parseInt(id, 10),
            },
            share
          )
        }
        onComplete={(shared) => onComplete({ name: rowData.name, shared })}
        checked={rowData.shared ?? checkedDefaultValue}
      />
    ),
  },
];

export default shareColumns;
