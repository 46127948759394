export const ACTION_SET_ERROR = "ACTION_SET_ERROR";

export interface GlobalError {
  readonly message?: string;
}

export interface ActionSetError {
  readonly type: typeof ACTION_SET_ERROR;
  readonly payload: GlobalError;
}

export default (message: string): ActionSetError => ({
  type: ACTION_SET_ERROR,
  payload: { message },
});
