import CheckBoxItemProps from "../CommonProps/CheckBoxItemProps";
import AuditScheduleTypeData from "../APIGetData/Audits/AuditScheduleTypeData";
import Standard from "../common/Standard";
import Option from "../forms/Option";

export default interface CreateAuditScheduleState {
  standard?: Standard | null;
  site?: { id: number; name: string };
  fromAuditPage?: boolean;
  auditId?: string;
  chosenStatus: number;
  scheduledDate: Date;
  chosenModules: string[];
  chosenStandards: string[];
  scheduleTypes: AuditScheduleTypeData[];
  standards: CheckBoxItemProps[];
  modules: CheckBoxItemProps[];
  readonly certificationBody: Option | null;
}

export const defaultCreateAuditScheduleState: CreateAuditScheduleState = {
  standard: null,
  site: {
    id: 0,
    name: "",
  },
  chosenStatus: 0,
  chosenModules: [],
  chosenStandards: [],
  standards: [],
  modules: [],
  scheduleTypes: [],
  scheduledDate: new Date(),
  certificationBody: null,
};
