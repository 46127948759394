import React, { MouseEvent, PropsWithChildren } from "react";
import styles from "./TextButton.module.scss";

const TextButton = ({
  children,
  onClick,
}: PropsWithChildren<{
  readonly onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}>) => (
  <button type="button" onClick={onClick} className={styles.button}>
    {children}
  </button>
);

export default TextButton;
