import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import Snackbar from "../../components/Snackbar";
import clearError from "../../store/error/actions/clearError";

const GlobalError = () => {
  const error = useSelector((state: RootState) => state.error.error);
  const dispatch = useDispatch();
  const clear = () => {
    dispatch(clearError());
  };

  const errorMessage = error?.message ?? "";

  return (
    <Snackbar
      type="error"
      open={!!error}
      message={errorMessage}
      close={clear}
    />
  );
};

export default GlobalError;
