import { PublicFilters } from "../index";
import Option from "../../../Models/forms/Option";

export const ACTION_SET_FILTER = "ACTION_SET_FILTER";

export interface ActionSetFilter {
  readonly type: typeof ACTION_SET_FILTER;
  readonly payload: {
    readonly type: keyof PublicFilters;
    readonly filterValues: Option[];
  };
}

export default (
  type: keyof PublicFilters,
  payload: Option[]
): ActionSetFilter => ({
  type: ACTION_SET_FILTER,
  payload: { filterValues: payload, type },
});
